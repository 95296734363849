
import HmgBanner from "../../components/Home/HmgBanner";
import TeamInnovation from "../../components/Home/TeamInnovation";
import EmpoweringYourBusinessWithITSolution from "../../components/Home/empower-business-with-it-solution/EmpoweringYourBusinessWithITSolution";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import TechnologyWeWork from "../../components/Home/TechnologyWeWork/TechnologyWeWork";
import ProjectsHelpedOurClients from "../../components/Home/ProjectsHelpedOurClients";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import { useEffect } from "react";
import { HomePageData } from "../../components/jsonData/HomePageData";
import NormalCta from "../../components/cta/NormalCta";
import HmgAboutSec from "../../components/Home/HmgAbout/HmgAboutSec";
import HeadMetaSec from "../../components/HeadMetaSec";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import { useRoutes } from "react-router-dom";
import ModalFormSubmit from "../../components/Contact_us/ContactForm/ModalFormSubmit";

function Home() {
  // const pathname = useRoutes();
  // console.log(pathname)
  useEffect(() => {
    const header = document.querySelector(".th-header");
    header.classList.add("home-header");
    return () => header.classList.remove("home-header");
  }, []);
  const ctaContent={
    ctaHeading:<>From concept to code, <span>we've got you covered.</span></>,
     btnName:<>Begin Your App Adventure! <i><HiOutlineArrowNarrowRight /></i></>,
  }
  const innovatingData = {
    title:<>Crafting Uniquely Tailored <br/> <span className="text-uppercase">Solutions for Varied Sectors</span></>,
    desc:"Our expert team distills the complexities faced by a multitude of industries, delivering streamlined and easy-to-understand solutions."
  }
  const bannerData = {
    subHeading:"YOUR TRUSTED TECHNOLOGY PARTNER",
    heading:<>Fueling Imagination <br/> with the Power of Technology</>,
    desc:"Boost your business by aligning with innovative tech experts who leverage cutting-edge technology through IT consulting services, reshaping how your company is perceived.",
    HomeBannerAwards:true,
    alt:"Fueling Imagination with the Power of Technology"
  }
  const normalCtaData = {
    title:<>Don't just adapt to the digital future. <br/><span>Shape it with us.</span></>
  }
  return (
    <>
    <ModalFormSubmit/>
      <HeadMetaSec HeadSecData={HomePageData.helmetSec} />
      <HmgBanner bannerData={bannerData} />
      <HmgAboutSec />
      <TeamInnovation />
      <EmpoweringYourBusinessWithITSolution />
      <OnDemandFoodCta {...ctaContent}/>
      <ProjectsHelpedOurClients />
      <InnovatingJust />
      <TechnologyWeWork />
      <NormalCta normalCtaData ={normalCtaData} />
      <B2BSec />
      <OurClientSec />
      <OurBlogSec />
    </>
  );
}

export default Home;
