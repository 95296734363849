import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import styles from "../../../assets/css/delivery-service-feature-online.module.scss";
import { featureOnlineListData } from "./FeatureOfOnlineData";
import { Element } from "react-scroll";

const FeatureOnlineList = () => {
  return (
    <>
      {featureOnlineListData.map((elem, index) => {
        return (
          <Element
            key={index}
            name={`${elem.pid}`}
            className={`${styles.features_list}`}
          >
            <Container>
              <div className="row align-items-center">
                <div
                  className={`${elem.col} ${elem.orderImg} ${
                    elem.order == "order-lg-2" ? "text-lg-end" : ""
                  }  order-1 text-center`}
                >
                  <img
                    src={elem.imgSource}
                    alt="appImg"
                    className="img-fluid"
                  />
                </div>
                <div
                  className={`${elem.col2} ${elem.orderContent} order-2 mt-5 mt-lg-0`}
                >
                  <div>
                    <h2>{elem.title}</h2>
                    <p>{elem.content}</p>
                    <ul>
                      {elem.appLists.map((e, i) => (
                        <li key={i}>{e}</li>
                      ))}
                    </ul>
                    <Link to="/">
                      Get your Free Quote{" "}
                      <span>
                        <HiOutlineArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          </Element>
        );
      })}
    </>
  );
};
export default FeatureOnlineList;
