import React from "react";
import {
  // appDeveloperUsaItFirms2021,
  // clutch,
  // extract,
  // goodFirms,
  // softwareDenver,
  // topRatedSoftware2021,
  CmiLevel3,
  Iso9001,
  Iso90012015
} from "../../assets/img";


import styles from '../../assets/css/hmg-banner-certificate.module.scss'

const HomeBannerCertificate = () => {
  return (
    <div className={styles.hmg_banner_awards}>
        <ul>
          <li>
            <img src={CmiLevel3} alt="cliLevel" />
          </li>
          <li>
            <img src={Iso9001} alt="iso9001" />
          </li>
          <li>
            <img src={Iso90012015} alt="ios2015" />
          </li>
        </ul>
    </div>
  )
}


export default HomeBannerCertificate