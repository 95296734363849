import assets from "./../../assets/css/Banner.module.scss";

const BannerContact = ({ bannerData }) => {
  return (
    <section className={`${assets.about_banner} ${assets.contact_us_banner}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-md-9 mx-auto text-center">
            <div className={`${assets.about_banner_heading}`}>
              <h2 className={`${assets.banner_h2} text-white`}>
                {bannerData.banner_hading} <span>{bannerData.banner_span}</span>
              </h2>
              <h5>{bannerData.banner_bott_head}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BannerContact;
