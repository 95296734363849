import { Link } from "react-router-dom";
import styles from "./../../assets/css/about-dedication-sec.module.scss";

const AllServiceDedication = (Dedication) => {
  return (
    <section className={`pdtb pt-0 ${styles.dedication}`}>
      <div className="container">
        <ul className={`${styles.dedication_list}`}>
          <li className="text-center">
            <div className={styles.dedication_list_inner}>
              <h2> 100% </h2>
              <h4>Dedication and Skills</h4>
            </div>
          </li>
          <li className="text-center">
            <div className={styles.dedication_list_inner}>
              <h2> 150+</h2>
              <h4>Delivered The Best</h4>
            </div>
          </li>
        {/* </ul>
        <ul className={`${styles.dedication_return}`}> */}
          <li className="text-center">
            <div className={styles.dedication_list_inner}>
              <h2>3+ </h2>
              <h4>Industry Experience</h4>
            </div>
          </li>
          <li className="text-center">
            <div className={styles.dedication_list_inner}>
              <h2> 40% </h2>
              <h4>Customers Who Always Return</h4>
            </div>
          </li>
          <li className="text-center">
            <div className={styles.dedication_list_inner}>
              <h2>50+</h2>
              <h4>Brilliant Minds</h4>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
export default AllServiceDedication;
