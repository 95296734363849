import { Link } from "react-router-dom";
import styles from "./../../assets/css/ContactUs.module.scss";

const ContactMail = (props) => {
  return (
    <Link to={props.href} className={`${styles.company_mail}`}>
      <div className={styles.content}>
        <div>
          <h5 className="text-white">{props.touch_with}</h5>
          <h3 className="text-white">{props.Contes_mail}</h3>
        </div>
        <div className="ms-xl-5 ms-lg-4">
          <img className="d-lg-block  d-none" src={props.img_mail} alt="images" />
        </div>
      </div>
    </Link>
  );
};
export default ContactMail;
