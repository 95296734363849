import {
  FoodMarketPlace,
  RestaurantChain,
  StandAloneRestaurant,
} from "../../../assets/img";

export const BusinessModelData = [
  {
    id: 1,
    source: StandAloneRestaurant,
    title: "Standalone Restaurant",
    content:
      "Tailored online food delivery apps for standalone restaurants to boost their digital presence and streamline operations.",
  },
  {
    id: 2,
    source: FoodMarketPlace,
    title: "Food Marketplaces/Aggregators",
    content:
      "Robust food delivery apps for food marketplaces and aggregators to connect customers with multiple restaurants in one platform.",
  },
  {
    id: 3,
    source: RestaurantChain,
    title: "Restaurant Chains",
    content:
      "Customized online food delivery apps for restaurant chains to manage multiple locations, streamline orders, and enhance customer experience.",
  },
];
