import { Aimeos, BigCommerce, ChatbotAngularLogo, ChatbotPythonLogo, OSCommerce, WooCommerce, Zencart } from "../../assets/img";
import {
    FaAngular,
    FaApple, FaAws,
    FaBitbucket, FaDigitalOcean, FaDocker, FaDrupal, FaGithub, FaGitlab, FaHtml5, FaLaravel,
    FaMagento, FaNode, FaOpencart, FaPhp, FaPython, FaReact, FaSalesforce,
    FaShopify, FaVuejs, FaWordpress ,FaJsSquare
}
from "react-icons/fa";
import { BsAndroid2 } from "react-icons/bs";
import { SiRubyonrails } from "react-icons/si";

// export const ChatbotTechnologyData = [
//     {
//         id:1,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:2,
//         imgSrc:ChatbotPythonLogo,
//         alt:"python Logo"
//     },
//     {
//         id:3,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:4,
//         imgSrc:ChatbotPythonLogo,
//         alt:"python Logo"
//     },
//     {
//         id:5,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:6,
//         imgSrc:ChatbotPythonLogo,
//         alt:"python Logo"
//     },
//     {
//         id:7,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:8,
//         imgSrc:ChatbotPythonLogo,
//         alt:"python Logo"
//     },
//     {
//         id:9,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:10,
//         imgSrc:ChatbotPythonLogo,
//         alt:"python Logo"
//     },
//     {
//         id:11,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:12,
//         imgSrc:ChatbotPythonLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:13,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:14,
//         imgSrc:ChatbotPythonLogo,
//         alt:"python Logo"
//     },
//     {
//         id:15,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:16,
//         imgSrc:ChatbotPythonLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:17,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:18,
//         imgSrc:ChatbotPythonLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:19,
//         imgSrc:ChatbotAngularLogo,
//         alt:"angular Logo"
//     },
//     {
//         id:20,
//         imgSrc:ChatbotPythonLogo,
//         alt:"angular Logo"
//     },
// ]

export const ChatbotTechnologyData = [
    {
      id: 1,
      icon: <FaPython />,
      title: "Python",
      bgColor: {
        backgroundColor: "#E3CF57",
      },
    },
    {
      id: 2,
      icon: <FaPhp />,
      title: "PHP",
      bgColor: {
        backgroundColor: "#7377ad",
      },
    },
    {
      id: 3,
      icon: <FaHtml5 />,
      title: "HTML5",
      bgColor: {
        backgroundColor: "#f74a1d",
      },
    },
    {
      id: 4,
      icon: <FaReact />,
      title: "React Js",
      bgColor: {
        backgroundColor: "#5ed3f3",
      },
    },
    {
      id: 5,
      icon: <FaNode />,
      title: "Node Js",
      bgColor: {
        backgroundColor: "#57a245",
      },
    },
    {
      id: 6,
      icon: <FaAngular />,
      title: "Angular",
      bgColor: {
        backgroundColor: "#d82d2f",
      },
    },
    {
      id: 7,
      icon: <FaLaravel />,
      title: "Laravel",
      bgColor: {
        backgroundColor: "#eb4d43",
      },
    },
    {
      id: 8,
      icon: <FaDrupal />,
      title: "Drupal",
      bgColor: {
        backgroundColor: "#006fb4",
      },
    },
    {
      id: 9,
      icon: <FaJsSquare />,
      title: "Javascript",
      bgColor: {
        backgroundColor: "#f7d800",
      },
    },
    {
      id: 9,
      icon: <FaMagento />,
      title: "Magento",
      bgColor: {
        backgroundColor: "#000",
      },
    },
    {
      id: 10,
      icon: <FaOpencart />,
      title: "OpenCart",
      bgColor: {
        backgroundColor: "#28b0ee",
      },
    },
    {
      id: 11,
      icon: <BsAndroid2 />,
      title: "Android",
      bgColor: {
        backgroundColor: "#81c208",
      },
    },
    {
      id: 12,
      icon: <FaApple />,
      title: "Ios",
      bgColor: {
        backgroundColor: "#404040",
      },
    },
    {
      id: 13,
      icon: <FaWordpress />,
      title: "Wordpress",
      bgColor: {
        backgroundColor: "#007298",
      },
    },
    {
      id: 14,
      icon: <FaDocker />,
      title: "Docker",
      bgColor: {
        backgroundColor: "#2391e6",
      },
    },
    {
      id: 15,
      icon: <FaBitbucket />,
      title: "Bitbucket",
      bgColor: {
        backgroundColor: "#2580f7",
      },
    },
    {
      id: 16,
      icon: <FaGithub />,
      title: "GitHub",
      bgColor: {
        backgroundColor: "#000000",
      },
    },
    {
      id: 17,
      icon: <FaGitlab />,
      title: "GitLab",
      bgColor: {
        backgroundColor: "#db4128",
      },
    },
    {
      id: 18,
      icon: <FaAws />,
      title: "Aws",
      bgColor: {
        backgroundColor: "#f79400",
      },
    },
    {
      id: 19,
      icon: <FaSalesforce />,
      title: "Salesforce",
      bgColor: {
        backgroundColor: "#009cd9",
      },
    },
    {
      id: 20,
      icon: <FaVuejs />,
      title: "Vue Js",
      bgColor: {
        backgroundColor: "#3fb27f",
      },
    },
    {
      id: 21,
      icon: <FaDigitalOcean />,
      title: "Digital Ocean",
      bgColor: {
        backgroundColor: "#0066f7",
      },
    },
    {
      id: 22,
      icon: <SiRubyonrails/>,
      title: "Rubyonrails",
      bgColor: {
        backgroundColor: "#d30001",
      },
    },
    {
      id: 23,
      icon: <FaShopify />,
      title: "Shopify",
      bgColor: {
        backgroundColor: "#95bf46",
      },
    },
    {
      id: 24,
      imgSrc: Aimeos,
      title: "Aimeos",
    },
    {
      id: 25,
      imgSrc: BigCommerce,
      title: "BigCommerce",
    },
    {
      id: 26,
      imgSrc: OSCommerce,
      title: "OsCommerce",
    },
    {
      id: 27,
      imgSrc: WooCommerce,
      title: "WooCommerce",
    },
    {
      id: 27,
      imgSrc: Zencart,
      title: "ZenCart",
    },
  ];