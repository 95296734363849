import React from "react";
import {WebsiteDevServicesData } from "../../components/WebsiteDevServicesComponent/WebsiteDevServicesData";
import ServiceWeOffer from "../../components/WebsiteDevServicesComponent/ServiceWeOffer";
import BannerAbout from "../../components/Banner/BannerAbout";
import ECommerceOurProcess from "../../components/EcommerceDevelopmentPageComponents/ECommerceOurProcess";
import MobileAppTechnologiesWeUse from "../../components/MAD/MobileAppTechnologiesWeUse";
import WebDevWhyChooseUs from "../../components/WebsiteDevServicesComponent/WebDevWhyChooseUs";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import NormalCta from "../../components/cta/NormalCta";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import ChatbotTechnologyWeWork from "../../components/ChatbotComponents/ChatbotTechnologyWeWork";
import TeamInnovation from "../../components/Home/TeamInnovation";
import HeadMetaSec from "../../components/HeadMetaSec";


const WebsiteDevelopmentServices = () =>{
    return(
        <>
        <HeadMetaSec HeadSecData={WebsiteDevServicesData.helmetSec}/>
          <BannerAbout bannerData ={WebsiteDevServicesData.bannerData}/>
          <ServiceWeOffer/>
          <ECommerceOurProcess ourProcessData = {WebsiteDevServicesData.ourProcessData}/>
          {/* <ChatbotTechnologyWeWork technologyWeWorkData={WebsiteDevServicesData.technologyWeWorkData}/> */}
          <MobileAppTechnologiesWeUse technologyData={WebsiteDevServicesData.technologyData}/>
          {/* <TeamInnovation /> */}
          <WebDevWhyChooseUs/>
          <NormalCta normalCtaData ={WebsiteDevServicesData.normalCtaData} />
          <B2BSec />
          <InnovatingJust />
          <OurClientSec />
          <OurBlogSec />
        </>
    )
}

export default WebsiteDevelopmentServices;