import { salebannrcardman } from "../../assets/img";
import styles from "../../assets/css/salesforse/Sale_banner.module.scss";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CustomInputComponent,
  CustomTextareaComponent,
} from "../formik/FieldComponents";

const validationform = Yup.object().shape({
  name: Yup.string().required("Name is Required*"),
  email: Yup.string().required("Email is Required*"),
  phone: Yup.string().required("phone is Required*"),
  textarea: Yup.string().required("About Your Project Required*"),
});

const SaleBanner = () => {
  return (
    <section className={`${styles.Sale_banner} pdtb`}>
      <div className={`${styles.salford} container-lg container`}>
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className={`${styles.sal_heading}`}>
              <h1 className="text-white">
              Hire Salesforce Developers
              </h1>
              <p className="text-white">
                Get the most out of #1 CRM with experienced Salesforce
                developers. Hire Salesforce certified developers from us and
                leverage the benefit of digital transformation to meet emerging
                customer demands. Have a specific requirement? Relax! We’ve got
                you covered.
              </p>
              <div className={`${styles.hire_dedicate}`}>
                <div className={`${styles.banner_card}`}>
                  <img src={salebannrcardman} alt="images" />
                  <span>HIRE A DEDICATED DEVELOPER STARTING FROM</span>
                </div>
                <div className="d-flex align-items-center">
                  <h3>$25</h3>
                  <span>/HR</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className={`${styles.banner_form}`}>
              <div className={`${styles.form_heading}`}>
                <h5>Request a free quote</h5>
                <p>Guaranteed response within one business day!</p>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  textarea: "",
                }}
                validationSchema={validationform}
                enableReinitialize={true}
              >
                <Form>
                  <div className="mb-4">
                    <Field
                      name="name"
                      component={CustomInputComponent}
                      type="text"
                      placeholder="Full Name *"
                      autoComplete="off"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      name="email"
                      component={CustomInputComponent}
                      type="email"
                      placeholder="Email Address *"
                      autoComplete="off"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      name="phone"
                      component={CustomInputComponent}
                      type="text"
                      placeholder="Phone Number *"
                      autoComplete="off"
                      className="form-control"
                    />
                  </div>
                  <div>
                    <Field
                      name="textarea"
                      component={CustomTextareaComponent}
                      type="text"
                      placeholder="Meassage *"
                      autoComplete="off"
                      className={`form-control ${styles.textarea}`}
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn theme-btn mt-5">
                      Send your Inquiry{" "}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SaleBanner;
