import React from "react";
import styles from '../../assets/css/chatbot-technology-we-work.module.scss'
import { Col, Container, Row } from "react-bootstrap";
// import { ChatbotAngularLogo, ChatbotPythonLogo } from "../../assets/img";
import { ChatbotTechnologyData } from "./ChatbotTechnologyData";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ChatbotTechnologyWeWork = ({ technologyWeWorkData }) => {
    const renderTooltip = (tooltip) => <Tooltip className={styles.tooltip_content}>{tooltip}</Tooltip>;
    return (
        <section className={`pdtb ${styles.chatbot_technology_we_work} ${technologyWeWorkData?.darkTheme ? styles.darkTheme:""}`}>
            <Container>
                <Row>
                    <Col xxl={8} xl={12} className="mx-auto">
                        <div className="sub-heading-outline text-center mb-0">
                            <h2>Innovative <span>Technologies We Specialize In</span></h2>
                            <p>Explore cutting-edge solutions that drive digital transformation.</p>
                        </div>
                    </Col>
                </Row>
                {/* <div className={styles.chatbot_technology_list}>
                    {
                        ChatbotTechnologyData.map((list)=>(
                        <div className={styles.chatbot_technology_list_inner} key={list.id}>
                            <img src={list.imgSrc} alt={list.alt} />
                        </div>
                        ))
                    }
                </div> */}
            <ul className={styles.technology_list}>
            {ChatbotTechnologyData.map((elem, i) => (
              <li key={i}>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip(elem.title)}
                >
                  <div className={styles.technology_icon}>
                    {elem.icon && <span style={elem.bgColor}>{elem.icon}</span>}
                    {elem.imgSrc && <img src={elem.imgSrc} alt={elem.title} />}
                  </div>
                </OverlayTrigger>
              </li>
            ))}
          </ul>
            </Container>
        </section>
    )
}

export default ChatbotTechnologyWeWork;