import React from "react";
import styles from "../../../assets/css/fantasy-feature.module.scss";
import { Container } from "react-bootstrap";
import Panel from "./Panel";

const FantasyFeature = () => {
  return (
    <section className={`pdtb ${styles.fantasy_feature}`}>
      <Container>
        <div className="row">
          <div className="col-lg-10 mx-auto text-center sub-heading">
            <h2>
              Fantasy Sports App Development <span>Features</span>
            </h2>
            <p className="mb-0">
              Our fantasy sports app development company offers custom solutions
              tailored to your unique requirements. Learn more about our
              development features.
            </p>
          </div>
        </div>
        <Panel />
      </Container>
    </section>
  );
};

export default FantasyFeature;
