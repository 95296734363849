import React from "react";
import BannerAbout from "../../components/Banner/BannerAbout";
// import MobileApplicatioServices from "../../components/MAD/MobileApplicatioServices";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import ImgContentSec from "../../components/services/ImgContentSec";
import FeaturesAppNeeds from "../../components/MAD/FeaturesAppNeeds";
import ECommerceOurProcess from "../../components/EcommerceDevelopmentPageComponents/ECommerceOurProcess";
import MobileAppDevelopmentServices from "../../components/MAD/MobileAppDevelopmentServices";
import MobileAppPlatform from "../../components/MAD/MobileAppPlatform";
import MobileAppTechnologiesWeUse from "../../components/MAD/MobileAppTechnologiesWeUse";
import { mobileApplicationData } from "../../components/MAD/mobileApplicationData";
import HeadMetaSec from "../../components/HeadMetaSec";


const MobileAppDevelopment = ()=>{
    return(
        <>
          <HeadMetaSec HeadSecData={mobileApplicationData.helmetSec}/>
          <BannerAbout bannerData={mobileApplicationData.bannerData} />
          <ImgContentSec imgContentSec={mobileApplicationData.imgContentSec} />
          <MobileAppDevelopmentServices sliderServiceData={mobileApplicationData.sliderServiceData}/>
          <MobileAppPlatform/>
          <MobileAppTechnologiesWeUse technologyData={mobileApplicationData.technologyData}/>
          {/* <MobileApplicatioServices/> */}
          <OnDemandFoodCta {...mobileApplicationData.ctaContent}/>
          <FeaturesAppNeeds/>
          <ECommerceOurProcess ourProcessData = {mobileApplicationData.ourProcessData}/>
          <InnovatingJust />
          <OurClientSec />
          <OurBlogSec />
        </>
    )

}


export default MobileAppDevelopment