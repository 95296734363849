import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styles from "../../../assets/css/fantasy-partner.module.scss";
import { partnerFantasyData } from "./PartnerFantasyData";

const PartnerFantasy = () => {
  const [data, setData] = useState(partnerFantasyData);
  return (
    <>
      <section className={`pdtb ${styles.fantasy_sports}`}>
        <Container>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-7 sub-heading">
              <h2>{data.title}</h2>
              <p className="mb-0">{data.desc}</p>
              <button className="btn theme-btn">Talk to a Consultant</button>
            </div>
            <div className="col-lg-5">
              <ul className="d-flex flex-wrap">
                {data.list.map((elem, index) => {
                  return (
                    <li key={index}>
                      <div className={styles.sports_list}>
                        <img
                          src={elem.src}
                          className="img-fluid"
                          alt={elem.sportName}
                        />
                        <p>{elem.sportName}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PartnerFantasy;
