import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styles from "../../../assets/css/delivery-service-latest-design.module.scss";

const LatestDesigns = ({ mobileSliderData }) => {
  const settings = {
    dots: false,
    arrows: false,
    vertical: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <section className={`pdtb ${styles.latest_designs}`}>
        <Container>
          <div className="row">
            <div className="col-lg-8 sub-heading mx-auto text-center">
              <h2>{mobileSliderData.heading}</h2>
              <p>{mobileSliderData.desc}</p>
            </div>
          </div>
          <div className={`mobile-slider ${styles.slider}`}>
            <Slider {...settings}>
              {mobileSliderData.screenList.map((elem, index) => (
                <div key={index}>
                  {" "}
                  <img
                    src={elem.src}
                    alt={elem.alt}
                    className="m-auto img-fluid"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default LatestDesigns;
