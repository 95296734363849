import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import * as Yup from "yup";
import { footerbg } from "../../assets/img/";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Container } from "react-bootstrap";
import { FaArrowRightLong } from 'react-icons/fa6';
// import { PhoneInput } from "react-international-phone";
// import "react-international-phone/style.css";
import { Link, NavLink } from "react-router-dom";
import styles from "../../assets/css/footer.module.scss";
import FormikForm from "../../components/Contact_us/ContactForm/Formikform";
import {
  CustomCheckbox, CustomInputComponent, CustomTextareaComponent
} from "../../components/formik/FieldComponents";
import { headerMegaMenuData } from "../../components/jsonData/Data";
import {
  CompanyContactDetail,
  CopyrightLink,
  SocialLink,
} from "../../components/jsonData/FooterData";
import { FooterFormField } from "../../components/jsonData/FooterFormField";

const validation = Yup.object().shape({
  name: Yup.string().required("Name is Required*"),
  email: Yup.string().required("Email is Required*"),
  // mobileNumber: Yup.number().required("Mobile Number is Required*"),
  skype: Yup.string().required(),
  textarea: Yup.string().required(),
});

const Footer = () => {
  const [phone, setPhone] = useState("");
  return (
    <>
      <footer className={styles.th_ftr}>
        <img src={footerbg} className={styles.footer_bg_img} alt="footerBg" />
        <Container>
          <div className={`${styles.th_ftr_innr}`}>
            <div className="row">
              <div className={`${styles.ftr_cntct_lft} col-xl-5`}>
                <h2>
                  <span className={styles.fr_letter}>Contact</span>
                  <span className={styles.lst_letter}>TODAY!</span>
                </h2>
                <div>
                  <ul>
                    {CompanyContactDetail.map((contact, i) => (
                      <li key={i}>
                        <img
                          src={contact.icon}
                          className="lazyload"
                          alt={contact.imgAlt}
                        />
                        <div>
                          <p>{contact.title}</p>
                          {Array.isArray(contact.contactList) ? (
                            contact.contactList.map((list, i) => (
                              <Link to={list.href} key={i}>
                                <img
                                  src={list.src}
                                  className="lazyload"
                                  alt={list.alt}
                                />
                                {list.PhoneNumber}
                              </Link>
                            ))
                          ) : (
                            <Link to={contact.contactList.href}>
                              {contact.contactList.email}
                            </Link>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={`${styles.ftr_cntct_rght} col-xl-7 foot-ber`}>
                <h3>Share your requirements with us!</h3>
                <FormikForm formFieldData={FooterFormField}/>

              </div>
            </div>
          </div>
          <div className={styles.footer_service_link_sec}>
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <h3>Digital Transformation</h3>
                <ul className={styles.digital_transformation_service}>
                  {headerMegaMenuData[0].serviceList.map((list, i) => (
                    <li key={i}>
                      {" "}
                      <span>
                        <BsArrowRight />
                      </span>
                      <Link to={list.href}>{list.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <h3>Digital Marketing</h3>
                <ul>
                  {headerMegaMenuData[1].serviceList.map((list, i) => (
                    <li key={i}>
                      {" "}
                      <span>
                        <BsArrowRight />
                      </span>
                      <Link to={list.href}>{list.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <h3>Startup Consulting</h3>
                <ul>
                  {headerMegaMenuData[2].serviceList.map((list, i) => (
                    <li key={i}>
                      {" "}
                      <span>
                        <BsArrowRight />
                      </span>
                      <Link to={list.href}>{list.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={`${styles.th_ftr_innr_cpyright}`}>
            <div className="row ftr-bttm-lne align-items-center">
              <div className="col-lg-7 order-2 order-lg-1 row ms-0 me-0">
                <p
                  className={`${styles.Copyright_text} text-center text-lg-start mb-0 mb-lg-2 mt-2 mt-lg-0 ps-0 pe-0 order-2 order-lg-1`}
                >
                  © Copyright 2018 - 2023 -{" "}
                  <Link to="https://scrumdigital.com">
                    Scrum Digital Pvt Ltd.
                  </Link>
                  All Rights Reserved.
                </p>
                <ul
                  className={`${styles.left_list} ps-0 pe-0 order-1 order-lg-2`}
                >
                  {CopyrightLink.map((link, i) => (
                    <li key={i}>
                      <Link
                        to={link.href}
                        target={link.blank ? "_blank" : "_self"}
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-5 order-1 order-lg-2">
                <ul className={`${styles.right_icon}`}>
                  <p>Follow us on</p>
                  {SocialLink.map((link, i) => (
                    <li key={i}>
                      <Link to={link.href} target="_blank">
                        <i className={styles.tp_icn}>{link.socialIcon}</i>
                        <i className={styles.bt_icn}>{link.socialIcon}</i>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
