import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../assets/css/delivery-service-business.module.scss";
import { Container } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { BusinessModelData } from "./BusinessModelData";

const BusinessModel = () => {
  return (
    <section className={`pdtb ${styles.business_modal}`}>
      <Container>
        <div style={{ position: "relative", zIndex: "2" }}>
          <div className="row">
            <div
              className={` col-lg-10 sub-heading text-center mx-auto ${styles.sub_heading}`}
            >
              <h2 className="text-white">
                Online Food Delivery App Development for <br />
                <span>All Business Models</span>
              </h2>
              <p className="text-white">
                From traditional restaurants to giant food marketplaces, our
                expertise spans across diverse food delivery models to help you
                succeed in the ever-evolving food industry.
              </p>
            </div>
          </div>
          <ul className="row">
            {BusinessModelData.map((elem, index) => {
              return (
                <li key={index} className="col-lg-4 col-sm-6 col-12 mx-auto">
                  <div className={styles.business_item}>
                    <img src={elem.source} alt="chef" />
                    <h2>{elem.title}</h2>
                    <p>{elem.content}</p>
                    <Link to="/">
                      Book Free Demo{" "}
                      <span>
                        <BsArrowRight />
                      </span>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default BusinessModel;
