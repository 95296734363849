import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from '../../assets/css/chatbot-benefits.module.scss'
import { FaRegHandPointRight } from "react-icons/fa";
const WhyChooseUsAiMl = () => {
    return (
        <section className={`pdtb ${styles.chatbot_benefits}`}>
            <Container className="container-lg">
                <Row>
                    <Col lg={6} >
                        <div className="sub-heading-outline">
                            <h2>
                                <span className="text-uppercase">WHY CHOOSE US</span> for AI solutions?
                            </h2>
                            <p>
                            We assist your team and customers with rich insights, real-time stats, and automation with our best, customized chatbot solutions.
                            </p>
                        </div>
                        <div className={`mb-0 ${styles.chatbot_benefits_list}`}>
                            <i>
                                <FaRegHandPointRight />
                            </i>
                            <h5>Tailored AI Solutions</h5>
                            <p>
                                We craft personalized AI strategies that align perfectly with your unique business needs and objectives, ensuring your app stands out.
                            </p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={styles.chatbot_benefits_right_sec}>
                            <div className={styles.chatbot_benefits_list}>
                                <i>
                                    <FaRegHandPointRight />
                                </i>
                                <h5>Innovative Approach</h5>
                                <p>
                                    Our commitment to innovation means your app benefits from the latest AI advancements, keeping you ahead in a rapidly evolving digital landscape.
                                </p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i>
                                    <FaRegHandPointRight />
                                </i>
                                <h5>User-Centric Design</h5>
                                <p>
                                    We prioritize your users, integrating AI to enhance user experience with personalized interactions that drive engagement and satisfaction.
                                </p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i>
                                    <FaRegHandPointRight />
                                </i>
                                <h5>Ethical and Responsible AI</h5>
                                <p>
                                We adhere to ethical AI practices, ensuring our solutions are transparent, fair, and designed with user privacy and trust at the forefront.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default WhyChooseUsAiMl;