import React from "react";
import styles from "../../assets/css/hmg-banner.module.scss";
import { hmgBannerImg } from "../../assets/img";
import Modal_form from "../Banner/Modal";
// import {
//   appDeveloperUsaItFirms2021,
//   clutch,
//   extract,
//   goodFirms,
//   softwareDenver,
//   topRatedSoftware2021,
// } from "../../assets/img";
import HomeBannerCertificate from "./HomeBannerCertificate";

const HmgBanner = ({ bannerData }) => {
  return (
    <>
      <section className={styles.hmg_banner}>
        <div className="container-lg">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <h6 className="text-white text-uppercase">{bannerData?.subHeading}</h6>
              <h1 className="text-white">{bannerData?.heading}</h1>
              <p className="text-white">{bannerData?.desc}</p>
              <div>
                <Modal_form
                  customClass={`${styles.theme_btn}`}
                  // btnName="Talk to a Consultant"
                  btnName={bannerData.bannerBtn?bannerData.bannerBtn:"Talk to a Consultant"}
                />

              </div>
            </div>
            <div className={`col-lg-5 col-md-8 mx-auto text-center text-lg-end ${styles.banner_right_img}`}>
              <img src={bannerData.bannerImg ? bannerData.bannerImg : hmgBannerImg} className="img-fluid" alt={bannerData.alt ? bannerData.alt:"homeBanner"} />
            </div>
          </div>
            {
              bannerData?.HomeBannerAwards &&
              <HomeBannerCertificate />
            }
        </div>
      </section>
    </>
  );
};

export default HmgBanner;
