import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../../assets/css/service-enhanced-mobility-solutions.module.scss";
import {  DigitalMarketingSeoService, application, chatbot, websiteDevelopmentServiceIcon } from "../../assets/img";

const EnhancedMobilitySolution = ({ enhanceMobilityData }) => {
  return (
    <>
      <section className={`pdtb ${styles.enhance_mobility}`}>
        <Container>
          <div className="row justify-content-center">
            <div
              className={`col-lg-10 sub-heading text-center ${styles.sub_heading}`}
            >
              <h2 className="">{enhanceMobilityData.heading}</h2>
              <p className="">{enhanceMobilityData.desc}</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 mt-5">
              <Link to="/mobile-application-development" className={`d-flex align-items-start ${styles.what_we_offer_card}`}>
                <img src={application} alt="mobile application"/>
                <div className={styles.right_content_sec}>
                    <h4>Mobile <span>Application</span></h4>
                    <p className="mb-0">
                      Improve your online experience by utilising our custom mobile application services, which are made to realise your ideas and engage consumers while they're on the go with simple, user-friendly interfaces and innovative features.
                    </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 mt-5">
              <Link to="/website-development-company" className={`d-flex align-items-start ${styles.what_we_offer_card}`}>
                <img src={websiteDevelopmentServiceIcon} alt="website development"/>
                <div className={styles.right_content_sec}>
                    <h4>Website <span>Development</span></h4>
                    <p className="mb-0">
                    Transform your online presence with our website development services, creating visually stunning and highly functional websites tailored to meet your unique needs, ensuring a seamless user experience.</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 mt-5">
              <Link to="/digital-marketing-services" className={`d-flex align-items-start ${styles.what_we_offer_card}`}>
                <img src={DigitalMarketingSeoService} alt="crm"/>
                <div className={styles.right_content_sec}>
                    <h4>Digital <span>Marketing</span></h4>
                    <p className="mb-0">Propel your brand to new heights with our comprehensive digital marketing strategies, leveraging SEO, social media, and content marketing to enhance your online visibility, engage your audience, and drive growth.</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 mt-5">
              <Link to="/chatbot-development-services" className={`d-flex align-items-start ${styles.what_we_offer_card}`}>
                <img src={chatbot} alt="chatbot development"/>
                <div className={styles.right_content_sec}>
                    <h4>Chatbot <span>Development Services</span></h4>
                    <p className="mb-0">Revolutionize your customer interactions with our chatbot development services, offering personalized, AI-powered chatbots that provide instant, 24/7 support and engagement, streamlining communication and improving user satisfaction.</p>
                </div>
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EnhancedMobilitySolution;
