import {
  AlcoholDelivery,
  CourierDelivery,
  ECommerceDelivery,
  FoodDelivery,
  GroceryDelivery,
  MedicineDelivery,
} from "../../../assets/img";

export const SellYourBusinessData = [
  {
    alt: "courier",
    src: CourierDelivery,
    title: "COURIER DELIVERY",
    href: "#",
  },
  {
    alt: "food",
    src: FoodDelivery,
    title: "FOOD DELIVERY",
    href: "#",
  },
  {
    alt: "ECommerce",
    src: ECommerceDelivery,
    title: "ECommerce DELIVERY",
    href: "#",
  },
  {
    alt: "grocery",
    src: GroceryDelivery,
    title: "GROCERY DELIVERY",
    href: "#",
  },
  {
    alt: "medicine",
    src: MedicineDelivery,
    title: "MEDICINE DELIVERY",
    href: "#",
  },
  {
    alt: "alcohol",
    src: AlcoholDelivery,
    title: "ALCOHOL DELIVERY",
    href: "#",
  },
];
