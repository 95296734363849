// import "react-international-phone/style.css";
import styles from "./../../assets/css/ContactUs.module.scss";
import { FormField } from "./ContactForm/FormData";
import Formikform from "./ContactForm/Formikform.jsx";

const ContactForm = () => {
  return (
    <section className={`pdtb ${styles.contact_form}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="sub-heading text-center">
              <h2>
                Discuss in detail <span>about your project </span> with us!
              </h2>
              <p>
                Let’s partner for engineering something incredible together,
                because if it is technology it has to be us. Call us, mail us or
                let’s discuss your project over a cup of coffee.
              </p>
            </div>
            <Formikform formFieldData={FormField} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactForm;
