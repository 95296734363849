import {
  FantasyScreen1,
  FantasyScreen2,
  FantasyScreen3,
  FantasyScreen4,
  FantasyScreen5,
  FantasyScreen6,
  FantasyScreen7,
} from "../../assets/img";

export const FantasyAppDevData = {
  helmetSec: {
    title: "Fantasy Sports App Development Company: Launch Your Dreamy App Now",
    description:
      "Scrum Digital is a leading fantasy sports app and web development company offering custom solutions to take your business to the next level. Contact us today!",
    keywords:
      "growth consultant, growth consultancy services, consulting growth, business growth strategy consultant",
    conical: "/services/fantasy-sports-app-development",
  },
  MobileScreenSliderSec: {
    heading: (
      <>
        {" "}
        A Showcase of <span>Our Best Projects</span>
      </>
    ),
    desc: "Explore our portfolio & watch the incredible impact of our digital technology solutions in Fantasy App Development. Dive into the world of captivating digital experiences as we create innovative & immersive apps for our clients.",
    screenList: [
      {
        alt: "screen",
        src: FantasyScreen1,
      },
      {
        alt: "screen",
        src: FantasyScreen2,
      },
      {
        alt: "screen",
        src: FantasyScreen3,
      },
      {
        alt: "screen",
        src: FantasyScreen4,
      },
      {
        alt: "screen",
        src: FantasyScreen5,
      },
      {
        alt: "screen",
        src: FantasyScreen6,
      },
      {
        alt: "screen",
        src: FantasyScreen7,
      },
    ],
  },
  faqSec: {
    title: (
      <>
        <span>FAQ's</span> for Fantasy Sports App Development
      </>
    ),
    desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
    inner: [
      {
        id: 1,
        question:
          "What services does your fantasy sports app development company offer?",
        answer:
          "We specialize in custom fantasy sports app development, including Fantasy Cricket, Football, Kabaddi, Basketball and many more. We offer end-to-end solutions, from concept to launch, with advanced features and seamless user experience.",
      },
      {
        id: 2,
        question:
          "How is your fantasy sports app development company different from others?",
        answer:
          "We are a leading fantasy app development company with expertise in the fantasy sports industry. We offer a personalized approach, advanced features, and a deep understanding of the market. Our team is skilled in developing scalable, secure, and user-friendly apps.",
      },
      {
        id: 3,
        question: "Can you develop a fantasy sports app like Dream11?",
        answer:
          "Yes, we can develop a fantasy sports app similar to Dream11, with custom features and functionalities. Our team can create a unique and engaging user experience that aligns with your business goals.",
      },
      {
        id: 4,
        question: "What features can be included in a sports fantasy app?",
        answer:
          "Our sports fantasy app can include features such as user registration and authentication, player selection, live score updates, team management, payment gateway integration, social media sharing, leaderboards, and more.",
      },
      {
        id: 5,
        question: "How long does it take to develop a fantasy sports app?",
        answer:
          "The development timeline depends on the complexity and features of the app. Our team can provide you with a detailed timeline based on your requirements and project scope, ensuring timely delivery without compromising on quality.",
      },
      {
        id: 6,
        question: "Do you provide post-launch support for the app?",
        answer:
          "Yes, we offer post-launch support and maintenance services to ensure the smooth functioning and performance of the app. We provide timely updates, bug fixes, and technical support to keep your app up-to-date and optimized.",
      },
      {
        id: 7,
        question: "Is my app idea and data secure with your company?",
        answer:
          "Yes, we ensure complete confidentiality and security of your app idea and data. We sign non-disclosure agreements (NDAs) to protect your intellectual property and follow industry best practices for data security and privacy.",
      },
    ],
    Note: (
      <>
        <strong> Note:</strong> For specific inquiries or more information,
        please contact us directly.
      </>
    ),
  },
};
