import styles from "../../assets/css/service/businessgrowth.module.scss";
import Modal_form from "../Banner/Modal";

const ServiceCta = () => {
  return (
    <section className={`${styles.cta_sec}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="service-heading text-center text-white">
              <h2 className="mb-4 mb-md-5">
                Experience the full spectrum of online marketing advantages with
                our digital services.
              </h2>
              {/* <p className="text-white mb-5">
            Get in touch with the Scrum Digital team to learn more about our
            services and how you can leverage these services for your business
            growth.
          </p> */}
              <Modal_form btnName="Get a Proposal" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ServiceCta;
