import React, { useState, useEffect } from "react";
import styles from "../../../assets/css/hmg-technology-we-work.module.scss";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { TechnologyData } from "./TechnologyWeWorkData";
import { FaAngular, FaBootstrap, FaHtml5, FaNode, FaPhp, FaPython, FaReact, FaVuejs } from "react-icons/fa";
import { SiRubyonrails, SiFlask, SiFastapi, SiFlutter, SiKotlin, SiXamarin, SiApachecordova, SiJquery } from "react-icons/si";


const TechnologyWeWork = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(TechnologyData);
  });
  return (
    <>
      <section
        className={`pdtb ${styles.technology_we_work}`}
      >
        <Container>
          <div className="sub-heading-outline text-center mb-2">
            <h2>{data.heading}</h2>
            <p>{data.desc}</p>
          </div>
          <Row>
            <div className="col-lg-6">
              <div className={styles.technology_we_work_innr}>
                <h4 className="text-center">Front-End</h4>
                <ul className="row justify-content-center">
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                    {/* <img src={hmgTechIhtml5} alt="technology" /> */}
                    <span><FaHtml5 /></span>
                    <h5>HTML5</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                    {/* <img src={hmgTechItypeScript} alt="technology" /> */}
                    <span style={{ backgroundColor:"#0865a7" }}><SiJquery /></span>
                    <h5>Jquery</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                    {/* <img src={hmgTechIreactJs} alt="technology" /> */}
                    <span style={{ backgroundColor:"#5ed3f3" }}><FaReact /></span>
                    <h5>React Js</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <span style={{ backgroundColor:"rgb(216, 45, 47)" }}><FaAngular /></span>
                    <h5>Angular js</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                    {/* <img src={hmgTechIvueJs} alt="technology" /> */}
                    <span style={{ backgroundColor:"rgb(63, 178, 127)" }}><FaVuejs /></span>
                    <h5>Vue</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <span style={{ backgroundColor:"#7952b3" }}><FaBootstrap /></span>
                    <h5>Bootstrap</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.technology_we_work_innr}>
                <h4 className="text-center">Back-End</h4>
                <ul className="row justify-content-center">
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <span style={{ backgroundColor:"rgb(87, 162, 69)" }}><FaNode /></span>
                    <h5>Node js</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <span style={{ backgroundColor:"rgb(115, 119, 173)" }}><FaPhp /></span>
                    <h5>PHP</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <span style={{ backgroundColor:"#d30001" }}><SiRubyonrails /></span>
                    <h5>Ruby on Rails</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <span style={{ backgroundColor:"#42adc1" }}><SiFlask /></span>
                    <h5>Flask</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <span style={{ backgroundColor:"#059386" }}><SiFastapi /></span>
                    <h5>FastAPI</h5>
                  </li>
                  <li className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <span style={{ backgroundColor:"#f7c93d" }}><FaPython /></span>
                    <h5>Python</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className={styles.technology_we_work_innr}>
                <h4 className="text-center">Android</h4>
                <ul className="row justify-content-center">
                  <li className="col-lg-6 col-md-6 col-sm-4 col-6">
                  <span style={{ backgroundColor:"#c110da" }}><SiKotlin /></span>
                    <h5>Kotlin</h5>
                  </li>
                  <li className="col-lg-6 col-md-6 col-sm-4 col-6">
                  <span style={{ backgroundColor:"#f7c93d" }}><FaPython /></span>
                    <h5>Python</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className={styles.technology_we_work_innr}>
                <h4 className="text-center">iOS</h4>
                <ul className="row justify-content-center">
                <li className="col-lg-6 col-md-6 col-sm-4 col-6">
                <span style={{ backgroundColor:"#5ac2f0" }}><SiFlutter /></span>
                    <h5>Flutter</h5>
                </li>

                  <li className="col-lg-6 col-md-6 col-sm-4 col-6">
                  <span style={{ backgroundColor:"#5ed3f3" }}><FaReact /></span>
                    <h5>React Native</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={styles.technology_we_work_innr}>
                <h4 className="text-center">Cross-Platform</h4>
                <ul className="row justify-content-center">
                  <li className="col-lg-6 col-md-6 col-sm-4 col-6">
                  <span style={{ backgroundColor:"#8a18c2" }}><SiXamarin /></span>
                    <h5>Xamarin</h5>
                  </li>
                  <li className="col-lg-6 col-md-6 col-sm-4 col-6">
                  <span style={{ backgroundColor:"#344250" }}><SiApachecordova /></span>
                    <h5>Apache Cordova</h5>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
          <div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default TechnologyWeWork;
