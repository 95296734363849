import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export function toastSuccess(message){
    toast.success(message, {theme:"colored"})
}
export function toastError(message){
    toast.error(message, {theme:"colored"})
}
export function toastWarning(message){
    toast.warning(message, {theme:"colored"})
}