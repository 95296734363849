import React, { useState, useEffect } from "react";
import OfficeAddressList from "./OfficeAddressList";
import styles from "./../../../assets/css/ContactUs.module.scss";
import { OfficeAddressData } from "./OfficeAddressData";
import { Container } from "react-bootstrap";
const CardCountry = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(OfficeAddressData);
  }, []);
  return (
    <section className={`pdtb ${styles.office_address_sec}`}>
      <Container>
        <ul className="row justify-content-center">
          <OfficeAddressList officeAddressData={list} />
        </ul>
      </Container>
    </section>
  );
};
export default CardCountry;
