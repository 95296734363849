import {
  AutoDelivery,
  InAppChat,
  Offer,
  Payment,
  RatingReview,
  VendorRoles,
} from "../../../assets/img";

export const AdvanceFeatureOnlineRestaurantData = [
  {
    id: 1,
    title: "Auto-Delivery Assignment",
    src: AutoDelivery,
    content:
      "Streamline your delivery operations with our advanced auto-delivery assignment feature. Our solution optimizes the delivery process by automatically assigning orders to available drivers based on their location and capacity.",
  },
  {
    id: 2,
    title: "Sub-Admin/Vendor Roles",
    src: VendorRoles,
    content:
      "Efficiently manage your staff & vendors with our sub-admin/vendor roles functionality. Our solution provides hierarchical access and control, allowing you to assign specific roles and permissions to different users.",
  },
  {
    id: 3,
    title: "Rating & Reviews",
    src: RatingReview,
    content:
      "Build trust and transparency with our comprehensive ratings and reviews features. Our solution allows customers to rate and review restaurants based on their experiences, helping you showcase your reputation and quality of service.",
  },
  {
    id: 4,
    title: "In-App Chat",
    src: InAppChat,
    content:
      "Facilitate seamless communication between customers, drivers, and restaurants with our in-app chat feature. Our solution enables real-time updates, customization options & issue resolution, ensuring smooth communication and superior customer service.",
  },
  {
    id: 5,
    title: "Payments & Commissions",
    src: Payment,
    content:
      "Ensure secure & transparent financial transactions with our integrated payments and commissions system. Our solution allows customers to make online payments, while enabling restaurants and drivers to receive their commissions in a seamless manner.",
  },
  {
    id: 6,
    title: "Discounts & Offers",
    src: Offer,
    content:
      "Boost your sales with our flexible discounts & offers management features. Our solution allows restaurants to create and manage promotions, discounts, and offers, helping you drive customer loyalty and increase revenue.",
  },
];
