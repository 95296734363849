import {
  saleprcsinquery,
  salescreening,
  saleInterview,
  salehire,
} from "../../../assets/img";

export const ProcessOfHiringData = {
  heading: (
    <>
      Process of Hiring <span> Salesforce Developer</span>
    </>
  ),
  desc: " Our goal is to provide top-notch Salesforce services, so we handpick the ideal team of Salesforce experts to meet your needs.",
  hiringProcessList: [
    {
      sNo: "01",
      title: "Inquiry",
      desc: "Your project is discussed. The requirement is analyzed and the scope of service is defined. Assuring confidentiality at all levels.",
      src: saleprcsinquery,
    },
    {
      sNo: "02",
      title: "Screening",
      desc: "You get to screen and shortlist Salesforce developers as per your criteria of skillset and experience.",
      src: salescreening,
    },
    {
      sNo: "03",
      title: "Interview",
      desc: "You interview the candidate testing their knowledge, aptitude, and communication on a phone or video call.",
      src: saleInterview,
    },
    {
      sNo: "04",
      title: "Hire",
      desc: "Hire the selected candidate and pool them in as your resource to remotely work on your project as a dedicated expert.",
      src: salehire,
    },
  ],
};
