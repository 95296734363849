import React from "react";
import { Container, Table } from "react-bootstrap";
import styles from "../../assets/css/service/Serviceneeds.module.scss";

const ServiceNeed = ({}) => {
  return (
    <>
      <section className={`pdtb ${styles.serviceneed}`}>
        <Container>
          <div className="service-heading text-center">
            <h2>Packages for Digital Marketing </h2>
            <p>
              Scrum Digital offers a range of pricing packages from premium to
              affordable options to choose from without straining your budget.
            </p>
          </div>
          <div className="digital-marketing-packages-table">
            <Table responsive bordered>
              <thead>
                <tr>
                  <th></th>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <th key={index} width="20%">
                      <h4>Bronze</h4>
                      <h2>
                        <sup>$</sup>599<sub>/Month</sub>
                      </h2>
                      <button className="theme-btn">Request proposal</button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <td key={index}>Table cell {index}</td>
                  ))}
                </tr>
                <tr>
                  <td>2</td>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <td key={index}>Table cell {index}</td>
                  ))}
                </tr>
                <tr>
                  <td>3</td>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <td key={index}>Table cell {index}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      </section>
    </>
  );
};
export default ServiceNeed;
