import {
  FoodDeliveryServiceBannerImg,
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
} from "../../assets/img";

export const FoodDeliveryAppDevData = {
  helmetSec: {
    title:
      "Best Food Delivery App Development Company: Scale Your Food Business",
    description:
      "Scrum Digital one of the best food delivery app development company in the USA. Our expert solutions helps you develop a feature-rich app that meets your needs.",
    keywords: "",
    conical: "/food-delivery-app-development",
  },
  bannerSec: {
    heading: (
      <>
        On-Demand Food Delivery <span>App Development Company</span>
      </>
    ),
    desc: (
      <>
        <p>
          ScrumDigital is a cutting-edge On-Demand Food Delivery App Development
          Company. We specialize in creating bespoke, user-friendly, and
          feature-rich apps that cater to the dynamic needs of the modern food
          delivery industry.
        </p>
      </>
    ),
    src: FoodDeliveryServiceBannerImg,
  },
  onlineRestaurantSec: {
    desc: "Experience the unparalleled power of advanced features for online restaurant app development, designed to enhance your customers' user experience like never before.",
  },
  MobileScreenSliderSec: {
    heading: (
      <>
        {" "}
        Check Out The <span>Latest Designs</span> Of Our Food Delivery App
        Solutions
      </>
    ),
    desc: "Our visually captivating and intuitive designs guarantee a smooth and hassle-free online ordering experience for customers, drivers, and restaurant partners alike.",
    screenList: [
      {
        alt: "screen",
        src: Screen1,
      },
      {
        alt: "screen",
        src: Screen2,
      },
      {
        alt: "screen",
        src: Screen3,
      },
      {
        alt: "screen",
        src: Screen4,
      },
      {
        alt: "screen",
        src: Screen5,
      },
      {
        alt: "screen",
        src: Screen6,
      },
      {
        alt: "screen",
        src: Screen7,
      },
    ],
  },
  faqSec: {
    title: (
      <>
        <span>FAQ's</span> for Application Development
      </>
    ),
    desc: "If you cannot find the answer to your question in the FAQs provided, feel free to reach out to our team of experts for assistance.",
    inner: [
      {
        id: 1,
        question:
          "Why should I choose a professional food delivery app development company for building my on-demand food delivery app?",
        answer:
          "Hiring a professional food delivery app development company ensures that you get a high-quality and feature-rich app that is customized to meet your specific business requirements. They have expertise in developing mobile apps for the food delivery industry, and can provide you with end-to-end solutions, from concept to deployment, to help you launch a successful food delivery business.",
      },
      {
        id: 2,
        question:
          "Can I develop my own food delivery mobile app without any technical knowledge?",
        answer:
          "Yes, you can develop your own food delivery app without any technical knowledge by using app development platforms and tools that require no coding. These platforms provide easy-to-use drag-and-drop interfaces and pre-built templates, allowing you to create a custom food delivery app with minimal effort and investment.",
      },
      {
        id: 3,
        question:
          "What are the key features that should be included in a food delivery app?",
        answer:
          "A food delivery app should have features such as a user-friendly interface for placing orders, real-time order tracking, multiple payment options, restaurant listings with menus, ratings and reviews, push notifications, and customer support. Additionally, features like social media integration, loyalty programs, and discounts can also enhance the overall user experience.",
      },
      {
        id: 4,
        question:
          "What is the typical cost and timeline for developing a food delivery app?",
        answer:
          "The cost and timeline for developing a food delivery app depends on various factors such as the complexity of the app, the features included, the platform (iOS, Android, or both), and the development approach (native or hybrid). You can connect with us to discuss your requirements.",
      },
      {
        id: 5,
        question:
          "How can a custom food delivery application development benefit my business?",
        answer:
          "Custom food delivery application development allows you to build a unique app that caters specifically to your business requirements and branding. It provides you with the flexibility to add custom features, integrate with third-party APIs, and create a differentiated user experience, giving you a competitive edge in the food delivery market.",
      },
      {
        id: 6,
        question:
          "What is the recommended business model for an online food delivery app?",
        answer:
          "The recommended business model for an online food delivery app can be either commission-based or subscription-based. In a commission-based model, the app charges a percentage of the order value from the restaurants for every successful order. In a subscription-based model, the restaurants pay a monthly or annual subscription fee to be listed on the app.",
      },
      {
        id: 7,
        question:
          "What are some food delivery solutions available for small and medium-sized businesses?",
        answer:
          "There are several food delivery solutions available for small and medium-sized businesses, such as white-label food delivery apps, third-party food delivery platforms, and cloud kitchen solutions.",
      },
    ],
  },
};
