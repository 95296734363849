import Modal from "../Banner/Modal";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CustomInputComponent,
  CustomTextareaComponent,
} from "../formik/FieldComponents";
import styles from "../../assets/css/service/servicebanner.module.scss";
import FormikForm from "../Contact_us/ContactForm/Formikform";
import { FormField } from "../Contact_us/ContactForm/FormData";

const serviceform = Yup.object().shape({
  name: Yup.string().required("Name is Required*"),
  email: Yup.string().required("Email is Required*"),
  phone: Yup.string().required("phone is Required*"),
  title: Yup.string().required("Title is Required*"),
  business: Yup.string().required("Business is Required*"),
  address: Yup.string().required("Address Name is Required*"),
  textarea: Yup.string().required("About Your Project Required*"),
});

const ServiceBanner = ({}) => {
    // const serviceBannerForm =[
    //     {
    //         id: 1,
    //         name: "name",
    //         type: "text",
    //         placeholder: "Full Name",
    //         as: "input",
    //         col:"col-lg-6",
    //         label:"Full Name"
    //       },
    //       {
    //         id: 2,
    //         name: "email",
    //         type: "email",
    //         placeholder: "Email Address",
    //         as: "input",
    //         col:"col-lg-6",
    //         label:"Email Address"
    //       },
    //       {
    //         id: 3,
    //         name: "skype",
    //         type: "text",
    //         placeholder: "Skype",
    //         as: "input",
    //         col:"col-lg-6",
    //         label:"Skype"
    //       },
    //       {
    //         id: 4,
    //         name: "contactNo",
    //         type: "number",
    //         placeholder: "Mobile Number",
    //         as: "input",
    //         col:"col-lg-6",
    //         label:"Mobile Number"
    //       },
    //       {
    //         id: 5,
    //         name: "businessName",
    //         type: "text",
    //         placeholder: "Business Name",
    //         as: "input",
    //         col:"col-lg-6",
    //         label:"Business Name"
    //       },
    //       {
    //         id: 6,
    //         name: "address",
    //         type: "text",
    //         placeholder: "Address",
    //         as: "input",
    //         col:"col-lg-6",
    //         label:"Address"
    //       },
    //       {
    //         id: 7,
    //         name: "textarea",
    //         type:"textarea",
    //         placeholder: "Message",
    //         as: "textarea",
    //         label:"Message"
    //       },
    // ]

  return (
    <section className={`${styles.serviceBan}`}>
      <div className={`${styles.servicecontainer} container-lg container`}>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className={`${styles.service_banner_heading}`}>
              <h2 className="text-white"><span>Digital Marketing</span> Packages</h2>
              <h6 className="text-white">
              Step into the digital spotlight with our tailored marketing solutions. Discover packages designed to elevate your brand, starting at an unbeatable $599. Where excellence meets affordability – let's amplify your online presence together! Embark on a transformative journey with us and watch your vision come to life.
              </h6>
              <div className={styles.pricing_content}>
                <h4>$599.<span>00</span></h4>
                <span className={styles.first}>Starting From</span>
                <span className={styles.second}>Per Month</span>
              </div>
            </div>
            <Modal btnName="Schedule Free Discovery Call Now" />
          </div>
          <div className="col-lg-6">
            <div className={`${styles.form_formik}`}>
              <div className={`${styles.form_heading}`}>
                <h5>Get A Free SEO Report</h5>
                <p>
                Embark on an SEO adventure with our FREE in-depth report and competitor analysis. Dive in with packages beginning at only $599.
                </p>
              </div>
              {/* <div className={styles.form_banner}>
                <FormikForm formFieldData={serviceBannerForm} />
              </div> */}
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  title: "",
                  business: "",
                  address: "",
                  textarea: "",
                }}
                validationSchema={serviceform}
                enableReinitialize={true}
              >
                <Form className="row">
                  <div className="col-sm-6">
                    <div className={styles.form_banner}>
                      <label>Full Name</label>
                      <Field
                        name="name"
                        component={CustomInputComponent}
                        type="text"
                        placeholder="Full Name"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={styles.form_banner}>
                      <label>Email Address</label>
                      <Field
                        name="email"
                        component={CustomInputComponent}
                        type="email"
                        placeholder="Email Address"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className={styles.form_banner}>
                      <label>Title</label>
                      <Field
                        name="title"
                        component={CustomInputComponent}
                        type="text"
                        placeholder="title"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className={styles.form_banner}>
                      <label>Mobile Number</label>
                      <Field
                        name="phone"
                        component={CustomInputComponent}
                        type="text"
                        placeholder="Phone Number"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className={styles.form_banner}>
                      <label>Business Name</label>
                      <Field
                        name="business"
                        component={CustomInputComponent}
                        type="text"
                        placeholder="Business Name"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className={styles.form_banner}>
                      <label>Address</label>
                      <Field
                        name="address"
                        component={CustomInputComponent}
                        type="text"
                        placeholder="Address"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className={styles.form_banner}>
                      <label>Website URL</label>
                      <Field
                        name="websiteUrl"
                        component={CustomInputComponent}
                        type="text"
                        placeholder="Website URL"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className={styles.form_banner}>
                      <label>Meassage</label>
                      <Field
                        name="textarea"
                        component={CustomTextareaComponent}
                        type="text"
                        placeholder="Meassage *"
                        autoComplete="off"
                        className={`form-control ${styles.textarea}`}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn theme-btn mt-5">
                      Get this offer
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ServiceBanner;
