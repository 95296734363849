import React from "react";
import BannerAbout from "../../components/Banner/BannerAbout";
import { webAppDevData } from "../../components/WebAppDevelopmentComponent/WebAppDevData";
import MobileAppDevelopmentServices from "../../components/MAD/MobileAppDevelopmentServices";
import WhyChooseUsServices from "../../components/services/WhyChooseUsService";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import ImgContentSec from "../../components/services/ImgContentSec";
import WebAppSecurity from "../../components/WebAppDevelopmentComponent/WebAppSecurity";
import ECommerceOurProcess from "../../components/EcommerceDevelopmentPageComponents/ECommerceOurProcess";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import HeadMetaSec from "../../components/HeadMetaSec";

const WebApplicationDevelopmentServices = () =>{


    return(
        <>
        <HeadMetaSec HeadSecData={webAppDevData.helmetSec}/>
        <BannerAbout bannerData ={webAppDevData.bannerData}/>
        <ImgContentSec imgContentSec={webAppDevData.imgContentSec1} />
        <MobileAppDevelopmentServices sliderServiceData={webAppDevData.sliderServiceData}/>
        <OnDemandFoodCta {...webAppDevData.ctaContent}/>
        <WhyChooseUsServices whyChooseUsService ={webAppDevData.whyChooseUs}/>
        <WebAppSecurity/>
        <ECommerceOurProcess ourProcessData = {webAppDevData.ourProcessData}/>
        <InnovatingJust/>
        <B2BSec />
        <OurClientSec />
        <OurBlogSec />

        </>
    )
}

export default WebApplicationDevelopmentServices