import {
  appDeveloperUsaItFirms2021,
  clutch,
  extract,
  goodFirms,
  softwareDenver,
  topRatedSoftware2021,
  topDevelopmentCompanies,
  topDevelopersLogo,
  itFirmClient,
  appFutureClient,
  designRushDevelopmentClient,
  designRushDigitalMarketingClient,
  expertiseAward,
  softwareDevelopmentImg,
  appDevelopmentCompanyClient,
  appFutureBadge,
  itratecoClient,
} from "../../../assets/img";

export const B2BCompaniesData = {
  heading: (
    <>
      Recognized Excellence in <span className="text-uppercase">B2B Services</span>
    </>
  ),
  desc: "Celebrating our esteemed accolades from industry-leading platforms.",
  b2bCompanyTechnologyLogo: [
    {
      id: 1,
      href: "#",
      src: appDeveloperUsaItFirms2021,
      alt: "App Developer Usa It Firms 2021 logo",
    },
    {
      id: 2,
      href: "#",
      src: softwareDenver,
      alt: "Software Denver logo",
    },
    {
      id: 3,
      href: "#",
      src: topRatedSoftware2021,
      alt: "Top Rated Software 2021 logo",
    },
    {
      id: 4,
      href: "#",
      src: appFutureClient,
      alt: "App Developer Usa ItF irms 2021 logo",
    },
    {
      id: 5,
      href: "#",
      src: extract,
      alt: "Extract logo",
    },
    {
      id: 6,
      href: "https://clutch.co/profile/scrum-digital#highlights",
      src: clutch,
      alt: "Clutch logo",
    },
    {
      id: 7,
      href: "#",
      src: goodFirms,
      alt: "Good Firms logo",
    },
    {
      id: 8,
      href: "#",
      src: itFirmClient,
      alt: "software Denver logo",
    },
    {
      id: 9,
      href: "#",
      src: topDevelopersLogo,
      alt: "Top Rated Software 2021 logo",
    },
    {
      id: 10,
      href: "#",
      src:  designRushDigitalMarketingClient,
      alt: "Good Firms logo",
    },
    {
      id: 11,
      href: "#",
      src: appDeveloperUsaItFirms2021,
      alt: "App Developer Usa It Firms 2021 logo",
    },
    {
      id: 12,
      href: "#",
      src: topDevelopmentCompanies,
      alt: "Top Development Companies logo",
    },
    {
      id: 13,
      href: "#",
      src: expertiseAward,
      alt: "expertiseAward",
    },
    {
      id: 14,
      href: "#",
      src: softwareDevelopmentImg,
      alt: "softwareDevelopmentImg",
    },
    {
      id: 15,
      href: "#",
      src: appDevelopmentCompanyClient,
      alt: "expertiseAward",
    },
    {
      id: 16,
      href: "#",
      src: appFutureBadge,
      alt: "expertiseAward",
    },
    {
      id: 17,
      href: "#",
      src: itratecoClient,
      alt: "expertiseAward",
    },
  ],
};
