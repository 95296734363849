import { PortfolioBanner } from "../../assets/img";

export const PortfolioPageData = {
  helmetSec: {
    title: "App Development Company | Portfolio | Scrum Digital",
    description:
      "Have a look at the highlights of our web development and mobile app development portfolio and see why our clients love our work. We have delivered successful projects on time to many brands.",
    keywords: "",
    conical: "/portfolio",
  },
  bannerSec: {
    bannerHeading:<>Scrum Digital <span>Portfolio</span></>,
    banner_bott_head:
      "Investing your capital in a portfolio having greater capabilities, rewards your business's potential and growth",
    bannerImg: PortfolioBanner,
    alt: "portfolio",
  },
};
