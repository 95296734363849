import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../../assets/css/service-img-content-sec.module.scss";

const ImgContentSec = ({ imgContentSec }) => {
  return (
    <>
      <section className={`pdtb ${styles.img_content_sec}`}>
        <Container>
          <div className="row align-items-center">
            <div
              className={`col-xl-6  order-md-2 order-sm-2 order-2 ${
                imgContentSec.orderImg ? "text-end " : "order-lg-1"
              }`}
            >
              <img
                src={imgContentSec.src}
                alt={imgContentSec.alt}
                className="img-fluid"
              />
            </div>
            <div
              className={`col-xl-6 ${
                imgContentSec.orderContent ? "" : "order-lg-2"
              } order-md-1 order-sm-1 order-1`}
            >
              <div className="sub-heading">
                <h2>{imgContentSec.heading}</h2>
                {imgContentSec.desc}
              </div>
              <ul className="col-lg-12" style={imgContentSec.listColumn}>
                {imgContentSec.list.map((list, index) => (
                  <li key={index}>
                    {list.href ? (
                      <Link to={list.href}>{list.name}</Link>
                    ) : (
                      list.name
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ImgContentSec;
