import React from "react";
import FantasyBanner from "../../components/FantasyAppDevService/FantasyAppBanner";
import TrustedBy from "../../components/DeliveryServices/TrustedBy/TrustedBy";
import PartnerFantasy from "../../components/FantasyAppDevService/PartnerFantasy/PartnerFantasy";
import FantasySportsDevServices from "../../components/FantasyAppDevService/FantasySportsDevServices/FantasySportsDevServices";
import FantasyFeature from "../../components/FantasyAppDevService/FantasyFeature/FantasyFeature";
import FantasyCta from "../../components/FantasyAppDevService/FantasyCta";
import MobileScreenSlider from "../../components/DeliveryServices/MobileScreenSlider/MobileScreenSlider";
import FAQ from "../../components/services/FAQ";
import { FantasyAppDevData } from "../../components/jsonData/FantasyAppDevData";
import HeadMetaSec from "../../components/HeadMetaSec";

const FantasySportsAppDevService = () => {

  return (
    <>
      <HeadMetaSec HeadSecData={FantasyAppDevData.helmetSec} />
      <FantasyBanner />
      <TrustedBy />
      <PartnerFantasy />
      <FantasySportsDevServices />
      <FantasyFeature />
      <FantasyCta />
      <MobileScreenSlider
        mobileSliderData={FantasyAppDevData.MobileScreenSliderSec}
      />
      <FAQ faqData={FantasyAppDevData.faqSec} />
    </>
  );
};

export default FantasySportsAppDevService;
