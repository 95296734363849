import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FormField } from "../Contact_us/ContactForm/FormData";
import Formikform from "../Contact_us/ContactForm/Formikform.jsx";
import assets from "./../../assets/css/Modal.module.scss";
const Modal_form = (props) => {
  const [showModalForm, setShowModalForm] = useState(false);

  return (
    <>
      <Button
        className={`theme-btn ${props.customClass}`}
        onClick={() => setShowModalForm(true)}
      >
        {props.btnName}
      </Button>
      <Modal
        size="lg"
        show={showModalForm}
        onHide={() => setShowModalForm(false)}
        aria-labelledby="example"
        className="contact-form-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example">
            <div className={`${assets.modal_heading}`}>
              <h5>Contact Us</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formikform formFieldData={FormField} modalHide={setShowModalForm} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Modal_form;
