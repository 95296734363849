import Slider from "react-slick";
import styles from "./salesforce-slider.module.scss";
import { SliderData } from "./SalsesforceSliderData";
const Saleslidercertified = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <section className={`${styles.Sale_slide}`}>
      <div className="container">
        <div className={`${styles.slider_certified}`}>
          <Slider {...settings}>
            {SliderData.map((elem, i) => (
              <div key={i}>
                <img src={elem.src} alt={elem.alt} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default Saleslidercertified;
