import styles from "../../../assets/css/salesforse/Process-Salesforce.module.scss";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { ProcessOfHiringData } from "./ProcessOfHiringData";

const ProcessOfHiring = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(ProcessOfHiringData);
  }, []);
  return (
    <section className={`pdtb ${styles.Process_developer}`}>
      <Container>
        <div className="sub-heading text-center">
          <h2>{data.heading}</h2>
          <p className={`${styles.heading_pra}`}>{data.desc}</p>
        </div>
        <ul className={`${styles.enquiry_list} row`}>
          {data.hiringProcessList &&
            data.hiringProcessList.map((elem, i) => (
              <li className="col-lg-6 col-md-6" key={i}>
                <div className={`${styles.enquiry_proses}`}>
                  <h4>{elem.sNo}</h4>
                  <div>
                    <h6>{elem.title}</h6>
                    <p>{elem.desc}</p>
                  </div>
                </div>
                <img src={elem.src} alt={elem.title} />
              </li>
            ))}
        </ul>
      </Container>
    </section>
  );
};
export default ProcessOfHiring;
