import React from "react";
import styles from "../../../assets/css/fantasy-feature.module.scss";
import { PanelList } from "./FantasyFeatureData";

const Panel = () => {
  return (
    <>
      {PanelList.map((list, i) => (
        <div className={`row ${styles.panel}`} key={i}>
          <div className={`col-lg-6 ${list.orderContentSec}`}>
            <div
              className={`${list.designLeft ? styles.design_left : ""} ${
                styles.content_sec
              }`}
            >
              <h3>{list.title}</h3>
              <p>{list.desc}</p>
              <ul>
                {list.listInner.map((list, index) => (
                  <li key={index}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={`col-lg-6 ${list.orderImgSec} ${
              list.designLeft && "text-center text-lg-start"
            }`}
          >
            {list.design}
          </div>
        </div>
      ))}
    </>
  );
};

export default Panel;
