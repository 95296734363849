import React from "react";
import { Container } from "react-bootstrap";
import styles from "../../assets/css/service-about-service.module.scss";
import { Affordable, Agile, Longevity, Team } from "../../assets/img";
import AboutServiceCard from "./AboutServiceCard";

const DigitalTransformation = ({ aboutServiceSec }) => {
  const aboutServiceCardData = [
    {
      id:1,
      imgSrc:Team,
      alt:"team",
      title:<>Experienced <span>Team</span></>,
      desc:"Working for our Client's Success."
    },
    {
      id:2,
      imgSrc:Longevity,
      alt:"Longevity",
      title:<>Longevity</>,
      desc:"Creating Long Term Value"
    },
    {
      id:3,
      imgSrc:Affordable,
      alt:"Affordable",
      title:<>Affordable <span>Strategies</span></>,
      desc:"Cost Effective with High Pay Off"
    },
    {
      id:4,
      imgSrc:Agile,
      alt:"Agile",
      title:<>Agile <span>Process</span></>,
      desc:"Dynamic Business Solutions"
    },
  ]
  return (
    <>
      <section className={`pdtb ${styles.about_service_sec}`}>
        <Container>
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div className={`sub-heading ${styles.sub_heading}`}>
                <h2>{aboutServiceSec.title}</h2>
                {aboutServiceSec.desc}
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                {/* <ul className={`d-flex justify-content-between ${styles.cards_container}`}>
                  <li>
                    <div className={styles.card_inner}>
                      <div className={`${styles.card_icon}`}>
                        <img src={Team} alt="team" />
                      </div>
                      <h5>
                        Experienced <span>Team</span>
                      </h5>
                      <p>Working for our Client's Success.</p>
                    </div>
                  </li>

                  <li>
                    <div className={styles.card_inner}>
                      <div className={`${styles.card_icon}`}>
                        <img src={Longevity} alt="agile" />
                      </div>
                      <h5>
                        <span>Longevity</span>
                      </h5>
                      <p>Creating Long Term Value</p>
                    </div>
                  </li>
                  <li>
                    <div className={styles.card_inner}>
                      <div className={`${styles.card_icon}`}>
                        <img src={Affordable} alt="affordable" />
                      </div>
                      <h5>
                        Affordable <span>Strategies</span>
                      </h5>
                      <p>Cost Effective with High Pay Off</p>
                    </div>
                  </li>
                  <li>
                    <div className={styles.card_inner}>
                      <div className={`${styles.card_icon}`}>
                        <img src={Agile} alt="agile" />
                      </div>
                      <h5>
                        Agile <span>Process</span>
                      </h5>
                      <p>Dynamic Business Solutions</p>
                    </div>
                  </li>
                </ul> */}
                <div className={`d-flex justify-content-between flex-wrap ${styles.cards_container}`}>
                {
                  aboutServiceCardData.map((list)=><AboutServiceCard key={list.id} cardData ={list}/>)
                }
                  {/* <AboutServiceCard/>
                  <AboutServiceCard/>
                  <AboutServiceCard/>
                  <AboutServiceCard/> */}
                </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DigitalTransformation;
