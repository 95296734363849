import React from "react";
import styles from "../../../assets/css/delivery-service-feature-online-res.module.scss";
import { Container } from "react-bootstrap";
import { AdvanceFeatureOnlineRestaurantData } from "./FeatureOnlineRestaurantData";

const AdvanceFeatureOnlineRestaurant = ({ onlineRestaurant }) => {
  return (
    <>
      <section className={`pdtb ${styles.online_restaurants}`}>
        <Container>
          <div className="row justify-content-center">
            <div className={`col-lg-10 sub-heading ${styles.sub_heading}`}>
              <h2>
                Advanced Features for <span>Online Restaurant</span> App
                Development
              </h2>
              <p>{onlineRestaurant.desc}</p>
            </div>
          </div>
          <ul className="row">
            {AdvanceFeatureOnlineRestaurantData.map((elem, index) => {
              return (
                <li key={index} className="col-lg-4 col-md-6 col-sm-6">
                  <div className={styles.online_restaurants_inner}>
                    <img src={elem.src} alt={elem.title} />
                    <h3>{elem.title}</h3>
                    <p>{elem.content}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </Container>
      </section>
    </>
  );
};
export default AdvanceFeatureOnlineRestaurant;
