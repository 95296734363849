
import styles from "./../../assets/css/about-our-vision.module.scss";

const AboutVision = (vision) => {
  return (
    <section className={`pdtb ${styles.about_vision}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className={`${styles.img_box} `}>
              <img className="img-fluid" src={vision.vision_img} alt="about page our vision" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className={`${styles.our_content}`}>
              <div className="sub-heading">
                <h2>
                  Our <span> Vision</span>
                </h2>
                <p>
                  Our Vision is to create design-driven, scalable, and
                  customer-centric business solutions that drive innovation
                  through digital excellence. We seek to be a name associated
                  with endless possibilities and forward-thinking technology
                  strategists that supports your future business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutVision;
