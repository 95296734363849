import React, { useEffect, useState } from "react";

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type = "text",
  ...props
}) => (
  <div>
    <input type={type} className="form-control" {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <small className="text-danger">{errors[field.name]}</small>
    )}
  </div>
);

export const CustomTextareaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type = "text",
  ...props
}) => (
  <div>
    <textarea type={type} className="form-control" {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <small className="text-danger">{errors[field.name]}</small>
    )}
  </div>
);

export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options = [],
  initialOption = "Select an option",
  ...props
}) => (
  <div>
    <select className="form-control" {...field} {...props}>
      {/* <option value="">{initialOption}</option> */}
      {options.map((option) => (
        <option key={option.value} value={option.value} slug={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {touched[field.name] && errors[field.name] && (
      <small className="text-danger">{errors[field.name]}</small>
    )}
  </div>
);

export const CustomRadioComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options = [],
  onChange, // added onChange prop
  ...props
}) => {
  const handleRadioChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setFieldValue(field.name, event.target.value);
  };

  return (
    <div className="form-group">
      {options.map((option) => (
        <div key={option.value} className="react-radio radio-inline mr-15">
          <label className="form-check-label">
            <input
              type="radio"
              className="form-check-input"
              {...field}
              {...props}
              checked={field.value === option.value}
              value={option.value}
              onChange={handleRadioChange} // pass event to handler
            />
            <span>{option.label}</span>
          </label>
        </div>
      ))}
      {touched[field.name] && errors[field.name] && (
        <div className="text-danger">{errors[field.name]}</div>
      )}
    </div>
  );
};

export const CustomCheckbox = ({
  field,
  form: { touched, errors },
  label,
  onChange,
  ...props
}) => {
  // const { setFieldValue } = useFormikContext();

  // const handleChange = (evt) => {
  //   const { value } = evt.target;
  //   setFieldValue(field.name, value);
  // };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }

    field.onChange({
      target: {
        name: field.name,
        value: !field.value,
      },
    });
  };

  return (
    <div className="react-checkbox">
      <label>
        <input
          type="checkbox"
          {...field}
          {...props}
          onChange={handleChange}
          checked={Boolean(field.value)}
        />
        <span>{label}</span>
      </label>
      {touched[field.name] && errors[field.name] && (
        <small className="text-danger">{errors[field.name]}</small>
      )}
    </div>
  );
};

export const CustomCheckboxGroup = ({
  options,
  field,
  multiline,
  form: { touched, errors },
  ...props
}) => {
  return (
    <>
      <div className={multiline == "yes" ? "multiline" : "singleline"}>
        {options.map((option, index) => (
          <div className="react-checkbox" key={option.value}>
            <label>
              <input
                type="checkbox"
                id={`${field.name}-${index}`}
                name={field.name}
                value={option.value}
                checked={field.value.includes(option.value)}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = [...field.value];
                  if (isChecked) {
                    newValue.push(option.value);
                  } else {
                    const index = newValue.indexOf(option.value);
                    if (index !== -1) {
                      newValue.splice(index, 1);
                    }
                  }
                  field.onChange({
                    target: { name: field.name, value: newValue },
                  });
                }}
                {...props}
              />
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </div>
      {touched[field.name] && errors[field.name] && (
        <small className="text-danger">{errors[field.name]}</small>
      )}
    </>
  );
};
