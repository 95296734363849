import React from "react";
import styles from './service-we-offer.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import { applicationDevWebAppService, ormServiceIcon, ppcServiceIcon, seoServiceIcon, smoServiceIcon, socialMediaMarketingServiceIcon, websiteAssessmentServiceIcon, websiteDevelopmentServiceIcon, websiteMockUpServiceIcon, websiteRedesignServiceIcon } from "../../assets/img";
import Modal_form from "../Banner/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";



const ServiceWeOffer = () => {
    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 992,
    //           settings: {
    //             slidesToShow: 2,
    //             dots: true,
    //             arrows:false
    //           },
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //               slidesToShow: 1,
    //               dots: true,
    //               arrows:false
    //             },
    //           },
    //       ],
    //   };
    const serviceWeOfferList = [
        {
            id:1,
            imgSrc:websiteAssessmentServiceIcon,
            alt:"Website Assessment",
            title:<><span>Website Assessment</span></>,
            desc:"Get an in-depth evaluation where we assess the existing condition of your website to identify important areas that need to be improved, guaranteeing a determined approach for digital optimisation."
        },
        {
            id:2,
            imgSrc:websiteMockUpServiceIcon,
            alt:"Website mockup creation",
             title:<><span>Website Mock-up Creation</span></>,
            desc:"We create extensive website mock-ups that combine creative design with strategic planning to make sure the foundation of your website is set up for success and user interaction."
        },
        {
            id:3,
             title:<><span>Website Development</span></>,
             imgSrc:websiteDevelopmentServiceIcon,
            alt:"Website development",
            desc:"Your digital goals become a reality with Scrum Digital's innovative websites, which combine functionality and visual appeal to propel growth and improve your online presence."
        },
        {
            id:4,
            imgSrc:websiteRedesignServiceIcon,
            alt:"Website Redesign",
             title:<><span>Website Redesign / Modernization</span></>,
            desc:"Stay at the forefront of digital innovation with Scrum Digital's website redesign and modernization services, ensuring your site remains engaging, contemporary, and aligned with the latest trends."
        },
        {
            id:5,
            imgSrc:seoServiceIcon,
            alt:"seo",
             title:<><span>Search Engine Optimization</span></>,
            desc:"Elevate your online presence with our expert SEO strategies, designed to boost your visibility and rankings on search engine results pages, driving organic traffic to your site."
        },
        {
            id:6,
            imgSrc:smoServiceIcon,
            alt:"smo",
             title:<><span>Social Media Optimization</span></>,
            desc:"We optimize your social media profiles and content to increase reach, drive traffic, and foster meaningful interactions with your audience. Our goal is to ensure that your brand maintains a strong presence in the ever-evolving social media landscape."
        },
        {
            id:7,
             title:<><span>Online Reputation Management</span></>,
             imgSrc:ormServiceIcon,
            alt:"orm",
            desc:"Protect and enhance your brand's online reputation with our comprehensive ORM services, monitoring digital conversations and addressing negative content to maintain a positive brand image."
        },
        {
            id:8,
            imgSrc:ppcServiceIcon,
            alt:"ppc",
             title:<><span>Pay-Per-Click Advertising</span></>,
            desc:"Achieve immediate visibility and targeted traffic with our PPC services, creating and managing ads that convert visitors into customers, optimizing for the best possible return on investment."
        },
        {
            id:9,
            imgSrc:socialMediaMarketingServiceIcon,
            alt:"Social Media Marketing",
             title:<><span>Social Media Marketing</span></>,
            desc:"From content creation and campaign management to analytics and optimization, we help you achieve your marketing objectives and maximize your ROI through effective social media marketing campaigns."
        },
    ]
    return(
        <section className={`pdtb ${styles.service_we_offer} website_development_service_we_offer`}>
            <Container>
                <div className="sub-heading text-center">
                    <h2>Our Specialized <span>Offerings</span></h2>
                    <p>
                        Explore our comprehensive suite of specialized services,
                        starting with expert website development, followed by
                        a bundle of offerings aimed at boosting your online presence
                        and driving business growth.
                    </p>
                </div>
                {/* <Row className={styles.services_list}>
                    <Col lg={4}>
                        <div className={styles.service_item}>
                            <img src={applicationDevWebAppService} alt="service" />
                            <h3>ECommerce <span>Development</span></h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className={styles.service_item}>
                            <img src={applicationDevWebAppService} alt="service" />
                            <h3>ECommerce <span>Development</span></h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className={styles.service_item}>
                            <img src={applicationDevWebAppService} alt="service" />
                            <h3>ECommerce <span>Development</span></h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>

                        </div>
                    </Col>
                </Row> */}
                {/* <Slider {...settings}>
                {
                    serviceWeOfferList.map((list, i)=>(
                        <div key={i} className={styles.services_list}>
                        <div className={styles.service_item}>
                            <img src={applicationDevWebAppService} alt="service" />
                            <h3>{list.title}</h3>
                            <p>
                                {list.desc}
                            </p>

                        </div>
                        </div>
                    ))
                }

                </Slider> */}
                <Row>
                {
                    serviceWeOfferList.map((list, i)=>(
                       <Col lg={4} md={6} key={i} className={styles.services_list}>
                        <div className={styles.service_item}>
                            <img src={list?.imgSrc} alt={list.alt} />
                            <h3>{list.title}</h3>
                            <p>
                                {list.desc}
                            </p>

                        </div>
                       </Col>
                    ))
                }

                </Row>
                <div className={`text-center mt-5`}>
                    <Modal_form btnName="Get In Touch" />
                </div>
            </Container>
        </section>
    )
}

export default ServiceWeOffer
