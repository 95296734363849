import {
  BasketBall,
  Cricket,
  Football,
  Hockey,
  Kabaddi,
  Tennis,
} from "../../../assets/img";

export const partnerFantasyData = {
  title: (
    <>
      One-stop <span>Fantasy Sports App Solution</span> Partner for All Games
    </>
  ),
  desc: "As fantasy app development experts, we create innovative solutions for sports and gaming. Our skilled team stays up-to-date with the latest tech and trends to deliver customized apps for football, basketball, cricket, and more. Trust us for top-notch fantasy sports apps that engage users and elevate your gaming experience to new heights.",
  list: [
    {
      id: 1,
      src: Football,
      sportName: "Football",
    },
    {
      id: 2,
      src: Cricket,
      sportName: "Cricket",
    },
    {
      id: 3,
      src: Kabaddi,
      sportName: "kabaddi",
    },
    {
      id: 4,
      src: Tennis,
      sportName: "Tennis",
    },
    {
      id: 5,
      src: BasketBall,
      sportName: "Basketball",
    },
    {
      id: 6,
      src: Hockey,
      sportName: "Hockey",
    },
  ],
};
