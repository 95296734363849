import React, { useEffect } from "react";
import FeatureOfOnlineList from "./FeatureOfOnlineList";
import { Container } from "react-bootstrap";
import { featureOnlineListData } from "./FeatureOfOnlineData";
import { Link, Element } from "react-scroll";
import styles from "../../../assets/css/delivery-service-feature-online.module.scss";

const FeatureOfOnline = () => {
  const links = ["Customer App", "Driver App", "Restaurant App", "Admin Panel"];
  return (
    <>
      <section className={`pdtb ${styles.feature_online}`}>
        <Container>
          <div className="row justify-content-center">
            <div
              className={`col-lg-10 text-center sub-heading ${styles.sub_heading}`}
            >
              <h2>
                Essential <span>Features of Online</span> Food Delivery Apps
              </h2>
              <p>
                Our online food delivery apps feature essential functionalities
                like customizable menus and user-friendly <br /> interfaces for
                a seamless user experience.
              </p>
            </div>
          </div>
        </Container>
        <div className={styles.features_item}>
          <div className={styles.features_item_link}>
            <Container>
              <ul className="d-flex justify-content-center">
                {links.map((elem, index) => (
                  <li key={index}>
                    <Link
                      to={`${featureOnlineListData[index].pid}`}
                      activeClass={styles.active}
                      spy={true}
                      offset={-220}
                      smooth={false}
                      duration={1000}
                    >
                      {elem}
                    </Link>
                  </li>
                ))}
              </ul>
            </Container>
          </div>
          <FeatureOfOnlineList />
        </div>
      </section>
    </>
  );
};

export default FeatureOfOnline;
