import React from "react";
import styles from "../../assets/css/delivery-service-app-uber.module.scss";
import { Container } from "react-bootstrap";
import { AppLikeUberEatImg } from "../../assets/img";

const AppLikeUberEats = () => {
  return (
    <section className={`pdtb ${styles.app_like_uber}`}>
      <Container>
        <div className="row align-items-center">
          <div className="col-lg-7 mb-5 mb-lg-0">
            <div className={styles.app_like_uber_content}>
              <div className="sub-heading">
                <h2>
                  Develop A Food Delivery App Like{" "}
                  <span>Uber Eats & GrubHub</span>
                </h2>
                <p>
                  Embark on a culinary revolution with our expertise in
                  developing food delivery apps like Uber Eats and GrubHub. Our
                  expert team will collaborate with you to understand your
                  specific needs and develop a cutting-edge app that exceeds
                  industry standards. With our expertise in leveraging the
                  latest technologies and industry best practices, you can trust
                  us to create a feature-rich and user-friendly app that sets
                  you apart from the competition. Join the ranks of successful
                  food delivery businesses with our top-notch app development
                  services.
                </p>
                <button className="theme-btn">
                  I want to Develop my Food Delivery App
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-5 text-lg-end text-center mt-5 mt-lg-0">
            <img src={AppLikeUberEatImg} alt="foodApp" className="img-fluid" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AppLikeUberEats;
