import React, { useEffect } from "react";
import AIPageBanner from "../../components/AIDevelopmentPageComponents/AIPageBanner";
import ChatbotDevelopmentServices from "../../components/ChatbotComponents/ChatbotDevelopmentServices";
import { AIPageData } from "../../components/AIDevelopmentPageComponents/AIPageData";
import AIModels from "../../components/AIDevelopmentPageComponents/AIModels";
import AIMachineLearningServices from "../../components/AIDevelopmentPageComponents/AIMachineLearningServices";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import ChatbotTechnologyWeWork from "../../components/ChatbotComponents/ChatbotTechnologyWeWork";
import WhyChooseUsAiMl from "../../components/AIDevelopmentPageComponents/WhyChooseUsAiMl";
import NormalCta from "../../components/cta/NormalCta";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import ECommerceOurProcess from "../../components/EcommerceDevelopmentPageComponents/ECommerceOurProcess";
import HeadMetaSec from "../../components/HeadMetaSec";


const AIDevelopmentPage = () =>{

    useEffect(()=>{
        document.body.classList.add('ai-development-body')
        return()=>document.body.classList.remove('ai-development-body')
    },[])

    return(
        <>
        <HeadMetaSec HeadSecData={AIPageData.helmetSec}/>
           <AIPageBanner bannerData = {AIPageData.bannerData}/>
           <ChatbotDevelopmentServices developmentServicesData = {AIPageData.developmentServicesData}/>
           {/* <AIModels/> */}
           <ECommerceOurProcess ourProcessData={AIPageData.ourProcessData}/>
           {/* <AIMachineLearningServices/> */}
           <OnDemandFoodCta  {...AIPageData.ctaContent} />
           <ChatbotTechnologyWeWork technologyWeWorkData={AIPageData.technologyWeWorkData}/>
           <WhyChooseUsAiMl/>
           <NormalCta normalCtaData={AIPageData.normalCtaData} />
           <B2BSec/>
           <OurClientSec/>
           <OurBlogSec/>
        </>
    )
}


export default AIDevelopmentPage;
