import React from "react";
import styles from "../../assets/css/portfolio-list.module.scss";
import { clientwebsiteimg1 } from "../../assets/img";
import { clientwebsiteimg2 } from "../../assets/img";
import { clientwebsiteimg3 } from "../../assets/img";
import { clientwebsiteimg4 } from "../../assets/img";
import { clientwebsiteimg5 } from "../../assets/img";
import { clientwebsiteimg6 } from "../../assets/img";
import { clientwebsiteimg7 } from "../../assets/img";
import { clientwebsiteimg8 } from "../../assets/img";
import { clientwebsiteimg9 } from "../../assets/img";
import { clientwebsiteimg10 } from "../../assets/img";
const PortfolioList = () => {
  return (
    <>
      <section className={`pdtb ${styles.portfolio_list}`}>
        <div className="container">
          <ul className="row">
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg10}
                  className="img-fluid"
                  alt="siteStaff image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg1}
                  className="img-fluid"
                  alt="empire image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg2}
                  className="img-fluid"
                  alt="family dental image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg3}
                  className="img-fluid"
                  alt="knfdds image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg4}
                  className="img-fluid"
                  alt="premier image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg5}
                  className="img-fluid"
                  alt="senior blue book image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg6}
                  className="img-fluid"
                  alt="skills n more image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg7}
                  className="img-fluid"
                  alt="statWorld image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg8}
                  className="img-fluid"
                  alt="senior guru image portfolio"
                />
              </div>
            </li>
            <li className="col-lg-6 col-md-6">
              <div className={`${styles.portfolio_list_inner}`}>
                <img
                  src={clientwebsiteimg9}
                  className="img-fluid"
                  alt="urja healing image portfolio"
                />
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default PortfolioList;
