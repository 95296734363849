import React, { useState, useEffect } from "react";
import BannerAbout from "../../components/Banner/BannerAbout";
import ProvideSolution from "../../components/About/ProvideSolution";
import AboutVision from "../../components/About/AboutVision";
import AllServiceDedication from "../../components/About/AllServiceDedication";
import {
  happy_client,
  startup,
  delivery,
  team,
  vision_mission,
} from "./../../assets/img";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import EnhancedMobilitySolution from "../../components/services/EnhancedMobilitySolutions";
import { AboutPageData } from "../../components/jsonData/AboutPageData";
import HeadMetaSec from "../../components/HeadMetaSec";
function About() {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(AboutPageData);
  }, []);
  // console.log(data);
  return (
    <>
      <HeadMetaSec HeadSecData={AboutPageData.helmetSec} />
      <BannerAbout bannerData={AboutPageData.bannerSec} />
      <ProvideSolution
        happyImg={happy_client}
        startupImg={startup}
        deliveryImg={delivery}
        teamImg={team}
      />
      <EnhancedMobilitySolution
        enhanceMobilityData={AboutPageData.AboutWhatWeOfferData}
      />
      <AboutVision vision_img={vision_mission} />
      <AllServiceDedication />
      <B2BSec />
      <OurClientSec />
      <OurBlogSec />
    </>
  );
}
export default About;
