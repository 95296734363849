import React from "react";
import { Container } from "react-bootstrap";
import styles from "../../assets/css/fantasy-app-banner.module.scss";
import { FantasyBannerImg } from "../../assets/img/FantasyAppServiceImg";

const FantasyBanner = () => {
  return (
    <section className={styles.banner_bg}>
      <Container className="container-lg">
        <div className="row">
          <div className="col-lg-7">
            <div className={styles.content_sec}>
              <h1>
                <span>Premier Fantasy Sports</span> App Development Company
              </h1>
              <p>
                We excel in cutting-edge tech to create visually stunning
                fantasy sports apps that engage users & drive business success.
                At ScrumDigital our experts collaboratively develop
                feature-rich, intuitive apps that deliver an unparalleled user
                experience.
              </p>
              <p>
                Partner with ScrumDigital a top-notch fantasy sports app
                development company to build apps that captivates your audience
                and sets you apart in the market.
              </p>
              <div className="text-center text-sm-start">
                <button className="theme-btn">Talk to a Consultant</button>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={FantasyBannerImg} alt="bannerImg" className="img-fluid" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FantasyBanner;
