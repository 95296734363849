import React from "react";
import Modal_form from "../Banner/Modal";
import styles from '../../assets/css/ai-page-banner.module.scss'
import HomeBannerCertificate from "../Home/HomeBannerCertificate";


const AIPageBanner = ({bannerData}) =>{
    return(
        <section className={`${styles.ai_development_banner} position-relative`}>
          <div className="container-lg">
          <div className="row justify-content-between align-items-center">
            <div className={`col-lg-6 ${styles.banner_left_content_sec}`}>
              <h1 className="text-white">{bannerData?.heading}</h1>
              <p className="text-white">{bannerData?.desc}</p>
              <div>
                <Modal_form
                  customClass={`${styles.theme_btn}`}
                  btnName="Get in Touch"
                />

              </div>
            </div>
            <div className={`col-lg-5 col-sm-8 mx-auto text-center text-lg-end ${styles.right_img_sec}`}>
              <img src={bannerData?.imgSrc} className="img-fluid" alt={bannerData?.alt} />
            </div>
             <div className={styles.certificate_sec}><HomeBannerCertificate/></div>
          </div>
        </div>
      </section>
    )
}

export default AIPageBanner;