import { Container } from "react-bootstrap";
import Modal_form from "../Banner/Modal";
import assets from "./../../assets/css/Banner.module.scss";
const BannerAbout = ({ bannerData }) => {
  return (
    <section className={`${assets.about_banner}`}>
      <Container className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className={`${assets.about_banner_heading}`}>
              <h2>{bannerData.bannerHeading}</h2>
              {bannerData.banner_bott_head_multipara ? (
                <>{bannerData.banner_bott_head_multipara}</>
              ) : (
                <h5>{bannerData.banner_bott_head}</h5>
              )}
              <Modal_form btnName="Talk to a Consultant" />
            </div>
          </div>
          <div className="col-lg-6 col-md-8 mx-auto mt-5 mt-lg-0">
            <div className={`${assets.banner_img}`}>
              <img
                className="img-fluid"
                src={bannerData.bannerImg}
                alt={bannerData.alt}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default BannerAbout;
