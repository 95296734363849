export const HomePageData = {
  helmetSec: {
    title:
      "Custom Software Development Company | Scrum Digital",
    description:
      "Scrum Digital, a US-based software development company, offers global businesses customized software solutions crafted by industry-specialized developers.",
    keywords:
      "Software Development Company, Custom Software Development, Application Development Company, Digital Marketing Services, it consulting services",
    conical: "/",
  },
};
