import { SwiftImg, aiDevelopmentServiceIcon, androidDevelopmentServiceIcon, androidStudioIcon, arVrDevelopmentServiceIcon, cSharpeIcon, circleCi, cloudKit, cloudKitIcon, crossPfDevelopmentServiceIcon, fastlaneIcon, flutterIcon, gradleIcon, html5Icon, hybridDevelopmentServiceIcon, iosDevelopmentServiceIcon, javaIcon, jetPackIcon, kotlinIcon, mobileApp1ImgContent, mobileAppDevAboutUsImg, mobileAppDevelopmentBanner, objectCIcon, pythonIcon, reactIcon, serviceBannerAppDev, swiftIcon, swiftLintIcon, swiftUiIcon, xCodeIcon } from "../../assets/img";

export const mobileApplicationData = {


    helmetSec: {
        title: "Mobile App Development Services - Scrum Digital",
        description:
          "We are a top mobile app development company offering custom mobile apps for iOS and Android. Hire our experienced developers for app strategy and design.",
        keywords:
          "mobile app development process, Mobile App Development Company, Mobile App Development Services,",
        conical: "/mobile-application-development",
      },
    bannerData:{
        bannerHeading: (
          <>
            Mobile App <span>Development Company</span>
          </>
        ),
        banner_bott_head:
          "Creating innovative mobile solutions to strengthen your digital presence.",
        // bannerImg: mobileAppDevelopmentBanner,
        bannerImg: serviceBannerAppDev,
        alt: "Mobile Application Development service",
      },

      imgContentSec : {
        src: mobileAppDevAboutUsImg,
        alt: "Mobile Application Development company",
        heading: (
          <>
           Our Premium Services For <span>Developing Mobile Apps</span>
          </>
        ),
        desc: (
          <p>
            Discover our comprehensive suite of top-tier mobile app development services.
            From conceptualization and design to seamless integration and ongoing support,
            we guide the entire process. Whether you seek a consumer-focused app or an
            innovative enterprise solution, we're dedicated to delivering excellence every step of the way.
          </p>
        ),
        list: [
          {
            name: "Native & Cross-Platform",
          },
          {
            name: "UI/UX Design",
          },
          {
            name: "Consulting & Prototyping",
          },
          {
            name: "Automated QA & Testing",
          },
        ],
      },

      sliderServiceData : {
        heading:<>Our Unique Mobile App Development Services</>,
        desc:"Discover the power of innovative mobile solutions with our expertise in Android, iOS, cross-platform, and progressive web app development!",
        serviceList:[
          {
            id:1,
            imgSrc:androidDevelopmentServiceIcon,
            imgAlt:"android development service",
            title:<><span className="text-uppercase">Android</span> Mobile App Development Services</>,
            desc:<>Our Android app development service creates innovative apps that are customized to your company's requirements using Java, React Native, and Kotlin.
            </>
          },
          {
            id:2,
            imgSrc:iosDevelopmentServiceIcon,
            imgAlt:"ios development service",
            title:<><span className="text-uppercase">iOS</span> Mobile App Development Services</>,
            desc:<>Discover excellent iOS app creation with our in-depth knowledge of Apple technology, providing excellent solutions for the iPhone and iPad platforms.
            </>
          },
          {
            id:3,
            imgSrc:crossPfDevelopmentServiceIcon,
            imgAlt:"cross platform service",
            title:<><span className="text-uppercase">Cross-platform</span> Mobile App</>,
            desc:<>We have a team with full-scale expertise in developing cross-platform mobile apps for IOS and Android by using the most agile technologies. We can customize your mobile app as per your business needs and budget.
            </>
          },
          {
            id:4,
            imgSrc:aiDevelopmentServiceIcon,
            imgAlt:"ai development service",
            title:<><span className="text-uppercase">AI/IoT/Blockchain</span> App Development</>,
            desc:<>We can get you world-class mobile apps developed with futuristic technologies like AI, IoT, and blockchain. Our services will get an edge over your competition.
            </>
          },
          {
            id:5,
            imgSrc:arVrDevelopmentServiceIcon,
            imgAlt:"AR/VR development service",
            title:<><span className="text-uppercase">AR/VR</span> App Development</>,
            desc:<>We leverage the power of modern technologies like Augmented Reality (AR) and Virtual Reality (VR) to create mobile apps that offer seamless user experience. Our team of developers are well-versed with these next-gen technologies and can deliver exceptional apps.
            </>
          },
          {
            id:6,
            imgSrc:hybridDevelopmentServiceIcon,
            imgAlt:"hybrid app development",
            title:<><span className="text-uppercase">Hybrid</span> App Development</>,
            desc:<>We can help you develop top-of-the-line Hybrid apps with fast and reliable back-end, easy navigation, appealing menus, etc. Our aim is to render an app that entices your customer with an overall satisfying user-experience.
            </>
          },
        ]
      },
       technologyData : {
        title:<>Our Expertise in High-Tech <span className="d-sm-block">Mobile App Development</span></>,
        desc:"With our in-depth knowledge of dealing with all major platforms, delight your users with expressive and feature-rich native iOS / Android, Flutter, or Progressive Web Apps.",
        technologyList: [
            {
                id:1,
                eventKey:"android",
                tabBtnTitle:"Android",
                tabContent:{
                    title:<><span className="text-uppercase">Android</span> App Development</>,
                    desc:"We make use of the latest technologies to help companies establish their presence across a variety of modern platforms and devices, including:",
                    serviceList:[
                        "Advanced Android Smartphone App Solutions",
                        "Android Interface Design and UX Optimization",
                        "Custom API Integration for Android Functionality",
                        "Android App Maintenance and Updates",
                        "Secure Android App Deployment and Management Services"
                    ],
                    technologyList:[
                        {
                            imgSrc:javaIcon,
                            alt:"java technology",
                            name:"Java"
                        },
                        {
                            imgSrc:kotlinIcon,
                            alt:"kotlin technology",
                            name:"Kotlin"
                        },
                        {
                            imgSrc:androidStudioIcon,
                            alt:"android studio technology",
                            name:"Android Studio"
                        },
                        {
                            imgSrc:flutterIcon,
                            alt:"flutter technology",
                            name:"Flutter"
                        },
                        {
                            imgSrc:pythonIcon,
                            alt:"python technology",
                            name:"Python"
                        },
                        {
                            imgSrc:html5Icon,
                            alt:"html5 technology",
                            name:"HTML5"
                        },
                        {
                            imgSrc:jetPackIcon,
                            alt:"Jetpack technology",
                            name:"Jetpack"
                        },
                        {
                            imgSrc:fastlaneIcon,
                            alt:"fastlane technology",
                            name:"Fastlane"
                        },
                        {
                            imgSrc:gradleIcon,
                            alt:"gradle technology",
                            name:"Graddle"
                        },
                    ]
                },

            },
            {
                id:2,
                eventKey:"ios",
                tabBtnTitle:"Ios",
                tabContent:{
                    title:<><span className="text-uppercase">iOS</span> App Development</>,
                    desc:"We have all the skills required to make complete, reliable, and expandable mobile apps, including:",
                    serviceList:[
                        "Full-scale iPhone App Solutions",
                        "Custom iOS Application Engineering",
                        "Custom iOS Utility App Engineering",
                        "App Innovations",
                        "App Clip Experiences for Quick Access"
                    ],
                    technologyList:[
                        {
                            imgSrc:swiftIcon,
                            alt:"swift technology",
                            name:"Swift"
                        },
                        {
                            imgSrc:swiftUiIcon,
                            alt:"swift ui technology",
                            name:"Swift UI"
                        },
                        {
                            imgSrc:xCodeIcon,
                            alt:"x code technology",
                            name:"Xcode"
                        },
                        {
                            imgSrc:cloudKitIcon,
                            alt:"cloud kit technology",
                            name:"Cloud Kit"
                        },
                        {
                            imgSrc:flutterIcon,
                            alt:"flutter technology",
                            name:"Flutter"
                        },
                        {
                            imgSrc:reactIcon,
                            alt:"react native technology",
                            name:"React Native"
                        },
                        {
                            imgSrc:objectCIcon,
                            alt:"object c technology",
                            name:"Objective C"
                        },
                        {
                            imgSrc:cSharpeIcon,
                            alt:"c# technology",
                            name:"C#"
                        },
                        {
                            imgSrc:circleCi,
                            alt:"circle CI technology",
                            name:"Circle CI"
                        },
                        {
                            imgSrc:swiftLintIcon,
                            alt:"swift lint technology",
                            name:"Swift Lint"
                        },
                    ]
                },

            },
            // {
            //     id:3,
            //     eventKey:"flutter",
            //     tabBtnTitle:"Flutter",
            //     tabContent:{
            //         title:<><span className="text-uppercase">Flutter </span></>,
            //         desc:"Our Flutter app development services enable us to deliver cross-platform applications that offer a seamless user experience across both iOS and Android devices.",
            //         serviceList:[
            //             "Cross-Platform App Development with Flutter",
            //             "Custom Flutter Widget Creation for Native Performance",
            //             "Elegant Material Design and Style Widgets",
            //             "Flutter App Upgradation and Scalability",
            //             "Integrated State Management Solutions in Flutter Apps"
            //         ],
            //         technologyList:[
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //             {
            //                 imgSrc:SwiftImg,
            //                 alt:"service",
            //                 name:"Kotlin"
            //             },
            //         ]
            //     },

            // },
        ]
    },

      ctaContent :{
        ctaHeading:<>Transform ideas into apps, <span>inquire now!</span></>,
         btnName:"Begin Your App Adventure!"
      },
      ourProcessData:{
        heading:<>Our Mobile App <span> Development Process</span></>,
        desc:"We follow a streamlined approach, from initial consultation to final deployment, ensuring a user-friendly and feature-rich mobile application. Our process prioritizes client collaboration and development to deliver high-quality results efficiently.",
        list:[
          {
            id:1,
            desc:"Understanding market trends, identifying user needs, aligning with strategic goals."
          },
          {
            id:2,
            desc:"Creating intuitive interfaces, developing functional mockups, ensuring user engagement."
          },
          {
            id:3,
            desc:"Ensuring robust architecture, integrating key features, coding with best practices."
          },
          {
            id:4,
            desc:"Comprehensive quality checks, functionality and compatibility testing, user experience evaluation."
          },
          {
            id:5,
            desc:"Regular updates, performance enhancements, addressing user feedback."
          },
          {
            id:6,
            desc:"Measuring app success, analyzing user engagement, refining development strategy."
          },
          {
            id:7,
            desc:"Regular updates, performance enhancements, addressing user feedback."
          },
        ]
      }

}