export const SalesforceServicesData = {
  heading: (
    <>
      Salesforce Development <span>Services We Offer</span>
    </>
  ),
  desc: "Let our team of experts guide you every step of the way as they turn your visions into reality and propel your business to new heights of success.",
  serviceList: [
    {
      id: 1,
      title: (
        <>
          Salesforce <span>Development</span>
        </>
      ),
      desc: "Our team of experts will work with you to design and execute custom-fit strategies that will give you a competitive edge, whether you're a building startup or a big Enterprise.",
      innerList: [
        "Salesforce Implementation Consulting",
        "Force.com Development",
        "Force.com Integration and Migration",
      ],
    },
    {
      id: 2,
      title: (
        <>
          Salesforce <span>Mobile Development</span>
        </>
      ),
      desc: "Maximize the use of Salesforce and take it to the next level with Scrum Digital's Salesforce development team. Our experts will help you enhance Salesforce's capabilities on mobile devices, unlocking its full potential to drive success for your organization.",
      innerList: [
        "Salesforce Mobile App Consulting & Development",
        "Mobile apps for Appexchange",
        "UI/UX Design Services",
      ],
    },
    {
      id: 3,
      title: (
        <>
          Salesforce <span>AppExchange Development</span>
        </>
      ),
      desc: "With our experts, you can build manage & unmanaged packages on Salesforce AppExchange platform. Our developers will also help you in AppExchange App comparisons, selection, and implementation.",
      innerList: ["App Consulting", "App Development", "Lightning Apps"],
    },
    {
      id: 4,
      title: (
        <>
          Salesforce <span>Lightning Development</span>
        </>
      ),
      desc: "Leverage the full potential of Salesforce Lightning with Scrum Digital Salesforce services. Our team of experts will work with you to optimize your Salesforce implementation, making it effortless and intuitive.",
      innerList: [
        "Salesforce Lightning Consulting",
        "Salesforce App Development",
        "Component Development for Salesforce Lightning",
      ],
    },
    {
      id: 5,
      title: (
        <>
          Integration <span>With Third-party Solutions</span>
        </>
      ),
      desc: "Our certified Salesforce integration experts are experienced with platforms like MuleSoft, Dell Boomi, etc are capable to integrate any third party enterprise application with Salesforce.",
      innerList: [
        "Integration Using Tools",
        "API Development",
        "Integration App Development",
      ],
    },
    {
      id: 6,
      title: (
        <>
          Technical <span>Expertise</span>
        </>
      ),
      desc: "Our Salesforce experts will help you maximize Salesforce to boost growth and optimize operations. Our team's skills and knowledge will enable you to increase Salesforce functionality, integrate with other systems, and build custom apps.",
      innerList: [
        "Salesforce Lightning Consulting",
        "Salesforce App Development",
        "Component Development for Salesforce Lightning",
      ],
    },
  ],
};
