
import React from "react";
import { Container } from "react-bootstrap";
import styles from '../../assets/css/service-technologies-for-cms.module.scss'
import { ServiceWhyChoosesCmsWordPress, ServiceWhyChoosesCmsJoomla, ServiceWhyChoosesCmskentico, ServiceWhyChoosesCmsDrupal } from "../../assets/img/ServiceWhyChooseUsSecImg";

const TechnologiesForCms = () => {
  const cmsDevelopmentList = [
    {
      id: 1,
      source: ServiceWhyChoosesCmsWordPress,
      title: (
        <>
          <span>WordPress</span> Development
        </>
      ),
      content:
        "We have a dedicated team of expert WordPress developers who can build beautiful and user-friendly websites according to your niche.",
    },
    {
      id: 2,
      source: ServiceWhyChoosesCmskentico,
      title: (
        <>
          <span>Kentico</span> Development
        </>
      ),
      content:
        "We hold vast experience in Kentico CMS development and over the years have executed various Kentico application development projects.",
    },
    {
      id: 3,
      source: ServiceWhyChoosesCmsJoomla,
      title: (
        <>
          <span>Joomla</span> Development
        </>
      ),
      content:
        "Our highly proficient Joomla CMS developers render highly efficient, feature-packed and, bug free content management systems to the customers.",
    },
    {
      id: 4,
      source: ServiceWhyChoosesCmsDrupal,
      title: (
        <>
          <span>Drupal</span> Development
        </>
      ),
      content:
        "We specialize in Drupal development designs and have developed custom-tailored applications for several clients as per their needs.",
    },
  ];
  return (
    <div className={`pdtb ${styles.technology_cms}`}>
      <Container>
        <div className="row text-center justify-content-center">
          <div className="col-lg-10 sub-heading">
            <h2>
              Technologies we use for <br /> <span>CMS Development</span>
            </h2>
            <p>
              Scrum Digital is a leading software development company that
              provides end-to-end IT, consulting and digital technology
              solutions to deliver better customer experience in any business
              process.
            </p>
          </div>
        </div>
        <ul>
          {cmsDevelopmentList.map((list, index) => {
            return (
              <li key={index}>
                <div className={styles.cms_technology_list}>
                  <img
                    src={list.source}
                    alt="wordpress"
                  />
                  <h4>{list.title}</h4>
                  <p>{list.content}</p>
                </div>
              </li>
            )
          })}
        </ul>
      </Container>
    </div>
  );
};

export default TechnologiesForCms;
