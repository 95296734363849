import React from "react";
import { Container } from "react-bootstrap";
import styles from "../../../assets/css/delivery-service-last-ten-year.module.scss";
import { LastTenYearData } from "./LastTenYearData";

const LastTenYear = () => {
  return (
    <>
      <section className={`pdtb ${styles.last_ten_years}`}>
        <Container className={styles.main}>
          <div className="row justify-content-center">
            <div className={`col-lg-10 sub-heading ${styles.sub_heading}`}>
              <h3>In The Last Ten Years</h3>
              <h2 className="text-white">
                Over <span>1000+ </span> On-demand Delivery Businesses Trusted
                Scrum Digital For <span>Scaling Their Growth</span>
              </h2>
            </div>
          </div>
          <ul className={`row ${styles.top}`}>
            {LastTenYearData.map((elem, index) => {
              return (
                elem.id <= 2 && (
                  <li key={index} className="col-6">
                    <div className={styles.last_10_year_inner}>
                      <img src={elem.imgSource} alt={elem.subTitle} />
                      <div className={styles.title}>
                        <h2>{elem.title}</h2>
                        <h4>{elem.subTitle}</h4>
                      </div>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
          <ul className={`row text-center ${styles.bottom}`}>
            {LastTenYearData.map((elem, index) => {
              return (
                elem.id > 2 && (
                  <li
                    key={index}
                    className="col-lg-4 col-md-4 col-sm-6 col-6 mx-auto mb-md-0 mb-5"
                  >
                    <div>
                      <img src={elem.imgSource} alt={elem.subTitle} />
                      <div>
                        <h2>{elem.title}</h2>
                        <h5>{elem.subTitle}</h5>
                      </div>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
        </Container>
      </section>
    </>
  );
};

export default LastTenYear;
