import {
  CustomerReviewIcon,
  OrderProcessIcon,
  SalesIcon,
} from "../../../assets/img";

export const LastTenYearData = [
  {
    id: 1,
    imgSource: OrderProcessIcon,
    title: "1.2M+",
    subTitle: "Orders Processed",
  },
  {
    id: 2,
    imgSource: CustomerReviewIcon,
    title: "$25K+",
    subTitle: "Customer Reviews",
  },
  {
    id: 3,
    imgSource: SalesIcon,
    title: "Monthly",
    subTitle: "Transactions",
  },
  {
    id: 4,
    imgSource: SalesIcon,
    title: "Monthly",
    subTitle: "Transactions",
  },
  {
    id: 5,
    imgSource: SalesIcon,
    title: "Monthly",
    subTitle: "Transactions",
  },
];
