import {
  hmgTechIangularJs,
  hmgTechIhtml5,
  hmgTechIreactJs,
  hmgTechItypeScript,
  hmgTechIvueJs,
} from "../../../assets/img";

export const TechnologyData = {
  heading: (
    <>
      <span className="text-uppercase">Technologies</span> we work with
    </>
  ),
  desc: "We're excited to provide cutting-edge solutions using the most recent technology.",
  technology: [
    {
      id: 1,
      title: "Front End",
      lists: [
        {
          src: hmgTechItypeScript,
          name: "TypeScript",
        },
        {
          src: hmgTechIangularJs,
          name: "Angular Js",
        },
        {
          src: hmgTechIvueJs,
          name: "Vue Js",
        },
        {
          src: hmgTechIreactJs,
          name: "React Js",
        },
        {
          src: hmgTechIhtml5,
          name: "HTML5",
        },
      ],
    },
    {
      id: 2,
      title: "Backend",
      lists: [
        {
          src: hmgTechItypeScript,
          name: "TypeScript",
        },
        {
          src: hmgTechIangularJs,
          name: "Angular Js",
        },
        {
          src: hmgTechIvueJs,
          name: "Vue Js",
        },
      ],
    },
    {
      id: 3,
      title: "Mobile",
      lists: [
        {
          src: hmgTechItypeScript,
          name: "TypeScript",
        },
        {
          src: hmgTechIangularJs,
          name: "Angular Js",
        },
        {
          src: hmgTechIvueJs,
          name: "Vue Js",
        },
        {
          src: hmgTechIreactJs,
          name: "React Js",
        },
        {
          src: hmgTechIhtml5,
          name: "HTML5",
        },
      ],
    },
    {
      id: 4,
      title: "Database",
      lists: [
        {
          src: hmgTechIreactJs,
          name: "React Js",
        },
        {
          src: hmgTechIhtml5,
          name: "HTML5",
        },
      ],
    },
    {
      id: 5,
      title: "CMS",
      lists: [
        {
          src: hmgTechItypeScript,
          name: "TypeScript",
        },
        {
          src: hmgTechIangularJs,
          name: "Angular Js",
        },
        {
          src: hmgTechIvueJs,
          name: "Vue Js",
        },
        {
          src: hmgTechIreactJs,
          name: "React Js",
        },
        {
          src: hmgTechIhtml5,
          name: "HTML5",
        },
      ],
    },
    {
      id: 6,
      title: "Infra and DevOps",
      lists: [
        {
          src: hmgTechItypeScript,
          name: "TypeScript",
        },
        {
          src: hmgTechIhtml5,
          name: "HTML5",
        },
      ],
    },
  ],
};
