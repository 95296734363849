export const FormField = {
  inputFieldData:[
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
      as: "input",
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      as: "input",
    },
    {
      id: 3,
      name: "mobileNumber",
      as: "input",
      required:1,
    },
    {
      id: 4,
      name: "skype",
      type: "text",
      placeholder: "Skype ID / WhatsApp No.",
      as: "input",
      required:1
    },
    {
      id: 5,
      name: "interested",
      as: "select",
      options: [
        {
          name: "I am Interested in",
          // value: "I am Interested in",
          isDisabled:true
        },
        {
          name: "Enterprise Software Solutions",
          value: "Enterprise Software Solutions",
        },
        {
          name: "Creating a Mobile App",
          value: "Creating a Mobile App",
        },
        {
          name: "Web Development",
          value: "Web Development",
        },
        {
          name: "Dedicated Team Service",
          value: "Dedicated Team Service",
        },
        {
          name: "IT Consulting Services",
          value: "IT Consulting Services",
        },
        {
          name: "Enhancing an Existing Product",
          value: "Enhancing an Existing Product",
        },
        {
          name: "Partnership Opportunities",
          value: "Partnership Opportunities",
        },
        {
          name: "Other",
          value: "Other",
        },
      ],
    },
    {
      id: 6,
      name: "Budget",
      as: "select",
      options: [
        {
          name: "Project Budget (in USD)",
          value: "Project Budget (in USD)",
        },
        {
          name: "Less than 5,000",
          value: "Less than 5,000",
        },
        {
          name: "5,000 - 10,000",
          value: "5,000 - 10,000",
        },
        {
          name: "10,000 - 20,000",
          value: "10,000 - 20,000",
        },
        {
          name: "20,000 - 35,000",
          value: "20,000 - 35,000",
        },
        {
          name: "35,000 - 50,000",
          value: "35,000 - 50,000",
        },
        {
          name: "50,000 - 1,00,000",
          value: "50,000 - 1,00,000",
        },
        {
          name: "Above 1,00,000",
          value: "Above 1,00,000",
        },
        {
          name: "Not Sure",
          value: "Not Sure",
        },
      ],
    },
    {
      id: 7,
      name: "textarea",
      type: "textarea",
      placeholder: "About your project",
      as: "textarea",
    },
    {
      id:8,
      type:"checkbox",
      pId:"accept",
      name:"accept",
      labelTitle:"I accept",
      link:"Terms and Conditions",
      href:"/terms-of-use",
      target:"_blank"
    },
    {
      id:9,
      type:"checkbox",
      pId:"send",
      name:"send",
      labelTitle:"Send me NDA",
    }
  ],
  btnData:{
    align_center:true,
    btnName:"Send your Inquiry"
  }
};
