import React from "react";
import { Container } from "react-bootstrap";
import styles from "../../assets/css/fantasy-cta.module.scss";
import { Link } from "react-router-dom";
import { FantasyCtaImg } from "../../assets/img";

const FantasyCta = () => {
  return (
    <>
      <section className={styles.cta_bg}>
        <Container>
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className={`sub-heading ${styles.contact}`}>
                <h2 className="text-white">
                  Looking For a <span>technology</span> partner?
                </h2>
                <p className="text-white">
                  We're here to help you with the best solutions. Send us your
                  query
                </p>
                <div className={styles.contact_no}>
                  <p className="text-white">
                    USA: <Link to="tel:+1 3038006089">+1 3038006089</Link> ,
                    India:{" "}
                    <Link to="tel:+91 141 403 6138">+91 141 403 6138</Link>,{" "}
                    <Link to="tel:+91 637 658 5239">+91 637 658 5239</Link>
                  </p>
                  <p className="text-white">
                    Email ID:{" "}
                    <Link to="mailto:contact@scrumdigital.com">
                      contact@scrumdigital.com
                    </Link>{" "}
                    | Skype ID:{" "}
                    <Link to="skype:sales@scrumdigital.com?chat">
                      scrum.digital
                    </Link>
                  </p>
                </div>
                <button className="theme-btn">Other info</button>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-md-block d-none">
              <img src={FantasyCtaImg} alt="cta" className="img-fluid" />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FantasyCta;
