import React from "react";
import styles from "../../../assets/css/HmgAboutList.module.scss";
import { Row } from "react-bootstrap";
import {
  HmgAboutImg,
} from "../../../assets/img";

const HmgAboutList = (props) => {
  return (
    <>
      <Row>
        <div className={`col-lg-7 ${styles.hmg_content_sec}`}>
          <div className={`${props.className} mb-0`}>
            <h2>{props.heading}</h2>
            {props.desc}
          </div>
        </div>
        <div className="col-lg-5 text-end">
          <img
            src={props.imgSrc ? props.imgSrc:HmgAboutImg}
            className={`img-fluid ${styles.hmg_about_img}`}
            alt={props.mainImgAlt}
          />
        </div>
        <div className={`col-lg-12 ${styles.hmg_about_list} `}>
          <ul>
            {props.list.map((list) => (
              <li key={list.id}>
                <div className={`${styles.hmg_about_list_item}`}>
                  <span className={`${styles.hmg_about_list_item_img}`}>
                    <img src={list.imgSrc} alt={list.alt} />
                  </span>
                  <h3>{list.title}</h3>
                  <p className="mb-0">{list.desc}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    </>
  );
};

export default HmgAboutList;
