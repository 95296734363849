import { salesyear, salehassle, saleforsecostsaving, salesforseflexible, saleforseIntegrity, saleforseversatility } from "../../assets/img";
import styles from "../../assets/css/salesforse/ScrumSalesforce.module.scss";

const ScrumSalesforce = () => {
    return (
        <section className={`${styles.scrum_hire_salesforce}`}>
            <div className="container">
                <div className="sub-heading text-center">
                    <h2 className="text-white"><span>Why Hire Salesforce Developers From Scrumdigital? </span> </h2>
                </div>
                <ul className={`${styles.develop_sale} row align-items-center`}>
                    <li className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className={`${styles.avenge_earpiece}`}>
                            <img src={salesyear} alt="images" />
                            <div>
                                <h2>5+</h2>
                                <h4>Years Of Average Experience </h4>
                            </div>
                        </div>
                    </li>
                    <li className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className={`${styles.avenge_earpiece}`}>
                            <img src={salehassle} alt="images" />
                            <div>
                                <h2>Hassle-free</h2>
                                <h4>Project Management</h4>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className={`${styles.flexible_cost} row`}>
                    <li className="col-lg-3 col-md-3 col-sm-6  col-6">
                        <div className={`${styles.saving_prices}`}>
                            <img src={saleforsecostsaving} alt="images" />
                            <h4>Cost Saving</h4>
                            <p>Save upto 50% cost with competitive prices</p>
                        </div>
                    </li>
                    <li className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className={`${styles.saving_prices}`}>
                            <img src={salesforseflexible} alt="images" />
                            <h4>Flexible</h4>
                            <p>& Transparent with detailed timesheets.</p>
                        </div>
                    </li>
                    <li className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className={`${styles.saving_prices}`}>
                            <img src={saleforseIntegrity} alt="images" />
                            <h4>Integrity</h4>
                            <p>Covered by NDA and data security</p>
                        </div>
                    </li>
                    <li className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className={`${styles.saving_prices}`}>
                            <img src={saleforseversatility} alt="images" />
                            <h4>CVersatile Experience </h4>
                            <p>Wide range of certifications</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}
export default ScrumSalesforce;