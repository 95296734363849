import React from "react";
import styles from "../../assets/css/hmg-team-innovation.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {
  KidsAdultLogo,
  MagicEduLogo,
  SeniorBlueBookLogo,
  SiteStaffLogo,
  TriAgeLogo,
  ULukGudLogo,
  VarselorLogo,
  UrjaLogo,
  EmpireMedicineLogo,
  FamilyDentalLogo,
  NouveausurgeLogo,
  PremierSeniorLogo,
  SkillMoreLogo,
} from "../../assets/img";

const TeamInnovation = () => {
  const achievementSlider = [
    {
      id:1,
      imgSrc:KidsAdultLogo,
      alt:"kids adult logo"
    },
    {
      id:2,
      imgSrc:MagicEduLogo,
      alt:"MagicEdu logo"
    },
    {
      id:3,
      imgSrc:SeniorBlueBookLogo,
      alt:"SeniorBlueBook logo"
    },
    {
      id:4,
      imgSrc:SiteStaffLogo,
      alt:"SiteStaff logo"
    },
    {
      id:5,
      imgSrc:TriAgeLogo,
      alt:"TriAge logo"
    },
    {
      id:6,
      imgSrc:ULukGudLogo,
      alt:"ULukGud logo"
    },
    {
      id:7,
      imgSrc:VarselorLogo,
      alt:"Varselor logo"
    },
    {
      id:8,
      imgSrc:UrjaLogo,
      alt:"Urja logo"
    },
    {
      id:9,
      imgSrc:EmpireMedicineLogo,
      alt:"EmpireMedicine logo"
    },
    {
      id:10,
      imgSrc:FamilyDentalLogo,
      alt:"FamilyDental logo"
    },
    {
      id:11,
      imgSrc:NouveausurgeLogo,
      alt:"Nouveausurge logo"
    },
    {
      id:12,
      imgSrc: PremierSeniorLogo,
      alt:" PremierSenior logo"
    },
    {
      id:13,
      imgSrc: SkillMoreLogo,
      alt:" SkillMore logo"
    },
  ];

  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <section className={`hmg-team-innovation ${styles.team_innovation}`}>
        <div className="container-fluid">
          <div className={styles.achievement_slider}>
            <Slider {...settings}>
              {achievementSlider.map((achievement, i) => (
                <div key={i}>
                  <div>
                    <img src={achievement.imgSrc} alt={achievement.alt} />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};
export default TeamInnovation;
