import {
  CallIcon,
  USAFlag,
  IndFlag,
  MailIcon,
  SkypeIcon,
} from "../../assets/img";
import {
  FaArrowRightLong,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa6";

export const CompanyContactDetail = [
  {
    id: 1,
    icon: CallIcon,
    imgAlt: "support",
    title: "Phone Number",
    contactList: [
      {
        src: USAFlag,
        alt: "US flag",
        PhoneNumber: "+1 303 800 6089",
        href: "tel:+13038006089",
      },
      {
        src: IndFlag,
        alt: "Ind flag",
        PhoneNumber: " +91 637 658 5239",
        href: "tel:+916376585239",
      },
    ],
  },

  {
    id: 2,
    icon: MailIcon,
    imgAlt: "MailIcon",
    title: "E-mail ID",
    contactList: {
      href: "mailto:sales@scrumdigtial.com",
      email: "sales@scrumdigtial.com",
    },
  },
  {
    id: 2,
    icon: SkypeIcon,
    imgAlt: "SkypeIcon",
    title: "Skype ID",
    contactList: {
      href: "skype:sales@scrumdigital.com?chat",
      email: " scrumdigital",
    },
  },
];

export const SocialLink = [
  {
    href: "https://www.facebook.com/scrumdigital",
    socialIcon: <FaFacebookF />,
  },
  {
    href: "https://twitter.com/scrum_digital",
    socialIcon: <FaTwitter />,
  },
  {
    href: "https://www.linkedin.com/company/scrum-digital-llc",
    socialIcon: <FaLinkedinIn />,
  },
  {
    href: "https://www.youtube.com/channel/UC8tnjOGuupGGEGS7MOOcOQA",
    socialIcon: <FaYoutube />,
  },
  {
    href: "https://www.instagram.com/scrum.digital/",
    socialIcon: <FaInstagram />,
  },
];

export const CopyrightLink = [
  {
    href: "/privacy-policy",
    name: "Privacy Policy",
    blank: true,
  },
  {
    href: "/terms-of-use",
    name: "Terms of Use",
    blank: true,
  },
  {
    href: "/contact-us",
    name: "Contact Us",
    blank: false,
  },
];
