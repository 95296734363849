
import {
  apiDevelopmentIcon,
  chatBot,
  cloudSolutions,
  cmsDevelopment,
  crmIntegration,
  DigitalTransformation,
  ecommerceApplicationDevelopment,
  erpSolutions,
  iotApplication,
  MobileApplicationDevelopment,
  salesforce,
  WebApplicationDevelopment,
  UiUxDesignServices,
  DigitalMarketing,
  Seo,
  Sem,
  Smo,
  Smm,
  Orm,
  Ppc,
  StartupConsulting,
  MvpDev,
  ProductDev,
  StaffAug,
  GrowthConsultation,
} from "../../assets/img";



export const headerMegaMenuData = [
  {
    id: 1,
    title: "Digital Transformation",
    serviceList: [
      {
        id: 1,
        href: "/digital-transformation-services",
        src: DigitalTransformation,
        name: "Digital Transformation",
      },
      {
        id: 2,
        href: "/website-development-company",
        src: WebApplicationDevelopment,
        name: "Website Development",
      },
      {
        id: 3,
        href: "/web-application-development-services",
        src: WebApplicationDevelopment,
        name: "Web Application Development",
      },
      {
        id: 4,
        href: "/mobile-application-development",
        src: MobileApplicationDevelopment,
        name: "Mobile Application Development",
      },
      {
        id: 5,
        href: "/ecommerce-development-services",
        src: ecommerceApplicationDevelopment,
        name: "E-commerce Development",
      },
      {
        id: 6,
        href: "/ui-ux-development-services",
        src: UiUxDesignServices,
        name: "UI-UX Design Services",
      },
      {
        id: 7,
        href: "/api-development-services",
        src: apiDevelopmentIcon,
        name: "API Development",
      },
      {
        id: 8,
        href: "/cms-development-services",
        src: cmsDevelopment,
        name: "CMS Development",
      },
      {
        id: 9,
        href: "/crm-development-solutions",
        src: crmIntegration,
        name: "CRM Customization & Integration",
      },
      {
        id: 10,
        href: "/iot-app-development-services",
        src: iotApplication,
        name: "IOT Applications",
      },
      {
        id: 11,
        href: "/chatbot-development-services",
        src: chatBot,
        name: "Chatbots",
      },
      {
        id: 12,
        href: "/cloud-computing-solutions",
        src: cloudSolutions,
        name: "Cloud Solutions",
      },
      {
        id: 13,
        href: "/erp-consulting-services",
        src: erpSolutions,
        name: "ERP Solutions",
      },
    ],
    blogCategory:11,
  },
  {
    id: 2,
    title: "Digital Marketing",
    serviceList: [
      {
        id: 1,
        href: "/digital-marketing-services",
        src: DigitalMarketing,
        name: "Digital Marketing",
      },
      {
        id: 2,
        href: "/seo-services",
        src: Seo,
        name: "SEO (Search Engine Optimization)",
      },
      {
        id: 3,
        href: "/search-engine-marketing",
        src: Sem,
        name: "SEM (Search Engine Marketing)",
      },
      {
        id: 4,
        href: "/social-media",
        src: Smo,
        name: "SMO (Social Media Optimization)",
      },
      {
        id: 5,
        href: "/social-media-marketing",
        src: Smm,
        name: "SMM (Social Media Marketing)",
      },
      {
        id: 6,
        href: "/online-reputation-management",
        src: Orm,
        name: "ORM (Online Reputation Management)",
      },
      {
        id: 7,
        href: "/ppc-management-services",
        src: Ppc,
        name: "PPC (Pay Per Click)",
      },
    ],
    blogCategory:10,
    // blogs: [
    //   {
    //     imgSrc: Blog1,
    //     title: "On-Demand Medicine Delivery App Development Cost and Features",
    //   },
    //   {
    //     imgSrc: Blog1,
    //     title: "On-Demand Medicine Delivery App Development Cost and Features",
    //   },
    // ],
  },
  {
    id: 3,
    title: "Startup Consulting",
    serviceList: [
      {
        id: 1,
        href: "/startup-consulting-services",
        src: StartupConsulting,
        name: "Startup Consulting",
      },
      {
        id: 2,
        href: "/mvp-development-services",
        src: MvpDev,
        name: "MVP Development",
      },
      {
        id: 3,
        href: "/product-development-services",
        src: ProductDev,
        name: "Product Development",
      },
      {
        id: 4,
        href: "/staff-augmentation-services",
        src: StaffAug,
        name: "Staff Augmentation",
      },
      {
        id: 5,
        href: "/growth-consultation",
        src: GrowthConsultation,
        name: "Growth Consultation",
      },
    ],
    blogCategory:12,
    // blogs: [
    //   {
    //     imgSrc: Blog1,
    //     title: "On-Demand Medicine Delivery App Development Cost and Features",
    //   },
    //   {
    //     imgSrc: Blog1,
    //     title: "On-Demand Medicine Delivery App Development Cost and Features",
    //   },
    // ],
  },
  {
    id:4,
    title: "AI Development",
    serviceList:[
      {
        id: 1,
        href: "/ai-development-services",
        src: DigitalTransformation,
        name: "AI Development",
      },
    ],
    blogCategory:128,
  },
  {
    id:5,
    title: "Salesforce Development",
    serviceList:[
      {
        id: 14,
        href: "/hire-salesforce-developers",
        src: salesforce,
        name: "Salesforce Consulting",
      }
    ],
    blogCategory:185,
  },
];
