import React from "react";
import MainLayout from "../layouts/MainLayout";
import About from "../views/pages/About";
import ContactUs from "../views/pages/ContactUs";
import DeliveryAppDevelopmentCompany from "../views/pages/DeliveryAppDevelopmentCompany";
import FantasySportsAppDevService from "../views/pages/FantasySportsAppDevService";
import FoodDeliveryAppDev from "../views/pages/FoodDeliveryAppDev";
import Home from "../views/pages/Home";
import Portfolio from "../views/pages/Portfolio";
import PrivacyPolicy from "../views/pages/PrivacyPolicy";
import Salesforce from "../views/pages/Salesforce";
import ServiceGenerate from "../views/pages/ServiceGenerate";
import ServicePage from "../views/pages/ServicePage";
import TermsOfUse from "../views/pages/TermsOfUse";
import ChatBot from "../views/pages/ChatBot";
import AIDevelopmentPage from "../views/pages/AIDevelopmentPage";
import EcommerceDevelopmentPage from "../views/pages/EcommerceDevelopmentPage";
import MobileAppDevelopment from "../views/pages/MobileAppDevelopment";
import WebApplicationDevelopmentServices from "../views/pages/WebApplicationDevelopmentServices";
import WebsiteDevelopmentServices from "../views/pages/WebsiteDevelopmentServices";
import NotFoundPage from "../views/pages/NotFoundPage";
const MainRoutes = {
  path: "",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Home />,
    },

    {
      path: "*",
      element: <NotFoundPage />,
    },

    {
      path: "contact-us",
      element: <ContactUs />,
    },
    {
      path: "about-us",
      element: <About />,
    },
    {
      path: "portfolio",
      element: <Portfolio />,
    },
    {
      path: "/:name",
      element: <ServicePage />,
    },

    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfUse />,
    },
    {
      path: "/delivery-app-development-company",
      element: <DeliveryAppDevelopmentCompany />,
    },
    {
      path: "/food-delivery-app-development",
      element: <FoodDeliveryAppDev />,
    },
    {
      path: "/fantasy-sports-app-development",
      element: <FantasySportsAppDevService />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfUse />,
    },
    {
      path: "/hire-salesforce-developers",
      element: <Salesforce />,
    },
    {
      path: "/services",
      element: <ServiceGenerate />,
    },
    {
      path: "/chatbot-development-services",
      element: <ChatBot />
    },
    {
      path: "/ai-development-services",
      element: <AIDevelopmentPage />
    },
    {
      path: "/ecommerce-development-services",
      element: <EcommerceDevelopmentPage />
    },
    {
      path: "/mobile-application-development",
      element: <MobileAppDevelopment />
    },
    {
      path: "/web-application-development-services",
      element: <WebApplicationDevelopmentServices />
    },
    {
      path: "/website-development-company",
      element: <WebsiteDevelopmentServices />
    },
  ],
};

export default MainRoutes;
