import React from "react";
import styles from "../../assets/css/our-client-sec.module.scss";
import { Container } from "react-bootstrap";
import { ClientImgMale, ClientImgFeMale, drAvisha, drIzadi, marcCherabie, sandraJensen, pankajSharma, gauravBasra, drAmir } from "../../assets/img";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import Slider from "react-slick";
const slider2ResponsiveSettings = [
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 1,
    },
  },
];
const OurClientSec = () => {
  const slider = React.useRef(null);
  return (
    <>
      <section className={`pdtb our-client-slider ${styles.our_client_sec}`}>
        <Container>
          <div className="sub-heading">
            <h2>Our clients simply <span className="text-uppercase">love our work</span></h2>
            <p>Don’t believe what we say? Maybe our partners’ words are</p>
          </div>
          <button
            className={`${styles.slick_prev}`}
            onClick={() => slider?.current?.slickPrev()}
          >
            <i>
              <BsArrowLeft />
            </i>
          </button>
          <button
            className={`${styles.slick_next}`}
            onClick={() => slider?.current?.slickNext()}
          >
            <i>
              <BsArrowRight />
            </i>
          </button>
          <Slider
            ref={slider}
            arrows={false}
            slidesToShow={2}
            slidesToScroll={1}
            swipeToSlide={true}
            focusOnSelect={true}
            responsive={slider2ResponsiveSettings}
            // autoplay={true}
            // autoplaySpeed={3000}
            speed={3000}
          >
            <div>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={sandraJensen} alt="sandra jensen" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Sandra Jensen</h4>
                    <h6>CMO, Seniors Blue Book</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p>
                    "While the work is ongoing, the client has been pleased with
                    the progress and they're looking forward to the project's
                    completion. Scrum Digital Pvt Ltd establishes an effective
                    workflow through transparent communication. The team uses
                    Jira for monitoring tasks and Slack for sharing updates."
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
            <div className={`${styles.content_tp}`}>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={marcCherabie} alt="marc c Cherabie" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Marc C Cherabie</h4>
                    <h6>Executive Director of Sales, SiteStaff LLC</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p> So far, stakeholders have been pleased with Scrum Digital Pvt Ltd’s performance. They use various technologies to ensure smooth workflow and seamless communication, which the client appreciates. The team builds whatever the firm asks, and they do it in a personalized and cost-effective manner.
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={pankajSharma} alt="pankaj Sharma" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Pankaj Sharma</h4>
                    <h6>Co-Founder, Nouveausurge</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p>The client’s success is in part from Scrum Digital Pvt Ltd's deliverables. While the iOS app continues in the testing phase, the Android app has helped the client gain 200 users and 12 service providers. The client is very optimistic about the results of both products; they expect positive feedback.
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
            <div className={`${styles.content_tp}`}>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={gauravBasra} alt="gaurav Basra" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Gaurav Basra</h4>
                    <h6>Director of Special Projects, SiteStaff LLC</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p>The quality of product delivered by Scrum Digital Pvt Ltd was high. Their flexibility and adaptability impressed, allowing the agile development process to succeed. Their team was communicative, transparent, and confident, delivering the project on-budget and ahead of schedule.
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={drIzadi} alt="dr Izadi" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Dr. Mazier Izadi</h4>
                    <h6>General Dentist</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p>Working with Scrum Digital was a game-changer for our business. Their expertise in website development and digital marketing significantly boosted our online presence. From creating a user-friendly website to implementing effective digital strategies, they exceeded our expectations. Their dedication, professionalism, and results-driven approach truly set them apart.
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
            <div className={`${styles.content_tp}`}>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={drAmir} alt="dr Amir" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Dr. Amir Fereydouni</h4>
                    <h6>General Dentist</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p>Thrilled with the results! Scrum Digital nailed our dental website's development and digital marketing. The site is polished, user-friendly, and ranks high on search engines. Their expertise brought in new clients regularly. Exceptional service, highly recommend for anyone seeking top-notch website development and digital marketing solutions.
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.content}>
                <div className={styles.content_img_sec}>
                  <div className={styles.content_img}>
                    <img src={drAvisha} alt="dr Avisha" className="img-fluid" />
                  </div>
                  <div className={styles.content_img_heading}>
                    <div className="text-center">
                      <i><BiSolidQuoteAltRight /></i>
                    </div>
                    <h4>Dr. Avisha Mathur</h4>
                    <h6>Founder Urja Healing</h6>
                  </div>
                </div>
                <div className={styles.details}>
                  <p>Exceptional service from Scrum Digital! Their website development and digital marketing expertise have been instrumental in establishing my healing website. They captured the essence of my practice beautifully in the design and effectively promoted it online. Grateful for their professionalism and dedication to delivering outstanding results.
                    <i><BiSolidQuoteAltRight /></i>
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </Container>
      </section>
    </>
  );
};

export default OurClientSec;
