import React from "react";
import ChatbotDevelopmentServices from "../../components/ChatbotComponents/ChatbotDevelopmentServices";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import ChatbotBenefits from "../../components/ChatbotComponents/ChatbotBenefits";
import TeamInnovation from "../../components/Home/TeamInnovation";
import ChatbotSolutionWeOffer from "../../components/ChatbotComponents/ChatbotSolutionWeOffer";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import ChatbotTechnologyWeWork from "../../components/ChatbotComponents/ChatbotTechnologyWeWork";
import NormalCta from "../../components/cta/NormalCta";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import ChatbotBanner from "../../components/ChatbotComponents/ChatbotBanner";
import HeadMetaSec from "../../components/HeadMetaSec";
import HmgBanner from "../../components/Home/HmgBanner";
import { ChatbotBannerImg, serviceBannerChatbotDev } from "../../assets/img";
import { ChatbotPageData } from "../../components/ChatbotComponents/ChatbotPageData";


const ChatBot = () =>{
//  const  helmetSec= {
//     title: "Chatbot Development Company in USA | Scrum Digital",
//     description:
//       "Innovative chatbot development company creating chatbot solutions for improved user interactions. Elevate your business with custom chatbot services.Connect today!",
//     keywords:
//       "chatbot development company, chatbot development services, chatbot solutions, open ai chatbot, generative ai",
//     conical: "/chatbot-development-services",
//   }
//       const ctaContent={
//         ctaHeading:<>Get started <span>with our advanced</span> chatbot services!</>,
//          btnName:<>Book Free Demo <i><HiOutlineArrowNarrowRight /></i></>,
//       }
//       const normalCtaData = {
//         title:<>Say hello to 24/7 support! Experience  <br/><span>our chatbot services now.</span></>
//       }
//       const developmentServicesData = {
//         heading:<>Delivering Tailored <span className="">Chatbot Solutions</span>, Perfectly Suited to You.</>,
//         desc:"Our chatbot solutions stand at the forefront of digital innovation, driving transformative interactions that redefine user experiences, to elevate your customer service to new heights of excellence.",
//         serviceList:[
//           {
//             id: 1,
//             title: (
//               <>
//                 Chatbot <span>Design</span>
//               </>
//             ),
//             desc: (
//               <>
//                 We work for your unique needs and craft smart, domain-specific chatbots that feel like they're truly part of your team.
//               </>
//             ),
//             href: "/",
//           },
//           {
//             id: 2,
//             title: (
//               <>
//                 Chatbot <span>Architecture </span>
//               </>
//             ),
//             desc: (
//               <>
//                 Our chatbots are like social butterflies - they can mingle across various platforms and domains, making every customer interaction feel personal and tailored just for them.
//               </>
//             ),
//             href: "/",
//           },
//           {
//             id: 3,
//             title: (
//               <>
//                 Conversational <span>Intelligence </span>
//               </>
//             ),
//             desc: (
//               <>
//                 Thanks to Natural Language Processing (NLP), our chatbots do more than just "talk." They understand emotions, grasp intentions, and even sense sentiments, making decisions that add a new layer of sophistication to customer interactions.
//               </>
//             ),
//             href: "/",
//           },
//           {
//             id: 4,
//             title: (
//               <>
//                 Chatbot <span>Consultancy</span>
//               </>
//             ),
//             desc: (
//               <>
//                 We're here to help with complimentary consulting, packed with insights and tailored advice to ensure your chatbot ranks among the best AI chatbots, making it the best it can be for your industry.
//               </>
//             ),
//             href: "/",
//           },
//           {
//             id: 5,
//             title: (
//               <>
//                 Chatbot <span>Integration </span>
//               </>
//             ),
//             desc: (
//               <>
//                 With our chatbot integration services, we ensure your chatbot evolves, getting smarter with each interaction by adapting to new trends and customer feedback, promising a constantly enriching experience.
//               </>
//             ),
//             href: "/",
//           },
//           {
//             id: 6,
//             title: (
//               <>
//                 Chatbot <span>Analytics</span>
//               </>
//             ),
//             desc: (
//               <>
//                 After your chatbot goes live, we don't stop there! We keep an eye on how it's doing, so you can see what's working well and what needs improvement. This helps make your chatbot even better for your users.
//               </>
//             ),
//             href: "/",
//           },
//         ]
//       }
//       const technologyWeWorkData = {
//         heading:<><span className="text-uppercase">Crafting innovations</span> tailored to your unique sector</>,
//         desc:"Shaping unique solutions, just for your industry's needs."
//       }
//       const bannerData = {
//         subHeading:"Your search ends here",
//         heading:<>Chatbot Development <br/> Services</>,
//         desc:"Intelligent AI Chatbot Solutions for Effortless Customer Conversations.",
//         HomeBannerAwards:false,
//         // bannerImg:ChatbotBannerImg,
//         bannerImg:serviceBannerChatbotDev,
//         alt:"chatbot banner"
//       }
    return (
        <>
        <HeadMetaSec HeadSecData={ChatbotPageData.helmetSec}/>
        {/* <ChatbotBanner/> */}
        <HmgBanner bannerData={ChatbotPageData.bannerData} />
        <ChatbotDevelopmentServices developmentServicesData={ChatbotPageData.developmentServicesData}/>
        <OnDemandFoodCta  {...ChatbotPageData.ctaContent} />
        <ChatbotBenefits/>
        <TeamInnovation/>
        <ChatbotSolutionWeOffer/>
        <InnovatingJust />
        <ChatbotTechnologyWeWork technologyWeWorkData = {ChatbotPageData.technologyWeWorkData}/>
        <NormalCta normalCtaData={ChatbotPageData.normalCtaData} />
        <B2BSec/>
        <OurClientSec/>
        <OurBlogSec/>
        </>
    )
}

export default ChatBot;
