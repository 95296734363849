import styles from "../../assets/css/service/getstarted.module.scss";

const GetStarted = ({ getStartedData }) => {
  return (
    <section className={`${styles.get_start_section}`}>
      <div className="container">
        <div className="service-heading text-center">
          <h2>{getStartedData.title}</h2>
          <p className="text-muted">{getStartedData.desc}</p>
        </div>
        <div className={`${styles.row_top} row`}>
          {getStartedData.StartedList.map((startData, index) => {
            return (
              <div className="col-xl-3 col-md-4 col-sm-6 pad-response mx-auto" key={index}>
                <div className={`${styles.get_sec_card}`}>
                  <div className={`${styles.img_get}`}>
                    <img src={startData.src} alt={startData.title} />
                  </div>
                  <div className={`${styles.contes}`}>
                    <h5>{startData.title}</h5>
                    <p>{startData.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default GetStarted;
