import {
  serviceneedwebdesining,
  serviceneedwebmantance,
  serviceneedmobile,
  serviceneeddevelopment,
  serviceneedchatbot,
  serviceneeddigitalicon,
  serviceneedcontenticon,
  serviceneedconsultation,
  getStartPlan,
  getStartGoal,
  getStartGoLive,
  neseceryweb,
  nesecerywebdevelop,
  nesecerybussions,
  neseceryscrum,
} from "../../assets/img/serviceneed";
import {
  DigitalMarketingSocialMediaService,
  DigitalMarketingOrmService,
  DigitalMarketingPpcService,
  DigitalMarketingSeoService,
} from "../../assets/img";
import { HiOutlineUserGroup } from "react-icons/hi";
import { SiGoogleanalytics } from "react-icons/si";
import { AiOutlineCrown } from "react-icons/ai";
import { FaBarsProgress } from "react-icons/fa6";

export const ServiceGenerateData = {
  helmetSec: {
    title: "Web & App Development, Digital Marketing Services | ScrumDigital",
    description:
      "Scrum Digital managed services to develops businesses through strategy, design, and the development of the products, from startup to top level company.",
    keywords: "",
    conical: "/services",
  },
  serviceSec: {
    bg: {
      backgroundColor: "#000f49",
    },
    title: (
      <>
        <span>Digital Marketing Services</span>
      </>
    ),
    desc: "Our expert team delivers comprehensive marketing services that encompass each phase of your customer's journey and sales funnel. Whether you aim to elevate your search rankings, secure a competitive advantage, or boost your online presence, our specialised professionals are equipped to assist you with each requirement.    ",
    list: [
      {
        id: 1,
        source: DigitalMarketingSeoService,
        alt: "seo",
        title: (
          <>
            SEO <span>Services</span>
          </>
        ),
        content:
          "If you’re looking for first page rankings on your preferred search engines like Google, Bing, Yahoo, etc. Hire us for one of the finest SEO services in the industry with a dedicated resource who will ensure that your website gets the highest visibility and desired traffic.",
      },
      {
        id: 2,
        source: DigitalMarketingSocialMediaService,
        alt: "social media",
        title: (
          <>
            Social <span>Media</span>
          </>
        ),
        content:
          "Social media is the most potent platform for branding and reaching the potential audience. We possess the right talent who will create compelling and engaging posts, interact with customers and help to drive revenue for your business.",
      },
      {
        id: 3,
        source: DigitalMarketingOrmService,
        alt: "orm service",
        title: (
          <>
            Online Reputation <span>Management</span>
          </>
        ),
        content:
          "To stay relevant in the digital world, a brand requires a positive and reputable image. Our ORM services can help you to manage, protect or repair your brand image online. We can also help you get rid of the negative reviews if any.",
      },
      {
        id: 4,
        source: DigitalMarketingPpcService,
        alt: "ppc service",
        title: (
          <>
            PPC <span>Advertising</span>
          </>
        ),
        content:
          "We can help you gain instant ranking on the top pages of your preferred search engines with our best PPC services. We have the expertise to run successful PPC campaigns for clients as per their budget that delivers high-quality leads and revenue.",
      },
    ],
  },
  OurServiceSec: {
    heading: <>Our Services</>,
    desc: "Scrum Digital has a wide spectrum of digital services with a customer-first approach that helps you lay a strong foundation for digital growth. Understanding the business needs and industry practices, our experts serve premium services combined with strong management practices that help businesses enhance their profitability as well as drive long-term value.",
    OurServiceList: [
      {
        id: 1,
        href: "/ui-ux-development-services",
        src: serviceneedwebdesining,
        heading: "Website Design & Development",
      },
      {
        id: 2,
        href: "/web-development-services",
        src: serviceneedwebmantance,
        heading: "Website Maintenance",
      },
      {
        id: 3,
        href: "/mobile-application-development",
        src: serviceneedmobile,
        heading: "Mobile Application Development",
      },
      {
        id: 4,
        href: "/web-development-services",
        src: serviceneeddevelopment,
        heading: "Web Application Development",
      },
      {
        id: 5,
        href: "/chatbot-development-services",
        src: serviceneedchatbot,
        heading: "AI Assistant Services",
      },
      {
        id: 6,
        href: "/digital-marketing-services",
        src: serviceneeddigitalicon,
        heading: "Digital Marketing",
      },
      {
        id: 7,
        href: "/digital-marketing-services",
        src: serviceneedcontenticon,
        heading: "Content Creation",
      },
      {
        id: 8,
        href: "/startup-consulting-services",
        src: serviceneedconsultation,
        heading: "Consulting and Auditing",
      },
    ],
  },
  GetStartedSec: {
    title: (
      <>Fast-Track to Internet Stardom with Our Digital Marketing Approach</>
    ),
    desc: "With the optimal mix of tools and strategies, we ensure your brand goes viral, yielding maximum return on your marketing investment.",
    StartedList: [
      {
        id: 1,
        src: getStartPlan,
        title: "Goal Analysis",
        desc: "Assisting brands in scrutinizing their business objectives.",
      },
      {
        id: 2,
        src: getStartGoal,
        title: "Strategic Planning",
        desc: "Crafting plans for SEO, SMM, PPC, and Content, while identifying ideal platforms for your brand.",
      },
      {
        id: 3,
        src: getStartGoLive,
        title: "Campaign Execution",
        desc: "Scheduling and divulging comprehensive campaign details.",
      },
      {
        id: 4,
        src: getStartGoLive,
        title: "Performance Monitoring",
        desc: "Observing campaign performance and making necessary adjustments for optimal results.",
      },
    ],
  },
  NecessarySec: [
    {
      id: 1,
      webHeading: "Why Is It Necessary To Have A Good Website?",
      prop: "Your website is a highly effective marketing tool and introduces your brand to potential clients from across the world, you get a global outreach for your business. Having a good website also offers you the credibility, trust, and confidence of your existing customers and prospective clients. Having a responsive, user-friendly website also means intuitive web design, easy navigation, and swift page loading, which contribute greatly to customer experience. A well-designed website design can increase your conversion rates up to 400% and serve a lasting impression on visitors and improve your brand recall value, and boost your sales significantly.",
      src: neseceryweb,
      orderImg: "order-1 order-lg-2",
      orderContent: "order-2 order-lg-1",
    },
    {
      id: 2,
      webHeading:
        "Why Web Or Mobile Application Is Important For Your Business?",
      prop: "A website or mobile application is the best way to earn the trust of your target audience instantly. Web app development services help businesses to develop customized web applications for maximizing their business outreach. Scrum Digital has been providing web app development for global clients, helping gain a cost-effective competitive edge through responsive, user-friendly and high-performing web applications. With comprehensive web app development services, your business can improve user experience, contributing to qualified lead generation.",
      src: nesecerywebdevelop,
      orderContent: "order-2",
      orderImg: "order-1",
    },
    {
      id: 3,
      webHeading: "How Will Digital Marketing Help Your Business?",
      prop: "Digital Marketing is the most cost-efficient way of spreading awareness about your brand and promoting your business through different online platforms. Most people look up search engines and social media platforms whenever they need anything. Today, most people are shopping online because of the flexibility and convenience. Therefore, if your business needs any marketing, the digital medium is the best option to invest in. Scrum Digital has been creating and executing numerous digital marketing campaigns enhancing the popularity of our client's business. Our digital marketing campaigns take an omnichannel approach to take your engagement rates and ROIs to the next level.",
      src: nesecerybussions,
      orderImg: "order-1 order-lg-2",
      orderContent: "order-2 order-lg-1",
    },
    {
      id: 4,
      webHeading: "Why You Should Hire Scrum Digital?",
      prop: "Scrum Digital provides end-to-end IT solutions and consulting services for emerging startups, medium-sized businesses, and large organizations. We have provided IT and marketing services to multiple organizations that have experienced dramatic improvement in staff productivity as well as customer experience. With a spotless record of customer success, Scrum Digital has the capability to help you pace up business performance with robust web development services and boost your revenue generation through effective digital marketing services. We are focused on establishing long-term relationships with clients enabling them to leverage technology and digital medium to thrive in their businesses.",
      src: neseceryscrum,
      orderContent: "order-2",
      orderImg: "order-1",
    },
  ],
  DigitalPartnerSec: {
    heading: <>Expertise Unmatched Globally!</>,
    desc: "Unlock unparalleled digital success with our seasoned marketing mavens, dedicated to catapulting your brand's online presence and visibility. Experience a surge in sales and brand loyalty as we deploy cutting-edge strategies tailored to your unique business landscape.",
    partnerList: [
      {
        id: 1,
        icon: <HiOutlineUserGroup />,
        title: "Global Reach",
        description:
          "Seize the chance to broaden your global reach through our dynamic digital marketing strategies.",
      },
      {
        id: 2,
        icon: <SiGoogleanalytics />,
        title: "Cost- Effective Marketing",
        description:
          "We provide budget-friendly marketing solutions that not only save you money but also effortlessly attract valuable leads.",
      },
      {
        id: 3,
        icon: <FaBarsProgress />,
        title: "Measurable Results",
        description:
          "We diligently monitor business development, ensuring timely delivery and tangible outcomes that meet your expectations",
      },
      // {
      //   id: 4,
      //   icon: <AiOutlineCrown />,
      //   title: "Customer Success",
      //   description:
      //     "Scrum Digital has been providing services that helped multiple clients achieve their objectives adding to their delightful experience of working with us.",
      // },
    ],
  },
};
