import React from "react";
import styles from "../../assets/css/hmg-projects-helped-our-clients.module.scss";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { clientSliderImg1, clientSliderImg2, clientSliderImg3, clientSliderImg4, clientSliderImg5, clientSliderImg6, clientSliderImg7, clientSliderImg8, familyDentalTestimonial, knfddsTestimonial, premierSeniorsTestimonial, seniorBlueBookTestimonial, siteStaffTestimonial, skillsnmoreTestimonial, statWorldTestimonial, urjaTestimonial } from "../../assets/img";

const ProjectsHelpedOurClients = (props) => {
  const slider = React.useRef(null);
  const clients = [
    {
      id:1,
      imgSrc:premierSeniorsTestimonial,
      alt:"premier seniors testimonial"
    },
    {
      id:2,
      imgSrc:familyDentalTestimonial,
      alt:"family dental testimonial"
    },
    {
      id:3,
      imgSrc:knfddsTestimonial,
      alt:"knfdds testimonial"
    },
    {
      id:4,
      imgSrc:statWorldTestimonial,
      alt:"statWorld testimonial"
    },
    {
      id:5,
      imgSrc:siteStaffTestimonial,
      alt:"site staff testimonial"
    },
    {
      id:6,
      imgSrc:urjaTestimonial,
      alt:"urja healing testimonial"
    },
    {
      id:7,
      imgSrc:seniorBlueBookTestimonial,
      alt:"senior blue book testimonial"
    },
    {
      id:8,
      imgSrc:skillsnmoreTestimonial,
      alt:"skillsnmore testimonial"
    },
  ];
  const settings = {
    centerMode: true,
    centerPadding: "70px",
    slidesToShow: 1,
    adaptiveHeight: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          centerPadding: "10px",
          autoplay: true,
          autoplaySpeed: 0,
          speed: 3000,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section
        className={`pdtb hmg-projects-helped ${styles.projects_helped}`}
        style={props.Bg && props.Bg}
      >
        <Container>
          <div className="sub-heading-outline text-center">
            <h2>Where Client Dreams Meet <span className="text-uppercase d-inline d-md-block">Achievement</span></h2>
            <p>
              With advanced technology amalgamated with a creative mindset, we
              try to bring on the table solutions that are revolutionizing the
              digital transformation journey for others online. We turn vision
              into an actionable reality that builds your brand image in the
              market.
            </p>
          </div>
          <div className={styles.slider_box}>
            <button
              className={`${styles.slick_prev} d-none d-sm-block`}
              onClick={() => slider?.current?.slickPrev()}
            >
              <i>
                <BsArrowLeft />
              </i>
            </button>
            <button
              className={`${styles.slick_next} d-none d-sm-block`}
              onClick={() => slider?.current?.slickNext()}
            >
              <i>
                <BsArrowRight />
              </i>
            </button>
            <Slider ref={slider} {...settings}>
              {clients.map((client, index) => (
                <div key={index}>
                  <img src={client.imgSrc} alt={client.alt} />
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ProjectsHelpedOurClients;
