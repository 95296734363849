import { Container } from "react-bootstrap";
import styles from "../../assets/css/service/businessgrowth.module.scss";

const BusinessGrowth = () => {
  return (
    <section className={`${styles.business_growth}`}>
      <Container>
        <div className="row justify-content-center">
          <div className="col-lg-7 service-heading text-center text-white">
            <h2> Unleash Business Growth Through Digital Transformation</h2>
            <p className="text-white mb-0">
              Discover the premium services to unlock the world of
              possibilities, driving your business towards success.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default BusinessGrowth;
