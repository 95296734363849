import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import PortfolioList from "../../components/portfolio/PortfolioList";
import BannerAbout from "../../components/Banner/BannerAbout";
import { PortfolioPageData } from "../../components/jsonData/PortfolioPageData";
import HeadMetaSec from "../../components/HeadMetaSec";

function Portfolio() {
  return (
    <>
      <HeadMetaSec HeadSecData={PortfolioPageData.helmetSec} />
      <BannerAbout bannerData={PortfolioPageData.bannerSec} />
      <PortfolioList />
      <B2BSec />
      <OurClientSec />
      <OurBlogSec />
    </>
  );
}

export default Portfolio;
