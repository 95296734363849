export const ContactUsPageData = {
  helmetSec: {
    title: "Contact Us | Scrum Digital",
    description:
      "Scrum Digital is the most responsive to inquires and emails. Feel free to contact us if you have any queries.",
    keywords: "Contact Us",
    conical: "/contact-us",
  },
  bannerSec: {
    banner_hading: "Contact ",
    banner_span: "Us",
    banner_bott_head:
      "Let’s discuss your ideas and give them a flight! Drop us a note here and we will get back to you within 24 hours.",
  },
};
