import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../../assets/css/chat-bot-development-services.module.scss";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ChatbotDevelopmentServices = ({developmentServicesData}) => {
  return (
    <section className={`pdtb ${styles.chatbot_dev_service} ${developmentServicesData.paddingTop ?developmentServicesData.paddingTop :""}`}>
      <Container className="container-lg position-relative">
        <Row>
          <Col lg="10" className="mx-auto">
            <div className="sub-heading-outline text-center mb-md-0">
              <h2>{developmentServicesData?.heading}</h2>
              <p>{developmentServicesData?.desc}</p>
            </div>
          </Col>
        </Row>
        <ul>
          {developmentServicesData?.serviceList.map((list) => (
            <li key={list.id}>
              <div className={styles.chatbot_dev_service_list}>
                <div>
                  <h3>{list.title}</h3>
                </div>
                <p>{list.desc}</p>
                {/* <Link to={list.href}>
                  Discover More
                  <i>
                    <BsArrowRight />
                  </i>
                </Link> */}
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default ChatbotDevelopmentServices;
