import React, { useState, useEffect } from "react";
import styles from "../../../assets/css/hmg-b2b-sec.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { B2BCompaniesData } from "./B2BCompanyData";

const B2BSec = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(B2BCompaniesData);
  }, []);
  const slider2ResponsiveSettings = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow:5,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
  ];

  return (
    <section className={`pdtb b2b-sec ${styles.b2b_sec}`}>
      <Container className={styles.container}>
        <div className={`sub-heading-outline text-center ${styles.sub_heading}`}>
          <h2>{data.heading}</h2>
          <p>{data.desc}</p>
        </div>
        <div className={`row align-items-end `}>
          <div className={`col-lg-12`}>
            <div className={styles.b2b_right}>
              <div className={styles.thumbnail_slider}>
                <Slider
                  arrows={false}
                  slidesToShow={7}
                  slidesToScroll={1}
                  infinite={true}
                  // swipeToSlide={true}
                  // focusOnSelect={true}
                  responsive={slider2ResponsiveSettings}
                  autoplay={true}
                  autoplaySpeed={1000}
                  speed={1000}
                >
                  {data.b2bCompanyTechnologyLogo &&
                    data.b2bCompanyTechnologyLogo.map((logo, index) => (
                      <div key={index}>
                        <Link to={logo.href}>
                          <img
                            src={logo.src}
                            className="img-fluid"
                            alt={logo.alt}
                            target="_blank"
                          />
                        </Link>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default B2BSec;