import React from "react";
import styles from "../../../assets/css/HmgAboutSec.module.scss";
import { HmgAboutIcon2, HmgAboutCalendar, HmgAboutApps, HmgAboutInHouseTeam, HmgAboutCustomerSatisfaction, HmgAboutExperts1, aboutUsProviding } from "../../../assets/img";
import HmgAboutList from "./HmgAboutList";
const HmgAboutSec = () => {
  const data = {
    mainImgAlt:"home your vision",
    heading: <>Your Vision, <span className="text-uppercase">Our Code</span></>,
    className: `sub-heading-outline`,
    desc: <>
      <p className="mb-4">Scrum Digital is a premier software development company and IT consulting services provider that specializes in offering end-to-end digital technology solutions. Our goal is to enhance customer experiences across various business processes through our cutting-edge digital transformation services and innovative software development strategies. We play a pivotal role in our customer's digital transformation journey by seamlessly integrating our extensive digital capabilities with our foundational core processes.</p>
      <p>Our collaborative approach, coupled with our competent team of experts in digital marketing services, empowers us to tackle intricate customer business challenges and deliver optimum solutions tailored to meet the unique needs of our clients.</p>
    </>,
    list: [
      {
        id: 1,
        title: "5+",
        imgSrc: HmgAboutCalendar,
        desc: "Years of Experience",
        alt: "Experience"
      },
      {
        id: 2,
        title: "70+",
        imgSrc: HmgAboutIcon2,
        desc: "Clients",
        alt: "clients"
      },

      {
        id: 3,
        title: "100+",
        imgSrc: HmgAboutApps,
        desc: "Project Delivered",
        alt: "Project Delivered"
      },
      {
        id: 4,
        title: "35+",
        imgSrc: HmgAboutExperts1,
        desc: "Senior Experts",
        alt: "Experts"
      },
      {
        id: 5,
        title: "100%",
        imgSrc: HmgAboutCustomerSatisfaction,
        desc: "Customer Satisfaction",
        alt: "Customer Satisfaction"
      },
    ]
  }
  return (
    <>
      <section className={`pdtb ${styles.hmg_about_sec}`}>
        <div className="container-lg">
          <HmgAboutList {...data} />
        </div>
      </section>
    </>
  );
};

export default HmgAboutSec;
