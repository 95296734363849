import React from "react";
import styles from "../../../assets/css/fantasy-sports-dev-services.module.scss";
import { Container } from "react-bootstrap";
import { serviceList } from "./FantasySportsDevServicesData";

const FantasySportsDevServices = () => {
  return (
    <section className={`pdtb ${styles.sports_services}`}>
      <Container>
        <div className="row">
          <div className="col-lg-10 mx-auto text-center sub-heading">
            <h2 className="text-white">
              <span>Fantasy Sports </span> App Development Services
            </h2>
            <p className="text-white">
              Development excellence along with finesses in complex technology,
              encourage us to deploy your fantasy sports solution on-time and
              assist you from idea to launch
            </p>
          </div>
        </div>
        <ul className={`d-flex flex-wrap ${styles.services_list}`}>
          {serviceList.map((elem, index) => {
            return (
              <li key={index}>
                <div className={styles.service_list_inner}>
                  <h2>{elem.title}</h2>
                  <p>{elem.description}</p>
                  <img src={elem.imgSrc} alt={elem.alt} />
                </div>
              </li>
            );
          })}
        </ul>
      </Container>
    </section>
  );
};

export default FantasySportsDevServices;
