import React, { useState, useEffect } from "react";
import { trustedData } from "./TrustedData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styles from "../../../assets/css/delivery-service-trusted-by.module.scss";

const TrustedBy = () => {
  // const [data, setData] = useEffect();

  // useEffect(() => {
  //   setData(trustedData);
  // }, []);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 0,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <section className={`${styles.trusted_by}`}>
        <div className="container-fluid">
          <div className={styles.heading}>
            <h3>
              <span>
                TRUSTED BY FORTUNE 500 COMPANIES & LEADING STARTUPS GLOBALLY
              </span>
            </h3>
          </div>
          <div className={styles.company_logo_slider}>
            <Slider {...settings}>
              {trustedData.map((elem, index) => (
                <div key={index}>
                  {" "}
                  <img src={elem.src} alt={elem.alt} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrustedBy;
