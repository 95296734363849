import React from "react";
import { Container } from "react-bootstrap";
import BannerContact from "../../components/Banner/ContactBanner";
import { PrivacyPolicyData } from "../../components/jsonData/PrivacyPolicyData";
import HeadMetaSec from "../../components/HeadMetaSec";

const PrivacyPolicy = () => {
  return (
    <>
      <HeadMetaSec HeadSecData={PrivacyPolicyData.helmetSec} />
      <BannerContact bannerData={PrivacyPolicyData.bannerSec} />
      <section className="pdtb privacy-term-sec">
        <Container>{PrivacyPolicyData.privacyDesc}</Container>
      </section>
    </>
  );
};

export default PrivacyPolicy;
