import {
  about_us,
  application,
  chatbot,
  cloud_computing,
  crm,
  settings
} from "./../../assets/img";

export const AboutPageData = {
  helmetSec: {
    title: "Online Marketing Company | About Scrum Digital",
    description:
      "Scrum Digital is an award-winning mobile app development company providing digital solutions to customers along with timely delivery of the project.",
    keywords: "About Us, Online Marketing Company",
    conical: "/about-us",
  },
  bannerSec: {
    bannerHeading:<>We Help Startups and Businesses <span>Build a Digital Face</span></>,
    banner_bott_head:
      "Our tech-geeks showcase excellent skills with creativity and innovation to bring on the screen an aesthetically strong experience",
    bannerImg: about_us,
    alt: "about us",
  },
  AboutWhatWeOfferData: {
    heading: (
      <>
        <span>What </span> We Offer ?
      </>
    ),
    desc: "Scrum Digital provides end-to-end IT consulting and digital technology solutions to deliver better customer experience in any business process. We help customers on their digital transformation journey by connecting a distinctive set of digital capabilities integrated with our core processes. We work collaboratively with our clients to deliver the best solutions and possess the right team to address complex customer business challenges.",
    list: [
      {
        id: 1,
        href: "/application-development-services",
        title: (
          <>
            Application <span>Services</span>
          </>
        ),
        src: application,
        alt: "application",
      },
      {
        id: 2,
        href: "/chatbot-development-services",
        title: (
          <>
            ChatBot <span>Solutions</span>
          </>
        ),
        src: chatbot,
        alt: "chatbot",
      },
      {
        id: 3,
        href: "/crm-development-solutions",
        title: (
          <>
            CRM <span>Solutions</span>
          </>
        ),
        src: crm,
        alt: "crm",
      },
      {
        id: 4,
        href: "/erp-consulting-services",
        title: (
          <>
            ERP <span>Solutions</span>
          </>
        ),
        src: settings,
        alt: "Erp",
      },
      {
        id: 5,
        href: "/cloud-computing-solutions",
        title: (
          <>
            Cloud <span>Solutions</span>
          </>
        ),
        src: cloud_computing,
        alt: "cloud computing",
      },
    ],
  },
};
