import React from "react";
import { Container } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import styles from "../../assets/css/faq.module.scss";
import { faqImg } from "../../assets/img";
import { BiChevronDown } from "react-icons/bi";

const FAQ = ({ faqData }) => {
  return (
    <>
      <div
        className={`pdtb ${styles["faq-bg"]}`}
        style={faqData.faqBg && faqData.faqBg}
      >
        <Container>
          <div className="row justify-content-center">
            <div className={`col-lg-10 sub-heading ${styles.faq_sub_heading}`}>
              <h2>{faqData.title}</h2>
              {faqData.desc && <p>{faqData.desc}</p>}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <Accordion>
                {faqData.inner.map((faq, index) => (
                  <Accordion.Item eventKey={faq.id} key={index}>
                    <Accordion.Header>
                      {faq.question}{" "}
                      <span>
                        <BiChevronDown />
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {faq.para && <>{faq.para}</>}
                      {!Array.isArray(faq.answer) ? (
                        faq.answer
                      ) : (
                        <ul>
                          {faq.answer.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })}
                        </ul>
                      )}
                      {faq.paraEnd && <p>{faq.paraEnd}</p>}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
              {faqData.Note && <p className="mb-0 mt-4">{faqData.Note}</p>}
            </div>
            {/* <div className="col-lg-5 mt-lg-0 mt-md-5 mt-sm-5 mt-5">
              <img src={faqImg} alt="faq" className="img-fluid" />
            </div> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default FAQ;
