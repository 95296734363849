import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import TextErrorMsg from "../../TextErrorMsg";
import Loader from "./Loader";
import ModalFormSubmit from "./ModalFormSubmit";
import { toastSuccess } from "../../../ToastOver";
import { ToastContainer } from "react-toastify";

const validation = Yup.object().shape({
  name: Yup.string().required("Name is Required*"),
  email: Yup.string().email().required("Email is Required*"),
  // skype: Yup.string().required("skype is Required*"),
  // skype: Yup.string().when('isSkype', {
  //   is: (skype) => skype === true,
  //   then: Yup.string().required("Skype is Required*"),
  // }),
  textarea: Yup.string().required("About Your Project Required*"),
});



const FormikForm = ({ formFieldData, modalHide }) => {
  const [phone, setPhone] = useState("");
  // const [show, setShow] = useState(false);
  // const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [validatePhone, setValidatePhone] = useState(false)
  const API_PATH = "https://myleads.scrumdigital.in/api/leads";
  // const mod = <ModalFormSubmit show={() => setShowModal(true)} />;
  const hideSuccess = () => {
    setShowSuccess(false);
  };

  const handleOnChange = (e, type) => {
    if (type === "phone") {
      if(e && isValidPhoneNumber(e)){
        setValidatePhone(false)
        setPhone(e)
      }else{
        setValidatePhone(true)
        return;
      }

    }
  }

  async function FormSubmit(values, { resetForm }) {
    setLoading(true);
    let formData = {
      clientID: "Vz8FwKeO4VbGjiBu",
      name: values.name,
      email: values.email,
      phone,
      textarea: values.textarea,
      skype: values.skype,
      accept:values.accept,
      send:values.send,
      title:values.title,
      businessName:values.businessName,
      address:values.address,
      Budget:values.Budget,
      interested:values.interested,
      uuid: localStorage.getItem("scrum-visitor-trackingId"),
    };
    try{
      if(phone && isValidPhoneNumber(phone)){
        axios({
          method: "POST",
          url: `${API_PATH}`,
          headers: { "content-type": "application/json" },
          data: JSON.stringify(formData),
        })
          .then((result) => {
            // setShow(false);
            resetForm();
            setPhone("");
            setLoading(false);
            modalHide(false)
            // setShowSuccess(true)
            toastSuccess("success")
          })
          .catch((error) => {
            setLoading(false);
            console.log("error", error);
          });
      }else{
        // console.log("please enter phone number");
        // toastError("That phone doesn't look quite right")
        setValidatePhone(true)
        setLoading(false);
      }
    }catch(err){
      console.log("Error is :", err)
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          mobileNumber: "",
          contactNo:"",
          skype: "",
          textarea: "",
          Budget:"",
          interested:"",
          title:"",
          businessName:"",
          address:"",
          accept:false,
          send:false,
        }}
        validationSchema={validation}
        onSubmit={FormSubmit}
      >
        {(formik) => {
          return (
            <>
            <ToastContainer/>
              <Form className="row contact-us-form">
                {formFieldData?.inputFieldData?.map((field, i) =>
                  field.as === "input" ? (
                    <div
                      className={`${field.col? field.col:"col-lg-4"} col-md-6 col-sm-6 input-box ${field?.custom_class?field?.custom_class:""}`}
                      key={i}
                    >
                      {field.name !== "mobileNumber" ? (
                       <>
                       {field.label && <label>{field.label}</label>}
                        <Field
                          as="input"
                          name={field.name}
                          type={field.type}
                          placeholder={field.placeholder}
                          className="form-control"
                          autoComplete="off"
                        />
                       </>
                      ) : (
                        <Field name="mobileNumber">
                          {({ field, form }) => (
                            <>
                            {field.label && <label>{field.label}</label>}
                              {/* <PhoneInput
                                name={field.name}
                                className="form-control"
                                defaultCountry="us"
                                value={phone}
                                showDisabledDialCodeAndPrefix={true}
                                onChange={(phone) => {
                                  form.setFieldValue(field.name, phone);
                                  setPhone(phone);
                                }}
                              /> */}
                              <PhoneInput
                                  maxLength={15}
                                  id={field.id}
                                  placeholder={field.label}
                                  required={field.required}
                                  name={field.name}
                                  value={phone}
                                  onChange={(data) => handleOnChange(data, "phone", field.id)}
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="US"
                                />
                                {validatePhone && <small className="text-danger">phone number must be valid <sup>*</sup> </small>}
                            </>
                          )}
                        </Field>
                      )}
                      <ErrorMessage
                        name={field.name}
                        component={TextErrorMsg}
                      />
                    </div>
                  ) : field.as === "select" ? (
                    <div
                      className={`col-lg-4 col-md-6 col-sm-6 input-box`}
                      key={i}
                    >
                      <Field
                        name={field.name}
                        as="select"
                        className="form-select"
                      >
                        {field.options.map((option, i) => (
                          <option value={option.value} key={i}>
                            {option.name}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name={field.name} />
                      </span>
                    </div>
                  ) : field.type === "checkbox" ? (
                    <div className={`col-xl-6 col-lg-6 col-md-6 d-flex input-box`} key={i}>
                      <Field
                        type={field.type}
                        id={field.pId}
                        name={field.name}
                        checked={formik.values[field.name]}
                        className="me-3"
                      ></Field>
                      <label htmlFor={field.pId} style={{ cursor:"pointer" }}>
                        {field.labelTitle}
                        {field.link && (
                          <Link to="/terms-of-use" target={field.target && field.target}>
                            {field.link}
                          </Link>
                        )}
                        <sup className="text-danger">*</sup>
                      </label>
                    </div>
                  ) : (
                    <div className={`col-lg-12 input-box`} key={i}>
                    {field.label && <label>{field.label}</label>}
                      <Field
                        as="textarea"
                        name={field.name}
                        className="form-control"
                        placeholder={field.placeholder}
                      />
                      <ErrorMessage
                        name={field.name}
                        component={TextErrorMsg}
                      />
                    </div>
                  )
                )}
                <div className={formFieldData?.btnData?.align_center ? "text-center":""}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button type="submit" className="theme-btn">
                      {formFieldData?.btnData?.btnName}
                    </Button>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <ModalFormSubmit
        show={showSuccess}
        hide={hideSuccess}
        title="Success"
        desc="We have received your information and one of our representative will get in touch with you shortly to schedule an appointment."
      />
    </>
  );
};
export default FormikForm;
