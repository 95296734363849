import React from "react";
import { Container } from "react-bootstrap";
import CountUp from "react-countup";
import { MdOutlineAppSettingsAlt } from "react-icons/md";
import styles from "../../assets/css/service-company-profile-counter.module.scss";
import { serviceAppCounterApp } from "../../assets/img";

const CompanyProfileCounter = ({ companyProfileCounterSec }) => {
  return (
    <>
      <section className={`pdtb ${styles.company_profile}`}>
        <Container>
          <div className="row">
            <div className="col-lg-10 mx-auto text-center sub-heading">
              <h2>{companyProfileCounterSec.title}</h2>
              <p>{companyProfileCounterSec.desc}</p>
            </div>
          </div>
          <ul className="row">
            {companyProfileCounterSec.counter.map((count, index) => (
              <li className="col-lg-4 col-md-4 col-sm-6 mx-auto" key={index}>
                <div className={styles.company_profile_card}>
                  <div className={styles.img_box}>
                    <img src={count.iconSrc} alt="app" />
                  </div>
                  <h6>{count.title}</h6>
                  <CountUp end={count.count} suffix=" +" />{" "}
                  <span>{count.prefix}</span>
                </div>
              </li>
            ))}
          </ul>
        </Container>
      </section>
    </>
  );
};

export default CompanyProfileCounter;
