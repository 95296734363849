import React from "react";
import styles from "../../assets/css/normal-cta.module.scss";
import { Container } from "react-bootstrap";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { NormalCtaImg } from "../../assets/img";
import Modal_form from "../Banner/Modal";

const NormalCta = ({normalCtaData}) => {
  return (
    <>
      <section className={styles.normal_cta}>
        <Container>
          <div className={`row ${styles.content_sec}`}>
            <div className="col-lg-12 text-center">
              <h2>{normalCtaData?.title}</h2>
              <Modal_form btnName={<>Book Free Demo{" "}
                <i>
                  <HiOutlineArrowNarrowRight />
                </i></>} customClass={styles.theme_btn} />
            </div>
          </div>
        </Container>
        <img src={NormalCtaImg} className={styles.bg_img} alt="ctaBg" />
      </section>
    </>
  );
};

export default NormalCta;
