import styles from "../../assets/css/service/DigitalPartner.module.scss";

const WhyChooseSec = ({ digitalPartnerData }) => {
  return (
    <section className={`${styles.digi_Partner}`}>
      <div className="container">
        <div className="service-heading text-center">
          <h2>{digitalPartnerData.heading}</h2>
          <p>{digitalPartnerData.desc}</p>
        </div>
        <div className="row">
          {digitalPartnerData.partnerList.map((result, index) => {
            return (
              <div
                className="col-lg-4 col-md-6 col-sm-6 pad-response mx-auto"
                key={index}
              >
                <div className={`${styles.expertise}`}>
                  <div className={`${styles.icon}`}>
                    <i>{result.icon}</i>
                  </div>
                  <h6>{result.title}</h6>
                  <p>{result.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default WhyChooseSec;
