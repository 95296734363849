import { HealthcareImg } from "../../../assets/img";
import { TravelImg } from "../../../assets/img";
import { RealState } from "../../../assets/img";
import { EducationImg } from "../../../assets/img";
import { FoodImg } from "../../../assets/img";
import { EntertainmentImg } from "../../../assets/img";
import { EcommerceImg } from "../../../assets/img";




export const data = [
  {
    id:1,
    imgSrc:HealthcareImg,
    alt:"Health Icon",
    title:"Healthcare Solutions"
  },
  {
    id:2,
    imgSrc:TravelImg,
    alt:"Travel Icon",
    title:"Travel and Hospitality"
  },
  {
    id:3,
    imgSrc:RealState,
    alt:"Real Estate Icon",
    title:"Real Estate & Housing"
  },
  {
    id:4,
    imgSrc:EducationImg,
    alt:"Education Icon",
    title:"Education & learning"
  },
  {
    id:5,
    imgSrc:FoodImg,
    alt:"Food Icon",
    title:"Food & Beverages"
  },
  {
    id:6,
    imgSrc:EntertainmentImg,
    alt:"Entertainment Icon",
    title:"Entertainment & Media"
  },
  {
    id:7,
    imgSrc:EcommerceImg,
    alt:"E-Commerce Icon",
    title:<>Retail & <br/> E-Commerce</>
  }
]