import React from "react";
import styles from './mobile-app-development-services.module.scss'
// import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
// import Slider from "react-slick";
// import { mobileAppDevIosService } from "../../assets/img";
import { Col, Row } from "react-bootstrap";


const MobileAppDevelopmentServices = ({sliderServiceData}) =>{
    return(
        <section className={`pdtb ${styles.mbl_sevices_sec}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="sub-heading text-center">
                  <h2>{sliderServiceData?.heading}</h2>
                  <p>
                    {sliderServiceData?.desc}
                  </p>
                </div>
              </div>
            {/* <div className="mbl-sevices-slider">
              <button
                className={`${styles.arrow_btn} ${styles.slick_prev} left-arrow`}
                onClick={() => slider?.current?.slickPrev()}
              >
                <i>
                  <BsArrowLeft />
                </i>
              </button>
              <button
                className={`${styles.arrow_btn} ${styles.slick_next} right-arrow`}
                onClick={() => slider?.current?.slickNext()}
              >
                <i>
                  <BsArrowRight />
                </i>
              </button>
              <Slider
                ref={slider}
                arrows={false}
                slidesToShow={3}
                slidesToScroll={1}
                swipeToSlide={true}
                focusOnSelect={true}
                responsive={slider2ResponsiveSettings}
                centerMode={true}
                speed={300}
                centerPadding={0}
                infinite={true}
                autoplaySpeed={5000}
                autoplay={true}
              >
              {
                sliderServiceData?.serviceList.map((list, i)=>(
                  <div>
                  <div className={`${styles.mbl_sevices_sec_item}`}>
                    <img src={list.imgSrc} alt={list.imgAlt} />
                    <h4>{list.title}</h4>
                    <p>
                        {list.desc}
                      </p>
                  </div>
                </div>
                ))
              }
                <div>
                  <div className={`${styles.mbl_sevices_sec_item}`}>
                    <img src={mobileAppDevIosService} alt="images" />
                    <h4><span className="text-uppercase">ios</span> App Development</h4>
                    <p>
                        We have the right expertise to transform your vivid ideas into a
                        perfect iOS application for the entire range of Apple products.
                        Our team of developers has a vast experience in creating iOS apps
                        for clients in your budget and as per your timeline.
                      </p>
                  </div>
                </div>
                <div>
                  <div className={styles.mbl_sevices_sec_item}>
                    <img src={mobileAppDevIosService} alt="images" />
                    <h4><span className="text-uppercase">Android</span> App Development</h4>
                      <p>
                        Building high-quality Android apps have become one of our core strengths.
                        We have successfully developed Android applications for clients in various
                        industries and accelerated their business growth.
                      </p>
                  </div>
                </div>
                <div>
                  <div className={styles.mbl_sevices_sec_item}>
                    <img src={mobileAppDevIosService} alt="images" />
                    <h4><span className="text-uppercase">Hybrid</span> App Development</h4>
                      <p>
                        We can help you develop top-of-the-line Hybrid apps with fast and
                        reliable back-end, easy navigation, appealing menus, etc. Our
                        aim is to render app that entices your customer with an overall
                        satisfying user-experience.
                      </p>
                  </div>
                </div>
                <div>
                  <div className={styles.mbl_sevices_sec_item}>
                    <img src={mobileAppDevIosService} alt="images" />
                    <h4><span className="text-uppercase">Cross-platform</span> Mobile App</h4>
                      <p>
                        We have a team with full-scale expertise in developing cross-platform
                        mobile apps for IOS and Android by using the most agile technologies.
                        We can customize your mobile app as per your business need and budget.
                      </p>
                  </div>
                </div>
                <div>
                  <div className={styles.mbl_sevices_sec_item}>
                    <img src={mobileAppDevIosService} alt="images" />
                    <h4><span className="text-uppercase">AR/VR</span> App Development</h4>
                      <p>
                        We leverage the power of modern technologies like Augmented Reality
                        (AR) and Virtual Reality (VR) to create mobile apps that offers
                        seamless user experience. Our team of developers are well-versed
                        with these next-gen technologies and can deliver exceptional apps.
                      </p>
                  </div>
                </div>
                <div>
                  <div className={styles.mbl_sevices_sec_item}>
                    <img src={mobileAppDevIosService} alt="images" />
                    <h4><span className="text-uppercase">AI/IOT/Blockchain</span> App Development</h4>
                    <p>
                      We can get you world-class mobile apps developed with futuristic technologies
                      like AI, IoT, and blockchain. Our services will get an edge over your competition.
                    </p>
                  </div>
                </div>
              </Slider>
            </div> */}
          </div>
          <Row>
            {
                sliderServiceData?.serviceList.map((list, i)=>(
                  <Col lg={4} md={6} key={i} className={styles.mbl_service_list}>
                  <div className={`${styles.mbl_sevices_sec_item}`}>
                    <img src={list.imgSrc} alt={list.imgAlt} />
                    <h4>{list.title}</h4>
                    <p>
                        {list.desc}
                      </p>
                </div>
                </Col>
                ))
              }
          </Row>
        </div>
      </section>
    )
}


export default MobileAppDevelopmentServices