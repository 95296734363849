import React from "react";
import styles from "./loader.module.scss";

const Loader = () => {
  return (
    <>
      <button className={`theme-btn ${styles.theme_btn}`}>Loading...</button>
    </>
  );
};

export default Loader;
