import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { HiOutlineChevronDown } from "react-icons/hi";
import { NavLink, useLocation } from "react-router-dom";
import { LogoMain, LogoMainWhite, SalesForceIcon } from "../../assets/img";
import { headerMegaMenuData } from "../../components/jsonData/Data";
import Modal_form from "../../components/Banner/Modal";
import HeaderMegaMenuBlog from "./HeaderMegaMenuBlog";

const Header = ({ setOverLayVisible }) => {
  const { pathname } = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [scrollHeader, setScrollHeader] = useState(false);


  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    // document.body.style.overflow="hidden";
  };
  useEffect(() => {
    setDropdownOpen(false);
    // setHeaderBgClrChange(false);
    setScrollHeader(false);
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let header = document.querySelector("header");
    const handleScroll = () => {
      if (window.scrollY > 90) {
        header.classList.add("scroll-header");
      } else {
        header.classList.remove("scroll-header");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const header = document.querySelector(".th-header");
    if (window.innerWidth > 991) {
      if (isDropdownOpen) {
        header.classList.add("drop-header");
      } else {
        header.classList.remove("drop-header");
      }
    } else {
    }
    if (isDropdownOpen) {
      setOverLayVisible(true);
      document.body.style.overflow = "hidden";
    } else {
      setOverLayVisible(false);
      document.body.style.overflow = "auto";
    }
  }, [isDropdownOpen]);

  return (
    <header className={`th-header`}>
      <Navbar expand="lg">
        <div className="container-lg">
          <NavLink to="/" className="navbar-brand">
            <img src={LogoMain} alt="logo" className="black-logo" />
            <img src={LogoMainWhite} alt="logo" className="white-logo" />
          </NavLink>
          {/* <NavLink to="/" className="navbar-brand salesfrce-logo">
            <img src={SalesForceIcon} alt="logo"/>
            <span>Consulting<br/>Partner</span>
          </NavLink> */}

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className={scrollHeader ? "cross" : ""}
            onClick={() => setScrollHeader(!scrollHeader)}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={!scrollHeader ? "" : "shows"}
          >
            <Nav className="ms-auto align-items-center">
              <NavDropdown
                show={isDropdownOpen}
                onToggle={handleDropdownToggle}
                title=<span>
                  Services{" "}
                  <i>
                    <HiOutlineChevronDown />
                  </i>
                </span>
                id="basic-nav-dropdown"
              >
                <div className="d-flex flex-wrap">
                  <Tabs
                    defaultActiveKey={1}
                    transition={false}
                    id="controlled-tab-example"
                  >
                    {headerMegaMenuData?.map((service, i) => (
                      <Tab eventKey={service.id} title={service.title} key={i}>
                        <div className="tab-content-inner">
                          {/* Service list */}
                          <div className="tab-content-inner-wrapper left">
                            <ul
                              className={
                                service.serviceList?.length > 8
                                  ? "columnCount-2"
                                  : "columnCount-1"
                              }
                            >
                              {service?.serviceList?.map(
                                (serviceList, index) => (
                                  <li key={index}>
                                    <NavLink to={serviceList.href}>
                                      <span className="img-icon">
                                        <img
                                          src={serviceList.src}
                                          alt="header icon"
                                        />
                                      </span>

                                      {serviceList.name}
                                    </NavLink>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>

                          {/* Blogs */}
                          <div className="tab-content-inner-wrapper right">
							                <HeaderMegaMenuBlog url={service.blogCategory} service={service} key={service.id} />
                          </div>
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </NavDropdown>

              <NavLink to="/about-us" className="nav-link">
                About Us
              </NavLink>

              <NavLink to="/contact-us" className="nav-link">
                Contact Us
              </NavLink>

              <NavLink
                to="https://scrumdigital.com/blog/"
                target="_blank"
                className="nav-link"
              >
                Blog
              </NavLink>

              <NavLink to="/portfolio" className="nav-link">
                Portfolio
              </NavLink>
              <Modal_form btnName="Request A Quote" />
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
