import React, { useState } from "react";
import styles from "../../../assets/css/hmg-empowering-business-with-it-solution.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { WhatWeOfferData } from "./EmpoweringBusinessData";

const EmpoweringYourBusinessWithITSolution = () => {
  const [data, setData] = useState(WhatWeOfferData);

  const [show, setShow] = useState(0);

  const handleMouseOver = (index, i) => {
    data.offerList[index].bottomLinkSec.forEach(element => {
      element.show = false;
    });

    data.offerList[index].bottomLinkSec[i].show = true

    setData(data)

    setShow(show + 1);
  }

  return (
    <>
      <section className={`pdtb ${styles.what_we_offer}`}>
        <Container className="container-lg">
          <div className="sub-heading-outline text-center mb-md-0">
            <h2>{data.heading}</h2>
            <p>{data.desc}</p>
          </div>
          <ul>
            {data.offerList &&
              data.offerList.map((offer, index) => (
                <li key={index}>
                  <div className={styles.what_we_offer_innr}>
                    <div className="d-flex align-items-center">
                      <h3>{offer.title}</h3>
                    </div>
                    <p>{offer.desc}</p>
                    <Link to={offer.link}>
                      Read More
                      <i>
                        <BsArrowRight />
                      </i>
                    </Link>
                  </div>
                  <div className={styles.what_we_offer_btm}>
                    {offer.bottomLinkSec &&
                      offer.bottomLinkSec.map((list, i) => (
                        <Link
                          key={i}
                          to={list.link}
                          className={`${list.show ? styles.active:""} ${styles.what_we_offer_btm_icn}`}
                          onMouseEnter={() => handleMouseOver(index, i)}
                        >
                          <img src={list.src} alt={list.text} />
                          <span>
                            {list.text}
                          </span>
                        </Link>
                      ))}
                  </div>
                </li>
              ))}
          </ul>
        </Container>
      </section>
    </>
  );
};

export default EmpoweringYourBusinessWithITSolution;
