import React from "react";
import styles from './mobile-app-technology.module.scss';
import { Tab, Tabs } from "react-bootstrap";


const MobileAppTechnologiesWeUse = ({technologyData}) => {
    return(
        <section className={`pdtb mbl-srvc-tech ${styles.mbl_service_tech} ${technologyData?.darkTheme ? styles.dark_theme :""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto mb-5">
              <div className={`sub-heading text-center ${technologyData?.darkTheme ? styles.dark_theme_sub_heading:""}`}>
                <h2>{technologyData?.title}</h2>
                <p>{technologyData?.desc}</p>
              </div>
            </div>
            <div className="col-lg-11 mx-auto">
                <Tabs
                  // defaultActiveKey={technologyData?.defaultActiveKey}
                  id="uncontrolled-tab-example"
                  className="custom_tbs_mobile_app_dev_technology"
                >
                {
                    technologyData?.technologyList.map((list)=>(
                    <Tab eventKey={list.eventKey} title={list.tabBtnTitle} key={list.id}>
                    <div>
                      <div className={styles.mbl_service_tech_item}>
                        <div className="row">
                          <div className="col-lg-5">
                            <h4>{list.tabContent.title}</h4>
                            <p>{list.tabContent.desc}</p>
                          </div>
                          <div className="col-lg-7">
                            <ul>
                            {
                                list.tabContent.serviceList.map((item, index)=><li key={index}>{item}</li>)
                            }
                            </ul>
                          </div>
                        </div>
                        {list.tabContent?.technologyList &&
                          <div className={styles.mbl_service_tech_logo}>
                        {
                            list.tabContent?.technologyList?.map((technology,i)=>(
                                <div className={styles.mbl_service_tech_logo_item} key={i}>
                                  <img src={technology.imgSrc} alt={technology.alt} />
                                   <h5 className="mb-0">{technology.name}</h5>
                                </div>
                            ))
                        }
                        </div>
                        }
                      </div>
                    </div>
                  </Tab>

                    ))
                }
                </Tabs>
            </div>
          </div>
        </div>
      </section>
    )
}

export default MobileAppTechnologiesWeUse