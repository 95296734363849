export const SalesforceData = {
  helmetSec: {
    title:
      "Hire Salesforce Developers | Certified Salesforce Developer",
    description: "Looking to hire an experienced Salesforce developer? Our team of experts has delivered top-tier custom solutions for clients. Get a free quote now!",
    keywords: "Hire Salesforce Developers, Certified Salesforce Developer",
    conical: "/hire-salesforce-developers",
  },
  HireSalesDeveloperData: {
    heading: (
      <>
        Hire Salesforce Developers and Certified Professionals To{" "}
        <span>Upscale Your Salesforce Capabilities</span>
      </>
    ),
    desc: "Work with a team of certified Salesforce developers to achieve your business goals. At Scrum Digital, we've a group of experts who are skilled in develop, integrate, and deploy the Salesforce applications successfully in various business verticals. Our dedication to exceeding your expectations sets us apart and makes us the ultimate choice for streamlined business flow and cutting-edge Salesforce services.",
    list: [
      "Good knowledge of Apex, Lightning, and Visualforce",
      "Integration experience with third-party applications",
      "Comprehensive range of Salesforce cloud experience",
      "Experienced on deployments to various environments",
      "Experience working with JavaScript",
      "Expert at handling Application Lifecycle Management",
    ],
  },
};
