import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "../../../assets/css/salesforse/salesforce-services.module.scss";
import { SalesforceServicesData } from "./SalesforceServicesData";
const SalesforceServices = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(SalesforceServicesData);
  }, []);
  return (
    <section className={`pdtb ${styles.service_offer}`}>
      <Container>
        <div className="row justify-content-center">
          <div
            className={`col-lg-8 sub-heading text-center ${styles.sub_heading}`}
          >
            <h2 className="text-white">{data.heading}</h2>
            <p className={`text-white`}>{data.desc}</p>
          </div>
        </div>
        <ul className={`${styles.service_development}`}>
          {data.serviceList &&
            data.serviceList.map((service, index) => (
              <li className={`${styles.service_development_list}`} key={index}>
                <div className={`${styles.multiple_dev_card}`}>
                  <h5>{service.title}</h5>
                  <p>{service.desc}</p>
                  <ul>
                    {service.innerList.map((list, i) => (
                      <li key={i}>{list}</li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
        </ul>
      </Container>
    </section>
  );
};
export default SalesforceServices;
