import { ServiceWhyChooseWebDevOntime, ServiceWhyChooseWebDevPrice, ServiceWhyChooseWebDevTactics, applicationDevelopmentBanner, cmsWebAppServiceIcon,  consultingWebAppServiceIcon,  customWebAppServiceIcon, eCommerceWebAppServiceIcon, innovativeDesign, mobileApp1ImgContent, mobileAppDevIosService, reEngineeringWebAppServiceIcon, responsiveWebAppServiceIcon, serviceBannerWebAppDev, tailoredWebSolution, webAppDevAboutUsImg } from "../../assets/img";

export const webAppDevData = {
  helmetSec: {
    title: "Web Application Development Services",
    description:
      "Discover how our premier digital transformation services and solutions can optimize your business operations with ease and efficiency. Get Started Today!",
    keywords:
      "Boost productivity with proven Web App Development Services. Our Web Application Development Company creates high-performing web apps tailored to your brand.",
    conical: "/web-application-development-services",
  },

    bannerData: {
        bannerHeading: (
          <>
            Web App Development <span>Services</span>
          </>
        ),
        banner_bott_head:
          "With our specialized web application development services, you can easily extend your user base and accelerate your organization to remarkable heights.",
        // bannerImg: applicationDevelopmentBanner,
        bannerImg: serviceBannerWebAppDev,
        alt: "web Application Development service",
      },
      imgContentSec1 : {
        src: webAppDevAboutUsImg,
        alt: "web Application Development company",
        heading: (
          <>
           Our Complete Custom <span>Web Development Solutions</span>
          </>
        ),
        desc: (
          <p>
            Our team of specialists offers a wide range of web development services, diligently addressing company requirements to provide a solution that exceeds your expectations.
          </p>
        ),
        list: [
          {
            name: "Timely Project Delivery ",
          },
          {
            name: "Competitive Pricing",
          },
          {
            name: "Agile Development Practices",
          },
          {
            name: "Tailored Services",
          },
        ],
      },
      sliderServiceData:{
        heading:<>Unique Web App Development Solutions</>,
        desc:"We offer a wide array of web app development solutions, thoroughly exploring your distinct business obstacles to provide a customized solution that surpasses your expectations.",
        serviceList:[
          {
            id:1,
            imgSrc:customWebAppServiceIcon,
            imgAlt:"Custom WebApp Service",
            title:<><span className="text-uppercase">Custom</span> Web App Development</>,
            desc:<>We specialized in developing a custom web application for all the niches. Our process involves 24/7 support to our esteemed clients along with quality maintenance.
            </>
          },
          {
            id:2,
            imgSrc:cmsWebAppServiceIcon,
            imgAlt:"Cms Web App Service",
            title:<><span className="text-uppercase">CMS</span> Web App Development</>,
            desc:<>Our team of developers are expert at developing complex CMS web applications with ease. They hold adequate experience in managing the content in your web application efficiently.
            </>
          },
          {
            id:3,
            imgSrc:consultingWebAppServiceIcon,
            imgAlt:"Web App Consulting",
            title:<><span className="text-uppercase">Web App</span> Consulting</>,
            desc:<>Need advice to get web applications developed? We offer you the finest web app consulting service in the industry along with world-class applications developed within the timelines.
            </>
          },
          {
            id:4,
            imgSrc:responsiveWebAppServiceIcon,
            imgAlt:"Responsive Web App Design",
            title:<><span className="text-uppercase">Responsive</span> Web App Design</>,
            desc:<>It is our continuous endeavor to create intuitive and responsive web app designs for our customers and provide them with flexible experience on different devices. Our aim is to create perfect web apps that support easy navigation.
            </>
          },
          {
            id:5,
            imgSrc:reEngineeringWebAppServiceIcon,
            imgAlt:"Web App Re-engineering",
            title:<><span className="text-uppercase">Web App</span> Re-engineering</>,
            desc:<>If you’re looking to revamp your old web application and give it a makeover. We have professionals who will re-engineer your web app and create the new one.
            </>
          },
          {
            id:6,
            imgSrc:eCommerceWebAppServiceIcon,
            imgAlt:"E-commerce Web Apps Development Services",
            title:<><span className="text-uppercase">E-commerce</span> Web Apps Development Services</>,
            desc:<>Use platforms with us to create e-commerce storefronts and B2B websites that will move your company forward.
            </>
          },
        ]
      },
      ctaContent:{
        ctaHeading:<>Start transforming your vision into <span>reality now!</span></>,
         btnName:"Begin Your App Adventure!"
      },
      whyChooseUs: {
        heading: (
          <>
            Why Choose Scrum Digital for <span>Web App Development Services?</span>
          </>
        ),
        // whyBg: {
        //   backgroundColor: "#f2f9ff",
        // },
        desc: "Enhance your online visibility with Scrum Digital's innovative web solutions.",
        list: [
          {
            id: 1,
            source: tailoredWebSolution,
            alt: "Tailored Web Solutions web app development",
            title: "Tailored Web Solutions",
            desc: "By picking the best technology stacks, we ensure that your web apps not only correspond with your company objectives, but also drive significant growth.",
          },
          {
            id: 2,
            source: ServiceWhyChooseWebDevPrice,
            imgBg:"#fffaf6",
            alt: "Strategic Support web app development",
            title: "Strategic Support",
            desc: "Our skilled developers provide valuable technology consultations, assist in modernizing outdated systems, to advanced web architectures, ensuring your business stays ahead.",
          },
          {
            id: 3,
            source: innovativeDesign,
            imgBg:"rgb(90 215 255 / 8%)",
            alt: "Innovative Design web app development",
            title: "Innovative Design",
            desc: "Explore  our design process, where creativity and utility merge to produce visually beautiful and user-friendly web apps.",
          },
        ],
      },
       ourProcessData:{
        heading:<>Our <span>Web App Development Process </span></>,
        desc:"To achieve top-tier results effectively, we emphasize collaborative efforts with our clients and a focused development route.",
        list:[
          {
            id:1,
            desc:"Exploring industry trends, pinpointing user requirements, and aligning with your business objectives."
          },
          {
            id:2,
            desc:"Crafting user-friendly designs, constructing interactive prototypes, and enhancing user interaction."
          },
          {
            id:3,
            desc:"Building a solid framework, incorporating essential functionalities, and employing industry-standard coding practices."
          },
          {
            id:4,
            desc:"Executing thorough quality assessments and evaluating the user experience."
          },
          {
            id:5,
            desc:"Launching the web application, ensuring operational stability, and observing user engagement levels."
          },
          {
            id:6,
            desc:"Assessing the web app's impact, user interactions, and fine-tuning the development approach."
          },
          {
            id:7,
            desc:"Implementing consistent updates, boosting performance, and integrating user suggestions."
          },
        ]
      },
}