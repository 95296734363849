import React from "react";
import { Container } from "react-bootstrap";
import BannerContact from "../../components/Banner/ContactBanner";
import { TermsOfUsePageData } from "../../components/jsonData/TermsOfUsePageData";
import HeadMetaSec from "../../components/HeadMetaSec";

const TermsOfUse = () => {
  return (
    <>
      <HeadMetaSec HeadSecData={TermsOfUsePageData.helmetSec} />
      <BannerContact bannerData={TermsOfUsePageData.bannerSec} />
      <section className="pdtb privacy-term-sec">
        <Container>{TermsOfUsePageData.TermsDesc}</Container>
      </section>
    </>
  );
};

export default TermsOfUse;
