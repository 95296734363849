import { Link } from "react-router-dom";
import styles from "../../assets/css/service/scrumyoutube.module.scss";
import Modal_form from "../Banner/Modal";
import { Container } from "react-bootstrap";
const ScrumYoutube = () => {
  return (
    <section className={`${styles.scrum_youtube}`}>
      <Container>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className={`${styles.video_scrum}`}>
              <iframe
                src="https://www.youtube.com/embed/wG8uLIa0gVs"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                allowFullScreen
                frameBorder="0"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="service-heading">
              <h2 className="text-white">
                Keep Your Business One Step Ahead In Digital Transformation
              </h2>
              <p className="text-white">
                The business landscape is dynamic and you need innovative and
                impactful digital solutions to stay ahead of your competitors.
                You can simplify the complexity of your business processes with
                Scrum Digital as your IT partner. Leveraging the latest
                technologies such as IoT, Machine Learning, and AI, our
                experienced teams closely collaborate with clients to develop
                digital solutions that make their business future ready.
              </p>
            </div>
            <div className="mt-5">
              <Modal_form btnName="Know More" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default ScrumYoutube;
