import ProjectsHelpedOurClients from "../../components/Home/ProjectsHelpedOurClients";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import ServiceBanner from "../../components/ServiceGenerate/ServiceBanner";
import ServiceNeed from "../../components/ServiceGenerate/ServiceNeed";
// import Ourservice from "../../components/ServiceGenerate/Ourservice";
import GetStarted from "../../components/ServiceGenerate/GetStarted";
import BusinessGrowth from "../../components/ServiceGenerate/BusinessGrowth";
import Necessary from "../../components/ServiceGenerate/NecessarySection";
import ServiceCta from "../../components/ServiceGenerate/ServiceCta";
import WhyChooseSec from "../../components/ServiceGenerate/WhyChooseSec";
import ScrumYoutube from "../../components/ServiceGenerate/ScrumYoutube";
import HeadMetaSec from "../../components/HeadMetaSec";
import { ServiceGenerateData } from "../../components/jsonData/ServiceGenerateData";
import ServicesOfTechnology from "../../components/services/ServicesOfTechnology";
import { DigitalMarketingSocialMediaService, DigitalMarketingOrmService, DigitalMarketingPpcService, DigitalMarketingSeoService } from "../../assets/img";
function services() {
  return (
    <>
      <HeadMetaSec HeadSecData={ServiceGenerateData.helmetSec} />
      <ServiceBanner />
      <ServiceNeed />
      <ServicesOfTechnology services={ServiceGenerateData.serviceSec}/>
      {/* <Ourservice ourServiceData={ServiceGenerateData.OurServiceSec} /> */}
      <GetStarted getStartedData={ServiceGenerateData.GetStartedSec} />
      <BusinessGrowth />
      <Necessary necessaryData={ServiceGenerateData.NecessarySec} />
      <ServiceCta />
      <WhyChooseSec
        digitalPartnerData={ServiceGenerateData.DigitalPartnerSec}
      />
      <ScrumYoutube />
      <ProjectsHelpedOurClients />
      <B2BSec />
      <OurClientSec />
      <OurBlogSec />
    </>
  );
}

export default services;
