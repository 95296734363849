import { BsCheck2 } from "react-icons/bs";
import styles from "../../assets/css/salesforse/hire-sales-developer.module.scss";

const HireSalesDeveloper = ({ HireSalesDeveloperData }) => {
  return (
    <section className={`pdtb ${styles.hire_salesforse}`}>
      <div className="container">
        <div className="sub-heading text-center">
          <h2>{HireSalesDeveloperData.heading}</h2>
          <p className={`${styles.heading_pra}`}>
            {HireSalesDeveloperData.desc}
          </p>
        </div>
        <ul>
          {HireSalesDeveloperData.list.map((elem, i) => (
            <li key={i}>
              <i>
                <BsCheck2 />
              </i>
              {elem}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default HireSalesDeveloper;
