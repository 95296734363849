import React from "react";
import { Container } from "react-bootstrap";
import styles from '../../assets/css/chatbot-solution-we-offer.module.scss'
import { ChatbotSolutionImg } from "../../assets/img";

const ChatbotSolutionWeOffer = () => {
    return (
        <section className={`pdtb ${styles.chatbot_solution_we_offer}`}>
            <Container>
                <div className="sub-heading-outline text-center">
                    <h2>
                        Enhance Customer Engagement{" "}
                        <span className="text-uppercase d-inline d-sm-block">Across Multiple Platforms</span>
                    </h2>
                    <p>We aim to deliver efficient and effective assistance across diverse platforms, ensuring effortless interactions with your business.</p>
                </div>
                <div className={styles.chatbot_solution_we_offer_list_row}>
                    <div className={styles.chatbot_solution_we_offer_list}>
                        <div className={styles.chatbot_solution_we_offer_list_img_sec}>
                            <img src={ChatbotSolutionImg} alt="chatbot solution" />
                        </div>
                        <h3><span>Website </span> Interaction</h3>
                        <p>Never miss an opportunity to engage with your website visitors. Our chatbots are ready to assist and interact with potential clients when they visit your website. We believe in making every visitor's experience on your website a positive one.</p>
                        <ul>
                            <li>Engage visitors with chatbots</li>
                            <li>Assist clients instantly</li>
                            <li>Positive website experiences</li>
                        </ul>
                    </div>
                    <div className={styles.chatbot_solution_we_offer_list}>
                        <div className={styles.chatbot_solution_we_offer_list_img_sec}>
                            <img src={ChatbotSolutionImg} alt="chatbot solution" />
                        </div>
                        <h3><span>Social Media</span> Connection</h3>
                        <p>Connect our Chatbot with your social media profiles to simplify interactions with your followers and potential customers. Your online presence becomes even more responsive, allowing you to address inquiries and engage with your audience effortlessly.</p>
                        <ul>
                            <li> Streamline chats across social platforms</li>
                            <li>Boost engagement with instant responses</li>
                            <li>Simplify follower interactions seamlessly</li>
                        </ul>
                    </div>
                    <div className={styles.chatbot_solution_we_offer_list}>
                        <div className={styles.chatbot_solution_we_offer_list_img_sec}>
                            <img src={ChatbotSolutionImg} alt="chatbot solution" />
                        </div>
                        <h3><span>Elevate E-Commerce</span> Experiences</h3>
                        <p>Shopping should be enjoyable and stress-free. Our virtual shopping assistants are here to enhance your e-commerce sites. They help customers find products, answer questions, and create an enjoyable shopping experience.</p>
                        <ul>
                            <li>Enhance your shopping journey</li>
                            <li>Quick product find assistance</li>
                            <li>Joyful, stress-free experience</li>
                        </ul>
                    </div>
                    <div className={styles.chatbot_solution_we_offer_list}>
                        <div className={styles.chatbot_solution_we_offer_list_img_sec}>
                            <img src={ChatbotSolutionImg} alt="chatbot solution" />
                        </div>
                        <h3><span>Android & iOS</span> Applications</h3>
                        <p>We are committed to delivering scalable and engaging experiences across both Android and iOS platforms. Our chatbots transform the way you interact with customers and employees, making it more personalized and efficient.</p>
                        <ul>
                            <li>Scalable apps for Android & iOS</li>
                            <li>Engaging chatbot experiences</li>
                            <li>Personalized customer interactions</li>
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ChatbotSolutionWeOffer;
