import {
  DigitalMarketingOrmService,
  DigitalMarketingPpcService,
  DigitalMarketingSeoService,
  DigitalMarketingSocialMediaService,
  EnhanceMobileapi_integration,
  EnhanceMobiledeesign,
  EnhanceMobiledevelopment,
  EnhanceMobilemaintenance,
  EnhanceMobilestretegy,
  OrmBetterReviewService,
  OrmBoostService,
  OrmFixReputationService,
  OrmStrategyService,
  PpcAdCampaignService,
  PpcAdDiverseService,
  PpcCreatingLandingService,
  PpcEnhanceRoiService,
  PpcManagementService,
  PpcRetargetingAdService,
  SeoCompetitorService,
  SeoConsultingService,
  SeoEnhanceRankService,
  SeoTrackingReportService,
  SeoWebAnalysisService,
  SeoWebOptimizeService,
  ServiceWhyChooseAppDevBadge,
  ServiceWhyChooseAppDevChallenges,
  ServiceWhyChooseAppDevSupport,
  ServiceWhyChooseEComerseagile,
  ServiceWhyChooseEComersegrowth,
  ServiceWhyChooseEComerseontimedel,
  ServiceWhyChooseMobileDevBest,
  ServiceWhyChooseMobileDevTalented,
  ServiceWhyChooseMobileDevquick,
  ServiceWhyChooseWebDevOntime,
  ServiceWhyChooseWebDevPrice,
  ServiceWhyChooseWebDevTactics,
  ServiceWhyChooseapiqulitywork,
  ServiceWhyChoosecmsdedicated,
  ServiceWhyChoosecmsquality,
  ServiceWhyChoosecrmcosteffect,
  ServiceWhyChoosecrmcostgreat,
  ServiceWhyChoosecrmcostrelible,
  ServiceWhyChoosecrmcostsupport,
  ServiceWhyChoosecrmerpfast,
  ServiceWhyChoosecrmerpimrove,
  ServiceWhyChoosecrmerpproductivity,
  ServiceWhyChoosecrmerpresult,
  ServiceWhyChoosecrmerpsimplyfy,
  ServiceWhyChoosecrmerpsolut,
  ServiceWhyChoosesCmsdigital,
  ServiceWhyChooseseoaccountmanager,
  ServiceWhyChooseseoanalysis,
  ServiceWhyChooseseobrand,
  ServiceWhyChooseseoenhanced,
  ServiceWhyChooseseoresources,
  ServiceWhyChooseseovisiblity,
  ServiceWhyChooseuiuxAmazing,
  ServiceWhyChooseuiuxMotivatedTeam,
  ServiceWhyChooseuiuxeasy,
  SocialMediaAuditService,
  SocialMediaCommunityService,
  SocialMediaCompetitorService,
  SocialMediaOrganicService,
  SocialMediaPaidService,
  SocialMediaReputationService,
  apiApiConsultingService,
  apiCustomApiService,
  apiDevelopmentBanner,
  apiIntegrationService,
  apiMobileApiService,
  apiService,
  apiWebApiService,
  applicationDevApiDevService,
  applicationDevCloudSolService,
  applicationDevEComService,
  applicationDevMobileDevService,
  applicationDevUiUxService,
  applicationDevWebAppService,
  applicationDevelopmentBanner1,
  applicationDevelopmentBanner,
  chatBotDevelopmentBanner,
  chatbotHighCustomerService,
  chatbotLeadsService,
  chatbotMinimizeService,
  cloudAppService,
  cloudConsultingService,
  cloudIntegrationService,
  cloudSolutionBanner,
  cmsDevelopmentBanner,
  cmsDevelopmentService,
  cmsECommService,
  cmsIntegrationService,
  cmsMigrationService,
  cmsSupportService,
  cmsThemeExtensionService,
  crm2ImgContent,
  crmConsultingService,
  crmDevelopmentBanner,
  crmDevelopmentService,
  crmIntegrationService,
  crmMigrationService,
  crmMobileService,
  crmSupportService,
  digitalMarketingBanner,
  digitalTransformationAppService,
  digitalTransformationBanner,
  digitalTransformationChatbotService,
  digitalTransformationContentManageService,
  digitalTransformationCrmService,
  digitalTransformationErpService,
  digitalTransformationIotService,
  eComAppB2bService,
  eComAppDevelopmentService,
  eComMigrationService,
  eComMultiStoreSolutionService,
  eComThirdPartyService,
  eComWebDesignService,
  eCommerceDevelopmentBanner,
  erpOdooService,
  erpSapService,
  erpSolutionBanner,
  growth1ImgContent,
  growth2ImgContent,
  growthBanner,
  iotAdvanceAppService,
  iotApplicationBanner,
  iotDominantService,
  iotIntegrationService,
  mobileApp1ImgContent,
  mobileApp2ImgContent,
  mobileAppDevARVRService,
  mobileAppDevAndroidService,
  mobileAppDevBlockChainService,
  mobileAppDevCrossPlatformService,
  mobileAppDevHybridService,
  mobileAppDevIosService,
  mobileAppDevelopmentBanner,
  mvp1ImgContent,
  mvp2ImgContent,
  mvpBanner,
  ormBanner,
  ormImgContent,
  ppcBanner,
  ppcImgContent,
  product1ImgContent,
  product2ImgContent,
  productBanner,
  semBanner,
  seoBanner,
  seoImgContent,
  serviceAppCounterApp,
  serviceAppCounterExpert,
  serviceAppCounterFeaturing,
  smmBanner,
  smoBanner,
  staffBanner,
  staffImgContent,
  startup2ImgContent,
  startupBanner,
  startupImgContent,
  uiUxCoding,
  uiUxDevelopmentBanner,
  uiUxImgContent,
  uiUxResearch,
  uiUxWireframes,
  webAppCmsAppService,
  webAppConsultantService,
  webAppECommerceService,
  webAppReEngineeringService,
  webAppResponsiveService,
  webAppService,
  Joomla,
  PHP,
  Magento,
  BigCommerce,
  Shopify,
  Opencart,
  WooCommerce,
  Drupal,
  Aimeos,
  Zencart,
  OSCommerce,
  serviceBannerAppDev,
  serviceBannerUiUxDev,
  serviceBannerSeoDev,
  serviceBannerSmoDev,
  websiteDevelopmentServiceIcon,
} from "../../assets/img";

export const servicePageData = [
  {
    id: 1,
    helmetSec: {
      title: "Digital Transformation Services and Solutions | Scrum Digital",
      description:
        "Discover how our premier digital transformation services and solutions can optimize your business operations with ease and efficiency. Get Started Today!",
      keywords:
        "Digital Transformation Services, Digital Transformation Solution",
      conical: "/digital-transformation-services",
      ogimg: "/ogdigital_transformation.png"
    },
    typeOfService: "Digital Transformation",
    name: " DigitalTransformation",
    link: "digital-transformation-services",
    bannerData: {
      bannerHeading: (
        <>
          Digital Transformation <span>Services</span>
        </>
      ),
      banner_bott_head:
        "Digitize Business Processes to Eliminate Business Challenges, Accelerate Growth, and Embrace Emerging Technologies",
      bannerImg: digitalTransformationBanner,
      alt: "Digital Transformation service",
    },
    aboutSec: {
      title: (
        <>
          <span>Digital Transformation</span> Company
        </>
      ),
      desc: (
        <p>
          Changing times require ever-evolving solutions and to meet the current
          business demand, enterprise are adapting agile technologies, new
          marketing models and techniques. Scrum Digital is an industry-leading
          digital transformation company that can maximize your digital
          potential. We have vast expertise in providing a myriad of digital
          transformation services that enhances customer experience, improves
          operational efficiency and delivers faster results. Our team of
          experts work in close coordination with you to understand your complex
          business challenges, presented by new technologies and accordingly
          formulate a strategy on how you can use these technologies to improve
          business performance. We offer custom-tailor solutions to fit your
          specific business needs in a variety of ways. You can rely on us for
          end-to-end services and to excel in digital innovation.
        </p>
      ),
    },
    serviceSec: {
      title: (
        <>
          Digital <span>Transformation Services</span>
        </>
      ),
      desc: "As you decide to break the boundaries of your business ecosystem, let the experts of the digital domain help you take your business to the new market. With perfect web design, web development, and digital transformation services, we help you take your business to your audience.",
      list: [
        {
          id: 1,
          href: "/website-development-company",
          source: websiteDevelopmentServiceIcon,
          alt: "application Image",
          title: (
            <>
             Website <span>Development Services</span>
            </>
          ),
          content:
            "We create applications that deliver the experience desired by customers.",
        },
        {
          id: 2,
          href: "/erp-consulting-services",
          source: digitalTransformationErpService,
          alt: "erp Image",
          title: (
            <>
              ERP <span>Solution</span>
            </>
          ),
          content:
            "Track key business resources, keep tabs on business commitments, and up to date data across departments.",
        },
        {
          id: 3,
          href: "/crm-development-solutions",
          source: digitalTransformationCrmService,
          alt: "crm",
          title: (
            <>
              CRM <span>Solutions</span>
            </>
          ),
          content:
            "Easily strategize, structure and organize customer data base which helps streamline various communications, processes and marketing strategies.",
        },
        {
          id: 4,
          href: "/cms-development-services",
          source: digitalTransformationContentManageService,
          alt: "content-management Image",
          title: (
            <>
              Content <span>Management Solutions</span>
            </>
          ),
          content:
            "Manage digital content, control intuitive indexing, format management especially for legacy content, content retrieval and revision control publicised content.",
        },
        {
          id: 5,
          href: "/iot-app-development-services",
          source: digitalTransformationIotService,
          alt: "iot Image",
          title: (
            <>
              IOT <span>Solution</span>
            </>
          ),
          content:
            "Revolutionize the health care system by connecting medical devices, set up smarter retail options with proximity based advertisement based on your location, improving supply chain with easier tracking of goods and inventory management.",
        },
        {
          id: 6,
          href: "/chatbot-development-services",
          source: digitalTransformationChatbotService,
          alt: "chatbot Image",
          title: (
            <>
              ChatBot <span>Solutions</span>
            </>
          ),
          content:
            "Integrating chat bots into your business in order to address customer needs faster and in timely fashion and generate leads.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for Digital Transformation
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      faqBg: {
        backgroundColor: "#f5f5f5",
      },
      inner: [
        {
          id: 1,
          question: "Why Is Digital Transformation Important for My Business?",
          answer: (
            <>
              <p>
                Well, as we have moved towards a global economy in the past
                years, the digital arena is the new marketplace. Most businesses
                these days are involved in adopting digital transformation to
                reach the global audience and survive this competitive
                environment. Those who would walk with the trends would thrive,
                survive and succeed.
              </p>
              <p>
                To not edge your business, you would need digital
                transformation.
              </p>
            </>
          ),
        },
        {
          id: 2,
          question: "Does My Organization Need Digital Transformation?",
          para: (
            <>
              <p>
                No matter what industry you serve, the digital marketplace is
                the new normal. From day to day grocery shopping to availing
                healthcare online, everything has changed for the better. The
                impact of digital transformation is different for different
                industries, but there is a significant impact that cannot be
                ignored.
              </p>
              <p>The scale of impact is determined by underlying factors:-</p>
            </>
          ),
          answer: [
            "Dependence of your industry on technology",
            "The technology that you use",
            "Influence of technology in general on your industry",
          ],
        },
        {
          id: 3,
          question: "When Is the Right Time to Opt for Digital Transformation?",
          para: (
            <>
              <p>
                Well, you can commence your digital transformation journey as
                soon as you are comfortable with the budget, you can start your
                transformation journey. The time is always right, as moving to
                the digital platform is the right decision to make.
              </p>
              <p>Consider the points like:-</p>
            </>
          ),
          answer: [
            "Industry Expertise or Marketplace",
            "Budget Constraints",
            "Company’s Future Plan",
          ],
          paraEnd:
            "Along with these, you would have to take care of the government regulations or other legal issues.",
        },
        {
          id: 4,
          question: "How Complicated Is the Digital Transformation Process?",
          para: "Digital transformation solutions aren’t complicated. The project involves these steps:-",
          paraEnd:
            "The digital transformation journey becomes easier if you partner with the right team.",
          answer: [
            "Adoption to new technology, tools, software, and platforms",
            "IT Transformation with hardware and software",
            "The customer experience can be refocused ",
          ],
        },

        {
          id: 5,
          question: "Do You Provide After Delivery Assistance?",
          answer:
            "Yes, as you reach our experts for digital transformation solutions, we would make it a point that even after we have delivered you future-ready solutions. In case you want us to make any changes or modify your product, our experts would connect with you and help you with the best possible alternative. We understand to walk with the pace of time you would often need assistance and thus make sure to assist you through your journey all the time.",
        },
        {
          id: 6,
          question:
            "After Hiring, When Would You Start Working on the Project?",
          answer: (
            <>
              <p>
                Once all the formalities are done, we start working on your
                project right away. Our team would connect with you for a better
                understanding of your business goals and expectations and would
                deliver products that are in line with your vision.
              </p>
              <p>
                We are named the best digital transformation consulting firms in
                USA and India for a reason. We make it a point that you are
                always connected with the team for your inputs so that the end
                product is exactly what your business resonates with.
              </p>
            </>
          ),
        },
      ],
    },
  },
  // {
  //   id: 2,
  //   typeOfService: "Digital Transformation",
  //   helmetSec: {
  //     title:
  //       "Mobile App Development Company | Hire Mobile App Developer",
  //     description:
  //       "Boost your business with our affordable digital marketing services! Elevate your online presence & ROI. Get started today with a FREE consultation!",
  //     keywords:
  //       "Software Development Company, digital solutions, Mobile App Development Company, digital marketing services, scrumdigital, scrum digital, scrum digital jaipur, Scrum Digital, Offshore IT Services ",
  //     conical: "/application-development-services",
  //     ogimg: "/ogdigital_transformation.png"
  //   },
  //   name: "Application Development",
  //   link: "application-development-services",
  //   bannerData: {
  //     bannerHeading: (
  //       <>
  //         Top Application <span>Development Company</span>
  //       </>
  //     ),
  //     banner_bott_head: (
  //       <>
  //         We provide end-to-end application development services using emerging technologies that are unique, innovative and user-friendly.
  //       </>
  //     ),
  //     // bannerImg: applicationDevelopmentBanner,
  //     bannerImg: serviceBannerAppDev,
  //     alt: "application Development",
  //   },
  //   aboutSec: {
  //     title: (
  //       <>
  //         <span>Application</span> Development Company
  //       </>
  //     ),
  //     desc: (
  //       <p>
  //         Scrum Digital is a reputed name in the industry for delivering
  //         top-class application development services to start-ups, SMBs and
  //         Enterprises. With the right blend of knowledge and skill, our team of
  //         developers have been able to deliver solutions that have delighted our
  //         customers. We have a decade long experience and rich industry exposure
  //         in providing high-end application development solutions to a wide
  //         range of industries and sectors. Whether you want a mobile app to be
  //         developed or a website or a UI/UX, we deliver it with utmost sincerity
  //         and precision. We work in a collaborative manner and all our efforts
  //         are aligned towards taking your business to the next level. Depending
  //         on your requirement, we can offer tailor-made solutions that help to
  //         boost your growth. We don’t compromise on quality and you can rely on
  //         us for world-class application development services.
  //       </p>
  //     ),
  //   },
  //   // companyProfileCounterSec:{
  //   //   title:<><span>Fueling Exceptional Ideas for Over 18+ Years.</span></>,
  //   //   desc:<>Our unwavering commitment and dedication shine through each
  //   //   stage of our mobile development process, ensuring we
  //   //   deliver nothing but unparalleled service to our clients.</>,
  //   //   counter:[
  //   //     {
  //   //       id:1,
  //   //       title:"We Delivered",
  //   //       count:150,
  //   //       prefix:"App",
  //   //       iconSrc:serviceAppCounterApp,
  //   //     },
  //   //     {
  //   //       id:2,
  //   //       title:"Certified Team",
  //   //       count:100,
  //   //       prefix:"Experts",
  //   //       iconSrc:serviceAppCounterExpert,
  //   //     },
  //   //     {
  //   //       id:3,
  //   //       title:"Featuring",
  //   //       count:18,
  //   //       prefix:"Years",
  //   //       iconSrc:serviceAppCounterFeaturing
  //   //     }
  //   //   ]

  //   // },
  //   serviceSec: {
  //     title: (
  //       <>
  //         <span>Application Development</span>{" "}
  //         Services
  //       </>
  //     ),
  //     desc: (
  //       <>
  //         We are bestowed in achieving customer's centric objectives and goals, delivering you with diversification in the Application Development Services.
  //       </>
  //     ),
  //     list: [
  //       {
  //         id: 1,
  //         href: "/mobile-application-development",
  //         source: applicationDevMobileDevService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             Mobile <span>Application Development</span>
  //           </>
  //         ),
  //         content:
  //           "We develop competitive and customized mobile apps for various industrial domains. We follow the latest market trends to create highly innovative and scalable mobile apps that will drive business for your brand. Our team has expertise in delivering mobile apps on cross platform, hybrid platform, iOS app, android app etc.",
  //       },
  //       {
  //         id: 2,
  //         href: "/web-application-development",
  //         source: applicationDevWebAppService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             Web <span>Application Development</span>
  //           </>
  //         ),
  //         content:
  //           "Scrum Digital has expertise in creating interactive applications and websites that align with your business goals. We have been consistently serving a myriad of industries and helping them unleash the power of the web. Our team will guide through the entire procedure of development and keep you updated on every stage of web application development.",
  //       },
  //       {
  //         id: 3,
  //         href: "/api-development-services",
  //         source: applicationDevApiDevService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             API <span>Development and Management</span>
  //           </>
  //         ),
  //         content:
  //           "We are a reliable company in API development and specialized in handling every type of API development requirement. Our talented team of API development experts will take care of all your business needs, both simple and complex regardless of the industry. Our bouquet of API development services includes mobile and web API development, API integrations, consulting etc.",
  //       },
  //       {
  //         id: 4,
  //         href: "/ecommerce-development-services",
  //         source: applicationDevEComService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             eCommerce <span>Application Development</span>
  //           </>
  //         ),
  //         content:
  //           "Scrum Digital has a team of experts in creating feature-rich eCommerce Applications for diverse industries. Based on individual business specifications, we use a wide array of technologies to expand online store engagement and brand visibility. We are result-oriented and committed to making your online store, a preferred destination.",
  //       },
  //       {
  //         id: 5,
  //         href: "/ui-ux-development-services",
  //         source: applicationDevUiUxService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             UI/UX <span>Designing</span>
  //           </>
  //         ),
  //         content:
  //           "If you’re looking for a flawless UI/UX for your website, then your search end here. Scrum Digital is an industry-leading UI/UX design company creating an intuitive, visually appealing design to delight potential clients. Our team of experts will understand your business goals and accordingly create an innovative design on your preferred platform.",
  //       },
  //       {
  //         id: 6,
  //         href: "/cloud-computing-solutions",
  //         source: applicationDevCloudSolService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             Cloud <span>Solution</span>
  //           </>
  //         ),
  //         content:
  //           "Manage and secure cloud environment, deploy your applications in leading cloud infrastructure, such as AWS, Google Cloud, Azure, DigitalOcean.",
  //       },
  //     ],
  //   },
  //   // servicePlatformData: {
  //   //   title: (
  //   //     <>
  //   //       <span>Technologies</span> We used
  //   //     </>
  //   //   ),
  //   //    technologyList :[
  //   //     {
  //   //       id: 1,
  //   //       source: Joomla,
  //   //       name: "Python",
  //   //     },
  //   //     {
  //   //       id: 2,
  //   //       source: PHP,
  //   //       name: "PHP",
  //   //     },
  //   //     {
  //   //       id: 3,
  //   //       source: Magento,
  //   //       name: "Node.js",
  //   //     },
  //   //     {
  //   //       id: 4,
  //   //       source: BigCommerce,
  //   //       name: "Java Script",
  //   //     },
  //   //     {
  //   //       id: 5,
  //   //       source: Shopify,
  //   //       name: "React",
  //   //     },
  //   //     {
  //   //       id: 6,
  //   //       source: Opencart,
  //   //       name: "Angular",
  //   //     },
  //   //     {
  //   //       id: 7,
  //   //       source: WooCommerce,
  //   //       name: "HTML5",
  //   //     },
  //   //     {
  //   //       id: 8,
  //   //       source: Drupal,
  //   //       name: "Laravel",
  //   //     },
  //   //     {
  //   //       id: 9,
  //   //       source: Aimeos,
  //   //       name: "Roby on rails (ROR)",
  //   //     },
  //   //     {
  //   //       id: 10,
  //   //       source: Zencart,
  //   //       name: "React Native",
  //   //     },
  //   //     {
  //   //       id: 11,
  //   //       source: Drupal,
  //   //       name: "Drupal",
  //   //     },
  //   //   ]
  //   // },
  //   whyChooseUs: {
  //     heading: (
  //       <>
  //         Why Choose Scrum Digital for <span>Application Development?</span>
  //       </>
  //     ),
  //     desc: (
  //       <>
  //         Scrum Digital crafts you with innovative and creative ideas for Application Development, guidance with experienced and expertise developers.
  //       </>
  //     ),
  //     col: "col-xl-8 col-lg-12",
  //     list: [
  //       {
  //         id: 1,
  //         source: ServiceWhyChooseAppDevBadge,
  //         alt: "Top Quality Work",
  //         title: "Top Quality Work",
  //         desc: "As a leading development company, we’ve helped diverse businesses with custom integration and taken their business to the next level. We have the expertise to deliver exceptional results.",
  //       },
  //       {
  //         id: 2,
  //         source: ServiceWhyChooseAppDevChallenges,
  //         alt: "Resolve Challenges",
  //         title: "Resolve Challenges",
  //         desc: "Our custom integration solutions are created to deliver optimal results. It is our constant endeavor to satisfy our customers with our value-driven solutions.",
  //       },
  //       {
  //         id: 3,
  //         source: ServiceWhyChooseAppDevSupport,
  //         alt: "Strong Support",
  //         title: "Strong Support",
  //         desc: "We are available round the clock to support our esteemed clients in every possible way and ensure that everything runs seamlessly from beginning to end.",
  //       },
  //     ],
  //   },
  //   FAQSec: {
  //     title: (
  //       <>
  //         <span>FAQ's</span> for Application Development
  //       </>
  //     ),
  //     desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
  //     inner: [
  //       {
  //         id: 1,
  //         question: "What are the platforms on which we develop apps?",
  //         answer:
  //           "At Scrum Digital we provide app development services for iOS and Android and our team has expertise in delivering mobile apps on cross platforms, hybrid platforms. We also offer services in Web Application development, API development and management, eCommerce app development, UI/UX designing, and cloud solutions.",
  //       },
  //       {
  //         id: 2,
  //         question: "How much will it cost to develop an app?",
  //         answer:
  //           "The cost of developing an app depends upon its complexity and your preferences. Graphics, sound effects, mobile platforms are other contributing factors in deciding the cost. To know the estimated price for your project kindly get in touch with our consultants.",
  //       },
  //       {
  //         id: 3,
  //         question: "What type of Industries have we served?",
  //         answer:
  //           "We have catered to the needs of companies from a wide range of industries ranging from Health, Real estate, food and beverages, retail, eCommerce, travel and hospitality, education and learning and Media and Entertainment.",
  //       },
  //       {
  //         id: 4,
  //         question: "What platform should I use for developing my app?",
  //         answer:
  //           "Android and iOS are the dominating smartphone operating systems in the market. They are two different mobile platforms that require two different types of language. You will have to decide based on the type of user you want to target first, in case you don’t want to build both at the same time. It is always recommended to build for both iOS and Android for total market penetration. In case you are having trouble making decisions about the platform, you have the option to opt for web applications. Web apps run on web servers instead of operating systems and the user can access web apps using a web browser with a good network connection. You can always talk to our team of experts at Scrum Digital to help guide you to make the right decision for your business.",
  //       },
  //       {
  //         id: 5,
  //         question: "What is the process of building an app?",
  //         answer:
  //           "We have an agile methodology for the software development process that ensures that your app is developed on time and within budget. Our team of skilled developers promote continuous iteration of development and testing and have been able to deliver solutions that have delighted our customers. Building an app is a three-step process. First, we work together to understand your business and the objective behind developing the app. The second step involves the design and development part, wherein using agile methodologies we create the structure, features, designs for the apps, character models, sound effects. These steps also involve testing the app and removing bugs and errors. The third and final step would be testing the app and launching it.",
  //       },
  //       {
  //         id: 6,
  //         question: "Should my business have an app?",
  //         para: "As more and more people switch to using smartphones, the market for mobile app is growing bigger and bigger every day. Developing a mobile app for your business can help you reach your target audience and provide you with valuable marketing opportunities that will put you ahead of your competition. Some of the benefits that mobile app offers are",
  //         answer: [
  //           "Direct communication with customers and clients.",
  //           "Boost customer engagement.",
  //           "Grow Brand Awareness.",
  //           "Design effective marketing channel for customers.",
  //           "Get ahead of your competition.",
  //         ],
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 3,
  //   typeOfService: "Digital Transformation",
  //   name: "Web Development",
  //   link: "web-development-services",
  //   helmetSec: {
  //     title: "Web Development Services | Web Application Development",
  //     description:
  //       "Looking for Top-Rated web development services &amp; solutions for your custom business requirements? Talk to our experienced team today!",
  //     keywords:
  //       "web development services, web application development company, website development company, Website App Development",
  //     conical: "/web-development-services",
  //     ogimg: "/ogdigital_transformation.png"
  //   },
  //   bannerData: {
  //     bannerHeading: (
  //       <>
  //         Web Development <span>Services</span>
  //       </>
  //     ),
  //     banner_bott_head:
  //       "We use cutting-edge technology to build responsive and robust web applications for businesses of all sizes.",
  //     bannerImg: applicationDevelopmentBanner,
  //     alt: "web Application Development",
  //   },
  //   aboutSec: {
  //     title: (
  //       <>
  //         <span>Web Application</span> Development Company
  //       </>
  //     ),
  //     desc: (
  //       <p>
  //         Scrum Digital provides custom web application development services to
  //         start-ups and established organizations from diverse industries,
  //         globally. We have prowess in delivering fast-loading, responsive, and
  //         user-friendly websites to our esteemed clients. As an end-to-end web
  //         application development company, we analyze the specifics of every
  //         business domain and accordingly offer optimal solutions to tackle your
  //         challenges.
  //       </p>
  //     ),
  //   },
  //   serviceSec: {
  //     title: (
  //       <>
  //         We Render Comprehensive <span>Web Application</span> Development
  //         Services
  //       </>
  //     ),
  //     desc: "We offer a plethora of web application services extending from E-commerce to CMS to Responsive Designs and a lot more that serves perfectly for all your requirements.",
  //     list: [
  //       {
  //         id: 1,
  //         source: webAppService,
  //         alt: "application Image",
  //         title: (
  //           <>
  //             Custom <span>Web App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We specialized in developing a custom web application for all the niches. Our process involves round the clock support to our esteemed clients along with quality maintenance.",
  //       },
  //       {
  //         id: 2,
  //         source: webAppECommerceService,
  //         alt: "eCommerce",
  //         title: (
  //           <>
  //             ECommerce <span>Web App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We have expertise in developing unique Ecommerce-oriented web applications with complete functionality. Our focus is to create an Ecommerce website that delights the end-user and prompt him to take action.",
  //       },
  //       {
  //         id: 3,
  //         source: webAppCmsAppService,
  //         alt: "cmsServices",
  //         title: (
  //           <>
  //             CMS Web <span> App Development</span>
  //           </>
  //         ),
  //         content:
  //           "Our team of developers are expert at developing complex CMS web applications with ease. They hold adequate experience in managing the content in your web application efficiently.",
  //       },
  //       {
  //         id: 4,
  //         source: webAppReEngineeringService,
  //         alt: "webAppReEngineeringService",
  //         title: (
  //           <>
  //             Web App <span>Re-engineering</span>
  //           </>
  //         ),
  //         content:
  //           "If you’re looking to revamp your old web application and give it a makeover. We have professionals who will re-engineer your web app and create the new one.",
  //       },
  //       {
  //         id: 5,
  //         source: webAppConsultantService,
  //         alt: "webAppConsultantService",
  //         title: (
  //           <>
  //             Web App <span>Consulting</span>
  //           </>
  //         ),
  //         title1: "Web App",
  //         title2: "Consulting",
  //         content:
  //           "Need an advice to get web application developed? We offer you the finest web app consulting service in the industry along with world-class application developed within the timelines.",
  //       },
  //       {
  //         id: 6,
  //         source: webAppResponsiveService,
  //         alt: "webAppResponsiveService",
  //         title: (
  //           <>
  //             Responsive <span>Web App Designs</span>
  //           </>
  //         ),
  //         content:
  //           "It is our continuous endeavor to create intuitive and responsive web app designs for our customers and provide them with flexible experience on different devices. Our aim is to create perfect web apps that supports easy navigation.",
  //       },
  //     ],
  //   },
  //   whyChooseUs: {
  //     heading: (
  //       <>
  //         Why Associate with Scrum Digital for{" "}
  //         <span>Web Application Development?</span>
  //       </>
  //     ),
  //     whyBg: {
  //       backgroundColor: "#fcfcfc",
  //     },
  //     desc: "Scrum Digital is chosen by the top notch companies for a reason. Just not only we go beyond the limits and think out of box, but also our has is an expertise with in-depth knowledge . We also known for our-",
  //     list: [
  //       {
  //         id: 1,
  //         source: ServiceWhyChooseWebDevTactics,
  //         alt: "badge",
  //         title: "Proven Tactics",
  //         desc: "We use proven methods, follow best practices, and work with the latest technologies on every project that we undertake.",
  //       },
  //       {
  //         id: 2,
  //         source: ServiceWhyChooseWebDevOntime,
  //         alt: "badge",
  //         title: "On-Time Delivery",
  //         desc: "We never disappoint our clients. Whatever project we handle, we strictly adhere to timelines and that is our core strength.",
  //       },
  //       {
  //         id: 3,
  //         source: ServiceWhyChooseWebDevPrice,
  //         alt: "badge",
  //         title: "Competitive Pricing",
  //         desc: "Our services are cost-effective and you can rely on us for the best solutions at the most competitive prices.",
  //       },
  //     ],
  //   },
  //   FAQSec: {
  //     title: (
  //       <>
  //         <span>FAQ's</span> for Web Application Development
  //       </>
  //     ),
  //     desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
  //     faqBg: {
  //       backgroundColor: "#f5f5f5",
  //     },
  //     inner: [
  //       {
  //         id: 1,
  //         question: "What are the benefits of web app development?",
  //         answer:
  //           "Developing a web application is a very important and essential part of anyone’s business as it directly represents the company portfolio and draws attention to it. Also, it is a very cost-effective way to reach out to your customers through any platform. Developing a custom web application provides more benefits. Firstly you won’t have to develop native mobile apps, since they can be reached through web apps via browsers. And secondly, you save a lot of time and expenses on unnecessary digital products. A web application can substantially improve your current business traction, brand awareness, showcase your business ideology and secure a top position in the target market.",
  //       },
  //       {
  //         id: 2,
  //         question: "What would be the cost of web application development?",
  //         answer:
  //           "The cost of developing a web application is affected by various factors like the complexity of your web application or the number of functionalities. A basic web app with a very light interface and basic functions would cost less than a database-driven eCommerce solution. Feel free to get in touch with us for a free quote for your project.",
  //       },
  //       {
  //         id: 3,
  //         question: "What kind of web application services do we provide?",
  //         answer:
  //           "Scrum Digital is one of the leading web application development companies that offer a plethora of services like Custom, eCommerce and CMS web app development. We also offer services to revamp your old web application and provide consultation for the same.",
  //       },
  //       {
  //         id: 4,
  //         question:
  //           "Why should you choose Scrum Digital for Web Application Development?",
  //         answer:
  //           "Scrum Digital has been providing a plethora of web application development services to startups as well as established businesses. We have served a diverse range of industries from healthcare, real estate, retail, e-commerce to education and entertainment. Our expertise in the field and out of the box thinking is what makes us unique. We believe in delivering world-class results in said time at the most competitive prices.",
  //       },
  //       {
  //         id: 5,
  //         question:
  //           "I have an idea for a web application but I am not sure about the technical specifications. Can your company still help me?",
  //         answer:
  //           "Developing a web application is a highly technical process. Therefore we do not expect you to have in-depth knowledge of the technical domain. We offer web app consulting services, our team of professionals will help you understand the technical aspect of your project so that you can make the right decision. We expect you to know the functional requirements while we manage the technical aspect of things.",
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 4,
  //   typeOfService: "Digital Transformation",
  //   name: "Mobile Application Development",
  //   link: "mobile-application-development",
  //   helmetSec: {
  //     title: "Mobile Application Development Company in USA | ScrumDigital",
  //     description:
  //       "Scrum Digital is a leading mobile app development company for businesses, focus on high quality Android, iPhone & web app development services.",
  //     keywords:
  //       "mobile app development company, mobile application development company, mobile app development services",
  //     conical: "/mobile-application-development",
  //     ogimg: "/ogdigital_transformation.png"
  //   },
  //   bannerData: {
  //     bannerHeading: (
  //       <>
  //         Mobile Application <span>Development Company</span>
  //       </>
  //     ),
  //     banner_bott_head:
  //       "We create Innovative, Vibrant, and Scalable Mobile Apps to enthrall users.",
  //     bannerImg: mobileAppDevelopmentBanner,
  //     alt: "Mobile Application Development",
  //   },
  //   aboutSec: {
  //     title: (
  //       <>
  //         <span>Leading Mobile App</span> Development Company
  //       </>
  //     ),
  //     desc: (
  //       <>
  //         <p>
  //           Scrum Digital is a top-notch mobile app development company
  //           delivering end-to-end mobile app development solutions to clients
  //           from diverse industries ranging from healthcare to manufacturing to
  //           marketing. With decade long experience, we’ve helped clients develop
  //           impactful and engaging mobile apps that drive business for their
  //           brand. From the ideation stage to the final product design, our
  //           developers ensure to satisfy your criteria and help your business
  //           stay connected.{" "}
  //         </p>
  //         <p>
  //           {" "}
  //           Choose us to get the most innovative mobile app developed in the
  //           industry.
  //         </p>
  //       </>
  //     ),
  //   },
  //   serviceSec: {
  //     title: (
  //       <>
  //         Our <span>Unique Mobile App</span> Development Services
  //       </>
  //     ),
  //     desc: "Our developers are provided with expert training and are versed in building solutions that meet the latest markets needs, empowering companies' branding, and vitalizing business growth and expansion. Seasoned with iOS development, Android, and Hybrid App Development.",
  //     list: [
  //       {
  //         id: 1,
  //         source: mobileAppDevIosService,
  //         alt: "mobileAppDevIosService",
  //         title: (
  //           <>
  //             ios <span>App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We have the right expertise to transform your vivid ideas into a perfect iOS application for the entire range of Apple products. Our team of developers has a vast experience in creating iOS apps for clients in your budget and as per your timeline.",
  //       },
  //       {
  //         id: 2,
  //         source: mobileAppDevAndroidService,
  //         alt: "mobileAppDevAndroidService",
  //         title: (
  //           <>
  //             Android <span>App Development</span>
  //           </>
  //         ),
  //         content:
  //           "Building high-quality Android apps have become one of our core strengths. We have successfully developed Android applications for clients in various industries and accelerated their business growth.",
  //       },
  //       {
  //         id: 3,
  //         source: mobileAppDevHybridService,
  //         alt: "mobileAppDevHybridService",
  //         title: (
  //           <>
  //             Hybrid <span>App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We can help you develop top-of-the-line Hybrid apps with fast and reliable back-end, easy navigation, appealing menus, etc. Our aim is to render app that entices your customer with an overall satisfying user-experience.",
  //       },
  //       {
  //         id: 4,
  //         source: mobileAppDevCrossPlatformService,
  //         alt: "mobileAppDevCrossPlatformService",
  //         title: (
  //           <>
  //             Cross-platform <span>Mobile App</span>
  //           </>
  //         ),
  //         content:
  //           "We have a team with full-scale expertise in developing cross-platform mobile apps for IOS and Android by using the most agile technologies. We can customize your mobile app as per your business need and budget.",
  //       },
  //       {
  //         id: 5,
  //         source: mobileAppDevARVRService,
  //         alt: "mobileAppDevARVRService",
  //         title: (
  //           <>
  //             AR/VR <span>App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We leverage the power of modern technologies like Augmented Reality (AR) and Virtual Reality (VR) to create mobile apps that offers seamless user experience. Our team of developers are well-versed with these next-gen technologies and can deliver exceptional apps.",
  //       },
  //       {
  //         id: 6,
  //         source: mobileAppDevBlockChainService,
  //         alt: " mobileAppDevBlockChainService",
  //         title: (
  //           <>
  //             AI/IOT/Blockchain <span>App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We can get you world-class mobile apps developed with futuristic technologies like AI, IoT, and blockchain. Our services will get an edge over your competition.",
  //       },
  //     ],
  //   },
  //   imgContentSec1: {
  //     src: mobileApp1ImgContent,
  //     alt: "mobileDevelopment",
  //     heading: (
  //       <>
  //         Why Do You Need Our <span>Mobile App Development Services?</span>
  //       </>
  //     ),
  //     desc: (
  //       <p>
  //         We have a team of self-motivated mobile app developers. Our sole aim
  //         is to provide our clients with the desired outcomes through our custom
  //         mobile app development process. Some of the benefits of our services
  //         includes the following:
  //       </p>
  //     ),
  //     list: [
  //       {
  //         name: "Agile Method",
  //       },
  //       {
  //         name: "Highly-Skilled Team",
  //       },
  //       {
  //         name: "Consistent Reports",
  //       },
  //       {
  //         name: "Quick & Safe",
  //       },
  //     ],
  //   },
  //   imgContentSec2: {
  //     src: mobileApp2ImgContent,
  //     alt: "mobileDevelopment",
  //     heading: (
  //       <>
  //         Key Advantages of <span>Mobile App Development</span> to Businesses
  //       </>
  //     ),
  //     desc: (
  //       <p>
  //         Mobile apps have become a necessity to thrive in the technology driven
  //         age. Businesses needs to leverage this technology to stay ahead in
  //         competition. Some of its vital benefits includes the following
  //       </p>
  //     ),
  //     list: [
  //       {
  //         name: "Enhances customer-engagement",
  //       },
  //       {
  //         name: "Offer value for customers",
  //       },
  //       {
  //         name: "Strengthens brand Loyalty",
  //       },
  //       {
  //         name: "Improves Accessibility",
  //       },
  //       {
  //         name: "Boost Profitability",
  //       },
  //       {
  //         name: "Creates brand awareness and recognition",
  //       },
  //     ],
  //     orderImg: "order-lg-2",
  //     orderContent: "order-lg-1",
  //   },
  //   enhanceMobileData: {
  //     heading: (
  //       <>
  //         Enhanced <span>Mobility Solutions</span>
  //       </>
  //     ),
  //     desc: "To provide unrivaled brilliance and capabilities in one powerful app, we offer a one-of-a-kind mobile app development experience.",
  //     list: [
  //       {
  //         id: 1,
  //         title: (
  //           <>
  //             Mobile App <span>Strategy</span>
  //           </>
  //         ),
  //         src: EnhanceMobilestretegy,
  //         alt: "application",
  //       },
  //       {
  //         id: 2,
  //         title: (
  //           <>
  //             Mobile App <span>Designing</span>
  //           </>
  //         ),
  //         src: EnhanceMobiledeesign,
  //         alt: "ChatBot",
  //       },
  //       {
  //         id: 3,
  //         title: (
  //           <>
  //             Mobile App <span>Development</span>
  //           </>
  //         ),
  //         src: EnhanceMobiledevelopment,
  //         alt: "crm",
  //       },
  //       {
  //         id: 4,

  //         title: (
  //           <>
  //             3rd Party <span>API Integration</span>
  //           </>
  //         ),
  //         src: EnhanceMobileapi_integration,
  //         alt: "ERP",
  //       },
  //       {
  //         id: 5,
  //         title: (
  //           <>
  //             App Support <span>& Maintenance</span>
  //           </>
  //         ),
  //         src: EnhanceMobilemaintenance,
  //         alt: "Cloud",
  //       },
  //     ],
  //   },
  //   whyChooseUs: {
  //     heading: (
  //       <>
  //         Why Scrum Digital For <span> Mobile App Development Services?</span>
  //       </>
  //     ),
  //     whyBg: {
  //       backgroundColor: "#fcfcfc",
  //     },
  //     desc: "Our Expert and talented developers serving and providing extraordinary solutions to your unresolved queries with greater flexibility, and having versed with the latest technologies to provide you the standardized quality of work.",
  //     list: [
  //       {
  //         id: 1,
  //         source: ServiceWhyChooseMobileDevTalented,
  //         alt: "badge",
  //         title: "Talented Developers",
  //         desc: "Our developers are experienced and have the capability to turn your vision into reality.",
  //       },
  //       {
  //         id: 2,
  //         source: ServiceWhyChooseMobileDevBest,
  //         alt: "badge",
  //         title: "Best-in-class Functionality",
  //         desc: "Providing better app usability is our top priority and the essence of our app design and development process.",
  //       },
  //       {
  //         id: 3,
  //         source: ServiceWhyChooseMobileDevquick,
  //         alt: "badge",
  //         title: "Quick Deployment",
  //         desc: "We strictly adhere to timelines, develop app faster, and deploy it on time.",
  //       },
  //     ],
  //   },
  //   FAQSec: {
  //     title: (
  //       <>
  //         <span>FAQ's</span> for Mobile App Development
  //       </>
  //     ),
  //     desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
  //     faqBg: {
  //       backgroundColor: "#f5f5f5",
  //     },
  //     inner: [
  //       {
  //         id: 1,
  //         question: "What are the benefits of web app development?",
  //         answer:
  //           "Developing a web application is a very important and essential part of anyone’s business as it directly represents the company portfolio and draws attention to it. Also, it is a very cost-effective way to reach out to your customers through any platform. Developing a custom web application provides more benefits. Firstly you won’t have to develop native mobile apps, since they can be reached through web apps via browsers. And secondly, you save a lot of time and expenses on unnecessary digital products. A web application can substantially improve your current business traction, brand awareness, showcase your business ideology and secure a top position in the target market.",
  //       },
  //       {
  //         id: 2,
  //         question: "What would be the cost of web application development?",
  //         answer:
  //           "The cost of developing a web application is affected by various factors like the complexity of your web application or the number of functionalities. A basic web app with a very light interface and basic functions would cost less than a database-driven eCommerce solution. Feel free to get in touch with us for a free quote for your project.",
  //       },
  //       {
  //         id: 3,
  //         question: "What kind of web application services do we provide?",
  //         answer:
  //           "Scrum Digital is one of the leading web application development companies that offer a plethora of services like Custom, eCommerce and CMS web app development. We also offer services to revamp your old web application and provide consultation for the same.",
  //       },
  //       {
  //         id: 4,
  //         question:
  //           "Why should you choose Scrum Digital for Web Application Development?",
  //         answer:
  //           "Scrum Digital has been providing a plethora of web application development services to startups as well as established businesses. We have served a diverse range of industries from healthcare, real estate, retail, e-commerce to education and entertainment. Our expertise in the field and out of the box thinking is what makes us unique. We believe in delivering world-class results in said time at the most competitive prices.",
  //       },
  //       {
  //         id: 5,
  //         question:
  //           "I have an idea for a web application but I am not sure about the technical specifications. Can your company still help me?",
  //         answer:
  //           "Developing a web application is a highly technical process. Therefore we do not expect you to have in-depth knowledge of the technical domain. We offer web app consulting services, our team of professionals will help you understand the technical aspect of your project so that you can make the right decision. We expect you to know the functional requirements while we manage the technical aspect of things.",
  //       },
  //     ],
  //   },
  // },
  // {
  //   id: 5,
  //   typeOfService: "Digital Transformation",
  //   name: "E-commerce Development",
  //   link: "ecommerce-development-services",
  //   helmetSec: {
  //     title: "Custom E-commerce Development Services | Scrum Digital",
  //     description:
  //       "Grow your e-commerce business with our ecommerce development company - custom online stores, shopping carts, payment integrations and SEO. Start driving sales today!",
  //     keywords:
  //       "ecommerce development company, ecommerce development services, ecommerce website development services, custom e commerce development, ecommerce app development services, ecommerce web developer",
  //     conical: "/ecommerce-development-services",
  //     ogimg: "/ogdigital_transformation.png"
  //   },
  //   bannerData: {
  //     bannerHeading: (
  //       <>
  //         E-commerce <span>Development Services</span>
  //       </>
  //     ),
  //     banner_bott_head:
  //       "We create feature-rich E-commerce applications that are reliable, secure, and easy to use.",
  //     bannerImg: eCommerceDevelopmentBanner,
  //     alt: "E-commerce Development",
  //   },
  //   aboutSec: {
  //     title: (
  //       <>
  //         <span>Robust E-commerce</span> Development Company
  //       </>
  //     ),
  //     desc: (
  //       <>
  //         <p>
  //           At Scrum Digital, we leverage futuristic technologies to create
  //           end-to-end E-commerce development solutions for various sectors
  //           across the globe. We build highly engaging and feature-packed
  //           E-commerce websites using agile methods to boost your online store
  //           engagement and enhance brand exposure. With vast industry experience
  //           in developing E-commerce websites and apps, our developers
  //           understand your requirements, identify your competitors, and then
  //           based on your inputs chalk out a development plan. Choose us for the
  //           best E-commerce development solutions in the industry.{" "}
  //         </p>
  //       </>
  //     ),
  //   },
  //   serviceSec: {
  //     title: (
  //       <>
  //         Our <span>Unique E-commerce </span> Website Development Services
  //       </>
  //     ),
  //     desc: "Our E-commerce website provides you unique services that scale up your business and expand its reach to a larger audience, our contrived and seasoned professionals formulate unique and better strategies and solutions that enable your business to register increased traffic and sales. Our services include B2B and B2C Marketplaces,E-commerce App, and Web Design Development.",
  //     list: [
  //       {
  //         id: 1,
  //         source: eComWebDesignService,
  //         alt: "eComWebDesign",
  //         title: (
  //           <>
  //             E-commerce <span>Web Design and development Development</span>
  //           </>
  //         ),
  //         content:
  //           "Every business has unique needs and demands and we create effective E-commerce websites keeping those specifications in mind. We bring value to your business by offering the right custom solutions that your business desires.",
  //       },
  //       {
  //         id: 2,
  //         source: eComAppDevelopmentService,
  //         alt: "eComAppDevelopment",
  //         title: (
  //           <>
  //             E-commerce <span>App Development</span>
  //           </>
  //         ),
  //         content:
  //           "We create highly engaging, flexible, and user-friendly E-commerce mobile apps to help you conquer the E-commerce app marketplace. We build effective B2B, B2C mobile apps offering advanced speed, convenience and adaptability.",
  //       },
  //       {
  //         id: 3,
  //         source: eComAppB2bService,
  //         alt: " eComAppB2bService",
  //         title: (
  //           <>
  //             B2B and B2C <span>Marketplaces</span>
  //           </>
  //         ),
  //         content:
  //           "We have relevant expertise in building B2B and B2C online marketplaces for our esteemed clients across the globe. Based on individual needs we can deliver the best E-commerce solutions packed with features like shopping list, reporting, shipping and order management, payments management, etc.",
  //       },
  //       {
  //         id: 4,
  //         source: eComThirdPartyService,
  //         alt: "eComThirdPartyService",
  //         title: (
  //           <>
  //             Third-party <span>API Integration</span>
  //           </>
  //         ),
  //         content:
  //           "Third-party API integration is inevitable for the smooth functioning of your E-commerce solutions and we do that with absolute proficiency. Our experienced developers can integrate third-party APIs like PayPal, FedEx, to Amazon and eBay API integration, and much more to provide ease in operations.",
  //       },
  //       {
  //         id: 5,
  //         source: eComMultiStoreSolutionService,
  //         alt: "eComMultiStoreSolutionService",
  //         title: (
  //           <>
  //             Multi-Store <span>E-commerce Solution</span>
  //           </>
  //         ),
  //         content:
  //           "We are equipped in creating top-of-the-line E-commerce multi-stores for our global clientele. We streamline the process at the enterprise stage by crafting feature-rich multi-stores for companies offering more than one brand.",
  //       },
  //       {
  //         id: 6,
  //         source: eComMigrationService,
  //         alt: "eComMigrationService",
  //         title: (
  //           <>
  //             Platform <span>Migration & Upgradation</span>
  //           </>
  //         ),
  //         content:
  //           "Our developers will assist you in migrating your E-commerce website to any other platform of your choice with ease. We can even help you upgrade your website to the latest version of platform.",
  //       },
  //     ],
  //   },
  //   servicePlatformData: {
  //     title: (
  //       <>
  //         Popular <span>E-commerce Platforms</span>
  //       </>
  //     ),
  //     technologyList: [
  //       {
  //         id: 1,
  //         source: Joomla,
  //         name: "Joomla",
  //       },
  //       {
  //         id: 2,
  //         source: PHP,
  //         name: "PHP",
  //       },
  //       {
  //         id: 3,
  //         source: Magento,
  //         name: "Magento",
  //       },
  //       {
  //         id: 4,
  //         source: BigCommerce,
  //         name: "BigCommerce",
  //       },
  //       {
  //         id: 5,
  //         source: Shopify,
  //         name: "Shopify",
  //       },
  //       {
  //         id: 6,
  //         source: Opencart,
  //         name: "OpenCart",
  //       },
  //       {
  //         id: 7,
  //         source: WooCommerce,
  //         name: "WooCommerce",
  //       },
  //       {
  //         id: 8,
  //         source: Drupal,
  //         name: "Drupal",
  //       },
  //       {
  //         id: 9,
  //         source: Aimeos,
  //         name: "Aimeos",
  //       },
  //       {
  //         id: 10,
  //         source: Zencart,
  //         name: "Zen Cart",
  //       },
  //       {
  //         id: 11,
  //         source: OSCommerce,
  //         name: "OsCommerce",
  //       },
  //     ]
  //   },
  //   whyChooseUs: {
  //     heading: (
  //       <>
  //         Why Scrum Digital for your <span> E-commerce Solutions?</span>
  //       </>
  //     ),
  //     desc: "To lead your business in a competitive market, increasing your website reach and traffic. We provide you solid foundations, with uniqueness in the workflow that commits to providing on-time delivery, the rapid growth of your business that use agile methodologies.",
  //     list: [
  //       {
  //         id: 1,
  //         source: ServiceWhyChooseEComersegrowth,
  //         alt: "badge",
  //         title: "Strategy for Growth",
  //         desc: "We develop feasible E-commerce websites with user-interactive features and intuitive design, making your online store, a shopper’s preferred destination.",
  //       },
  //       {
  //         id: 2,
  //         source: ServiceWhyChooseEComerseontimedel,
  //         alt: "badge",
  //         title: "On-Time Delivery",
  //         desc: "We understand that time is a valuable asset and so make sure to deliver all our projects on the deadlines.",
  //       },
  //       {
  //         id: 3,
  //         source: ServiceWhyChooseEComerseagile,
  //         alt: "badge",
  //         title: "Agile Method",
  //         desc: "We have an adopted agile methodology to render high-quality solutions for the E-commerce development process to delight our customers.",
  //       },
  //     ],
  //   },
  //   FAQSec: {
  //     title: (
  //       <>
  //         <span>FAQ's</span> for E-commerce Development
  //       </>
  //     ),
  //     desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
  //     inner: [
  //       {
  //         id: 1,
  //         question: "Why is an E-commerce app important for a business owner?",
  //         answer:
  //           "In the fast-paced world today, people find it inconvenient to visit a store physically to buy goods and services. They choose to browse for those goods and services online through browsers and mobile apps. Creating an E-commerce App for your business helps you gain leverage in this highly competitive world and assist in targeting the market base and keep the customers updated about all the latest services and products.",
  //       },
  //       {
  //         id: 2,
  //         question: "What are the different types of E-commerce mobile apps?",
  //         para: "It is important to be familiar with the type of E-commerce apps if you intend to build one for your business. Selecting the right type of E-commerce mobile app helps you choose better and create a sustainable business model for your business. This in turn will help you to target your customers effectively and assure amazing business growth. The different types of E-commerce mobile applications are:-",
  //         answer: [
  //           "Online shopping mobile app",
  //           "B2B E-commerce mobile app",
  //           "B2C E-commerce mobile app",
  //           "E-commerce aggregators mobile app",
  //           "Auction/bidding E-commerce app",
  //           "Ticket/Booking E-commerce app",
  //         ],
  //       },
  //       {
  //         id: 3,
  //         question: "Why choose Scrum Digital for E-commerce development?",
  //         answer:
  //           "Scrum Digital offers unique website development services. We lead your business in a competitive market, increasing your website reach and traffic. We provide you with a solid foundation and innovativeness in the workflow that commits to providing on-time delivery and uses agile methodologies for the rapid growth of your business.",
  //       },
  //       {
  //         id: 4,
  //         question: "Why should I invest in an E-commerce mobile app?",
  //         answer:
  //           "It is expected that by the year 2040 over 95% of the purchases would be made online by consumers. This gives you a bigger picture of the scope of the E-commerce business and opens up limitless possibilities. The comfort of E-commerce shopping is the biggest reason behind the success of E-commerce business around the world. From large companies to small vendors have started selling products online. The E-commerce business has potential for massive growth and the E-commerce mobile app is the flagship behind this groundbreaking growth.",
  //       },
  //       {
  //         id: 5,
  //         question:
  //           "What things should I consider before developing an E-commerce mobile app?",
  //         para: "If you want to venture into the E-commerce market, you must get a 360° view of the atmosphere. You need to consider all the aspects of mobile app development. Here are a few things to consider before proceeding:-",
  //         paraEnd:
  //           "Get in touch with our consultants here at Scrum Digital to help you with an in-depth guide to developing the app.",
  //         answer: [
  //           "Your budget",
  //           "Market study",
  //           "Competitor analysis",
  //           "Mobile app platforms",
  //           "Required Infrastructure",
  //           "MVP or full-fledged product",
  //           "Third-party alliance",
  //           "Geography specific regulations",
  //         ],
  //       },
  //       {
  //         id: 6,
  //         question:
  //           "What is the process of developing an E-commerce application?",
  //         para: "An E-commerce app needs to be intuitive, responsive and convenient for the consumers also it needs to keep its proposition base updated. A well-chosen platform or the right technology partner helps facilitate solutions for all these tasks. The entire app development procedure can be divided into few stages:-",
  //         paraEnd:
  //           "You can partner with Scrum Digital to create end-to-end E-commerce development solutions for various sectors around the globe.",
  //         answer: [
  //           "Preparation and planning",
  //           "Defining features/designs",
  //           "Development",
  //           "Testing",
  //           "Deployment and maintenance",
  //         ],
  //       },
  //     ],
  //   },
  // },
  {
    id: 6,
    typeOfService: "Digital Transformation",
    name: "UI/UX Design",
    link: "ui-ux-development-services",
    helmetSec: {
      title: "Best UI/UX Development Services | UI/UX Design Company in USA",
      description:
        "Scrum Digital is a UI/UX design company that enables smart user experience (UX) and dynamic user interface (UI) design for apps and websites.",
      keywords:
        "UI/UX Design Services, UI UX Design Company",
      conical: "/ui-ux-development-services",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          UI/UX <span>Design Services</span>
        </>
      ),
      banner_bott_head:
        "We develop intuitive, impactful, and engaging user experience to delight your customers and outshine your competition.",
      // bannerImg: uiUxDevelopmentBanner,
      bannerImg: serviceBannerUiUxDev,
      alt: "UI/UX Development service",
    },
    aboutSec: {
      title: (
        <>
          <span>UI/UX</span> Design Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a top-notch UI/UX design company with a global
            presence. We help companies create powerful, visually appealing
            designs for their clients as per their business needs. We have a
            dedicated team of professionals that follows certain standardized
            design process, meet deadlines, and delivers exactly what you’re
            looking for. Choose us to get the best solutions for your great
            front-end and back-end.{" "}
          </p>
          <p>
            {" "}
            Scrum Digital is a top-notch UI/UX design company with a global
            presence. We help companies create powerful, visually appealing
            designs for their clients as per their business needs. We have a
            dedicated team of professionals that follows certain standardized
            design process, meet deadlines, and delivers exactly what you’re
            looking for. Choose us to get the best solutions for your great
            front-end and back-end.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Explore our <span>UI/UX Design</span> Process
        </>
      ),
      desc: "We follow a flexible process that enhances user satisfaction, improves accessibility, usability and provides a pleasant experience to the interacted users.",
      list: [
        {
          id: 1,
          source: uiUxResearch,
          alt: "research",
          title: (
            <>
              Step 1 <span>Research</span>
            </>
          ),
          content:
            "As a reputed UI/UX company, we begin our design work by understanding your business goals, target segment, and competition and accordingly chalk out a plan that supports your business needs. After gaining insights, we help you in identifying your app user needs and align them with real-life user stories.",
        },
        {
          id: 2,
          source: uiUxWireframes,
          alt: "wireframes",
          title: (
            <>
              Step 2 <span>Wireframes</span>
            </>
          ),
          content:
            "Wireframe is an essential element of the interaction design process. Its presence ensures proper functionality within the app based on the needs of the user and business. It is cheaper, adaptable, easy to navigate, and allows the app to function without a snag. Wireframe also creates opportunities for shortcuts and makes the app faster.",
        },
        {
          id: 3,
          source: uiUxCoding,
          alt: "uiUxCoding",
          title: (
            <>
              Step 3 <span>Code Development</span>
            </>
          ),
          content:
            "As one of the finest UI design company, we ensure the front end of your website or app is appealing to the end-users. We make sincere efforts to ensure that your app’s UI design is in sync with your brand and merges well with your brand’s unified identity.",
        },
      ],
    },
    imgContentSec1: {
      src: uiUxImgContent,
      alt: "Get Amazing uiUxDevelopment",
      heading: (
        <>
          Get Amazing <span>UI/UX Design</span> Services
        </>
      ),
      desc: (
        <p>
          At Scrum Digital, our team of designers use the latest UI/UX practices
          to deliver high-performance products that aim to tackle business
          objectives and meet user needs. We are committed to enhancing your
          brand’s value with our best-in-class UI/UX design services.
        </p>
      ),
      list: [
        {
          name: "UX Strategy & Design",
        },
        {
          name: "Responsive Website Design",
        },
        {
          name: "Design Consulting",
        },
        {
          name: "Innovative and creative idea banks",
        },
        {
          name: "Futuristic web designs which are modern",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Choose Us for <span> UI/UX Design</span> Services?
        </>
      ),
      desc: "We provide you with compelling and appealing services that ensure to formulates an amazing design, with a versed, motivated, and experienced team.User Interface that attracts great traffic with easiness in the process we follow.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseuiuxAmazing,
          alt: "Amazing Design uiUx development service",
          title: "Amazing Design",
          desc: "A team with extensive experience and in-depth knowledge in UI/UX designing, we create innovative designs and aesthetics of your app as per your budget and project goals.",
        },
        {
          id: 2,
          source: ServiceWhyChooseuiuxMotivatedTeam,
          alt: "Motivated Team uiUx development service",
          title: "Motivated Team",
          desc: "At Scrum Digital, our goal is to deliver compelling visuals with great user-experience. We have a team of talented and self-motivated professionals, who utilizes the best strategies to turn your vision of an appealing app or website into reality.",
        },
        {
          id: 3,
          source: ServiceWhyChooseuiuxeasy,
          alt: "Easy-to-Use UI uiUx development service",
          title: "Easy-to-Use UI",
          desc: "We have the right expertise to create user friendly UI for the projects we handle and engage our customers in an advanced way.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for UI/UX Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "Why is UI/UX important?",
          answer:
            "The amalgamation of UI and UX forms the entire experience of a product. While two products can provide you with the same results, their UI/UX demonstrates how they provide it. If one product provides people with a good UI/UX experience then other people would prefer that product over the other because they prefer the overall experience. At Scrum Digital we develop intuitive, and engaging user experiences to delight your customers and outshine your competition.",
        },
        {
          id: 2,
          question: "What UI/UX design services do we offer?",
          answer:
            "Our team at Scrum Digital uses the latest practices to design high-performance products that address business objectives and meet user needs. We commit to enhance your brand value with our best-in-class UI/UX design services that include UX strategy & design, design consulting, mobile app design, futuristic web designs which are modern and much more.",
        },
        {
          id: 3,
          question: "Is it necessary to invest in UI/UX?",
          answer: (
            <>
              <p>
                Oftentimes clients get carried away by the core idea of their
                website/app which causes them to skip the design part and focus
                more on the development part. Later on, they may start losing
                the users due to poor design and usability issues which will
                lead them to spend more money to fix the design.
              </p>
              <p>
                The main focus while developing an app should be the intended
                audience. If the app is not built in accordance with the
                intended audience’s ease and expectations it will be both
                useless to the clients and their users.
              </p>
              <p>
                Thus it is important to invest in your app’s user experience and
                design.
              </p>
            </>
          ),
        },
        {
          id: 4,
          question: "What is the UI/UX design process?",
          para: "Every project to us is unique and we keep the process personalised. In most cases, our UI/UX design process includes three major steps:-",
          answer: [
            "Research- We begin our design process by understanding your business goals, our designers have the right skills to research for visuals and experience what works for your business.",
            "Wireframe- Wireframe is necessary for the design element process to ensure proper functionality in the app in accordance with the intended audience.",
            "Code Development-We make sure that your app’s UI design is in sync with your brand.",
          ],
        },
        {
          id: 5,
          question: "Why should we choose Scrum Digital for UI/UX design?",
          answer:
            "Scrum Digital is a top-notch UI/UX design company with a global presence. We provide you with a range of compelling and appealing designs that are compatible with your business. Our team of experts have served a wide range of industries from real estate, education to media and entertainment.",
        },
      ],
    },
  },
  {
    id: 7,
    typeOfService: "DigitalTransformation",
    name: "API Development",
    link: "api-development-services",
    helmetSec: {
      title: "API Development Services | API Integration Services",
      description: "With expertise in API Development and Integration Services, we engineer APIs that excel in reliability, usability, & thorough documentation. Explore Now!!",
      keywords: "api development services, custom api development services, API Developers, API Integration Services, API Solutions",
      conical: "/api-development-services",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          API Development <span>Services</span>
        </>
      ),
      banner_hading: "API Development",
      banner_span: "Services",
      banner_bott_head:
        "We offer cost-efficient and robust API development solutions that are custom-fitted for your business.",
      bannerImg: apiDevelopmentBanner,
      alt: "API Development service",
    },
    aboutSec: {
      title: (
        <>
          <span>Reliable API Development</span> Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is an industry-leading API development and management
            company providing custom integration solutions of top performance
            and security for any industry based on specific requirements. We are
            cost-effective and hold rich experience in delivering Application
            Programming Interface (API) development and integration services
            using programming languages like C, C++, Java, Python, and PHP. We
            have a highly motivated team of API development experts who are
            capable of handling all your API development needs, both simple and
            complex.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          <span>API Development</span> Services
        </>
      ),
      desc: "The API development services that we provide are reliable, well-documented and easy to consume, with better strategies, allowing flexible integrations and customization of existing products, to enrich and enhance services in more creative ways.",
      list: [
        {
          id: 1,
          source: apiService,
          alt: "apiDevelopment",
          title: (
            <>
              API Development <span>for Cloud Computing</span>
            </>
          ),
          content:
            "Cloud-based APIs facilitate integrating applications and other workloads into the cloud. They can be cross-platform and have the ability to improve cloud experience. We possess the right talent in the industry to build cloud-based APIs for social media platforms, images, video, and any other data in the cloud, etc.",
        },
        {
          id: 2,
          source: apiWebApiService,
          alt: "webApi",
          title: (
            <>
              Web <span>API Development</span>
            </>
          ),
          content:
            "Web API is a concept that can be accessed using the HTTP protocol. Our programmers have the expertise to develop Web APIs using advanced technologies including ROA and REST styles. We can create Web APIs for social media as well.",
        },
        {
          id: 3,
          source: apiMobileApiService,
          alt: "mobileApi",
          title: (
            <>
              Mobile <span>API Development</span>
            </>
          ),
          content:
            "Mobile API enables users to conveniently connect and engage with other applications and software for a superb mobile app experience. We at Scrum Digital develop APIs which allows users to make payments, fix appointments, search for geolocation, etc to refine your mobile applications",
        },
        {
          id: 4,
          source: apiIntegrationService,
          alt: "apiIntegration",
          title: (
            <>
              API <span>Integration</span>
            </>
          ),
          content:
            "API Integration improves connectivity and ensures seamless communication between various technologies, devices, and applications. It eases business and benefits customers. At Scrum Digital, our experts specialized in API integration: integration with PayPal, Google, Amazon, Twitter, Facebook; integration with JS, REST APIs, and much more.",
        },
        {
          id: 5,
          source: apiApiConsultingService,
          alt: "apiConsulting",
          title: (
            <>
              API <span>Consulting</span>
            </>
          ),
          content:
            "At Scrum Digital, we offer customized API development solutions to tackle your complex API needs. We use REST architecture and the finest development tools to create highly-efficient APIs for your business requirement.",
        },
        {
          id: 6,
          source: apiCustomApiService,
          alt: "customApi",
          title: (
            <>
              Custom <span>API Development</span>
            </>
          ),
          content:
            "At Scrum Digital, we offer customized API development solutions to tackle your complex API needs. We use REST architecture and the finest development tools to create highly-efficient APIs for your business requirement.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why choose Scrum Digital for <span> API Development?</span>
        </>
      ),
      desc: "Scrum Digital offers you and your business an extraordinary opportunity to ameliorate and improved user experience. Resolving your strenuous challenges, with sturdy support, and standardized quality of work.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseapiqulitywork,
          alt: "Top Quality Work api development service",
          title: "Top Quality Work",
          desc: "As a leading development company, we’ve helped diverse businesses with custom integration and taken their business to the next level. We have the expertise to deliver exceptional results.",
        },
        {
          id: 2,
          source: ServiceWhyChooseAppDevChallenges,
          alt: "Resolve Challenges api development service",
          title: "Resolve Challenges",
          desc: "Our custom integration solutions are created to deliver optimal results. It is our constant endeavor to satisfy our customers with our value-driven solutions.",
        },
        {
          id: 3,
          source: ServiceWhyChooseAppDevSupport,
          alt: "Strong Support api development",
          title: "Strong Support",
          desc: "We are available round the clock to support our esteemed clients in every possible way and ensure that everything runs seamlessly from beginning to end.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for API Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "How much do we charge for the API development services?",
          answer:
            "The cost of developing the API varies based on your requirements. We offer Application Programming Interface (API) development and integration services using programming languages like C, C++, Java, Python, and PHP. Our highly motivated team of API development first reviews your requirements and provides you with a written quote. You get to know the cost of your project in advance before you make any decision.",
        },
        {
          id: 2,
          question: "How long will it take for the API development?",
          answer:
            "At Scrum Digital we have a highly motivated team of API development experts that assure the fastest turnaround time. Our team assesses your requirements and gives you the complete details of the API development timeline via quotation.",
        },
        {
          id: 3,
          question: "How do I get started with API development?",
          answer:
            "The first step in starting your API development process is sending us all your requirements in detail and the technical specifications. If you do not have any information at this point, you can simply connect with us. Our expert consultants can work with you to figure out the details with you and help put together a strategic plan along with technical specifications.",
        },
        {
          id: 4,
          question: "Do I need API Consulting for my business?",
          answer:
            "In today’s modern IT business enterprise APIs are a necessity. A good API design and implementation helps drive your business. API consulting helps you create the right API for your business faster and at higher quality. At Scrum Digital we offer API consultation to create highly efficient API for your business requirements. We develop and test state of the art market fit hypotheses to drive the future of your business.",
        },
        {
          id: 5,
          question: "What kind of API Integration do we offer?",
          answer: (
            <>
              API Integration allows businesses to automate their process of
              conducting business and enhance the sharing and embedding of data
              between different systems and applications.At Scrum Digital our
              experts work with popular API Integration like integration with
              Paypal, Google, Amazon, Twitter, Facebook; integration with JS,
              REST APIs, and much more.
            </>
          ),
        },
      ],
    },
  },
  {
    id: 8,
    typeOfService: "Digital Transformation",
    name: "CMS Development",
    link: "cms-development-services",
    helmetSec: {
      title: "CMS Development Company | CMS Development Services",
      description:
        "Scrum Digital stands as a premier CMS development company in the USA, providing top-tier custom CMS solutions. Connect with our specialists today!",
      keywords:
        "CMS Development Company, CMS development services, Custom CMS Solutions",
      conical: "/cms-development-services",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          CMS <span>Development Company</span>
        </>
      ),
      banner_bott_head:
        "We create dynamic, secure, and scalable CMS enabled websites and apps for diverse business verticals.",
      bannerImg: cmsDevelopmentBanner,
      alt: "CMS  Development services",
    },
    aboutSec: {
      title: (
        <>
          <span>CMS</span> Development Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a transparent CMS development company in the
            industry. We offer end-to-end CMS development services to start-ups,
            big enterprise, and eCommerce stores across the globe. We follow
            best practices and an agile development process to create a
            well-built website as per your specifications and budget. We have an
            experienced team of developers to assist you to manage your content
            in a structured and more efficient way. We develop websites using
            the latest technologies compliant with international web standards.
            Partner with us for a wide variety of CMS development solutions.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Our Vital <span>CMS Development Services</span>
        </>
      ),
      desc: "The CUSTOM CMS DEVELOPMENT SERVICES let organizations save time and effort in the web development process. Our experts bring on the screen experience that your audience would love to interact with.",
      list: [
        {
          id: 1,
          source: cmsDevelopmentService,
          alt: "cmsDevelopment",
          title: (
            <>
              Custom <span>CMS Development</span>
            </>
          ),
          content:
            "We have the right expertise and experience to offer personalized CMS development solutions to a wide variety of clients. Choose us for best-in-class CMS solutions as per your business requirements.",
        },
        {
          id: 2,
          source: cmsECommService,
          alt: "ECommerceCms",
          title: (
            <>
              eCommerce <span>CMS Development</span>
            </>
          ),
          content:
            "We can help you create a high-end, and robust CMS platform for eCommerce websites that delivers a unique digital experience to your customers. You can trust us for a high-performance CMS website that suits your business goals",
        },
        {
          id: 3,
          source: cmsIntegrationService,
          alt: "cmsIntegrationService",
          title: (
            <>
              CMS <span>Integration</span>
            </>
          ),
          content:
            "We deliver CMS integration with ease. With profound experience, our developers can develop APIs that enables uninterrupted communication of CMS systems with other applications.",
        },
        {
          id: 4,
          source: cmsThemeExtensionService,
          alt: "cmsThemeExtension",
          title: (
            <>
              Theme/Extension <span> Development</span>
            </>
          ),
          content:
            "At Scrum Digital, our highly skilled developers are experts in providing custom theme or extension development solutions to clients from diverse industries as per their preference.",
        },
        {
          id: 5,
          source: cmsSupportService,
          alt: "cmsSupport",
          title: (
            <>
              Support <span>and Maintenance</span>
            </>
          ),
          content:
            "We not only just sell our services to the clients but also offer constant support and maintenance solutions during and post deployment of the solution. It is our endeavor to keep our clients happy at all times.",
        },
        {
          id: 6,
          source: cmsMigrationService,
          alt: "cmsMigration",
          title: (
            <>
              Migration <span>and Upgradation</span>
            </>
          ),
          content:
            "Our developers keep themselves abreast of emerging technologies. We upgrade your web application to the latest versions and if required migrate your application to another platform for better user experience and operational efficiency.",
        },
      ],
    },
    servicePlatformData: {
      title: (
        <>
          Popular <span>E-commerce Platforms</span>
        </>
      ),
      platformBg: {
        backgroundColor: "#424a54",
      },
      technologyList: [
        {
          id: 1,
          source: Joomla,
          name: "Joomla",
        },
        {
          id: 2,
          source: PHP,
          name: "PHP",
        },
        {
          id: 3,
          source: Magento,
          name: "Magento",
        },
        {
          id: 4,
          source: BigCommerce,
          name: "BigCommerce",
        },
        {
          id: 5,
          source: Shopify,
          name: "Shopify",
        },
        {
          id: 6,
          source: Opencart,
          name: "OpenCart",
        },
        {
          id: 7,
          source: WooCommerce,
          name: "WooCommerce",
        },
        {
          id: 8,
          source: Drupal,
          name: "Drupal",
        },
        {
          id: 9,
          source: Aimeos,
          name: "Aimeos",
        },
        {
          id: 10,
          source: Zencart,
          name: "Zen Cart",
        },
        {
          id: 11,
          source: OSCommerce,
          name: "OsCommerce",
        },
      ]
    },
    whyChooseUs: {
      heading: (
        <>
          Why choose Scrum Digital for <span> CMS Development Services?</span>
        </>
      ),
      desc: "CMS Software Development Solutions offered at Scrum Digital are designed specifically to meet your business needs and earn great profits. We are available 24*7 to discuss your needs and move ahead with your business expectations.",
      list: [
        {
          id: 1,
          source: ServiceWhyChoosecmsdedicated,
          alt: "Dedicated Approach cms development service",
          title: "Dedicated Approach",
          desc: "We follow a systematic approach during development which enables us to render projects that are liked by our clients.",
        },
        {
          id: 2,
          source: ServiceWhyChooseEComerseontimedel,
          alt: "On-Time Delivery cms development service",
          title: "On-Time Delivery",
          desc: "We are trained to work under tight deadlines and when we commit, we keep our promise. We deliver all our projects on time.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecmsquality,
          alt: "Quality service cms development service",
          title: "Quality service",
          desc: "We are committed to delivering high-quality service to all our clients. We offer solutions as per their business model.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> For CMS Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is the best CMS platform?",
          para: "Well the best CMS platform depends on your requirements and business needs. The CMS is basically a tool that lets you built a website without any in-depth understanding of coding complexities.Some of the platforms that our experts recommend are:-",
          answer: [
            "Joomla",
            "WordPress",
            "Drupal",
            "Wix",
            "Magento",
            "Textpattern",
            "PrestaShop",
          ],
        },
        {
          id: 2,
          question: "How does a content management system work?",
          para: (
            <>
              The Enterprise CMS Solutions that we offer you operate at two
              different levels: <br /> <b>Content Management Applications: </b>{" "}
              This lets the marketing team, merchandisers, and others update the
              content on your website directly. <br />
              <b>Content Delivery Applications:</b> This works as the back-end
              portion of the website, taking the content that you enter into the
              templates and let your website be accessible around the world. We
              build solutions that can easily be editable and help you bring a
              stronger identity to the screen. Easy to use and customize
              solutions for better results.
            </>
          ),
        },
        {
          id: 3,
          question: "How many types of CMS are there?",
          para: (
            <>
              <p>
                Basically, there are three types of CMS that you can choose
                from:
              </p>
              <p>
                <b>Traditional CMS</b> <br /> The simplest CMS in terms of
                layout and general functionality. Here basic HTML or WYSIWYG can
                be used to build solutions.
              </p>
              <p>
                <b>De-Coupled CMS</b> <br />
                It is a little complex as it offers different architecture for
                frontend and backend solutions. Thus, simply integrate the
                plugin, and the information is picked by the backend to give
                your website exactly the picture you want.
              </p>
              <p>
                <b>Headless CMS</b> <br />
                Headless CMS too offers similar functionality. It features
                separation between the content repository and backend and the
                publishing tools.
                <br />
                We offer CMS software development solutions for all kinds of CMS
                and can customize them in a way that they are exclusive to your
                organization.
              </p>
            </>
          ),
        },
        {
          id: 4,
          question: "Why use a content management system for your website?",
          para: "Content management system lets you create and manage the digital content on your website. You need not have an in-depth knowledge of programming languages to work on these systems. Other than this, some other advantages of using a content management system are:-",
          answer: [
            "You don’t need a web programming experience",
            "Easy to collaborate and access",
            "Can utilize advanced SEO tools",
            "Secure Solutions",
            "Cost-effective and affordable",
          ],
        },
        {
          id: 5,
          question:
            "What is the difference between a CMS and a website builders?",
          answer:
            "The difference between a CMS and a website builder is of ease and professional understanding. Working on CMS is easier and needs less understanding of the coding languages. These can easily be used to build strong websites that can mark your presence in the market. Website builders are more professional and need the expertise to build a website. These offer better features and easy customization alternatives. \nDepending on your business need, you can choose any of these solutions and our experts can help you build your website.",
        },
        {
          id: 6,
          question: "Why is content management important?",
          para: (
            <>
              <p>
                <b>Custom CMS Solutions</b> are important because they offer you
                the ease of building websites that are not only interesting but
                intuitive and can drive sales.
              </p>
              <p>
                It saves a lot of your time and resources without hampering your
                digital presence. If you want to build a name for yourself in
                the market then a customized solution can do wonders for you.
              </p>
            </>
          ),
        },
      ],
    },
  },
  {
    id: 9,
    typeOfService: "Digital Transformation",
    name: "CRM Development",
    link: "crm-development-solutions",
    helmetSec: {
      title: "CRM Development Services | CRM Development Company",
      description:
        "In search of a custom CRM Development Company? Experience our exceptional CRM Integration services! Our CRM developers offer optimal business solutions at competitive rates.",
      keywords:
        "CRM development company, crm development services, custom crm development services",
      conical: "/crm-development-solutions",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          CRM Development <span>Company</span>
        </>
      ),
      banner_bott_head:
        "Improve customer service and grow your business with our powerful CRM Solutions.",
      bannerImg: crmDevelopmentBanner,
      alt: "CRM  Development services",
    },
    aboutSec: {
      title: (
        <>
          <span>CRM Development</span> Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is an industry-leading CRM development company
            providing highly customized CRM solutions with rich functionality to
            a wide variety of industries. We help enterprises build and
            implement top-class CRM solutions that allow them to efficiently
            organize, and manage customer relationships and enhance
            profitability. We have a highly competent team of developers that
            will understand your business requirements and accordingly create a
            scalable and user-friendly customer relationship management (CRM)
            system. Become our partner and take your CRM process to the next
            level and feel the difference.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: crm2ImgContent,
      alt: "Advantages of crm Development service",
      heading: (
        <>
          Advantages of <span>Opting for a CRM</span>
        </>
      ),
      desc: (
        <p>
          CRM streamlines the business process and takes the client satisfaction
          to the next level. Some of its benefits includes the following:-
        </p>
      ),
      list: [
        {
          name: "Improves Productivity",
        },
        {
          name: "Effective Lead management",
        },
        {
          name: "Better management of customer information",
        },
        {
          name: "Data Security",
        },
        {
          name: "Proper Analysis & Reporting",
        },
        {
          name: "Automated Process for Sales",
        },
      ],
      listColumn: {
        column: 1,
      },
    },
    serviceSec: {
      title: (
        <>
          Customized <span>CRM Development Services</span>
        </>
      ),
      desc: "Our CRM development company helps you with strong and reliable CRM solutions that are tailored to suit your business objectives. Implement a well-developed feature-rich CRM software that drives sales and takes your business to new heights.",
      list: [
        {
          id: 1,
          source: crmDevelopmentService,
          alt: "crmDevelopment",
          title: (
            <>
              CRM <span>Development Solutions</span>
            </>
          ),
          content:
            "We help you create a CRM system quickly so that you can access and organize customer information and achieve higher efficiency in sales, marketing, and customer service. We build smart strategies that work for your profitability.",
        },
        {
          id: 2,
          source: crmConsultingService,
          alt: "crmConsulting",
          title: (
            <>
              CRM <span>Consultancy</span>
            </>
          ),
          content:
            "We provide CRM consultancy to medium and large enterprises and assist them to drive growth. Our consultant will take your business on the path of growth by leveraging technologies and customizing CRM solutions as per your specifications.",
        },
        {
          id: 3,
          source: crmIntegrationService,
          alt: "crmIntegrationService",
          title: (
            <>
              CRM <span>Integration Service</span>
            </>
          ),
          content:
            "Our team of CRM developers will help you find the best solutions to implement aperfect integration process. We will enable you to connect the missing dots that exist between the various departments with integration.",
        },
        {
          id: 4,
          source: crmMobileService,
          alt: "crmMobile",
          title: (
            <>
              CRM <span>Mobile Application</span>
            </>
          ),
          content:
            "We have the expertise of creating flawless CRM mobile apps that will enable your workforce to check real-time data when they are out in the field meeting customers and prospects.",
        },
        {
          id: 5,
          source: crmMigrationService,
          alt: "crmMigrationService",
          title: (
            <>
              CRM <span>Migration Solution</span>
            </>
          ),
          content:
            "Revolutionize the health care system by connecting medical devices, set up smarter retail options with proximity based advertisement based on your location, improving supply chain with easier tracking of goods and inventory management.",
        },
        {
          id: 6,
          source: crmSupportService,
          alt: "crmSupport",
          title: (
            <>
              CRM <span>Support and Maintenance</span>
            </>
          ),
          content:
            "We are available round the clock for your support and maintenance work. Our expert will be in touch with you for timely maintenance which is essential for flawless functioning.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why choose Scrum Digital for <span> CRM development?</span>
        </>
      ),
      desc: "We use years of experience and expertise to offer custom CRM development services to meet your business standards and generate heavy revenue. At Scrum Digital, we value your business like ours and ensure that you are a part of the development cycle.",
      list: [
        {
          id: 1,
          source: ServiceWhyChoosecrmcosteffect,
          alt: "Cost-Effective crm development service",
          title: "Cost-Effective",
          desc: "We are a reputed CRM development company that has helped several businesses find smart ways to track and nurture contacts with an existing and prospective client without much effort. You can bank on us for all the functionality required to manage information and relationships across multifarious touchpoints within the budget.",
        },
        {
          id: 2,
          source: ServiceWhyChooseEComerseontimedel,
          alt: "On-Time Delivery crm development  service",
          title: "On-Time Delivery",
          desc: "Customers are our top-most priority and when we commit to a particular timeline, we deliver with utmost sincerity. Alongside we ensure to deliver products of high quality. ",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmcostrelible,
          alt: "Highly Reliable crm development service",
          title: "Highly Reliable",
          desc: "We are your reliable partner in your quest for the best CRM software solutions. Our team is trained to offer complete reliability in terms of data generation and security for your business.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for CRM Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What does CRM do?",
          answer:
            "A CRM solution helps you stay closer to your customers and build strong customer relationships. With such software, you can easily identify the user activity on your website and choose to get the best results in a limited time.",
        },
        {
          id: 2,
          question: "Why Does CRM Matters?",
          answer:
            "CRM Integration Service providers believe the software can do wonders for your business. It brings you a little closer to your audience as you can observe their actions and record their needs. You can always be in a win-win situation as you would be aware of the customer needs and the pitfalls they may be facing in operating your application.",
        },
        {
          id: 3,
          question: "Who can benefit from CRM?",
          answer:
            "Well, the sales department can make the most of the CRM. It becomes easier for them to track the customers’ movements on the website and figure out the possible reasons that made them abandon the website without completing the expected action.\nOver the years, we have moved from a business-centric to a customer-centric approach and CRM application development teams help organizations stay committed to this approach.",
        },
        {
          id: 4,
          question: "What are the benefits of CRM?",
          para: (
            <>
              <p>
                A CRM system can easily benefit you in developing interesting
                solutions and understanding your customers better. For any
                business to flourish it is important to be in line with the
                customers’ thoughts and a well-developed CRM solution helps you
                in the same.
              </p>
              <p>Some of the vital roles the solution plays are:-</p>
            </>
          ),
          answer: [
            "Better Knowledge of Customer Needs",
            "Better Segmentation",
            "Better Customer Retention",
            "Better Anticipation of Needs",
            "Better and Speedier Communication",
            "Better Data Security",
          ],
        },
        {
          id: 5,
          question: "How does CRM work?",
          answer:
            "Customer Relationship Management System can save time, cash, and assets, just as end-client misfortunes. Client Relationship Management programming is intended to consequently gather significant information across different directs and aggregate it in a simple to-utilize data set.",
        },
        {
          id: 6,
          question: "How can CRM improve your business process?",
          answer: (
            <>
              <p>
                Most organizations that consider embracing a CRM framework,
                above all else, are searching for an approach to work on the
                quality and consistency of their associations with clients and
                assemble client devotion.
              </p>
              <p>
                This implies that they need to further develop their center
                business measures – overseeing contact data and smoothing out
                their relations with clients.
              </p>
            </>
          ),
        },
      ],
    },
  },
  {
    id: 10,
    typeOfService: "Digital Transformation",
    name: "IOT Application development",
    link: "iot-app-development-services",
    helmetSec: {
      title:
        "IOT Application Development | IOT App Development Services | ScrumDigital",
      description:
        "Scrum Digital provides cost effective IOT app devlopment services to connect physical devices to the clouds, leveraging our proficiency in IoT protocols, platforms, and gateways.",
      keywords:
        "IOT App Development Services, IOT Application Development",
      conical: "/iot-app-development-services",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          IOT App <span>Development Services</span>
        </>
      ),
      banner_bott_head:
        "IoT has connected the physical world with the digital world to create an effective ecosystem that delivers value at every stage.",
      bannerImg: iotApplicationBanner,
      alt: "IoT Development services",
    },
    aboutSec: {
      title: (
        <>
          IoT Application <span>Development Company</span>
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a leading name in the industry for delivering
            cost-effective, reliable, and scalable IoT solutions to clients
            across the globe. We offer ROI- oriented, customized IoT application
            development services that connects systems and integrates data of
            the real world enclosed with sensors, and internet connectivity.
            From ideation to integration, we help you address business
            challenges and tech hindrances with IoT technologies. We have the
            right expertise and in-depth knowledge to develop impactful IoT apps
            across diverse industries to help move your business forward.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Why Choose Us for <span>IoT App Development?</span>
        </>
      ),
      desc: "If you wish to lead your competitors in unfeigned technologically advanced world, with stringent quality compliance, adhering to strict deadlines, experiencing the excellency then avail our high-end IoT development services.",
      list: [
        {
          id: 1,
          source: iotDominantService,
          alt: "iotDominantService",
          title: (
            <>
              Dominant <span>Connectivity</span>
            </>
          ),
          content:
            "We are specialized in developing IoT platform that can connect with any device, sensors, or machine at any time over any network. Our talented team of developers will provide you a wide spectrum of connectivity options and align all your internet connected devices under real-time range.",
        },
        {
          id: 2,
          source: iotAdvanceAppService,
          alt: "AdvanceIotApp",
          title: (
            <>
              Advanced <span>IoT App</span>
            </>
          ),
          content:
            "At Scrum Digital, we render versatile deployment options to companies on applications like SAAS, PAAS, premises, on hybrid and edge. We help companies grow their business by delivering state-of-the-art IoT app with solutions for business integration, predictive, analysis, and machine learning.",
        },
        {
          id: 3,
          source: iotIntegrationService,
          alt: "iotIntegration",
          title: (
            <>
              Customized <span>and Integrating</span>
            </>
          ),
          content:
            "We are a full-service IoT development company, offering custom-tailored services and data integration to our valuable clients. We provide end-to-end app development solutions from ideation to integration which involves UI designing, development, testing, and eventually project completion.,",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          FAQ's for <span>IOT Development</span>
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is an IOT solution?",
          answer:
            "Internet of things (IoT) Solutions encompasses everything from a seamlessly integrated bundle of technologies from IoT device designers to sensors that organisations use to solve problems and create value for the organisation. IoT Solutions have proved beneficial for almost every single industry, assisting growth for new businesses and boosting productivity. IoT solutions have helped organisations to connect devices, manage work and analyze opportunities and transfer data in a secure manner.",
        },
        {
          id: 2,
          question: "What is IoT?",
          answer:
            "To explain briefly Internet of Things (IoT) is a concept of connecting any device to the Internet and other connected devices. IoT is a huge network of connected things and people all of which collect and share information about the usage and the environment around them, which includes an array of items around them from smart microwaves which cook your food for the perfect time to wearable fitness watches that tracks your sleep and your steps count for the day to suggest you exercises tailored to your activity level.",
        },
        {
          id: 3,
          question: "Are Iot and digitization the same?",
          answer:
            "Digitization is a fairly broad term, it can be called a business strategy aimed at the digital transformation of manual labour or analog business setup and practices. Like scanning paper files to store into a digital format as pdf can be considered as digitization. Whereas the Internet of Things is a means to that end.",
        },
        {
          id: 4,
          question: "What devices are part of Iot?",
          answer:
            "IoT devices are devices that connect wirelessly to a network and have the ability to exchange data with other devices connected to the Internet. IoT devices are a part of an ecosystem that brings together various digital components to create a continuous communication pathway between devices and people. IoT devices can be categorized into three main groups - Industrial, Enterprise and Consumer. Consumer devices include smart TVs, speakers, fitness trackers, and smart appliances.",
        },
        {
          id: 5,
          question: "How does IoT work?",
          answer:
            "IoT devices with built-in sensors are connected to an IoT platform that merges data from different connected devices and applies analytics to share useful data with applications and devices designed to address specific needs.",
        },
        {
          id: 6,
          question: "What is the future of IoT?",
          answer:
            "IoT has limitless potential in the upcoming future. It’s a growing trend with new products pouring into the market. The range of current and upcoming potential IoT devices in the market is huge. More and more companies and cities will use IoT technology to save time and money. Scrum Digital has been a leading name in the industry to offer IoT app development services from ideation to integration to develop IoT apps in your organisation to help you move forward in business.",
        },
      ],
    },
  },
  // {
  //   id: 11,
  //   typeOfService: "Digital Transformation",
  //   name: "Chatbot Development",
  //   link: "chatbot-development-services",
  //   helmetSec: {
  //     title:
  //       "Chatbot Development Company in USA | Scrum Digital",
  //     description:
  //       "Innovative chatbot development company creating chatbot solutions for improved user interactions. Elevate your business with custom chatbot services.Connect today!",
  //     keywords:
  //       "chatbot development company, chatbot development services, chatbot solutions, open ai chatbot, generative ai",
  //     conical: "/chatbot-development-services",
  //     ogimg: "/ogdigital_transformation.png"
  //   },
  //   bannerData: {
  //     bannerHeading: (
  //       <>
  //         Chatbot <span>Development Services</span>
  //       </>
  //     ),
  //     banner_bott_head:
  //       "Delivering simple, intuitive, and interacting user experience with conversational solutions.",
  //     bannerImg: chatBotDevelopmentBanner,
  //     alt: "Chatbot  Development",
  //   },
  //   aboutSec: {
  //     title: (
  //       <>
  //         Chatbot <span>Development Company</span>
  //       </>
  //     ),
  //     desc: (
  //       <>
  //         <p>
  //           Scrum Digital offers end-to-end custom chatbot development services
  //           for smooth and efficient interaction between business and customers.
  //           We use robust technology to build and implement smart chatbots over
  //           numerous channels like eCommerce, Healthcare, retail, marketing,
  //           customer support, software, and entertainment. Our dedicated team of
  //           developers with vast experience can create highly powerful and
  //           intelligent chatbots tailored to your business needs. We have an
  //           unwavering passion for excellence to build the best for our esteemed
  //           clients.
  //         </p>
  //       </>
  //     ),
  //   },
  //   serviceSec: {
  //     title: (
  //       <>
  //         Benefits of <span>Chatbot Development</span> Services
  //       </>
  //     ),
  //     desc: "Chatbots are anthropomorphic, they can act and communicate with responsiveness like humans. Our chatbots have high audience engagement, minimize workforce, automation conversation in your business.",
  //     list: [
  //       {
  //         id: 1,
  //         source: chatbotLeadsService,
  //         alt: "chatbotLeads",
  //         title: (
  //           <>
  //             Generate <span>Leads</span>
  //           </>
  //         ),
  //         content:
  //           "Bots are capable of engaging customers and gaining important feedback from customer conversations. This data will enable them to generate verified leads and ultimately helps to enhance sales.",
  //       },
  //       {
  //         id: 2,
  //         source: chatbotMinimizeService,
  //         alt: "chatbotMinimize",
  //         title: (
  //           <>
  //             Minimize <span>Workforce</span>
  //           </>
  //         ),
  //         content:
  //           "Chatbots have streamline interactions and revolutionized the way businesses engage with customers. They have helped to minimize unwanted workforce by executing easy tasks plenty of times.",
  //       },
  //       {
  //         id: 3,
  //         source: chatbotHighCustomerService,
  //         alt: "chatBotHighCustomer",
  //         title: (
  //           <>
  //             High <span>Customer-engagement</span>
  //           </>
  //         ),
  //         content:
  //           "Chatbots promote better customer engagement with proactive conversations round the clock rather than just plain automated response. These bots offer personalized recommendations that help to enhance the customer experience.",
  //       },
  //     ],
  //   },
  //   whyChooseUs: {
  //     heading: (
  //       <>
  //         Why choose Scrum Digital for <span> Chatbot Development?</span>
  //       </>
  //     ),
  //     desc: "Scrum provides you an efficient, user friendly, and automated way of expressing your thoughts through an artificial created communication channel that imitates humans with great flexibility. Influencing with great expertise, exceptional created products, great maintenance and with durable support.",
  //     list: [
  //       {
  //         id: 1,
  //         source: ServiceWhyChoosecrmcostsupport,
  //         alt: "badge",
  //         title: "Support and Maintenance",
  //         desc: "We offer complete support 24*7 round the year for any complications post-deployment. We keep a watch on the product delivered and provide constant training to the bot by building a database of the frequently asked questions. This activity helps us to enhance the capabilities of the bot and improve its intelligence in the best possible way.",
  //       },
  //       {
  //         id: 2,
  //         source: ServiceWhyChoosecrmcostgreat,
  //         alt: "badge",
  //         title: "Great Expertise",
  //         desc: "Our team of developers has in-depth knowledge and expertise to create an amazing product that exceeds your expectations. Over the years, we have developed some of the finest chatbots for various clients from diverse industries. We work on popular frameworks like Google ‘s DialogFlow, Amazon Lex, and Microsoft Bot, etc.",
  //       },
  //       {
  //         id: 3,
  //         source: ServiceWhyChoosecrmcostrelible,
  //         alt: "badge",
  //         title: "High-Quality Product",
  //         desc: "We consistently strive to deliver top-class, high-quality products to our esteemed clients. Our process of chatbot development begins with understanding your business needs coupled with our experience to shape a fine product. We ensure that the chatbot created has the perfect guise and fluent conversation flow to provide great customer experience.",
  //       },
  //     ],
  //   },
  //   FAQSec: {
  //     title: (
  //       <>
  //         FAQ's for <span>ChatBot Development</span>
  //       </>
  //     ),
  //     desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
  //     inner: [
  //       {
  //         id: 1,
  //         question: "Who will be the chatbot users?",
  //         para: (
  //           <>
  //             <p>
  //               The functionality of Chatbot is way beyond how it first started;
  //               they make everyday life for business owners easier. The use of
  //               chatbots can be found from the basic task of starting a simple
  //               conversation up to the bots that can manage your money.
  //             </p>
  //             <p>Different industries that use chatbot technology are:-</p>
  //           </>
  //         ),
  //         paraEnd:
  //           "Many businesses both small and large are using chatbot technology to grow their business, simplify their sales, increase engagement, streamline payments and for customer support. Chatbots support a lot of industries, they do serve their clients successfully. Chatbot development companies like Scrum Digital deliver a simple, intuitive and interactive user experience with conversational solutions.",
  //         answer: [
  //           "Entertainment",
  //           "News",
  //           "Health",
  //           "Customer service",
  //           "Real Estate",
  //         ],
  //       },
  //       {
  //         id: 2,
  //         question: "What information sources will chatbot use?",
  //         answer: (
  //           <>
  //             <p>
  //               A chatbot functions by accessing the business data from your
  //               business, from the current knowledge base, website, internal
  //               database, documents, reservation systems, shipping information,
  //               product inventories and from other different sources of
  //               information.
  //             </p>
  //             <p>
  //               Once it is determined which sources of data are needed to answer
  //               the questions you can distinguish how you want the chatbot to
  //               get access to the data in order to answer the question in a
  //               relevant manner.
  //             </p>
  //           </>
  //         ),
  //       },
  //       {
  //         id: 3,
  //         question:
  //           "What kind of questions should the chatbot be able to answer?",
  //         answer:
  //           "Chatbots respond to your customer’s need for immediacy and self-sufficiency. They answer the most frequent questions asked by the customer and most recurrent use cases.",
  //       },
  //       {
  //         id: 4,
  //         question: "What security concerns are there with chatbots?",
  //         answer:
  //           "Chatbots are well secured through the process of authentication and authorization. A user’s identity is verified before any information is provided. They protect user information from cybercrime so it is safe to integrate them into your business.",
  //       },
  //       {
  //         id: 5,
  //         question: "When will the chatbot escalate to a human agent?",
  //         para: (
  //           <>
  //             <p>
  //               Chatbots today are designed to handle simple as well as several
  //               complex queries of customers. But in some scenarios, a
  //               conversation needs a human touch, in such cases the
  //               conversations are handed over to live agents. Usually, a chatbot
  //               acts as the first line of support.
  //             </p>
  //             <p>
  //               There are four scenarios in which case the chatbot will escalate
  //               to a human agent:-
  //             </p>
  //           </>
  //         ),
  //         answer: [
  //           "When a customer is not happy, irritated or not satisfied with the chatbot.",
  //           "When a situation involves a sensitive conversation or a high volume transaction, the chat will be escalated to a human agent.",
  //           "When a customer raises a complex issue that a chatbot cannot analyze and handle the issue.",
  //           "When a customer chooses to talk to a live agent.",
  //         ],
  //       },
  //       {
  //         id: 6,
  //         question: "How does a chatbot works?",
  //         answer: (
  //           <>
  //             <p>
  //               A chatbot is a program designed to incite human conversation.
  //               Users communicate via chat or voice just like they converse with
  //               another human. The chatbot then interprets the question and
  //               answers them from a set of fixed answers.{" "}
  //             </p>
  //             <p>
  //               Our dedicated team of developers work around the clock to create
  //               highly powerful and intelligent chatbots tailored to your
  //               business needs.
  //             </p>
  //           </>
  //         ),
  //       },
  //     ],
  //   },
  // },
  {
    id: 12,
    typeOfService: "Digital Transformation",
    name: "Cloud Computing",
    link: "cloud-computing-solutions",
    helmetSec: {
      title: "Cloud Application Development Services | Scrum Digital",
      description:
        "Leverage our expertise for a diverse range of cloud app development services,tailored to your needs on platforms - AWS, Microsoft Azure & Google Cloud Platform.",
      keywords:
        "Cloud Computing Solutions, Cloud Application Development Company, Cloud Application Development services",
      conical: "/cloud-computing-solutions",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Cloud Application <span>Development Services</span>
        </>
      ),
      banner_bott_head:
        "Developing Flexible, Reliable, and Scalable Next-Generation Cloud Applications for organizations from diverse industries.",
      bannerImg: cloudSolutionBanner,
      alt: "Cloud Computing services",
    },
    aboutSec: {
      title: (
        <>
          <span>Cloud Application</span> Development Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a top-notch cloud application development company
            that helps enterprises create an easily accessible and
            cost-effective cloud project of any complexities. We can let you
            create cloud software from scratch, integrate, and migrate your
            system into the cloud along with round the clock support and
            maintenance. We use cutting-edge technologies for delivering
            application platform services and the right expertise for developing
            a cloud computing environment. Our cloud solutions can be
            conveniently implemented into your already existing infrastructure
            and can be customized according to the user’s business needs.
            Partner with us for the best-in-class cloud solutions for your
            enterprise.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Our <span>Cloud Application</span> Development Services
        </>
      ),
      desc: "Our cloud app development company offers you interesting solutions that take care of your business data. We create flexible, scalable, secure solutions that drive sales and extend support for optimized customer experiences.",
      list: [
        {
          id: 1,
          source: cloudAppService,
          alt: "cloudBaseService",
          title: (
            <>
              Cloud-Based <span>App Development</span>
            </>
          ),
          content:
            "We have expertise in building cloud-based applications that meet your precise business needs. We implement automated processes for validation, use agile methodologies, and strategize the entire development process by delivering top-end outputs.",
        },
        {
          id: 2,
          source: cloudConsultingService,
          alt: "cloudConsulting",
          title: (
            <>
              Cloud <span>Consulting Services</span>
            </>
          ),
          content:
            "We provide end-to-end consulting and support services to help our esteemed clients discover new opportunities to leverage the cloud. It begins with our cloud development specialists analyzing your business needs, infrastructure, and accordingly work-out a strategy for you.",
        },
        {
          id: 3,
          source: cloudIntegrationService,
          alt: "cloudIntegration",
          title: (
            <>
              Cloud <span>Integration Services</span>
            </>
          ),
          content:
            "We have expertise in integrating services around AWS, Azure, and other major solutions. Simultaneously, we work to ensure that data and processes are aligned towards on-premise and cloud services. We also keep track of the overall organizational needs of growing enterprises.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Choose Us for <span>Cloud Application</span> Services?
        </>
      ),
      desc: "With our Cloud Computing Solutions, you can rest assured to stay ahead in the digital journey. We design and build solutions that let you create innovative and comprehensive solutions that are efficient and can help you build a strong digital identity.",
      whyBg: {
        backgroundColor: "#fcfcfc",
      },
      list: [
        {
          id: 1,
          source: ServiceWhyChooseEComersegrowth,
          alt: "Strategy for Growth cloud application service",
          title: "Strategy for Growth",
          desc: "We develop feasible eCommerce websites with user-interactive features and intuitive design, making your online store, a shopper’s preferred destination.",
        },
        {
          id: 2,
          source: ServiceWhyChooseEComerseontimedel,
          alt: "on time delivery cloud application service",
          title: "On-Time Delivery",
          desc: "We understand that time is a valuable asset and so make sure to deliver all our projects on the deadlines.",
        },
        {
          id: 3,
          source: ServiceWhyChooseEComerseagile,
          alt: "agile method cloud application service",
          title: "Agile Method",
          desc: "We have an adopted agile methodology to render high-quality solutions for the eCommerce development process to delight our customers.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          FAQ's for <span>Cloud-Based Solutions Services</span>
        </>
      ),
      faqBg: {
        backgroundColor: "#f5f5f5",
      },
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is cloud computing?",
          answer:
            "Traditionally hardware and software are fully contained in a user’s computer, which meant that the data and programs were exclusively available on that computer. With the help of cloud computing, you can access data and programs outside of your computing environment. Instead of storing data on your computer or server, it is stored in ‘the cloud’. This could include applications, databases, emails and file services.",
        },
        {
          id: 2,
          question:
            "How do I know Cloud-based services are right for my business?",
          para: (
            <>
              <p>
                Companies that shift to cloud-based solutions do so for a
                variety of reasons. There are many benefits associated with
                switching to cloud-based computing. It basically creates a
                virtual office that allows you the flexibility to connect with
                your business at any time and anywhere.
              </p>
              <p>Some benefits of switching to Cloud-based services:-</p>
            </>
          ),
          answer: [
            "Reduced IT costs",
            "Scalability",
            "Business continuity",
            "The flexibility of work practices",
            "Quality Control",
            "Automatic software updates",
            "Sustainability",
          ],
        },
        {
          id: 3,
          question: "How do I develop a cloud strategy for my business?",
          answer:
            "Developing a cloud strategy for your business begins with outlining your business and technical objectives. Scrum Digital provides end-to-end consultation and support services to help our esteemed clients discover new opportunities to leverage the cloud. Our cloud development specialists analyze your business needs, infrastructure, and accordingly work out a strategy for you.",
        },
        {
          id: 4,
          question: "What workload can I move to the cloud?",
          answer:
            "There are many options for moving your workload to the cloud and most of it depends upon your business and cloud strategy. Most organisations use cloud computing for backing up the data for both the short and long term.CRM, marketing automation and other application-based workloads can also be moved to the cloud. The ability to set up the system and scale them up and down on-demand and to access data from anywhere is a major benefit of cloud computing.",
        },
        {
          id: 5,
          question:
            "Will my organisation need to hire more IT staff to handle the Integration?",
          answer:
            "Scrum Digital takes care of all the ins and outs of your cloud transitions. You don’t need to hire additional IT staff for cloud Integration. We have expertise in integrating services around AWS, Azure, and other major solutions. Also, our team works to ensure that data and processes are aligned towards on-premise and cloud services. We also keep track of the overall organizational needs of growing enterprises.",
        },
        {
          id: 6,
          question:
            "Are cloud-based applications beneficial for small business owners?",
          answer:
            "More and more SMEs are implementing Cloud-based applications because of their ease of use, ease of implementation and lower cost of purchase. We have the expertise in building cloud-based applications that meet your precise business needs.",
        },
        {
          id: 7,
          question:
            "How much does it cost to integrate my business with the cloud?",
          answer:
            "If you are a start-up or a small organisation then the cost of cloud integration services will be different as compared to a big organisation where the cost will be a little higher. With cloud computing solutions at Scrum Digital, you can rest assured to stay ahead in the digital journey at a reasonable cost.",
        },
        {
          id: 8,
          question: "What are the risks associated with cloud computing?",
          answer:
            "While cloud computing comes with many benefits, there are also few risks involved like compliance violations, identity theft, and breach of data. That’s why teaming up with a professional and trustworthy technology partner like Scrum digital is important to keep your business safe and growing.",
        },
      ],
    },
  },
  {
    id: 13,
    typeOfService: "Digital Transformation",
    name: "ERP Consulting services",
    link: "erp-consulting-services",
    helmetSec: {
      title:
        "Trusted ERP Software Development Company | ERP Consulting Services",
      description:
        "Empower your business growth with our comprehensive ERP consulting and software development services. Reach out to us today to unlock your potential!",
      keywords:
        "ERP Consulting Services, ERP Software Development Company ",
      conical: "/erp-consulting-services",
      ogimg: "/ogdigital_transformation.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          ERP Consulting <span>Services</span>
        </>
      ),
      banner_bott_head:
        "Simplify Business Process and Boosts Productivity with the most trusted and secure ERP Solutions.",
      bannerImg: erpSolutionBanner,
      alt: "Erp consulting services",
    },
    aboutSec: {
      title: (
        <>
          <span>ERP</span> Solutions Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a full-service ERP software development company
            with a global footprint. We use diverse technologies and industry
            expertise to deliver fully functional ERP to solve your business
            challenges. By implementing ERP solutions in your organization, we
            reduce fix costs and streamline multifarious processes into one
            single framework. We are a reputed one-stop solution provider for
            ERP services in the industry. We are committed to consistently
            deliver value to your business through our ERP products and
            services. Choose us as a reliable partner to work on your projects
            according to your specifications.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Our <span>ERP Software</span> Services
        </>
      ),
      desc: "Get the most reliable enterprise management tools that bring transparency, credibility, and system for your internal business operations from renowned ERP service providers. Over the years we have helped businesses build efficient and accurate ERP solutions that have helped them save time and invest in core functionalities.",
      list: [
        {
          id: 1,
          source: erpOdooService,
          alt: "odooService erp service",
          title: (
            <>
              ODOO <span>ERP Solutions</span>
            </>
          ),
          content:
            "We can help you integrate and streamline all business processes across various departments including accounting, manufacturing, inventory, purchasing, sales, etc using dedicated ODOO ERP solutions. It is a one-stop shop for all your business requirements to enable automated processes and to enhance operational efficiency.",
        },
        {
          id: 2,
          source: erpSapService,
          alt: "erpSap",
          title: (
            <>
              SAP <span>ERP Solutions</span>
            </>
          ),
          content:
            "We let all our clients follow the best industry practices and suggest to them the most amazing ERP solutions that deliver optimal benefits and flawless project management. We have a dedicated team of professional developers to help our clients in successfully implementing ERP services. We use futuristic technologies like artificial intelligence to transform your business processes as per your preference either in the cloud or on-premise.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Importance of <span>ERP Software</span> Integration for Business
        </>
      ),
      desc: "ERP software integration helps businesses streamline the functioning of all the departments of your business for the best revenue and efficient services.",
      list: [
        {
          id: 1,
          source: ServiceWhyChoosecrmerpproductivity,
          alt: "Enhances Productivity erp service",
          title: "Enhances Productivity",
          desc: "ERP improves the productivity of an organization to a great extent. By its implementation, all aspects of the organization can be managed from a single source efficiently. This has immensely enhanced productivity with lesser dependence on any particular type of software.",
        },
        {
          id: 2,
          source: ServiceWhyChoosecrmerpsimplyfy,
          alt: "Simplify Processes erp service",
          title: "Simplify Processes",
          desc: "ERP simplifies processes by integrating data from all areas of an organization into a single system that is easily accessible and manageable. If you associate with us for ERP testing and implementation services, we can help you achieve optimal results and scale greater heights.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpimrove,
          alt: "ERP Improves Employee Productivity erp service",
          title: "ERP Improves Employee Productivity",
          desc: "ERP has made every system automated. It facilitates autonomous functioning for all the processes. It has strengthened employee productivity in a big way by decreasing the time taken on physical purchase requests and approvals.",
        },
      ],
    },
    whyChooseUs1: {
      heading: (
        <>
          Why Choose <span>Us?</span>
        </>
      ),
      desc: "Enterprise Resource Planning at Scrum Digital provides you with dedicated developers that exhibit innovative creation with the best expertise for accounting,procurement,and risk management.",
      whyBg: {
        backgroundColor: "#fcfcfc",
      },
      list: [
        {
          id: 1,
          source: ServiceWhyChoosecrmerpfast,
          alt: "Better Risk Management erp services",
          title: "Better Risk Management",
          desc: "As one of the trusted online reputation management firm, Scrum Digital provides faster and efficient ORM services to revamp your digital reputation.",
        },
        {
          id: 2,
          source: ServiceWhyChoosecrmerpsolut,
          alt: "On-Time and With Budget erp service",
          title: "On-Time and With Budget",
          desc: "We offer personalized ORM services regardless of the size and scale of your business. Depending on your business needs, we work to clean up your online reputation, improve online reviews, and protect personal data.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpresult,
          alt: "Client's satisfaction erp service",
          title: "Client's satisfaction",
          desc: "We have a team of highly skilled and experienced ORM professionals who work tirelessly to exceed your expectations and provide business-driven results.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          FAQ's <span>for</span> ERP Software Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      faqBg: {
        backgroundColor: "#f5f5f5",
      },
      inner: [
        {
          id: 1,
          question: "What Kind of Companies Use ERP Services?",
          answer:
            "ERP Solutions are used to manage the workflow of an organization in a way that is efficient and requires the least human intervention. An advanced ERP solution would work on accounting, inventory management, sales and purchase orders, customer database, and HR, all under the same dashboard. Thus, no matter what kind of company you are running, you would surely need an ERP solution to streamline your business operations.",
        },
        {
          id: 2,
          question: "ERP vs CRM software: what is the difference?",
          answer: (
            <>
              <p>
                ERP SOFTWARE DEVELOPMENT centers around the financial capacities
                of the business. It is more about how the resources can be
                invested and what are the suitable outcomes. But when we talk
                about CRM software it is centered around customer relationships.
              </p>
              <p>
                Although both the software needs the data from each other and
                work in synchronization with each other, they do reflect the
                best results on the annual charts of the organization.
              </p>
            </>
          ),
        },
        {
          id: 3,
          question: "How much does ERP software cost?",
          answer: (
            <>
              <p>
                If you are a start-up or a small organization that would not
                need a complex ERP then the cost of development of ERP software
                would be less and if you have a big enterprise then the cost of
                development of complex solutions is a little higher.{" "}
              </p>
              <p>
                When it comes to setting up the ERP solutions, we at Scrum
                Digital make it a point to offer the most affordable solutions
                with the best features that do not disturb your finances.
              </p>
            </>
          ),
        },
        {
          id: 4,
          question: "Are there major risks associated with ERP systems?",
          answer:
            "Lack of adequate technological infrastructure and adequate skills may result in a complex ERP solution that would not be reliable. With some pitfalls in your software, your business would suffer. The secure and safe solutions need to be interesting and engaging that connect with your business data easily.",
        },
        {
          id: 5,
          question: "What is an ERP selection process?",
          para: "The ERP solutions that we offer are well worked on. The selection process would consist of five key phases:-",
          answer: [
            "Consider the Project Preparation and Planning Process",
            "RFI and Introductory Demonstrations for Business Needs",
            "Requirements and Request for Proposal to Deliver Better Results",
            "Scripted Software Demonstrations for Better Results",
            "Reference Calls, Site Visit, and Supplier Selection",
          ],
        },
        {
          id: 6,
          question: "How to choose the right ERP?",
          para: "When choosing the right ERP software just follow these simple steps:-",
          answer: [
            "Conduct review and analysis of the business needs",
            "Look for technical expertise",
            "Total cost of ownership",
            "Implementation Plan",
            "Benefits of the new system",
            "Consider various options",
            "Look for support and assistance from service",
          ],
        },
      ],
    },
  },
  {
    id: 14,
    typeOfService: "Digital Marketing",
    name: "Digital Marketing Services",
    link: "digital-marketing-services",
    helmetSec: {
      title: "Best Digital Marketing Services | Digital Marketing Company",
      description:
        "Boost sales with our data-driven digital marketing services. Get a free analysis of your website's SEO, PPC and web design needs today.",
      keywords:
        "digital marketing services,  digital marketing company,best digital marketing services",
      conical: "/digital-marketing-services",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Digital <span>Marketing Services</span>
        </>
      ),
      banner_bott_head:
        "We render end-to-end digital marketing solutions to help organizations attain online success.",
      bannerImg: digitalMarketingBanner,
      alt: "Erp consulting services",
    },
    aboutSec: {
      title: (
        <>
          <span>Digital</span> Marketing Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a full-service digital marketing company committed
            to helping businesses across the globe to succeed with our powerful
            digital solutions. Whether it is to enhance brand visibility, drive
            website traffic, generate qualified leads, or boost sales, we cover
            it all. We work closely with the team, try to understand your
            business requirement and accordingly chalk out a robust digital
            strategy to meet your business goals. We have an experienced team of
            digital marketing specialists who leaves no stone unturned in
            strengthening a company’s online presence. We believe in digital
            excellence and work towards creating engaging experiences that
            inspire actions.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Our Comprehensive Range of <span>Digital Marketing Services</span>
        </>
      ),
      desc: "To improve your sites' vitals metrics and traffic, we offer you a comprehensive range of digital marketing services at your end that encompasses search engine optimization, online reputation management, PPC advertising, social media marketing.",
      list: [
        {
          id: 1,
          source: DigitalMarketingSeoService,
          alt: "seo",
          title: (
            <>
              SEO <span>Services</span>
            </>
          ),
          content:
            "If you’re looking for first page rankings on your preferred search engines like Google, Bing, Yahoo, etc. Hire us for one of the finest SEO services in the industry with a dedicated resource who will ensure that your website gets the highest visibility and desired traffic.",
        },
        {
          id: 2,
          source: DigitalMarketingSocialMediaService,
          alt: "social media",
          title: (
            <>
              Social <span>Media</span>
            </>
          ),
          content:
            "Social media is the most potent platform for branding and reaching the potential audience. We possess the right talent who will create compelling and engaging posts, interact with customers and help to drive revenue for your business.",
        },
        {
          id: 3,
          source: DigitalMarketingOrmService,
          alt: "orm service",
          title: (
            <>
              Online Reputation <span>Management</span>
            </>
          ),
          content:
            "To stay relevant in the digital world, a brand requires a positive and reputable image. Our ORM services can help you to manage, protect or repair your brand image online. We can also help you get rid of the negative reviews if any.",
        },
        {
          id: 4,
          source: DigitalMarketingPpcService,
          alt: "ppc service",
          title: (
            <>
              PPC <span>Advertising</span>
            </>
          ),
          content:
            "We can help you gain instant ranking on the top pages of your preferred search engines with our best PPC services. We have the expertise to run successful PPC campaigns for clients as per their budget that delivers high-quality leads and revenue.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Choose Us for <span>Digital Marketing</span> Services?
        </>
      ),
      desc: "We provide you with the techniques that can boost your website traffic, gaining visibility across search engines, work with a highly qualified and proficient team guiding you with a result-oriented approach.",
      list: [
        {
          id: 1,
          source: ServiceWhyChoosecmsdedicated,
          alt: "Highly Proficient Team digital marketing service",
          title: "Highly Proficient Team",
          desc: "We have a highly skilled and competent team who can handle your complete digital needs with precision and deliver perfect solutions.",
        },
        {
          id: 2,
          source: ServiceWhyChoosesCmsdigital,
          alt: "Customized Digital Services",
          title: "Customized Digital Services",
          desc: "Partner with us for the best-in-class personalized services as per your project needs and the best possible strategy for your venture.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpresult,
          alt: "Result-Oriented Approach digital marketing services",
          title: "Result-Oriented Approach",
          desc: "We are reliable and work for all our clientele with a result-oriented approach that consists of the right strategies to deliver quality results.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for Digital Marketing Services
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "  What digital marketing services do we offer?",
          answer:
            "Scrum Digital is a full-service digital marketing company offering a variety of services like SEO, social media marketing, PPC advertising and Online reputation management.",
        },
        {
          id: 2,
          question: "  What is our pricing model?",
          answer:
            "We do not have a standardized pricing model as each project that we work on is specific to the business we are working with. Each of our digital marketing campaigns is tailored to the clients business needs and goals. You can contact our team to get a custom quote that will provide you with value.",
        },
        {
          id: 3,
          question: "  How can digital marketing help my business?",
          answer:
            " The internet has become such a competitive space and just having a website on the internet is not enough. Everyone is competing to get user attention and generate traffic. While the website is important and serves as a foundation for online marketing presence. Digital marketing helps in the promotion of your brand to battle against competitors.",
        },
        {
          id: 4,
          question: "Can digital marketing increase online sales?",

          answer:
            "Absolutely! The intention behind digital marketing is to increase website traffic and online conversion, thus helping you reach your overall business goals. If you would like to increase your online sales, we recommend you try different services offered by Scrum Digital. Our team of highly skilled individuals offer you the best personalised digital services that cater to your business needs at cost-effective rates.",
        },

        {
          id: 5,
          question: "  What is the advantage of advertising with PPC ads?",
          answer:
            " The benefit of advertising through PPC ads is that you will be driving suitable traffic to your website. We get data immediately that tells us whether the ad campaigns are working or not and how you can make sure it’s performing better. Though PPC ads are a bit expensive as you will be paying per click and campaign management they do generate traffic instantly.",
        },
        {
          id: 6,
          question: " When can I get SEO results?",
          answer:
            "SEO results depend on a number of factors, and it takes time, effort and patience to see results. When we first start, we start by focusing on low competitive keywords as they are easier to rank. And as we gain ground around them we start to focus more on more competitive words. Generally, SEO results can be seen anywhere from 3 to 6 months and with lasting effects showing around in one year. SEO ranking when properly maintained will have good ROI in future.",
        },
        {
          id: 7,
          question:
            " What type of activities do we perform on social media accounts?",
          answer:
            " Social media activities at Scrum Digital include Content creation, post creation, user engagement and other activities that improve your company’s social media presence. We aim to increase the followers and like counts and add to the number of potential clients.",
        },
      ],
    },
  },
  {
    id: 15,
    typeOfService: "Digital Marketing",
    name: "SEO Services",
    link: "seo-services",
    helmetSec: {
      title: "Affordable SEO Services | Search Engine Optimization Services",
      description:
        "Scrum Digital provides the best Seo Services in USA. Our Seo experts will ensure growth in rankings, traffic & sales. Request a free quote today!",
      keywords:
        "Search Engine Optimization Services, best seo services, affordable seo services",
      conical: "/seo-services",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Search Engine Optimization <span>Services</span>
        </>
      ),
      banner_bott_head:
        "Partner with us to get optimal traffic, leads and best marketing results for your business.",
      // bannerImg: seoBanner,
      bannerImg: serviceBannerSeoDev,
      alt: "SEO services",
    },
    aboutSec: {
      title: (
        <>
          <span>SEO</span> Company
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is a reputed name in the industry for providing
            high-quality and effective SEO services to start-ups and established
            companies across various domains. We have the expertise and the
            competent team to take your business to the height of success.
            Whether your goal is to grab first-page ranking on Google or to
            drive website traffic or conversion, we implement strategies that
            align with your business objectives. Optimizing your website for
            great SEO results is our topmost priority. All our energies are
            channelized towards positioning your brand at the top of search
            results.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: seoImgContent,
      alt: "Benefits of our seoServices",
      heading: (
        <>
          Benefits of our <span>SEO Services</span>
        </>
      ),
      desc: (
        <p>
          We offer the best SEO services in the industry. Our top-class SEO
          services benefits your business in the following ways:
        </p>
      ),
      list: [
        {
          name: "Improves Website Ranking",
        },
        {
          name: "Improves Page Prominence",
        },
        {
          name: "Drive Quality Traffic",
        },
        {
          name: "Magnify Brand Awareness",
        },
        {
          name: "Boost Site Conversion",
        },
        {
          name: "Optimizes User Experience",
        },
      ],
    },
    serviceSec: {
      title: (
        <>
          What <span>we Offer?</span>
        </>
      ),
      desc: "Our SEO strategy leads you in search engine rankings by optimizing website content, drive profitable and qualified traffic to your website, focussing to provide you with improved performance, standing at the top in the competitive landscape.",
      list: [
        {
          id: 1,
          source: SeoWebAnalysisService,
          alt: "website analysis",
          title: (
            <>
              Website <span>Analysis</span>
            </>
          ),
          content:
            "We do complete analysis of your website to gauge your website’s standing, its strengths, areas of improvement, and discover what is lacking in the optimization of the website",
        },
        {
          id: 2,
          source: SeoCompetitorService,
          alt: "competitor service",
          title: (
            <>
              Competitor <span>Analysis</span>
            </>
          ),
          content:
            "We identify our client’s competitors and conduct an assessment to understand their position against their top rivals in the search results pages and accordingly create an actionable SEO goal plan.",
        },
        {
          id: 3,
          source: SeoWebOptimizeService,
          alt: "website Optimize",
          title: (
            <>
              Optimize <span>Website Content</span>
            </>
          ),
          content:
            "We will edit and optimize your existing website content with the most relevant sales-driving keywords for maximum search visibility and online traffic.",
        },
        {
          id: 4,
          source: SeoTrackingReportService,
          alt: "tracking report service",
          title: (
            <>
              Tracking <span>Report</span>
            </>
          ),
          content:
            "We carefully track your SEO campaigns and consistently provide weekly or monthly performance reports and also monitor analytics for hacks, penalties or sales damaging events, if any.",
        },
        {
          id: 5,
          source: SeoConsultingService,
          alt: "consulting service",
          title: (
            <>
              SEO <span>Consultation</span>
            </>
          ),
          content:
            "We have a team of highly-skilled and experienced SEO specialists who provide exceptional SEO consultation to business of all sizes across diverse industries.",
        },
        {
          id: 6,
          source: SeoEnhanceRankService,
          alt: "Enhance Rank",
          title: (
            <>
              Enhance <span>Website Ranking</span>
            </>
          ),
          content:
            "We are well-versed with every existing SEO trends and the relevant industry norms, work dedicatedly towards ensuring that your site ranks higher on search result pages.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Choose Us as your preferred <span>SEO Consultant</span>
        </>
      ),
      desc: "Scrum Digital has the skills or expertise to successfully create and analyze authoritative content, evaluate the competitive landscape, enhanced visibility, dedicated resources that satisfy a searcher's intent and delivers the best possible results.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseseoresources,
          alt: "Dedicated Resource seo service",
          title: "Dedicated Resource",
          desc: "When you avail our services, we assign a dedicated resource to look after your project with great expertise in on-page, off-page, and technical SEO.",
        },
        {
          id: 2,
          source: ServiceWhyChooseseovisiblity,
          alt: "Enhanced Visibility seo service",
          title: "Enhanced Visibility",
          desc: "We help every business across industries to reach its potential audience with our high-end SEO services and gain improved visibility as per the business needs.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpresult,
          alt: "Amazing Results seo service",
          title: "Amazing Results",
          desc: "We analyze your website and accordingly implement the best possible marketing tactics to enable you to get higher ranking on major search engines.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for E-commerce Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is SEO?",
          answer:
            "SEO or Search Engine Optimization is a process of improving your website ranking to appear on the top results. This is an organic way of generating traffic for the website and boosting rank. In today’s digital age SEO is very crucial for creating a strong online presence.",
        },
        {
          id: 2,
          question: "Is SEO necessary for growing your business?",
          answer:
            "SEO is the best way to promote your business. Business owners spend lots of money every month promoting their business online and do they get returns accordingly but using SEO you get traffic on your website just by making it SEO friendly. Companies can generate organic traffic for their website with some budget every month and the conversion rate depends upon the services offered by the SEO provider. It is a must for doing business in the digital age. For high quality and effective SEO services go with Scrum Digital. We have the expertise and a competent team to take your business to the height of success.",
        },
        {
          id: 3,
          question: "What are the benefits of SEO for your business?",
          para: "The benefits of SEO for your business are practically limitless and taking advantage of them can improve your brand’s success rate in the marketplace.",
          answer: [
            "It leads to a better user experience",
            "SEO is a main source of lead",
            "SEO brings in high close rates",
            "SEO leads to a high conversion rate",
            "It also promotes better cost management",
            "It helps build brand credibility and awareness.",
          ],
        },
        {
          id: 4,
          question: "How much do SEO services cost?",
          para: "SEO service costs are usually determined through various factors like",
          answer: [
            "Brand Awareness- SEO costs are usually more for new brands than that of already existing brands.",
            "Competition- Service costs differ as per the competition in the industry.",
            "Target Audience- Cost is also determined by the audience you want to target whether it is local, national or global.",
            "Urgency- You may have to pay extra costs if you want the services quickly.",
            "Location",
            "Expertise- Companies with experience and high skills can demand high service costs.",
          ],
        },
        {
          id: 5,
          question: "Why should I hire an SEO?",
          answer:
            "Every business has a pre-established goal. One of the most important goals in a business is to attract more and more consumers to buy the products and services. Looking at the tremendous growth and the potential that businesses have with having a strong online presence, hiring a professional who can help build a strong image for your business is integral for your business. For that, you will have to hire a professional SEO service provider, like Scrum Digital who has a decade long experience in this field.",
        },
        {
          id: 6,
          question: "What is the ROI of SEO?",
          para: "Every business owner hopes for good returns on their investments. Similarly, businesses investing in Search Engine Optimization want good ROI. The ROIs of SEO are -",
          paraEnd:
            "Chose us as your preferred SEO Consultants to get optimal traffic, leads and best marketing results for your business.",
          answer: [
            "Increase Traffic- Investing in SEO helps your website gain more visibility organically. It improves your organic traffic and increases revenue.",
            "Reaching the target audience- SEO helps you reach the potential audience and user base. They help people who are highly engaged in your brand and prefer buying your products to contact you.",
            "Enhance user experience- SEO helps enhance your website and provide a better user experience.",
            "Improved conversion rates, increasing ad performance are other ROIs of SEOS.",
          ],
        },
      ],
    },
  },
  {
    id: 16,
    typeOfService: "Digital Marketing",
    name: "SEM Services",
    link: "search-engine-marketing",
    helmetSec: {
      title: "Search Engine Marketing Services | SEM Company",
      description:
        "Boost your online visibility with our award-winning search engine marketing company. We offer proven SEM strategies tailored to your business.",
      keywords:
        "Search Engine Marketing Services, search engine marketing company, SEM Company",
      conical: "/search-engine-marketing",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Search Engine <span>Marketing Services</span>
        </>
      ),
      banner_bott_head:
        "We deliver revenue-generating Search Engine Marketing solutions for each of our clients with our goal-driven and passionate team.",
      bannerImg: semBanner,
      alt: "SEM services",
    },
    aboutSec: {
      title: (
        <>
          SEM <span>Company</span>
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is the most proven and trusted Search Engine Marketing
            Agency providing effective and high-quality results. Scrum is
            uniquely qualified to get you fast visibility for your products and
            services, increase leads and brand awareness and generate more
            revenue.
          </p>
          <p>
            Our team of digital marketing experts understand the importance of
            brand awareness, multiple channel attribution, targeted ads and
            create customised disruptive models that increase our client’s
            digital presence. We have worked with startups as well as
            established businesses with our laser-like focus and have
            successfully transformed our client’s business.
          </p>
          <p>
            We have years of experience and passion for helping businesses reach
            their goals, so when you choose us we make sure you get the custom
            plan’s that fit your company's needs and goals.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: seoImgContent,
      alt: "sem",
      heading: (
        <>
          Benefits of our <span>SEM Services</span>
        </>
      ),
      desc: (
        <p>
          Scrum Digital is the best SEM Company in the industry with a high
          client retention rate. The SEM services offered by our experts benefit
          your business in the following way:
        </p>
      ),
      list: [
        {
          name: "Increased Brand Awareness",
        },
        {
          name: "Increased traffic through ad visibility",
        },
        {
          name: "Drive quality leads",
        },
        {
          name: "Pay only per action",
        },
        {
          name: "Reach your clients instantly",
        },
        {
          name: "Highly flexible and quick implementation",
        },
      ],
    },
    serviceSec: {
      title: (
        <>
          Our <span>Search Engine Marketing Services</span> include
        </>
      ),
      desc: "We provide you with reliable and bespoken Search Engine Marketing solutions that will help increase the visibility of your ads and website traffic. We help you convert prospects into the lead with your paid ads and website coming up on top of any search engine result.",
      list: [
        {
          id: 1,
          source: PpcCreatingLandingService,
          alt: "semCampaigns",
          title: (
            <>
              SEM <span>Campaigns</span>
            </>
          ),
          content:
            "With the use of the right terms and phrases, keywords we create impactful Search Engine Marketing campaigns related to specific products, promotions or target audience types.",
        },
        {
          id: 2,
          source: PpcAdCampaignService,
          alt: "GeoTargeted",
          title: (
            <>
              Geo-targeted <span>Search Ad</span>
            </>
          ),
          content:
            "Our SEM company gives you an option to target people based on location. Create ads in different languages and decide where you want to run them. Target specific countries, regions and cities anywhere in the world.",
        },
        {
          id: 3,
          source: PpcRetargetingAdService,
          alt: "ppcAction",
          title: (
            <>
              Pay only <span>Per Action</span>
            </>
          ),
          content:
            "After creating ads your ads appear for free and you can only pay when someone takes action- meaning, by clicking on your ad.Therefore you do get free exposure and brand awareness. ",
        },
        {
          id: 4,
          source: PpcEnhanceRoiService,
          alt: "betterCTR ppc service",
          title: (
            <>
              Better <span>CTR</span>
            </>
          ),
          content:
            "The average Click-through rate is at 2% for paid search engines, our paid SEM Services are several times more likely to help you move the needle of your business in comparison to other forms of digital marketing.",
        },
        {
          id: 5,
          source: PpcManagementService,
          alt: "brandAwareness ppc service",
          title: (
            <>
              Brand <span>Awareness</span>
            </>
          ),
          content:
            "We help you contribute to high brand awareness with the help of paid search ads especially when they appear on top of google search results.",
        },
        {
          id: 6,
          source: PpcAdDiverseService,
          alt: "testing and Analysis ppc service",
          title: (
            <>
              Testing and
              <span>Analysis</span>
            </>
          ),
          content:
            "We step by step test the ad performance and measure the results of each campaign. Depending on your campaign goals we then analyse your performance for better optimization and results in the future.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Choose us as our preferred <span>SEM Consultants?</span>
        </>
      ),
      desc: "Our team at ScrumDigital is made of highly dedicated and passionate marketers, developers and designers who know what it takes to get results online. We have worked with clients from diverse industries from health care, eCommerce to Real Estate, travel and hospitality.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseseoaccountmanager,
          alt: "Tailor-made SEM Service",
          title: "Tailor-made SEM Services",
          desc: "We develop custom SEM Strategies keeping in mind the needs and goals of every business we partner with. Our SEM Services are designed to drive results for our clients.",
        },
        {
          id: 2,
          source: ServiceWhyChooseEComerseontimedel,
          alt: "On-time Delivery sem service",
          title: "On-time Delivery",
          desc: "We value our client’s time and our focus has always been on providing quality services to our client as per the committed timeline.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpresult,
          alt: "Brand Awareness sem service",
          title: "Brand Awareness",
          desc: "One of the important benefits of SEM Services is increased brand awareness, our marketers will implement strategies and run campaigns that will lead to high brand awareness.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>Search Engine Marketing</span> FAQs
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question:
            "Should I be concerned about Search Engine Marketing as a business owner?",
          answer:
            "According to research a high percentage of the developed and developing world is online and the likelihood of you finding your potential customers, employees and shareholders online is also high. Search Engine Marketing is one of the most efficient and cost effective marketing channel available right now. It is an excellent avenue for building and promoting a brand and a very cost-effective way of generating leads and sales online.",
        },
        {
          id: 2,
          question: "How do I decide if SEM is good for my business?",
          answer:
            "SEM services are not limited to any particular industry, with the world getting digital almost every business can benefit from SEM services There are several advantages of doing Search Engine Marketing for your business from creating brand awareness, increasing quality leads and sales or generating recruits, business partners or investments.",
        },
        {
          id: 3,
          question:
            "What should I look for while picking an SEM service provider?",
          para: "Choosing the right SEM service provider is a challenging decision with so many options available out there. While picking out a Search Engine Marketing Agency make sure to keep in mind the following points-",
          answer: [
            "Do they have an impressive social media presence?",
            "Do the marketing agency has experience working with businesses like yours?",
            "Ask for their previous success stories and clients they have worked with.",
            "Does the agency have clear Key Performance Indicators?",
            "Do they offer customised services as per your business needs?",
          ],
        },
        {
          id: 4,
          question: "Why should I choose Scrum Digital?",
          answer:
            "Scrum Digital is a fastest growing IT, consulting and digital solutions company that offers a comprehensive range of digital marketing services that include SEO Services, Social media marketing, Online reputation management and PPC advertising. We have helped clients from diverse industries drive digital transformation and unlock the best possibilities for their business.",
        },
        {
          id: 5,
          question: "How much do SEM Services cost?",
          answer:
            "The cost of opting for SEM services mostly varies depending upon different factors like the size of your organization, your budget, the Services you want to opt for and your marketing needs. Get in touch with our team for your project proposal.",
        },
      ],
    },
  },
  {
    id: 17,
    typeOfService: "Digital Marketing",
    name: "Social Media Services",
    link: "social-media",
    helmetSec: {
      title: "Social Media Optimization Company | SMO Services",
      description:
        "Let our innovative SMO services take your social media from 0 to 100! Our experts develop high-converting Facebook, Instagram, LinkedIn & more. Let's Grow",
      keywords:
        "social media optimization services, social media optimization company, smo services",
      conical: "/social-media",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Social Media <span>Optimization Services</span>
        </>
      ),
      banner_bott_head:
        "We help brands leverage the power of social media to reach the desired audience and boost their business.",
      // bannerImg: smoBanner,
      bannerImg: serviceBannerSmoDev,
      alt: "SMO services",
    },
    aboutSec: {
      title: (
        <>
          <span>Social Media Marketing</span> Company
        </>
      ),
      desc: (
        <>
          <p>
            Every business needs to be associated with a trustworthy social
            media marketing company that can engage your customers, generate
            qualified leads, and exceed your expectations. Scrum Digital offers
            one of the best social media marketing services in the industry with
            clients from a wide array of industries. We help businesses to
            create a strong digital presence on every social hub such as
            Facebook, Twitter, LinkedIn, Pinterest, Instagram, etc. Our team of
            social media specialists use their industry experience to make your
            brand popular amongst your target audience. They understand your
            business needs and accordingly identify the right social network to
            promote your business and amplify your message. Hire us for the best
            social media marketing services in the industry.
          </p>
        </>
      ),
    },
    serviceSec: {
      title: (
        <>
          Our <span>Reliable Social Media</span> Services include
        </>
      ),
      desc: "We provide you with vital and reliable social media services to reach your audience, to gain maximum traffic. Provides you with excellent potential in digital marketing with organic social campaigns, community building, and management paid social campaigns with excellent content strategies.",
      list: [
        {
          id: 1,
          source: SocialMediaOrganicService,
          alt: "organic service",
          title: (
            <>
              Organic <span>Social Campaigns</span>
            </>
          ),
          content:
            "We create highly compelling social media post for maximum engagement on every social channel. We schedule posts regularly and monitor customer’s engagement and interaction for better results.",
        },
        {
          id: 2,
          source: SocialMediaCommunityService,
          alt: "community service",
          title: (
            <>
              Community <span>Building & management</span>
            </>
          ),
          content:
            "We help grow your business by joining various communities as per your niche on social channels. We identify and reach out to people who can be your potential brand advocates to promote your brand and expand it to a broader audience.",
        },
        {
          id: 3,
          source: SocialMediaPaidService,
          alt: "paid Service",
          title: (
            <>
              Paid <span>Social Campaigns</span>
            </>
          ),
          content:
            "Investing in paid social campaigns can expedite results. You can reach the audience instantly, get followers, engagement and website traffic pretty fast. The more the number of people you reach, you get more awareness, traffic and sales. ",
        },
        {
          id: 4,
          source: SocialMediaAuditService,
          alt: "audit service",
          title: (
            <>
              Social <span>Media Audit</span>
            </>
          ),
          content:
            "With rich experience in social media management, our talented team of social media experts carefully evaluate, conduct audit, and increase brand awareness for our valuable clients.",
        },
        {
          id: 5,
          source: SocialMediaCompetitorService,
          alt: "Competitor service",
          title: (
            <>
              Competitor <span>Analysis</span>
            </>
          ),
          content:
            "We step-by-step analyze your service area and niche. Identify the already existing players in your audience size, and according create content that excels in your industry.",
        },
        {
          id: 6,
          source: SocialMediaReputationService,
          alt: "handling reputation",
          title: (
            <>
              Handling
              <span>Social Reputation</span>
            </>
          ),
          content:
            "We have a team to keep a watch on your social reputation. They will monitor your social reviews, feedback and respond to queries for maximum social engagement. Alongside they monitor brand mentions across social platforms.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why should you choose <span>Us</span> for <span>SMO Services?</span>
        </>
      ),
      desc: "Scrum Digital provides you with an empowered team that operates in a precise manner with dedicated resources, to attract an audience's engagement in the best possible way. The services rendered by us provide better analysis and reporting, to accelerate brand promotion and drive instant traffic.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseseoresources,
          alt: "Dedicated Resource smo service",
          title: "Dedicated Resource",
          desc: "We assign a dedicated social media manager to assist you in all your campaigns and get you the desired results according to your business needs.",
        },
        {
          id: 2,
          source: ServiceWhyChooseseoanalysis,
          alt: "Analysis and Reporting smo service",
          title: "Analysis and Reporting",
          desc: "Our team will actively analyze your campaigns to ensure that you’re fetching the intended results. We update you weekly and monthly basis on how your campaigns are performing, and whether they’re meeting the set goals or not.",
        },
        {
          id: 3,
          source: ServiceWhyChooseseobrand,
          alt: "Brand Promotion smo service",
          title: "Brand Promotion",
          desc: "We are living in the times where the majority of people use social media. We create strategies to use these social networks to establish your brand and enhance your brand awareness among the target segment.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          FAQ's for <span>SMO Services</span>
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is social media marketing?",
          answer:
            "Social Media Marketing is an online marketing approach that uses various social media platforms like Facebook, Twitter, Instagram, LinkedIn to achieve brand awareness, capture customer attention and connect brands with a broader and diverse user base. In this digital age, social media marketing is a very powerful tool for business owners to promote and grow their brands. A dynamic, customised and data-based social media marketing plan can bring extraordinary success to your company.",
        },
        {
          id: 2,
          question: "Does my company need Social media marketing?",
          answer:
            "Yes, every business whether big or small needs social media presence. Social media marketing should be an integral part of your business marketing strategy. It helps you connect directly with your customers, increase awareness about your brand and boost your leads and sales.",
        },
        {
          id: 3,
          question: "What are the benefits of social media marketing services?",
          answer:
            "Social media marketing services have many benefits for startups as well as well established brands. With the right marketing plan and campaign system, social media marketing can lead to increased search traffic, better SEO, good customer engagement as well as improved brand loyalty.",
        },
        {
          id: 4,
          question:
            "How much does it cost to hire a social media management company?",
          answer:
            "The cost of hiring a social media management company varies. It depends upon the experience of the company, the size of your business, the marketing budget of your company, the number of services you opt for and many other factors. Kindly get in touch with our consultant at Scrum Digital for more details.",
        },
        {
          id: 5,
          question: "Which social media platforms do you use?",
          answer:
            "The traditional methods of marketing are becoming obsolete as we move towards a digital world and many companies face difficulties in this transition and struggle to generate more business. Let Scrum Digital help you with our reliable social media services. We leverage different social media platforms like Facebook, Instagram, Twitter, LinkedIn, and YouTube.",
        },
        {
          id: 6,
          question:
            "Why choose scrum digital for social media marketing services?",
          answer:
            "Every business needs to be associated with a trustworthy social media marketing company which can help them generate qualified leads, create customer engagement and create brand awareness. Scrum Digital with considerable experience offers one of the best social media marketing services in the Industry. We understand your business needs and create the right social media marketing plan to promote your business.",
        },
      ],
    },
  },
  {
    id: 18,
    typeOfService: "Digital Marketing",
    name: "Social Media Marketing",
    link: "social-media-marketing",
    helmetSec: {
      title: "Affordable Social Media Marketing Services in USA",
      description:
        "Our award-winning social media marketing company helps brands connect with targeted audiences across every major network. Get Your Plan Now ↗",
      keywords:
        "social media marketing services, affordable social media marketing services, social media marketing company",
      conical: "/social-media-marketing",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Social Media <span>Marketing Services</span>
        </>
      ),
      banner_bott_head:
        "We help brands leverage the power of social media to reach the desired audience and boost their business.",
      bannerImg: smmBanner,
      alt: "SMM services",
    },
    aboutSec: {
      title: (
        <>
          SMM <span>Services</span>
        </>
      ),
      desc: (
        <>
          <p>
            Social Media is a key element in every digital marketing campaign
            today and a full-service social media marketing company is a key to
            your successful business. Scrum Digital is a top-notch social media
            marketing agency that can help your business with a complete digital
            and social media strategy tailored to your business needs. Our SMM
            services are not just about random Instagram and Facebook posts,
            they go well beyond that. Our team of professionals understand the
            unique needs of your business and its customers and determine which
            social channels will be most effective to meet your business goals.
            Turbo boost your Marketing efforts with an experienced Social Media
            Marketing Company like Scrum Digital.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: seoImgContent,
      alt: "Benefits of our smm Services",
      heading: (
        <>
          Benefits of our <span>SMM Services</span>
        </>
      ),
      desc: (
        <p>
          Unlock the benefits of SMM services with Social Media Marketing firms
          like Scrum Digital.
        </p>
      ),
      list: [
        {
          name: "Better online Exposure",
        },
        {
          name: "High Search Rankings",
        },
        {
          name: "Specific Audience targeting",
        },
        {
          name: "Improves customer reach",
        },
        {
          name: "Complete brand control",
        },
        {
          name: "Enhanced customer trust",
        },
      ],
    },
    serviceSec: {
      title: (
        <>
          Our <span>Social Media Marketing</span> Services include
        </>
      ),
      desc: "We believe the heart of powerful Social Media Marketing lies in relationship building and creating a community around your brand. Good relationships and brand loyalty build trust and trust build sales.",
      list: [
        {
          id: 1,
          source: PpcCreatingLandingService,
          alt: "Strategy development smm service",
          title: (
            <>
              Strategy <span>Development</span>
            </>
          ),
          content:
            "Our marketers conduct profile and audience analysis and identify the areas that need more attention and then create a well rounded targeted social media marketing strategy.",
        },
        {
          id: 2,
          source: PpcAdCampaignService,
          alt: "platform smm service",
          title: (
            <>
              Platform Monitoring
              <span>and Moderation</span>
            </>
          ),
          content:
            "Our team closely monitors and moderates social media pages across all platforms ensuring smooth functioning.",
        },
        {
          id: 3,
          source: PpcRetargetingAdService,
          alt: "contentDevelopment smm service",
          title: (
            <>
              Content <span>Development</span>
            </>
          ),
          content:
            "We create weekly social media schedules and create engaging and creative content accordingly with daily updates and bespoke media assets. ",
        },
        {
          id: 4,
          source: PpcEnhanceRoiService,
          alt: "competitorAnalysis smm service",
          title: (
            <>
              Competitor <span>Analysis</span>
            </>
          ),
          content:
            "We carry out research and compare your competitor's social media presence to check where we need to focus our attention to be the best in the market.",
        },
        {
          id: 5,
          source: PpcManagementService,
          alt: "organic smm service",
          title: (
            <>
              Organic and Paid
              <span>Marketing Campaigns</span>
            </>
          ),
          content:
            "We run organic as well as paid marketing campaigns that are compiled together to create maximum social reach and engagement. ",
        },
        {
          id: 6,
          source: PpcAdDiverseService,
          alt: "reporting smm service",
          title: <>Reporting</>,
          content:
            "We use different varieties of social listening and reporting tools, for real-time monitoring and to report any significant changes and ensure you stay on top of your brand across all socials.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why should you Choose Us for <span>SMM Services?</span>
        </>
      ),
      desc: "Marketing Specialists at Scrum help you establish your company’s brand and connect with socially savvy consumers. We help you reap the benefits of the most economically effective marketing methods with an engaging and popular social media presence.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseseoaccountmanager,
          alt: "Experienced and Professional Team smm service",
          title: "Experienced and Professional Team",
          desc: "Our team of professionals at Scrum Digital have years of experience working with clients from diverse industries. We are passionate about turning your business goals into reality.",
        },
        {
          id: 2,
          source: ServiceWhyChooseseoenhanced,
          alt: "Quantify our Success smm service",
          title: "Quantify our Success",
          desc: "Tracking our work and progress allows us to see what is working and what is not working which helps us to quantify our success.",
        },
        {
          id: 3,
          source: ServiceWhyChooseEComerseontimedel,
          alt: "On-time Delivery smm service",
          title: "On-time Delivery",
          desc: "Time is money and we value our client’s time. We set realistic deadlines for us and our work and stick to them. We have constant communication with clients, they receive daily updates and progress reports.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>Social Media Marketing</span> FAQs
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "Does my business need to do Social Media Marketing?",
          answer:
            "One of the main reasons why brands need to be on social media irrespective of the type of business they are in is to increase website traffic, quality lead and sales while promoting their brand.",
        },
        {
          id: 2,
          question:
            "Why should I choose Scrum Digital for Social Media Marketing Services?",
          para: "Scrum Digital is one of the leading Social Media agencies that deliver world-class results to clients from diverse industries. Our experienced and professional team of marketers is what makes us the best. Scrum is here to help you with all your social media marketing needs. Our services include-",
          answer: [
            "Organic Social Media campaigns",
            "Community building and management",
            "Paid Social campaigns",
            "Social media audit",
            "Competitor Analysis",
            "Handling social reputation",
          ],
        },
        {
          id: 3,
          question: "Is paying for Social Media Marketing a good idea?",
          para: "Absolutely yes! There are many benefits to paid Social Media Marketing, it is a very cost-effective way to promote your business. Following are the benefits of paid Social Media Marketing-",
          answer: [
            "Instant brand visibility",
            "Run campaigns that fit your budget with the PPC model.",
            "Improves your targeting",
            "Boost brand awareness",
            "Optimize content marketing",
            "Offers unparalleled social media penetration",
            "Gain Social media insights",
          ],
        },
        {
          id: 4,
          question: "How do you measure ROI from SMM?",
          answer:
            "Once we pick up the right social media platforms for your business and plan the marketing campaigns we discuss the metrics you want to focus on in order to decide the ROI whether it is traffic, engagement or conversion.",
        },
        {
          id: 5,
          question:
            "How much does it cost for Social Media Marketing Services?",
          answer:
            "Each and every project that we have is unique and our services are customised according to our client’s needs. The cost of a project mainly depends upon the size of your company, your marketing budget and what services you want to opt for.",
        },
      ],
    },
  },
  {
    id: 19,
    typeOfService: "Digital Marketing",
    name: "Online Reputation Management",
    link: "online-reputation-management",
    helmetSec: {
      title: "Online Reputation Management Company | ORM Services",
      description:
        "Boost your online image with our complete ORM services. Professional reputation repair from the top-rated online reputation management company.",
      keywords:
        "online reputation management services, online reputation management company, best online reputation management, orm services",
      conical: "/online-reputation-management",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Online Reputation <span>Management Services</span>
        </>
      ),
      banner_bott_head:
        "We monitor, control, and fix your brand’s image on every digital platform with our Robust ORM Services.",
      bannerImg: ormBanner,
      alt: "ORM services",
    },
    aboutSec: {
      title: (
        <>
          <span>Best Online Reputation</span> Management Company
        </>
      ),
      desc: (
        <>
          <p>
            Did you know a single negative comment can ruin your brand’s online
            reputation? Customer reviews play a crucial role in the success of
            any business in the digital arena. If your online reputation is
            hampering your business growth, it’s time to take it with utmost
            sincerity. Hire a specialist who carefully monitors your project,
            comes up with a robust strategy to determine the right approach to
            every customer sentiment. Scrum digital is a leading and one of the
            best Reputation Management company committed to helping businesses
            build brand awareness, creating a strong connection with prospects,
            and creating an impactful online reputation. Here, we do a complete
            makeover of your brand image by repairing, managing, and protecting
            it.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: ormImgContent,
      alt: "Clients We Assist For orm service",
      heading: (
        <>
          Clients We Assist For <span>ORM Services</span>
        </>
      ),
      desc: (
        <p>
          We are a trusted name in the industry for providing quality Online
          Reputation Management services to client’s from a broad range of
          sectors and industries. Some of the clients/ businesses that benefit
          from our services includes the following
        </p>
      ),
      list: [
        {
          name: "Startups, Small, medium and Large Enterprises",
        },
        {
          name: "Politicians",
        },
        {
          name: "SportsPersons",
        },
        {
          name: "Celebrities",
        },
        {
          name: "Journalists",
        },
      ],
      listColumn: {
        columns: 1,
      },
    },
    serviceSec: {
      title: (
        <>
          We proffer the Best <span>ORM Services</span> for all your
          requirements
        </>
      ),
      desc: "Scrum Digital provides trustworthy, reputable services, avoiding any potential issues enable professionals to discover effective ways to manage and improve your brand's online reputation, boosting your business reputation. Our service provides you with better ratings and reviews.",
      list: [
        {
          id: 1,
          source: OrmStrategyService,
          alt: "Strategy",
          title: (
            <>
              ORM <span>Strategy Development</span>
            </>
          ),
          content:
            "We get in touch with you to understand your business requirements. We then assess customer queries and reviews that your business receives. We track your presence on every social channel, consumer complaint forums, discussion board, etc and accordingly formulate a strategy for your online reputation.",
        },
        {
          id: 2,
          source: OrmBetterReviewService,
          alt: "orm Better",
          title: (
            <>
              Better
              <span>Ratings & Reviews</span>
            </>
          ),
          content:
            "Healthy reputation is a sign of success for every brand and business. With that view in mind, we conduct a thorough analysis of your online reputation, and if we detect any websites containing negative reviews or ratings, we get it rectified immediately.",
        },
        {
          id: 3,
          source: OrmFixReputationService,
          alt: "orm Fix",
          title: (
            <>
              Fix <span>Online Reputation</span>
            </>
          ),
          content:
            "We have a team that continuously works on mending the reputation of a brand or website. Our experts will dedicatedly work to find the source of the negative information and come up with the result-oriented plan to fix it.",
        },
        {
          id: 4,
          source: OrmBoostService,
          alt: "orm Boost",
          title: (
            <>
              Boost <span>Business Reputation</span>
            </>
          ),
          content:
            "A positive and clean online image is what every business and brand desires. Our ORM professionals will leave no stone unturned and help you enhance your business image on every digital platform.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Scrum Digital For your <span>ORM</span>
        </>
      ),
      desc: "Scrum Digital inherent your trust, provides you solutions to improve your brand's reputation by repairing and converting the negative mentions into positive ones. We provide you fast, efficient and personalized solutions that aim at providing better results.",
      list: [
        {
          id: 1,
          source: ServiceWhyChoosecrmerpfast,
          alt: "Fast and Efficient orm service",
          title: "Fast and Efficient",
          desc: "As one of the trusted online reputation management firm, Scrum Digital provides faster and efficient ORM services to revamp your digital reputation.",
        },
        {
          id: 2,
          source: ServiceWhyChoosecrmerpsolut,
          alt: "Personalized Solutions orm service",
          title: "Personalized Solutions",
          desc: "We offer personalized ORM services regardless of the size and scale of your business. Depending on your business needs, we work to clean up your online reputation, improve online reviews, and protect personal data.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpresult,
          alt: "Result Driven orm service",
          title: "Result-Driven",
          desc: "We have a team of highly skilled and experienced ORM professionals who work tirelessly to exceed your expectations and provide business-driven results.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          FAQ's for <span>ORM Services</span> at Scrum Digital
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is online reputation management (ORM)?",
          answer:
            "Online reputation management describes the process of marketing, public relationship legal and SEO tactics to support, defend and safeguard your online image or business reputation. The process involves monitoring and identifying positive and negative online mentions of the brand and making sure that more emphasis is given to promoting positive mentions as compared to negative ones.",
        },
        {
          id: 2,
          question: "Why do businesses and individuals need ORM?",
          answer:
            "Nowadays people look up information about people/businesses before deciding to do business or interact with them. Negative online brand mentions have a direct negative influence on current and prospective new clients who are considering doing business with your brand. Moreover, negative brand mentions can escalate into negative word of mouth both offline and online resulting in continuous risk to brand reputation and sales.",
        },
        {
          id: 3,
          question: "What is the cost of Online Brand Reputation Management?",
          para: "When it comes to the cost of Online Reputation management service there is no “One size fits all”. In ORM every project is unique and customised as per the needs of each client. Also, the cost of the ORM project is based on several important factors like:-",
          answer: [
            "The strength of negative content",
            "Number of negative mentions",
            "Amount of positive content that can be promoted",
            "Amount of new content required for promotion",
          ],
        },
        {
          id: 4,
          question:
            "How long does it take before I can see the results of ORM?",
          answer:
            "Every online reputation management campaign is different, it can take a few short weeks to months to position the brand at the top from commencement. The time needed can be determined after assessing the negative and positive mentions. A proper evaluation helps create an effective strategy that will deliver positive results.",
        },
        {
          id: 5,
          question: "Why choose scrum digital to improve ORM?",
          answer:
            "Scrum digital is a leading and one of the best Reputation Management companies committed to helping businesses build brand awareness, creating a strong connection with prospects, and creating an impactful online reputation. Here, we do a complete makeover of your brand image by repairing, managing, and protecting it.",
        },
      ],
    },
  },
  {
    id: 20,
    typeOfService: "Digital Marketing",
    name: "Pay Per Click Management Services",
    link: "ppc-management-services",
    helmetSec: {
      title: "PPC Management Services | PPC Company in USA | PPC Services",
      description:
        "Drive sales with our PPC services. We provide the best PPC advertising services in USA that would help to increase your sales & leads.",
      keywords:
        "PPC Management Services, PPC services PPC Company",
      conical: "/ppc-management-services",
      ogimg: "/ogdigital_marketing.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          PPC Management <span className="d-inline d-lg-block">Services</span>
        </>
      ),
      banner_bott_head:
        "We help you dominate top page ranking on major search engines and get the desired ROI by turning visits into sales.",
      bannerImg: ppcBanner,
      alt: "PPC services",
    },
    aboutSec: {
      title: (
        <>
          <span>PPC Company</span>
        </>
      ),
      desc: (
        <>
          <p>
            Scrum Digital is an industry-leading PPC advertising agency that can
            help you attain high-quality leads and boost sales. With the right
            strategy and budget, we can get you instant visibility on Bing and
            Google Ads even for the most competitive keywords in the industry.
            We have a team of highly-skilled and experienced paid search
            specialists to manage your campaign successfully even on the most
            sophisticated platforms. We make data-driven decisions and
            meticulously optimize your campaign by converting visits into sales.
            We help grow your business in a cost-effective way and get you
            high-returns.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: ppcImgContent,
      alt: "ppcManagement",
      heading: (
        <>
          Distinct PPC Services for{" "}
          <span>Small, medium & Large Enterprises</span>
        </>
      ),
      desc: (
        <p>
          We have a team of highly motivated PPC experts who are dedicated to
          delivering exceptional results through our customized PPC services.
          Our goal is to ensure that our clients achieve their desired outcomes
          and maximize their return on investment. Here are some of the
          advantages of our PPC services:
        </p>
      ),
      list: [
        {
          name: "Google Ads",
        },
        {
          name: "Bing Ads",
        },
        {
          name: "Display Ads",
        },
        {
          name: "Social Media Campaigns",
        },
        {
          name: "Shopping Ads",
        },
        {
          name: "YouTube Advertising",
        },
      ],
    },
    serviceSec: {
      title: (
        <>
          Our Bouquet of <span>PPC</span> Services Includes
        </>
      ),
      desc: "Our PPC services aim at retargeting ads domination, ranking top in search engines, ensuring that your ads appear more prominently than your competitor's ads, stay abreast of developments to Google AdWords, demystify pay-per-click advertising management.",
      list: [
        {
          id: 1,
          source: PpcCreatingLandingService,
          alt: "Landing Page ppc service",
          title: (
            <>
              Creating <span>a Landing Page </span>
            </>
          ),
          content:
            "We create a well-designed and optimized PPC landing page for impactful PPC campaigns that gets you more leads and delivers high ROI.",
        },
        {
          id: 2,
          source: PpcAdCampaignService,
          alt: "Campaign ppc service",
          title: (
            <>
              Ad Campaign <span>creation</span>
            </>
          ),
          content:
            "Our PPC experts will set-up the most effective and creative ad campaign that is bound to get you high conversion.",
        },
        {
          id: 3,
          source: PpcRetargetingAdService,
          alt: "Retargeting ppc service",
          title: (
            <>
              Retargeting <span>Ads</span>
            </>
          ),
          content:
            "We use retargeting or remarketing campaigns to connect with the past site visitors and to produce high click-through rates and desired ROI. ",
        },
        {
          id: 4,
          source: PpcEnhanceRoiService,
          alt: "enhance ppc service",
          title: (
            <>
              Enhanced <span>ROI</span>
            </>
          ),
          content:
            "Our PPC specialists will deliver a robust PPC campaign that will increase your website traffic, brand visibility, and improve your return on investment.",
        },
        {
          id: 5,
          source: PpcManagementService,
          alt: "management ppc service",
          title: (
            <>
              Campaign <span>Management</span>
            </>
          ),
          content:
            "Our team manages and tweak bids for every campaign, and consistently monitor click-through rates and cost-per-click for better campaign management.",
        },
        {
          id: 6,
          source: PpcAdDiverseService,
          alt: "diverse ppc service",
          title: (
            <>
              PPC Ads <span>across diverse platform</span>
            </>
          ),
          content:
            "We help you run paid PPC campaigns on a wide variety of platforms like Facebook, Twitter, LinkedIn, Instagram, YouTube, Google, and Bing, etc.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Select Scrum Digital for <span>Pay Per Click</span> Services
        </>
      ),
      desc: "Scrum Digital promotes your brand offering, which offers complete control, provides flexibility, and promises instant and faster results, enriched with dedicated account managers and having an enhanced web presence.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseseoaccountmanager,
          alt: "Dedicated Account Manager ppc service",
          title: "Dedicated Account Manager",
          desc: "We will assign you a dedicated PPC specialist to manage and track your ads campaigns with precision and deliver desired results.",
        },
        {
          id: 2,
          source: ServiceWhyChooseseoenhanced,
          alt: "Enhanced Web Presence ppc service",
          title: "Enhanced Web Presence",
          desc: "We are a reputed and result-driven PPC Company in USA that can help you attain improved online presence and boost your business.",
        },
        {
          id: 3,
          source: ServiceWhyChoosecrmerpresult,
          alt: "Faster Results ppc service",
          title: "Faster Results",
          desc: "PPC advertising can fetch quick and instant results with the right strategy. Partner with us to get the best quality leads and revenue that too at a reasonable cost.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for PPC Services
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is Pay Per Click advertising?",
          answer:
            "Pay Per Click or PPC advertising is a specific type of search engine marketing created to draw attention to your website. When a user searches for certain keywords, your ad will appear in the search results. As the name suggests, you only have to pay when a user clicks on the ad.",
        },
        {
          id: 2,
          question: "Is Pay Per Click (PPC) right for my business?",
          answer:
            "In the highly competitive world, it’s quite tough to get your site on page one of search results. With PPC ads your website gets “front and centre attention” and it is also displayed to the customers who are looking specifically for your products or services. Plus these advertisements can be more affordable to small businesses compared to other kinds of advertising.",
        },
        {
          id: 3,
          question: "What sites offer PPC advertising?",
          answer:
            "There are several options available when it comes to PPC advertising. Google Adwords is among the most popular ones. There are also several others like Microsoft Bing Ads and Yahoo Search Ads. There are also other sites that offer PPC advertising but they account for less than 10% of the market share. Whereas Google AdWords and Bing Ads are responsible for almost 90% of the PPC advertising market.",
        },
        {
          id: 4,
          question: "Where will my PPC ads appear?",
          answer:
            "The Pay Per Click ad appears at the top of the search results and also along the right side of the list of results. Google allows you to show ads on google search lists as well as partner sites. Bing ads on the other hand allow showing PPC ads next to search results on Bing search engine, Yahoo and MSN sites and other digital space owned by yahoo and Microsoft.",
        },
        {
          id: 5,
          question: "What makes us the best PPC services company?",
          answer:
            "Scrum Digital offers the top quality PPC management services in the Industry.Having a relevant amount of experience, we help you dominate top page ranking on social search engines and get the desired ROI by turning visits into sales. We help grow your business in a cost-effective way and get you high returns.",
        },
      ],
    },
  },
  {
    id: 21,
    typeOfService: "StartUp Consulting",
    name: "Start Up Consulting Services",
    link: "startup-consulting-services",
    helmetSec: {
      title: "Startup Consulting Services | Startup Business Consultant",
      description:
        "From idea validation to explosive growth, our specialist startup consultants provide dedicated guidance tailored to your entrepreneurial needs. Click to begin.",
      keywords:
        "startup consulting services, startup consulting, startup business consultant",
      conical: "/startup-consulting-services",
      ogimg: "/ogstartup_consulting.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Startup Consulting <span>Services</span>
        </>
      ),
      banner_bott_head:
        "A startup works in much higher uncertainty and with limited resources and hence require consultants to help them with the early steps of enterprise to save their money, time, aggravation.",
      bannerImg: startupBanner,
      alt: "StartUp Consulting services",
    },
    aboutSec: {
      title: (
        <>
          <span>Startup</span> Consulting
        </>
      ),
      desc: (
        <>
          <p>
            If you are starting a new business you need an advisor for what to
            do and how to execute your plan so that you can save your time and
            money. A consultant company has the expertise and experienced
            people. Startups need experts who can understand their business and
            do the work properly and should also tell what needs to be done
            next.
          </p>
          <p>
            Business Consultants can examine your business plans, do compare
            them with your goals, and guide you to improve your business.
            Startups work with fewer resources that’s why they need to hire
            consultants to help them.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: startupImgContent,
      alt: " What we do startup Consulting service",
      heading: (
        <>
          What <span>we do?</span>
        </>
      ),
      desc: (
        <p>
          Product development means all the stages involved in bridging a
          product from idea to the market release and beyond. Product
          development methods are used to create many new offerings. A minimum
          viable product (MVP) is a development method in which a new product is
          developed with limited features to satisfy adopters. The complete set
          of features designed and developed after taking feedback from the
          product’s initial users. Staff Augmentation is a flexible outsourcing
          plan which enables you to hire technology talent manage and globally
          augmented team. You can choose from the candidates that fit your
          requirements.
        </p>
      ),
      list: [
        {
          href: "/product-development-services",
          name: "Product Development",
        },
        {
          href: "/mvp-development-services",
          name: "MVP Development",
        },
        {
          href: "/staff-augmentation-services",
          name: "Staff Augmentation",
        },
        {
          href: "/growth-consultation",
          name: "Growth Consultation",
        },
      ],
    },
    imgContentSec2: {
      src: startup2ImgContent,
      alt: "startupConsulting",
      heading: (
        <>
          Why startups are thriving <span>during lockdowns</span>
        </>
      ),
      desc: (
        <p>
          Startups seem to become pandemic winners. While traditional companies
          suffer from falling sales and production problems,investors continue
          to plough money into promising new firms, especially in the technology
          sector.
        </p>
      ),
      list: [
        {
          name: "Smart Surveillance Tools That Tracked Covid Transmission",
        },
        {
          name: "Health Manufacturing, 3D Printing Rise During The Pandemic",
        },
        {
          name: "Startups Bring Their Workforce To The Pandemic Fight",
        },
        {
          name: "From Testing To Monitoring, Indian Startups Take The Lead",
        },
      ],
      orderImg: "order-lg-2",
      orderContent: "order-lg-2",
      listColumn: {
        columns: 1,
      },
    },
    enhanceMobileData: {
      heading: (
        <>
          Turn your audience <span>into business</span>
        </>
      ),
      desc: "The real deal-sealer is when you’re able to turn your loyal fanbase into paying customers.A product becomes 10 times worth buying when proper advertisement is made. Various practical strategies you can use to convert your organization’s social media followers into customers.",
      list: [
        {
          id: 1,
          title: (
            <>
              Create Brand <span>Familiarity</span>
            </>
          ),
          src: EnhanceMobilestretegy,
          alt: "application",
        },
        {
          id: 2,
          title: (
            <>
              Provides <span>Value</span>
            </>
          ),
          src: EnhanceMobiledeesign,
          alt: "ChatBot",
        },
        {
          id: 3,
          title: (
            <>
              Handles <span>Objections</span>
            </>
          ),
          src: EnhanceMobiledevelopment,
          alt: "crm",
        },
        {
          id: 4,

          title: (
            <>
              Use social media to <span>engage audience</span>
            </>
          ),
          src: EnhanceMobileapi_integration,
          alt: "ERP",
        },
        {
          id: 5,
          title: (
            <>
              Team up with <span>complementary business</span>
            </>
          ),
          src: EnhanceMobilemaintenance,
          alt: "Cloud",
        },
      ],
    },
    serviceSec: {
      title: (
        <>
          Why Startups Need <span>Consultation?</span>
        </>
      ),
      desc: "Startups are self build and they need to prove themselves as viable businesses, penetrating a saturated market or educating on a newtype of product or service requires an expertise view.",
      list: [
        {
          id: 1,
          source: mobileAppDevIosService,
          alt: "unbiasedFeedback",
          title: (
            <>
              Unbiased <span>Feedback</span>
            </>
          ),
          content:
            "For startups receiving honest feedback can be extremely beneficial. That’s why they need expert, knowledgeable, talented, and experienced consultants. From here the role of consultant becomes very crucial. Consultants can provide expertise and an external outlook on the practices of the startup. Consultants give proper feedback for the details of organization, execution, and other important matters to startups.",
        },
        {
          id: 2,
          source: mobileAppDevAndroidService,
          alt: "creating Road Map",
          title: (
            <>
              Creating a <span>Road Map</span>
            </>
          ),
          content:
            "Strategizing is the key to business and it is one of the most important services given by startup consultants. They also help clients through proper analysis, statistics, and strategy. It is very essential for startups. Clients partner with experienced consultants to plan steps taken for startup businesses. Consultants will build a roadmap for your business.",
        },
        {
          id: 3,
          source: mobileAppDevHybridService,
          alt: "Expertise",
          title: <>Expertise</>,
          content:
            "A startup consultant brings vital expertise and knowledge to a business. It may be beyond the expertise of the existing skill of the business. A startup consultant is an expert for a startup company. They are specialized in the latest techniques of business and know how to take a business to the next level. Business growth increases the number of processes to develop and implement opportunities within organizations.",
        },
        {
          id: 4,
          source: mobileAppDevCrossPlatformService,
          alt: "management Advice",
          title: (
            <>
              Management <span>Advice</span>
            </>
          ),
          content:
            "At the initial stage, the idea and vision through the next steps of the startup business. The plan of your business plan is a piece of paper. The advice of startup consultants can be used in all different areas of your business. The client gives insights and gains clarity through working with professionals who have been through the process multiple times.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why Startups And Innovation Make <span>The Ideal Allies!</span>
        </>
      ),
      desc: "Innovation is doubly important for startups and small businesses in order to ensure they are able to successfully meet the demands of the people and the community they serve. In reference to startups, it is also important to consider the fact that the world has come to the point where millions of start-ups are launched every year. And in this fast-paced ecosystem,innovation is the key to both survival and growth for startups.",
      whyBg: {
        backgroundColor: "#fcfcfc",
      },
      list: [
        {
          id: 1,
          source: ServiceWhyChooseMobileDevTalented,
          alt: "What’s next startup consulting",
          title: "What’s next",
          desc: "It can’t be stressed enough how innovation helps startups stay ahead of the curve and establish a significant lead over their contemporaries.As long as startups keep thinking about the ‘what’s next’ in reference to innovation, they will stay a mile ahead of their competition.",
        },
        {
          id: 2,
          source: ServiceWhyChooseMobileDevBest,
          alt: "Competitive with contemporary industries startup consulting",
          title: "Competitive with contemporary industries",
          desc: "Innovation enables startups to compete with the big names in their industry. Bigger companies, irrelevant of the industry, admittedly have a greater amount of resources to rely on and also the ability to adapt and apply new technologies with relative ease. Hence, startups must consistently and persistently innovate to make sure that big companies do not put them out of business.",
        },
        {
          id: 3,
          source: ServiceWhyChooseMobileDevquick,
          alt: "Competitive with contemporary industries  startup consulting",
          title: "Innovative Customers",
          desc: "While innovation drives the startup’s strategy, it is the customers who drive the aforementioned innovation.It is also important to remember that innovation driven by customers helps startups keep their promise to their customers and also establish trust and credibility with them.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for Startup Consulting Services
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "Does my startup need a consultation?",
          answer:
            "A startup has a complex business ahead of them, after getting started the owner has to manage and set up a number of things to succeed. Startup consulting services help in these areas by assisting them and offering support by analysing the client’s priorities, formulating a plan and mentoring them.",
        },
        {
          id: 2,
          question: "What are the services that you provide?",
          answer:
            "At ScrumDigital we offer consulting services to startup companies to help shape their business idea, define project scope and gain clear visibility of the competitive market. Our services include product development, MVP development, Staff augmentation and growth consultation Through analysis, strategy and statistics we help you plan critical steps for your business and execute them.",
        },
        {
          id: 3,
          question: "What are the benefits of startup consulting?",
          para: "Clients who work with business consultants understand much better the initial stages of starting a new business venture. Business consultants help deliver measurable value to the clients instantly. Here are a few benefits of hiring a business consultant for your business:-",
          answer: [
            "Reduced risk factors",
            "Better business decisions",
            "Gain business experience",
            "Access to knowledge",
            "Mentored Startups",
          ],
        },
        {
          id: 4,
          question:
            "Why should I choose Scrum Digital as a Startup consultant?",
          answer:
            "Scrum Digital offers a vast amount of support services to its clients. From our consulting services to design and development as well as marketing and sales strategies, we assist our clients with services to start, excel and grow. Our years of experience working with clients from diverse industries has helped us understand the market better. It has enabled us to serve you better in this fast-paced ecosystem.",
        },
        {
          id: 5,
          question: "How do I get started with my business idea?",
          answer:
            "Our business consultant knows how to get to the next step. They will guide you and take you through the necessary steps required to open a business successfully.",
        },
      ],
    },
  },
  {
    id: 22,
    typeOfService: "StartUp Consulting",
    name: "MVP Development Services",
    link: "mvp-development-services",
    helmetSec: {
      title: "MVP Development Company | MVP Development Services",
      description:
        "Speed up software design & delivery with our proven MVP development services. We create fast product prototypes for demonstrating value. Start now.",
      keywords:
        "MVP development company, MVP development services, MVP software development",
      conical: "/mvp-development-services",
      ogimg: "/ogstartup_consulting.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          MVP Development <span>Company</span>
        </>
      ),
      banner_bott_head:
        "An even MVP is the most ideal approach to investigate the capability of your thought at the ideal expense and time. An even MVP is the most ideal approach to investigate the capability of your thought at the ideal expense and time.",
      bannerImg: mvpBanner,
      alt: "MVP Development services",
    },
    aboutSec: {
      title: (
        <>
          <span>Leading Minimum Viable Product</span> Development Company
        </>
      ),
      desc: (
        <>
          <p>
            If you are looking forward to developing a solution that takes over
            the market then you are at the right place. Prior to taking off on
            your expected computerized item, we ensure that your thought is
            tried for market potential.
          </p>
          <p>
            Not withstanding, not every one of you would require an MVP. For
            instance, a portion of the business people with distinct cycles
            plainly know their finished result needs and have a pre-endorsed
            financial plan for a full-fledged advanced item.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: mvp1ImgContent,
      alt: "Why Do You Need mvp service",
      heading: (
        <>
          Why Do You Need Our Minimum Viable Product{" "}
          <span>App Development Services?</span>
        </>
      ),
      desc: (
        <p>
          The minimum viable product is the most reasonable item, comprised of a
          base arrangement of features that you need to add to your item to test
          your key suppositions without losing the foreknowledge of future
          prospects. An MVP application technology is in excess of a model, and
          as it includes technique, thought age, estimating, learning, and
          investigation.
        </p>
      ),
      list: [
        {
          name: "Idea Validation Sprints",
        },
        {
          name: "Launch in the Market",
        },
        {
          name: "Budget-Friendly Investments",
        },
        {
          name: "Risk Mitigation Development",
        },
      ],
    },
    imgContentSec2: {
      src: mvp2ImgContent,
      alt: "Our MVP Development service",
      heading: (
        <>
          Our MVP Development <span>Service Company</span>
        </>
      ),
      desc: (
        <>
          <p>
            We execute our arrangements by planning a model that joins
            invigorating new highlights, urging and afterward reacting to
            criticism. From the model, we assemble your item to be adaptable,
            adaptable, and effortlessly changed in accordance with reflecting
            market real factors.
          </p>
          <p>
            Our client-driven methodology allows you to acquire knowledge into
            the things your clients esteem and permits you to make changes that
            enhance item execution, guaranteeing that your item dispatches
            firmly with an incredible market sway.
          </p>
        </>
      ),
      list: [
        {
          name: "Share Your Business Ideas",
        },
        {
          name: "Brainstorm Your Idea",
        },
        {
          name: "Design and Development",
        },
        {
          name: "MVP Launch",
        },
        {
          name: "Reviews and Modifications",
        },
        {
          name: "Final Launch",
        },
      ],
      orderImg: "order-lg-2",
      orderContent: "order-lg-2",
    },
    serviceSec: {
      title: (
        <>
          Our Unique MVP <span>Development Services</span>
        </>
      ),
      desc: "We help you accelerate your market time and reduce your investments with expert MVP development services. We share with you valuable insights into what is important for your product and build prototypes that are scalable, flexible, and easy to adjust.",
      list: [
        {
          id: 1,
          source: mobileAppDevIosService,
          alt: "business Analysis mvp service",
          title: (
            <>
              Business <span>Analysis </span>
            </>
          ),
          content:
            "Normally, you need to dispatch your new MVP rapidly to meet your organization's general objectives and explicit item needs. At MVP Solutions, we can uphold your objectives with deft item improvement, rapidly making an MVP that fulfills those necessities.",
        },
        {
          id: 2,
          source: mobileAppDevAndroidService,
          alt: "marketing strategy mvp service",
          title: (
            <>
              Marketing <span>Strategy</span>
            </>
          ),
          content:
            "Maybe then foster an MVP dependent on accepted client inclinations, Net Solutions examines what clients need straightforwardly. We then, at that point ensure that we fulfill the base and that the proposed item will truth be told be feasible.",
        },
        {
          id: 3,
          source: mobileAppDevHybridService,
          alt: "product evolution mvp service",
          title: (
            <>
              Product <span>Evolution</span>
            </>
          ),
          content:
            "Agile product development plans and MVP advancement are utilized to rapidly satisfy the needs of a developing business sector. As customer inclinations change and innovation propel, the necessities of your business and the assumptions for your items change also. ",
        },
        {
          id: 4,
          source: mobileAppDevCrossPlatformService,
          alt: "strategic plan mvp service",
          title: (
            <>
              Strategic <span>Plan</span>
            </>
          ),
          content:
            "As a component of our advancement interaction, we present you with an arrangement enumerating how we expect to continue, utilizing the data about your business and the gathered client information to show you how an essential methodology can bring about the item you need.",
        },
        {
          id: 5,
          source: mobileAppDevARVRService,
          alt: "prototypeDesign mvp service",
          title: (
            <>
              Prototype <span>Design</span>
            </>
          ),
          content:
            "We plan a high-loyalty model utilizing an agile methodology that allots needs to capacities dependent on client profiles and inclinations, just as your business needs and business climate.",
        },
        {
          id: 6,
          source: mobileAppDevBlockChainService,
          alt: "project development mvp service",
          title: (
            <>
              Project <span>Development</span>
            </>
          ),
          content:
            "Our devoted MVP improvement group goes to attempt to make the item your clients are searching for, and we base our plan on the usefulness of the model and the highlights your clients need.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          <span>MVP Development Services</span> That Let You Market in the
          Digital World
        </>
      ),
      desc: "In basic words MVP measure is an approach to approve your item thought through client examination and market contemplates. MVP gives clients a base arrangement of highlights to add to the item and check basic suppositions alongside foreknowledge for future prospects.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseMobileDevTalented,
          alt: "User-Centered Culture mvp service",
          title: "User-Centered Culture",
          desc: "We don't code without sense or reason. We are a client-driven group. We deal with your crowd and track down the most ideal approach to address their agonies",
        },
        {
          id: 2,
          source: ServiceWhyChooseMobileDevBest,
          alt: "Lean and Agile mvp service",
          title: "Lean and Agile",
          desc: "We include you in the development process. We utilize the agile development structure to construct fruitful ventures. It assists with isolating the item into tasks and ventures into runs. Each run has a specific extent of work.",
        },
        {
          id: 3,
          source: ServiceWhyChooseMobileDevquick,
          alt: "Business Thinking mvp service",
          title: "Business Thinking",
          desc: "Be that as it may, we know. Horrible showing can prompt cash squandering and disillusioning results. We need to be your accomplice and construct trustful, long-haul connections.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for Minimum Viable Product
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What is the need for building MVP?",
          answer:
            "A Minimum Viable Product is a version of the product that includes features that solve problems for targeted users which allows an organisation to release it in the market. Building an MVP helps to test the product and to build functionality over time. These tests are based on user testing data and feedback which then helps build product value, with minimum development costs and gather data and user feedback for iterations of the product in the future.",
        },
        {
          id: 2,
          question: "What are the benefits of MVP?",
          para: "Building an MVP has many benefits which include -",
          answer: [
            "An effective method to get stakeholders to invest.",
            "MVP allows businesses to test their products and market demands.",
            "Organizations use MVP to use their Monetization strategy",
            "Testing UX and usability",
            "Cost-efficiency",
          ],
        },
        {
          id: 3,
          question: "What is your MVP development process?",
          para: "Building a successful MVP mostly depends upon a successful development process, step by step from discovery to the launch of the project. Here are the steps our team at Scrum follows for MVP development.",
          answer: [
            "Discovery and MVP Planning",
            "Building prototype",
            "Gain business experience",
            "MVP development and project planning",
            "MVP Development",
            "MVP launch and further iterations",
          ],
        },
        {
          id: 4,
          question: "What’s your pricing model?",
          answer:
            "We have a project-based pricing model, you can share your project requirements with our team. Upon thorough evaluation of your project and its needs, we can get back to you with a detailed estimation of time and cost as well as a roadmap for the project.",
        },
        {
          id: 5,
          question: "What are the MVP development services that you offer?",
          answer:
            "Scrum Digital is a leading MVP Development company offering different services to cater to different business needs. Our services include Business Analysis, Marketing Strategy, Product Evolution and project development. We also offer strategic planning and prototype designing services for your business.",
        },
      ],
    },
  },
  {
    id: 23,
    typeOfService: "StartUp Consulting",
    name: "Product Development Services",
    link: "product-development-services",
    helmetSec: {
      title: "Product Development Services |  Product Development Company",
      description:
        "Bring your product idea to reality with our full product development services. Our expert team handles everything from designing, engineering, testing to launch. Start now.",
      keywords:
        "product development services, product development company",
      conical: "/product-development-services",
      ogimg: "/ogstartup_consulting.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Product Development <span>Services</span>
        </>
      ),
      banner_bott_head:
        "We help you reduce your time to market with advanced product development strategies that reflect 10X better results and help your ROI grow.",
      bannerImg: productBanner,
      alt: "Product Development services",
    },
    aboutSec: {
      title: (
        <>
          <span>Product Development Services</span>That Can Define Your Business
        </>
      ),
      desc: (
        <>
          <p>
            Our product development process is a strong, efficient product that
            makes your software and business website interesting and eye-catchy.
            If you are looking forward to building a strong digital presence
            then we have the team to take you to the goal.
          </p>
          <p>
            Find scalable, future-ready, and interesting solutions that do not
            have to be updated every now and then. The technology used is
            futuristic and can help you earn the most interesting solutions for
            the best results.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: product1ImgContent,
      alt: "Our Product Development Strategy",
      heading: (
        <>
          Our Product Development Strategy
          <span>Help Businesses Grow!</span>
        </>
      ),
      desc: (
        <p>
          The professionals offer interesting solutions that ensure product
          development strategy to help you earn the best results in no time. As
          market competition accelerates and clients' requests develop, making
          great items isn't sufficient. Small organizations and enterprises need
          innovation accomplices that can help them construct predominant,
          imaginative, and separated items. Regardless of whether it's creating
          bespoke programming items or broadening item improvement
          administrations, associations need innovation accomplices with top to
          bottom area information, upheld by effective cycles, and a group that
          can convey.
        </p>
      ),
      list: [
        {
          name: "Vision",
        },
        {
          name: "Mission",
        },
        {
          name: "Brainstorming",
        },
        {
          name: "Product Strategy",
        },
      ],
    },
    imgContentSec2: {
      src: product2ImgContent,
      alt: "productDevelopment",
      heading: (
        <>
          World-Class Product Development Services for{" "}
          <span>Aesthetically Strong Presence</span>
        </>
      ),
      desc: (
        <>
          <p>
            We comprehend that the product development services is steadily
            developing, and as time passes, it becomes critical to pick the
            prescribed procedures that would help in separating your business
            from the opposition. To start with an extensive requirement sharing
            stage that covers the whole range of the venture, necessities are
            made as the group works. Prerequisites are explicit and attached to
            client esteem. By testing highlights and new forms, groups confirm
            on the off chance that they are taking care of client issues and
            fostering the right item.
          </p>
        </>
      ),
      list: [
        {
          name: "Enhanced Product Quality",
        },
        {
          name: "Customer Satisfaction",
        },
        {
          name: "Improved Team Efficiency and Transparency",
        },
        {
          name: "Reduced Risks",
        },
        {
          name: "Better Project Predictability",
        },
        {
          name: "Delivering Business Value",
        },
      ],
      orderImg: "order-lg-2",
      orderContent: "order-lg-2",
    },
    serviceSec: {
      title: (
        <>
          Agile Product <span>Development Services</span>
        </>
      ),
      desc: "Hire experts to be in the loop of the development process. We follow an easy product development process that makes it easier for you to get the best results in no time.",
      list: [
        {
          id: 1,
          source: mobileAppDevIosService,
          alt: "risk management Product Development service",
          title: (
            <>
              Risk <span>Management</span>
            </>
          ),
          content:
            "The Agile procedure acknowledges that task change is inescapable. The utilization of little cycles permits changes to be assimilated rapidly without exacting huge task hazards. ",
        },
        {
          id: 2,
          source: mobileAppDevAndroidService,
          alt: "content development Product Development service",
          title: (
            <>
              Content <span>Development</span>
            </>
          ),
          content:
            "The idea advancement stage includes changing a thought for another item into a definite outline that can be tried and refined. During this step, experts consider the sorts of issues the item will endeavor to settle and how end clients will profit from having it.",
        },
        {
          id: 3,
          source: mobileAppDevHybridService,
          alt: "crossFunctionalTeams",
          title: (
            <>
              Cross-Functional <span>Teams</span>
            </>
          ),
          content:
            "An Agile group incorporates the elements of a bigger improvement association, like engineers, analyzers, documenters, and item supervisors. Colleagues are self-coordinating; they choose how and who conveys the prerequisites inside a cycle. ",
        },
        {
          id: 4,
          source: mobileAppDevCrossPlatformService,
          alt: "customerReview",
          title: (
            <>
              Customer <span>Reviews</span>
            </>
          ),
          content:
            "As the item group conveys evident capacities toward the finish of each cycle, the way toward getting client criticism is streamlined as highlights can be displayed to clients then, at that point, rather than delaying until the last delivered item.",
        },
        {
          id: 5,
          source: mobileAppDevARVRService,
          alt: "daily stands up",
          title: (
            <>
              Daily <span>Stand-Ups</span>
            </>
          ),
          content:
            "Consistently during a cycle, the group will hold a stand-up gathering (likewise called a scrum). During this gathering, every engineer will layout their advancement from the other day, just as any snags experienced. The objective is to determine issues and work on designers' efficiency.",
        },
        {
          id: 6,
          source: mobileAppDevBlockChainService,
          alt: "testingDevelopment",
          title: (
            <>
              Testing and <span>Development</span>
            </>
          ),
          content:
            "Consistently during a cycle, the group will hold a stand-up gathering (likewise called a scrum). During this gathering, every engineer will layout their advancement from the other day, just as any snags experienced. The objective is to determine issues and work on designers' efficiency.",
        },
      ],
    },
    whyChooseUs: {
      col: "col-lg-12",
      heading: (
        <>
          Product Development <span>Solutions by Industry Experts</span>
        </>
      ),
      desc: "Regardless of whether you're running runs in the conventional sense (you know, with your legs) or executing runs with your product advancement group, on the off chance that you need to be really powerful at what you do, you need to be deft about it. In any case, being Agile has a ton of necessities for a sensibly little word. In the product advancement local area, being Agile means significantly something other than being adaptable or fast; it implies you consider, measure, and play out each errand in adherence to the fundamental beliefs of the Agile dynamic.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseMobileDevTalented,
          alt: "Value for Customers Product Development service",
          title: "Value for Customers",
          desc: "The justification for any new product development is to offer some incentive to the clients. Without this, there is no motivation to quality your cash for new gadgets.",
        },
        {
          id: 2,
          source: ServiceWhyChooseMobileDevquick,
          alt: "Reduce Cost Product Development service",
          title: "Reduce Cost",
          desc: "There is no denying the way that making and carrying out new items prompts the extra colossal expense for the organization.",
        },
        {
          id: 3,
          source: ServiceWhyChooseMobileDevquick,
          alt: "Improved Performance Product Development service",
          title: "Improved Performance",
          desc: "Ordinarily, even in the wake of expenditure a large number of dollars on advancement or showcasing your item, an entrepreneur faces dissatisfaction in light of the low quality of the item.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for Product Development
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "What are product development services?",
          answer:
            "Product development is the process needed to carry an idea from being a concept through becoming a product and reaching the market. The product development process involves many stages from product idea generation, market research, research & development, manufacturing and distribution.",
        },
        {
          id: 2,
          question: "Why are Product development Services important?",
          answer: (
            <>
              <p>
                Product Development is important to guarantee value for your
                customers by ensuring that there is demand for your products and
                services and they are of the highest quality before you take
                your products to the market.
              </p>
              <p>
                In the process of product development steps like research and
                development and market research helps create products that add
                value to the customer and solve their problems, which in turn
                adds value to the brand. On the other developing new researched
                based and well-thought products improves market share and create
                growth in the company offering financial sustainability by the
                creation of new revenue streams.
              </p>
            </>
          ),
        },
        {
          id: 3,
          question: "What are the stages of Product development?",
          para: "A Product development journey should include all the steps from the concept of the product to manufacturing and distribution and engage as many stakeholders as possible to make sure their needs and concerns are addressed while engaging with the targeted user base to ensure the final product will have the market value. The process of product development can be broken down into the following steps.",
          paraEnd:
            "Scrum Digital offers strong, efficient product development services that make your software or business website interesting and eye-catchy.",
          answer: [
            "Identifying market needs",
            "Quantify the opportunity",
            "Conceptualise the product",
            "Validate the solution",
            "Build a product roadmap",
            "Develop a minimum viable product (MVP)",
            "Release MVP to test users",
            "Ongoing assessment and development",
          ],
        },
        {
          id: 4,
          question: "What is the main risk in product development?",
          answer:
            "There is always risk involved when you are developing a new product, the risk of not being able to produce what the customer wants. This risk is typically higher when the concept of a new product is based on the availability of new technology and not consumer-based. It is always a good idea to create a product that solves customer problems instead of rehashing existing market products. During the development process, you can also have a range of technical or operational risks. There also involves a financial risk, where the product is not able to generate enough demand for the sales to book a profit.",
        },
        {
          id: 5,
          question:
            "How can we reduce the risk in the product development process?",
          answer: (
            <>
              <p>
                Developing a new product is a speculative venture, wherein you
                need to weigh the pros and cons of developing the product. It is
                also important that throughout the whole process you partner
                with a reliable and trustworthy group of people, who will follow
                a simple product development process, that will make it easier
                for you to get the results in no time.
              </p>
              <p>
                At Scrum Digital we have a team of strong and highly motivated
                experts looking forward to building a strong product for you. We
                find scalable, future-ready, and interesting solutions that do
                not have to be updated now and then. The technology used is
                futuristic and can help you earn the most interesting solutions
                for the best results with minimum risks.
              </p>
            </>
          ),
        },
      ],
    },
  },
  {
    id: 24,
    typeOfService: "StartUp Consulting",
    name: "Staff Augmentation Services",
    link: "staff-augmentation-services",
    helmetSec: {
      title: "IT Staff Augmentation Services | Staff Augmentation Consulting",
      description:
        "Don’t lose time and opportunity over talent shortages. Our staffing augmentation services delivers ready teams to scale initiatives. Book a consultation.",
      keywords:
        "Staff Augmentation Services, IT Staff Augmentation Services, staff augmentation consulting",
      conical: "/staff-augmentation-services",
      ogimg: "/ogstartup_consulting.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Staff Augmentation <span>Services</span>
        </>
      ),
      banner_bott_head:
        "Our IT Staff Augmentation Services can give basic ranges of skill support to your business filling loopholes in your present team. We offer staffing arrangements that assist with guaranteeing your innovation drives to stay up with your business needs.",
      bannerImg: staffBanner,
      alt: "StaffAugmentation services",
    },
    aboutSec: {
      title: (
        <>
          <span>Staff Augmentation Services</span> to Help You in the Journey
        </>
      ),
      desc: (
        <>
          <p>
            With a scope of specialists to look over, our staff augmentation
            services can assist with increasing your IT staff on a case by case
            basis. Whatever kind of help you require, we have the staffing
            increase answer for get you where you need to go.
          </p>
          <p>
            At the point when you're shy of a vital ability to get your task
            conveyed, trust the innovation specialists to assist with filling
            the hole.
          </p>
        </>
      ),
    },
    imgContentSec2: {
      src: staffImgContent,
      alt: "staffAugmentation",
      heading: (
        <>
          Staff Augmentation to <span>Help You Accelerate Projects</span>
        </>
      ),
      desc: (
        <>
          <p>
            For any effective venture, it is fundamental to have the right-sized
            group. With the need of the business, some of the time, it is
            important to broaden the cross-utilization teams with individuals
            who are perfect for the work. Our staff augmentation consulting team
            increases service, improves your task speed and offers the expert
            aptitude your group needs to convey the venture on schedule.
          </p>
        </>
      ),
      list: [
        {
          name: "Better Management",
        },
        {
          name: "Cost efficacious",
        },
        {
          name: "Expanded Flexibility",
        },
        {
          name: "Preventing Debilitation",
        },
      ],
      orderImg: "order-lg-2",
      orderContent: "order-lg-2",
    },
    enhanceMobileData: {
      heading: (
        <>
          Strong Pillars of <span>Staff Augmentation Services</span>
        </>
      ),
      desc: "Getting your task finished on time takes a fluctuated ability base and you can draw from our pool of masters to help you own it to consummation. Our staff augmentation organization stands on various pillars that make things easier.",
      list: [
        {
          id: 1,
          title: (
            <>
              Undertaking <span>Managers</span>
            </>
          ),
          src: EnhanceMobilestretegy,
          alt: "application",
        },
        {
          id: 2,
          title: (
            <>
              Professional <span>Engineers</span>
            </>
          ),
          src: EnhanceMobiledeesign,
          alt: "ChatBot",
        },
        {
          id: 3,
          title: (
            <>
              Frameworks/Business <span>Analysts</span>
            </>
          ),
          src: EnhanceMobiledevelopment,
          alt: "crm",
        },
        {
          id: 4,

          title: (
            <>
              Programming <span>Developers</span>
            </>
          ),
          src: EnhanceMobileapi_integration,
          alt: "ERP",
        },
        {
          id: 5,
          title: (
            <>
              Foundation <span>Specialists</span>
            </>
          ),
          src: EnhanceMobilemaintenance,
          alt: "Cloud",
        },
      ],
    },
    serviceSec: {
      title: (
        <>
          <span>What Does IT</span> Staff Augmentation Offer?
        </>
      ),
      desc: "Staff Augmentation is an incredible method to upgrade your group and gain the required ranges of abilities. At scrum digital our services are well-defined and they help make a difference in your operations in various ways:",
      list: [
        {
          id: 1,
          source: mobileAppDevIosService,
          alt: "less time to hire Staff Augmentation Services",
          title: (
            <>
              Less Time
              <span>To Hire</span>
            </>
          ),
          content:
            "You can skip posting occupations with enrollment and staff aug, looking through the market, and various meetings.",
        },
        {
          id: 2,
          source: mobileAppDevAndroidService,
          alt: "rightFit Staff Augmentation Services",
          title: (
            <>
              Right Fit <span>For Your Project</span>
            </>
          ),
          content:
            "No matter whether you are looking for a brief agreement or a perpetual situation, we match you with specialists who are an ideal choice for your business. ",
        },
        {
          id: 3,
          source: mobileAppDevHybridService,
          alt: "leverageITProfessionals Staff Augmentation Services",
          title: (
            <>
              Leverage <span>IT Professionals</span>
            </>
          ),
          content:
            "We have an organization of specialists we work with and a cycle for associating you with the right one for your requirements.",
        },
        {
          id: 4,
          source: mobileAppDevCrossPlatformService,
          alt: "crossDown the churn Rate",
          title: (
            <>
              Cross Down <span>The Churn Rate</span>
            </>
          ),
          content:
            "In case you want to have a training strategy or retaining staff, then we are here to help you through.",
        },
        {
          id: 5,
          source: mobileAppDevARVRService,
          alt: "fullRangeItProfessionals",
          title: (
            <>
              Full Range <span>Of IT Professionals</span>
            </>
          ),
          content:
            "IT professionals who can help you through. We have a range of project managers, business analysts, architects, developers, network specialists, application specialists, and IT support professionals to help you.",
        },
        {
          id: 6,
          source: mobileAppDevBlockChainService,
          alt: "Flexible Resources",
          title: (
            <>
              Flexible Resources
              <span>For Better Results</span>
            </>
          ),
          content:
            "Just choose professionals to accelerate an existing project or to help your team in building stronger and better projects.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          What Makes Scrum Digital the{" "}
          <span>Right Choice for Your Business?</span>
        </>
      ),
      desc: "It is normally a lot more extensive organization than simply group increase, as it additionally covers a wide assortment of programming related administrations, like investigation and plan, UI/UX plan, quality confirmation, project the board or backing and upkeep.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseMobileDevTalented,
          alt: "Lesser Responsibilities and Reduced Costs Staff Augmentation Services",
          title: "Lesser Responsibilities and Reduced Costs",
          desc: "While reevaluating a committed group, the seller takes care of additionally the expenses of office spaces, programming licenses, equipment and utilities, worker's preparation, and their advantages. They likewise deal with the general prosperity of programming engineers.",
        },
        {
          id: 2,
          source: ServiceWhyChooseMobileDevBest,
          alt: "Easier to Adopt to Working Culture Staff Augmentation Services",
          title: "Easier to Adopt to Working Culture",
          desc: "There's likewise a significant contrast as far as working and authoritative culture in the two models. Sitting in a similar office permits engineers in a reevaluating group to establish an interesting climate of information trade and collaboration.",
        },
        {
          id: 3,
          source: ServiceWhyChooseMobileDevquick,
          alt: "Better Understanding of the Projects Staff Augmentation Services",
          title: "Better Understanding of the Projects",
          desc: "In the staff expansion model, the project of the board is generally done by the outsource, in spite of the undertaking being created by outer laborers.",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          <span>FAQ's</span> for Staff Augmentation
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "How does the Staff Augmentation process work?",
          answer:
            "First off, the client that is looking for expansion defines the skill set they want, the number of developers and other IT staff they need and layout a detailed job description of what’s needed.Only then does our team at Scrum digital start looking for recruits that match most of the job or project expectations. We have an organization of specialists that we work with. After the screening process and the interviews, the recruiter then is offered the job.",
        },
        {
          id: 2,
          question: "Why use Staff Augmentation services?",
          answer:
            "Staff Augmentation services help lower the cost of software development, get skilled individuals to work on a particular project allowing you more control over your business and also helps you to scale your business faster.",
        },
        {
          id: 3,
          question:
            "Why should I choose scrum digital for Staff Augmentation services?",
          answer:
            "IT staff augmentation services at Scrum Digital help you with skilled tech support that fills loopholes in your current team. We have a client-centric model with a dedicated team that have access to quality resources and skill sets giving you full control over the project and the flexibility to scale up and down.",
        },
        {
          id: 4,
          question: "What are your pricing options?",
          answer:
            "We have a project-based pricing model. Upon evaluation of your project and your requirement, we give you a detailed estimation of time and the cost.",
        },
        {
          id: 5,
          question: "Are Staff Augmentation and outsourcing similar?",
          answer:
            "No, Outsourcing is a collective term that comprises a wide selection of models one of which is Staff Augmentation. Outsourcing refers to outsourcing the full project or a part of the project to an external team or company whereas Staff Augmentation is about adding skilled individuals to the in-house team to manage the workload.",
        },
      ],
    },
  },
  {
    id: 25,
    typeOfService: "StartUp Consulting",
    name: "Growth Consultation",
    link: "growth-consultation",
    helmetSec: {
      title: "Growth Consultancy Services | Business Growth Consultant",
      description:
        "Let our team of strategic growth consultants conduct an impartial assessment to identify avenues for company development. Request a free call.",
      keywords:
        "Growth Consultancy Services,business growth consultant",
      conical: "/growth-consultation",
      ogimg: "/ogstartup_consulting.png"
    },
    bannerData: {
      bannerHeading: (
        <>
          Growth <span>Consultancy Services</span>
        </>
      ),
      banner_bott_head:
        "Initiating sustainable growth for clients, improving sluggish performance, highlighting growth opportunities.",
      bannerImg: growthBanner,
      alt: "Growth Consulting service",
    },
    aboutSec: {
      title: (
        <>
          Growth Consultation at <span>Scrum Digital</span>
        </>
      ),
      desc: (
        <>
          <p>
            Startups are full of uncertainty, which starts from scratch without
            any knowledge.For better monitoring, mentoring, better investments,
            to stand at the top in the competitive world, to pass unsolved
            hurdles and obstacles, startups need consultation. Scrum Digital
            provides you better consultation that leads your startup's ranking
            at the excellence level. We aim at diversifying every field of
            growth in the consultation and provide you with the best expertises
            that accelerates your business growth.
          </p>
        </>
      ),
    },
    imgContentSec1: {
      src: growth1ImgContent,
      alt: "growth",
      heading: (
        <>
          Frameworks Requiring <span>Growth Consultation</span>
        </>
      ),
      desc: (
        <>
          <p>
            Startups are glutted with dubiousness, and to survive in a
            competitive world with expeditious rate, consultation plays a pious
            role in monitoring the growth, ranking, success, and failure of
            startups. Embracing business with new strategies and technology is
            where Growth Consultation plays a vital role.
          </p>
        </>
      ),
      list: [
        {
          name: "Outcomes don't meet the expectations",
        },
        {
          name: "Fully-fledged Businesses Need To Re-Model Themselves",
        },
        {
          name: "Managing Workers’ Morale Amidst High-Speed Business Growth",
        },
        {
          name: "Initiating A Fresh Perspective Into Your Business",
        },
      ],
      listColumn: {
        columns: 1,
      },
    },
    imgContentSec2: {
      src: growth2ImgContent,
      alt: "Unbolting Hidden Growth Consultation service",
      heading: (
        <>
          Unbolting Hidden <span>capabilities</span> and opportunities
        </>
      ),
      desc: (
        <>
          <p>
            There exist innumerable possibilities and a great scope that can
            help and assist startups in unlatching more growth avenues within
            their business. The pillars of success to any consultation
            encompasses.
          </p>
        </>
      ),
      list: [
        {
          name: "Administration",
        },
        {
          name: "Plan of Action",
        },
        {
          name: "Advertisement",
        },
        {
          name: "Trading",
        },
      ],
      orderImg: "order-lg-2",
      orderContent: "order-lg-2",
    },
    enhanceMobileData: {
      heading: (
        <>
          Services of <span>Growth Consultants</span>
        </>
      ),
      desc: "Growth Consultants are an aid in diagnosing every possible problem and facilitate you with brimming of services.",
      list: [
        {
          id: 1,
          title: (
            <>
              Solving client's <span>raised problems.</span>
            </>
          ),
          src: EnhanceMobilestretegy,
          alt: "application",
        },
        {
          id: 2,
          title: (
            <>
              Dispatching necessary <span>information to a client.</span>
            </>
          ),
          src: EnhanceMobiledeesign,
          alt: "ChatBot",
        },
        {
          id: 3,
          title: (
            <>
              Assistance and implementation{" "}
              <span>of recommended solutions.</span>
            </>
          ),
          src: EnhanceMobiledevelopment,
          alt: "crm",
        },
        {
          id: 4,

          title: (
            <>
              Commitment around <span>corrective solutions.</span>
            </>
          ),
          src: EnhanceMobileapi_integration,
          alt: "ERP",
        },
        {
          id: 5,
          title: (
            <>
              Upgrading the organization's{" "}
              <span>effectiveness permanently.</span>
            </>
          ),
          src: EnhanceMobilemaintenance,
          alt: "Cloud",
        },
      ],
    },

    serviceSec: {
      title: (
        <>
          Consultancies Meeting <span>Up With New Challenges</span>
        </>
      ),
      desc: "Providing you with better efficiency, profitability, scalability to weaken the challenges and serving you to achieve your desired goals, unlocking the different phases of evolution and catering to you with better solutions.",
      list: [
        {
          id: 1,
          source: mobileAppDevIosService,
          alt: "HighTech-driven-scale",
          title: (
            <>
              High tech-driven
              <span>Scale</span>
            </>
          ),
          content:
            "Technology employing in research, analysis, facilitation, process management.",
        },
        {
          id: 2,
          source: mobileAppDevAndroidService,
          alt: "Monitoring in a fast-paced",
          title: (
            <>
              Monitoring in a fast-paced and
              <span>real-time world</span>
            </>
          ),
          content:
            "To gain all round-perspective on the market, consequently acquiring and delivering the right insights to users, real-time data monitoring is a necessity.",
        },
        {
          id: 3,
          source: mobileAppDevHybridService,
          alt: "Meticulous",
          title: (
            <>
              Meticulous
              <span>Intelligence </span>
            </>
          ),
          content:
            "From big data to machine learning, radical insights that are flourished and more impactful became the name of game in business consulting industry.",
        },
      ],
    },
    whyChooseUs: {
      heading: (
        <>
          Why <span>Choose Us?</span>
        </>
      ),
      desc: "Startups tend to explode market with greater speed and pace, capturing the latest needs and requirements of customers, providing maximum output with least investment and understanding market strategy.",
      list: [
        {
          id: 1,
          source: ServiceWhyChooseMobileDevTalented,
          alt: "Outline products to customer value and cost Growth Consultation service",
          title: "Outline products to customer value and cost",
          desc: "Styling products that widen customer value, with balanced cost and development.",
        },
        {
          id: 2,
          source: ServiceWhyChooseMobileDevBest,
          alt: "Illustrating a growth strategy Growth Consultation service",
          title: "Illustrating a growth strategy",
          desc: "Discovering the next vectors of growth and the plan to get there.",
        },
        {
          id: 3,
          source: ServiceWhyChooseMobileDevquick,
          alt: "Amplify marketing and sales Growth Consultation service",
          title: "Amplify marketing and sales",
          desc: "Learning to decrease sales cycles and improve conversions through better value propositions, sale process, and capabilities",
        },
      ],
    },
    FAQSec: {
      title: (
        <>
          FAQ's for <span>Growth Consultation</span>
        </>
      ),
      desc: "We understand you face doubts when trusting someone with your business. Our experts answer here the most frequently asked questions on digital solutions to help you make a wiser decision.",
      inner: [
        {
          id: 1,
          question: "Who is a growth consultant?",
          answer:
            "A growth consultant is a business advisor who understands the challenges a business faces and tries to help a business move forward and overcome the challenges prudently and sustainably.",
        },
        {
          id: 2,
          question: "Do I need to hire a growth consultant for my business?",
          answer:
            "Hiring a growth consultant for your business can be the best thing you can do for your business. This can be a cost-effective way for your business to take advantage of expert knowledge. A growth consultation can help your business in many key areas such as marketing, SEO, Improving traffic on your website, improving efficiency and creating a new user base.",
        },
        {
          id: 3,
          question:
            "What are the things I should look for in a growth consultant?",
          para: "Hiring the right Growth consultant can be a game-changer for your business, they can help you accelerate your business growth. Conversely hiring the wrong consultant can cost more than just money. It can cost you time and energy. Small businesses are always working on a tight budget, it’s crucial to choose the right consultant. Here are a few questions you can ask to hire the right consultant:-",
          answer: [
            <>
              Are you collaborative? <br />
              Businesses don’t want consultants who work in isolation.
            </>,
            <>
              How much experience and knowledge do you have of the industry?
              <br />
              It is always better to choose an experienced professional with
              Industry Knowledge.
            </>,
            <>
              Can you tell me about the process of your work? <br />
              The growth consultant must be able to demonstrate his work and
              process for achieving the results a business needs.
            </>,
            <>
              Can you guarantee success? <br />
              This can be a tricky question. Success can never be guaranteed,
              but this kind of question can root out a salesman from a
              trustworthy candidate.
            </>,
          ],
        },
        {
          id: 4,
          question: "What is the cost of hiring a growth consultant?",
          answer:
            "The cost of hiring a growth consultant varies and it is determined by various factors like the experience of the consultant, size of your business, your expectations and so on. Kindly contact our team at Scrum Digital to get an estimate of the cost.",
        },
        {
          id: 5,
          question: "Why should I hire Scrum Digital for Growth Consultation?",
          answer:
            "Our team at Scrum Digital has experience of years in this Industry. We have served clients from a diverse range of industries. We provide you with better efficiency, profitability, and scalability to resolve issues facing your business and serve you to achieve your desired goals, unlocking the different phases of evolution and catering to you with better solutions.",
        },
      ],
    },
  },
];
