import React, { useEffect } from "react";
import B2BSec from "../../components/Home/B2BSec/B2BCompanies";
import OurClientSec from "../../components/Home/OurClientsSec";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import AIPageBanner from "../../components/AIDevelopmentPageComponents/AIPageBanner";
import { ECommercePageData } from "../../components/EcommerceDevelopmentPageComponents/ECommercePageData";
import ChatbotDevelopmentServices from "../../components/ChatbotComponents/ChatbotDevelopmentServices";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import WhyChooseUsECommerce from "../../components/EcommerceDevelopmentPageComponents/WhyChooseEcommerce";
import TeamInnovation from "../../components/Home/TeamInnovation";
import ChatbotTechnologyWeWork from "../../components/ChatbotComponents/ChatbotTechnologyWeWork";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import ECommerceOurProcess from "../../components/EcommerceDevelopmentPageComponents/ECommerceOurProcess";
import HeadMetaSec from "../../components/HeadMetaSec";

const ECommerceDevelopmentPage = () =>{
    useEffect(()=>{
        document.body.classList.add('e-commerce-page-body')
        return()=>document.body.classList.remove('e-commerce-page-body')
    },[])

    return(
        <>
        <HeadMetaSec HeadSecData={ECommercePageData.helmetSec} />
           <AIPageBanner bannerData={ECommercePageData.bannerData}/>
           <ChatbotDevelopmentServices developmentServicesData = {ECommercePageData.developmentServicesData}/>
           <OnDemandFoodCta  {...ECommercePageData.ctaContent} />
           <WhyChooseUsECommerce/>
           <TeamInnovation />
           <ChatbotTechnologyWeWork technologyWeWorkData={ECommercePageData.technologyWeWorkData}/>
           <InnovatingJust headingData={ECommercePageData.innovatingData} />
           {/* <B2BSec/> */}
           {/* <ECommerceOurProcess ourProcessData={ECommercePageData.ourProcessData}/> */}
           <OurClientSec/>
           <OurBlogSec/>
        </>
    )
}


export default ECommerceDevelopmentPage;
