import React from "react";
import styles from "../../../assets/css/innovating-just.module.scss";
import { data } from "./InnovatingJustItemData";

const InnovatingJust = () => {
  return (
    <>
      <section className={`pdtb ${styles.innovating_just_sec}`}>
        <div className="container-lg">
          <div className="sub-heading-outline text-center mb-3">
            <h2 className="text-white"><span className="d-block">CRAFTING INNOVATIONS</span> Tailored To Your Unique Sector</h2>
            <p className="text-white">Shaping unique solutions, just for your industry's needs.</p>
          </div>
          <ul className="row justify-content-center">
            {
              data.map((list, i) =>
                <li key={i} className="col-lg-3 col-md-6 col-sm-6">
                  <div className={styles.innovating_just_inner}>
                    <span className={styles.innovating_just_inner_img}>
                      <img src={list.imgSrc} alt={list.alt} />
                    </span>
                    <h3>{list.title}</h3>
                  </div>
                </li>
              )
            }
          </ul>
        </div>
      </section>
    </>
  );
};

export default InnovatingJust;
