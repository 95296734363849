import React from "react";
import { Container } from "react-bootstrap";
import styles from '../../assets/css/service-cloud-partners.module.scss'
import { ServiceWhyChoosesCmsaws, ServiceWhyChoosesCmsazure, ServiceWhyChoosesCmsaws_cloud} from "../../assets/img/ServiceWhyChooseUsSecImg";

const CloudPlatformPartners = () => {
  const cloudPlatformList = [
    {
      id: 1,
      source: ServiceWhyChoosesCmsaws,
      title: (
        <>
          AWS Cloud Solutions <br /> & Consulting
        </>
      ),
    },
    {
      id: 2,
      source: ServiceWhyChoosesCmsazure,
      title: (
        <>
          Azure Cloud Solutions <br /> & Consulting
        </>
      ),
    },
    {
      id: 3,
      source: ServiceWhyChoosesCmsaws_cloud,
      title: (
        <>
          Google Cloud Solutions <br /> & Consulting
        </>
      ),
    },
  ];
  return (
    <div className={`pdtb ${styles.cloud_platform}`}>
      <Container className="custom-container">
        <div className="row">
          <div className="col-lg-9 mx-auto sub-heading text-center">
            <h2 className="text-white">
              Our <span>Cloud Platform</span> Partners
            </h2>
            <p className="text-white">
              We develop cloud computing applications by leveraging advanced and
              efficient platforms and frameworks. Various types of services are
              provided from cloud integration services to customizable the bare
              metal infrastructure to customize-able applications serving
              specific purposes.
            </p>
          </div>
        </div>
        <ul className="row">
          {cloudPlatformList.map((elem, index) => {
            return (
              <li className="col-lg-4 col-md-4" key={index}>
                <div className={styles.cloud_platform_list}>
                  <img src={elem.source} alt="aws" className="img-fluid" />
                  <h5>{elem.title}</h5>
                </div>
              </li>
            );
          })}
        </ul>
      </Container>
    </div>
  );
};

export default CloudPlatformPartners;
