import axios from "axios";
import { server } from "../config/config";


// export const getblogs = async(url, callback) => {
//     const result = await axios.get(`/${server.blogApi}${url}`)
//     return callback(result)
// }
// const blogApi = "https://scrumdigital.com/blog/wp-json/wp/v2";

export const getblogs = async (url, callback) => {
    try {
        const result = await axios.get(`${server.blogApi}${url}`);
        callback(result);
    } catch (error) {
        console.error("Error fetching blogs:", error);
    }
}