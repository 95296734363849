import styles from "../../assets/css/service/Necessary.module.scss";
const Necessary = ({ necessaryData }) => {
  return (
    <section className={`${styles.necessary}`}>
      <div className="container">
        {necessaryData.map((dataWeb, index) => {
          return (
            <div className="row align-items-center" key={index}>
              <div
                className={`col-lg-6 ${dataWeb.orderContent ? dataWeb.orderContent:""
                  }`}
              >
                <div className={`${styles.good_website}`}>
                  <div className="service-heading">
                    <h2>{dataWeb.webHeading}</h2>
                    <p>{dataWeb.prop}</p>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-6 ${dataWeb.orderImg ? dataWeb.orderImg :""}`}
              >
                <div className={`${styles.web_img}`}>
                  <img className="img-fluid" src={dataWeb.src} alt="images" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default Necessary;
