import React from "react";
import styles from "../../assets/css/hmg-on-demand-food-cta.module.scss";
import { Container } from "react-bootstrap";
import { hmgCtaImg } from "../../assets/img";
import Modal_form from "../Banner/Modal";

const OnDemandFoodCta = (props) => {
  return (
    <>
      <section className={styles.on_demand_food_cta}>
        <Container>
          <div className={`row align-items-end ${styles.content_sec}`}>
            <div className="col-lg-8">
              <h2>
                {props.ctaHeading}
              </h2>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <Modal_form btnName={props.btnName} customClass={styles.theme_btn} />
            </div>
          </div>
        </Container>
        <img src={hmgCtaImg} className={styles.bg_img} alt="ctaBg" />
      </section>
    </>
  );
};

export default OnDemandFoodCta;
