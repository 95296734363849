import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { serviceBannerAiDev } from "../../assets/img";


export const AIPageData = {
   helmetSec: {
    title: "AI Development Services | AI Development Company",
    description:
      "Transform your business landscape with our AI development services. Smart, strategic solutions to outpace the competition. Revolutionize Now!!",
    keywords:
      "ai development company, ai development services, conversational ai platform, AI app development",
    conical: "/ai-development-services",
  },
     bannerData:{
      heading:<>AI Development Services</>,
      desc:"Discover the future with our AI development company transforming interactions into conversions smoothly.",
      imgSrc:serviceBannerAiDev,
      alt:"ai banner"
     },
     developmentServicesData : {
      paddingTop:"pt-0",
        heading:<>Our AI Development <span className="text-uppercase">Services</span></>,
        desc:"Boost your business with our cutting-edge AI solutions, driving innovation and unlocking limitless possibilities.",
        serviceList:[
          {
            id: 1,
            title: (
              <>
                Chatbot Development <span>Services</span>
              </>
            ),
            desc: (
              <>
                With our innovative chatbot development services,
                take the first steps towards revolutionary client engagement.
                Our chatbots do more than just operate as automated answers
                they become brand ambassadors, skillfully fusing personality
                and conversational flair.
              </>
            ),
            href: "/",
          },
          {
            id: 2,
            title: (
              <>
                AI <span>Solutions</span>
              </>
            ),
            desc: (
              <>
                Our unique AI solutions can solve complicated
                data problems and automate repetitive operations,
                transforming your business environment. Imagine
                a system that not only comprehends your business
                objectives, but also moves you in the direction
                of them with agility and innovation.
              </>
            ),
            href: "/",
          },
          {
            id: 3,
            title: (
              <>
                AI-Integrated Mobile and <span>Web Applications</span>
              </>
            ),
            desc: (
              <>
                Elevate your mobile and web applications with
                our AI integration services. We infuse AI into
                your digital platforms, making them smarter, and
                incredibly user-friendly. With our AI-powered applications,
                the possibilities are as limitless as your imagination.
              </>
            ),
            href: "/",
          },
          {
            id: 4,
            title: (
              <>
                Ethical AI <span>Consulting</span>
              </>
            ),
            desc: (
              <>
                We support the ethical use of AI,
                making sure that your AI business
                solutions are open, equitable, and compliant
                with moral standards. Allow us to walk you
                through the moral dilemmas so you can
                ethically innovate while gaining the trust of your users.
              </>
            ),
            href: "/",
          },
          {
            id: 5,
            title: (
              <>
                AI-Driven User <span>Experience Personalization</span>
              </>
            ),
            desc: (
              <>
                Transform your applications into intelligent platforms
                that adapt to each user's preferences, behaviors, and historical
                interactions. Our AI-driven personalization engines analyze
                user data in real time to tailor content, recommendations,
                and features to each individual's unique tastes and needs.
              </>
            ),
            href: "/",
          },
          {
            id: 6,
            title: (
              <>
                AI-Optimized <span>Analytics</span>
              </>
            ),
            desc: (
              <>
                Leverage AI to not only enhance the front-end user
                experience but also to supercharge your app's backend
                efficiency and intelligence. Our AI-optimized performance
                solutions, including chatbot development services,
                ensure your app runs smoothly, predicting and mitigating
                bottlenecks before they impact your users.
              </>
            ),
            href: "/",
          },
        ]
      },
       aiModelData : {
        heading:<>We Have Expertise In Various <br/> <span>AI Models</span></>,
        desc:"Our expert chatbot developers offer highly sophisticated and smart solutions that can be implemented into varied domains, like marketing, retail, eCommerce, media and entertainment, etc.",
        modelList : [
          {
              id:1,
              title:<>Chatbot <span>Testing</span></>,
              desc:<>GPT-3 is a group of AI models that can perform natural language processing tasks,
              including but not limited to text generation, summarization, translation,
              and question answering. Some specific models within the GPT-3 framework are
              Davinci, Curie, Babbage, and Ada.</>
          },
          {
              id:2,
              title:<>Chatbot <span>Testing</span></>,
              desc:<>GPT-3 is a group of AI models that can perform natural language processing tasks,
              including but not limited to text generation, summarization, translation,
              and question answering. Some specific models within the GPT-3 framework are
              Davinci, Curie, Babbage, and Ada.</>
          },
          {
              id:3,
              title:<>Chatbot <span>Testing</span></>,
              desc:<>GPT-3 is a group of AI models that can perform natural language processing tasks,
              including but not limited to text generation, summarization, translation,
              and question answering. Some specific models within the GPT-3 framework are
              Davinci, Curie, Babbage, and Ada.</>
          },
          {
              id:4,
              title:<>Chatbot <span>Testing</span></>,
              desc:<>GPT-3 is a group of AI models that can perform natural language processing tasks,
              including but not limited to text generation, summarization, translation,
              and question answering. Some specific models within the GPT-3 framework are
              Davinci, Curie, Babbage, and Ada.</>
          },
          {
              id:5,
              title:<>Chatbot <span>Testing</span></>,
              desc:<>GPT-3 is a group of AI models that can perform natural language processing tasks,
              including but not limited to text generation, summarization, translation,
              and question answering. Some specific models within the GPT-3 framework are
              Davinci, Curie, Babbage, and Ada.</>
          },
          {
              id:6,
              title:<>Chatbot <span>Testing</span></>,
              desc:<>GPT-3 is a group of AI models that can perform natural language processing tasks,
              including but not limited to text generation, summarization, translation,
              and question answering. Some specific models within the GPT-3 framework are
              Davinci, Curie, Babbage, and Ada.</>
          },
      ]
      },
      ctaContent:{
        ctaHeading:<>Transform your app with AI <span>Let's innovate together</span></>,
         btnName:<>Connect now! <i><HiOutlineArrowNarrowRight /></i></>,
      },

      normalCtaData : {
        title:<>Ready to revolutionize your app with AI? Reach out today and let's shape the future together!</>
      },
       technologyWeWorkData : {
        heading:<><span className="text-uppercase">Technologies</span> we work with</>,
        desc:"Specializing in AI integration, we harness advanced algorithms, intelligent automation, and data analytics to elevate your app's capabilities and user experience."
      },

      ourProcessData:{
        heading:<>Our <span>Distinctive Process </span></>,
        desc:"Optimized performance and user satisfaction is ensured by a streamlined AI-driven development process.",
        list:[
          {
            id:1,
            desc:"Identifying needs, exploring AI possibilities, aligning with business objectives."
          },
          {
            id:2,
            desc:"Crafting user-centric designs, integrating AI features, building interactive prototypes."
          },
          {
            id:3,
            desc:"Assuring smooth functioning, incorporating AI techniques, and precisely coding."
          },
          {
            id:4,
            desc:"Rigorous QA, AI performance validation, user experience optimization checks."
          },
          {
            id:5,
            desc:"Launching AI-powered apps, monitoring initial performance, user adoption tracking."
          },
          {
            id:6,
            desc:"Assessing AI impact, user feedback analysis, strategy refinement."
          },
          {
            id:7,
            desc:"Continuous AI updates, system optimizations, ensuring peak performance."
          },
        ]
      }
}