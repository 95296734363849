import {
  AdminPanelImg,
  AdvanceFeatureImg,
  UserPanelImg,
} from "../../../assets/img";
import { LeftAdminDesign, RightImgDesign } from "./PanelDesign";

export const PanelList = [
  {
    id: 1,
    title: (
      <>
        <span>User</span> Panel
      </>
    ),
    desc: "Our user panel is designed to provide an intuitive and user-friendly  interface for fantasy sports enthusiasts & on this panel, users can leverage many pro benefits such as:-",
    listInner: [
      "User Authentication",
      "Leader board",
      "Create Contest",
      "Mock Draft",
      "Invite & Earn and many more",
    ],
    design: <RightImgDesign src={UserPanelImg} alt="user panel" />,
    orderContentSec: "order-lg-1",
    orderImgSec: "order-lg-2",
    designLeft: false,
  },
  {
    id: 2,
    title: (
      <>
        <span>Admin</span> Panel
      </>
    ),
    desc: "Our admin panel empowers administrators with comprehensive control over the fantasy sports platform which makes our admin panel a hub for efficient administration. Other features may include:-",
    listInner: [
      "Role-based dashboards",
      "User Management",
      "Transaction Management",
      "Content Management System",
      "Customer Relationship Management",
      "24/7 Customer Support & Many more",
    ],
    design: <LeftAdminDesign src={AdminPanelImg} alt="admin panel" />,
    orderContentSec: "order-1 order-lg-2",
    orderImgSec: "order-2 order-lg-1",
    designLeft: true,
  },
  {
    id: 3,
    title: (
      <>
        <span>Advanced</span> Features
      </>
    ),
    desc: "Our fantasy sports app goes beyond the basics to offer advanced features that offer an improved experience for users & administrators alike. These advanced features may include:-",
    listInner: [
      "Live Match Score",
      "Real-Time Analytics",
      "CRM & CMS Integrations",
      "Multiple Payment System",
      "Push Notifications",
      "Google Analytics & Many more",
    ],
    design: <RightImgDesign src={AdvanceFeatureImg} alt="advance-feature" />,
    orderContentSec: "order-lg-1",
    orderImgSec: "order-lg-2",
    designLeft: false,
  },
];
