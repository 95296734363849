import React from "react";
import styles from "../../assets/css/service-why-choose-us.module.scss";
import { Container } from "react-bootstrap";

const WhyChooseUsServices = ({ whyChooseUsService }) => {
  return (
    <>
      <section
        className={`pdtb ${styles.why_choose_us}`}
        style={whyChooseUsService.whyBg && whyChooseUsService.whyBg}
      >
        <Container>
          <div className="row justify-content-center text-center">
            <div
              className={`${whyChooseUsService.col?whyChooseUsService.col:"col-lg-9"} sub-heading ${styles.why_choose_sub_heading}`}
            >
              <h2>{whyChooseUsService.heading}</h2>
              <p>{whyChooseUsService.desc}</p>
            </div>
          </div>
          <ul className="row">
            {whyChooseUsService.list.map((list, index) => (
              <li className="col-lg-4 col-md-6 mx-auto" key={index}>
                <div className={styles.why_choose_list}>
                  <div className={styles.img_box} style={{ backgroundColor:list.imgBg ? list.imgBg :"" }}>
                    <img src={list.source} alt={list.alt} />
                  </div>
                  <h2> {list.title} </h2>
                  <p>{list.desc}</p>
                </div>
              </li>
            ))}
          </ul>
        </Container>
      </section>
    </>
  );
};
export default WhyChooseUsServices;
