import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { serviceBannerECommerceDev } from "../../assets/img";


export const ECommercePageData = {
  helmetSec: {
    title: "Custom E-commerce Development Services | Scrum Digital",
    description:
      "Grow your e-commerce business with our ecommerce development company - custom online stores, shopping carts, payment integrations and SEO. Start driving sales today!",
    keywords:
      "ecommerce development company, ecommerce development services, ecommerce website development services, custom e commerce development, ecommerce app development services, ecommerce web developer",
    conical: "/ecommerce-development-services",
  },
  bannerData:{
    heading:<>Ecommerce Development Services</>,
    desc:"Crafting digital success stories with our ecommerce development company tailored uniquely for you.",
    imgSrc:serviceBannerECommerceDev,
      alt:"eCommerce Development Services"
  },
     developmentServicesData : {
      paddingTop:"pt-0",
        heading:<>E-commerce <span className="text-uppercase">Development Services</span></>,
        desc:"Our comprehensive services cover everything from planning and development to ongoing maintenance, giving you a full-spectrum eCommerce solution that supports the growth of your online business.",
        serviceList:[
          {
            id: 1,
            title: (
              <>
                E-commerce <span>Consulting</span>
              </>
            ),
            desc: (
              <>
                To increase your productivity and improve the whole customer experience with your brand, we look deeply into operations, technology, and business.
              </>
            ),
            href: "/",
          },
          {
            id: 2,
            title: (
              <>
                Mobile E-commerce <span>Applications</span>
              </>
            ),
            desc: (
              <>
                Elevate your online store with a dedicated mobile app, offering your customers a seamless shopping experience wherever they are.
              </>
            ),
            href: "/",
          },
          {
            id: 3,
            title: (
              <>
                E-commerce Front-End <span>Development</span>
              </>
            ),
            desc: (
              <>
                We use powerful languages and frameworks like HTML, CSS, JavaScript, AngularJS, ReactJS, and Vue.js, among others, to bring e-commerce sites into existence with our front-end designs.
              </>
            ),
            href: "/",
          },
          {
            id: 4,
            title: (
              <>
                E-commerce Back-End <span>Development</span>
              </>
            ),
            desc: (
              <>
                We construct the core logic of your e-commerce application with reliable back-end programming in PHP, Java, Python, C++, Node.js, and more.
              </>
            ),
            href: "/",
          },
          {
            id: 5,
            title: (
              <>
                Ecommerce Website <span>Design</span>
              </>
            ),
            desc: (
              <>
                Our UX/UI services merge functionality with aesthetics, focusing on competitive analysis, usability testing, and core UX/UI design principles.
              </>
            ),
            href: "/",
          },
          {
            id: 6,
            title: (
              <>
                E-commerce  <span>Integrations</span>
              </>
            ),
            desc: (
              <>
                Our development process is enhanced by seamless API integrations, connecting disparate systems for smooth data flow.
              </>
            ),
            href: "/",
          },
        ]
      },
      ctaContent:{
        ctaHeading:<>Elevate your online services - <span>consult, develop, and grow with us!</span></>,
         btnName:<>Book Free Demo <i><HiOutlineArrowNarrowRight /></i></>,
      },

      normalCtaData : {
        title:<>Need Any Assistance in Generating Qualified Leads in Pandemic?</>
      },
       technologyWeWorkData : {
        heading:<><span className="text-uppercase">Technologies</span> we work with</>,
        desc:"Exploring Cutting-Edge Technologies to Shape Innovative Ecommerce Experiences."
      },
      innovatingData:{
        title:<>Innovating Just for <span>Your Niche</span></>,
        desc:"Creating Customized Innovations to Exactly Match Your Specific Industry Needs and Goals"
      },
      ourProcessData:{
        heading:<>Our <span>Process</span></>,
        desc:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, reprehenderit magnam? Debitis in, repellat aut aliquam ipsum explicabo harum illo dolor! Explicabo aut reiciendis temporibus commodi sunt labore sint tempora!",
        list:[
          {
            id:1,
            desc:"Consultation & Requirement Gathering, Market & Competitor’s Research & Goal Definition"
          },
          {
            id:2,
            desc:"Product Architecture & Design, Prototype, MVP & Wireframes"
          },
          {
            id:3,
            desc:"Functional Implementation, Software Coding, & Optimization"
          },
          {
            id:4,
            desc:"Quality Assurance, Touble-Shooting, & Testing"
          },
          {
            id:5,
            desc:"Launch, Beta Live, & Live"
          },
          {
            id:6,
            desc:"Performance Evaluation, & Analytics Implementation"
          },
          {
            id:7,
            desc:"Monitoring, Feedback, Analysis, & Complete Support"
          },
        ]
      }
}