import React from "react";
import styles from './about-service-card.module.scss'

const AboutServiceCard = ({cardData}) => {
  return (
    <div className={styles.card_about_service}>
      <div className={`${styles.card_icon}`}>
        <img src={cardData?.imgSrc} alt={cardData.alt} />
      </div>
      <h5>
        {cardData.title}
      </h5>
      <p>{cardData.desc}</p>
    </div>
  );
};

export default AboutServiceCard;
