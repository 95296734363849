
import {
  MobileDevelopmentIcn,
  EcommerceIcon,
  WebApplicationDevelopmwent,
  SalesforceIcon,
  SeoIcon,
  SemIcon,
  SmoIcon,
  OrmIcon,
  MvpIcon,
  StaffAIcon,
  ProductDevelopmentIcon,
  GrowthConsultationIcon,
} from "../../../assets/img/technology-expertise";

export const WhatWeOfferData = {
  heading: (
    <>
      Empowering Your Business with  <br /><span className="text-uppercase">Cutting-Edge IT Solutions</span>
    </>
  ),
  desc: "Dive into the future with our bespoke technology services tailored to accelerate your business growth. From strategic digital transformation to dynamic digital marketing and comprehensive startup consulting, we ensure your enterprise leverages the full potential of modern IT. Connect with us for solutions that are as innovative as they are reliable, driving your business forward in an ever-evolving digital landscape.",
  offerList: [
    {
      id: 1,
      title: (
        <>
          Digital <span>Transformation</span>
        </>
      ),
      desc: "With the ever-increasing complex technological expectations from consumers, our Digital Transformation services adapt to  deliver application and maintenance solutions to drive efficiency and effectiveness.",
      link: "digital-transformation-services",
      bottomLinkSec: [
        {
          id: 1,
          src: MobileDevelopmentIcn,
          text: "Mobile Application",
          link: "mobile-application-development",
          show: true,
        },
        {
          id: 2,
          src: WebApplicationDevelopmwent,
          text: "Web Application Development",
          link: "web-application-development-services",
          show: false,
        },
        {
          id: 3,
          src: SalesforceIcon,
          text: "Salesforce Consulting",
          link: "hire-salesforce-developers",
          show: false,
        },
        {
          id: 4,
          src: EcommerceIcon,
          text: "E-commerce Development",
          link: "ecommerce-development-services",
          show: false,
        },
      ],
    },
    {
      id: 2,
      title: (
        <>
          Digital <span>Marketing</span>
        </>
      ),
      desc: "Find the best ways to attract verified leads, promote a brand, improve performance, and revenue for your business with our sales and marketing specialists and right blend of digital marketing services from Scrum Digital.",
      link: "/digital-marketing-services",
      bottomLinkSec: [
        {
          id: 1,
          src: SeoIcon,
          text: "SEO",
          link: "/seo-services",
          show: true,
        },
        {
          id: 2,
          src: SemIcon,
          text: "SEM",
          link: "/search-engine-marketing",
          show: false,
        },
        {
          id: 3,
          src: SmoIcon,
          text: "SMO",
          link: "/social-media",
          show: false,
        },
        {
          id: 4,
          src: OrmIcon,
          text: "ORM",
          link: "/online-reputation-management",
          show: false,
        },
      ],
    },
    {
      id: 3,
      title: (
        <>
          Startup <span>Consulting</span>
        </>
      ),
      desc: "Ideation to building Minimum Viable Product and scaling up for growth, we hand hold startups to make them a success. Rely on our expertise to understand your product market, conduct specific market research, build an MVP.",
      link: "/startup-consulting-services",
      bottomLinkSec: [
        {
          id: 1,
          src: MvpIcon,
          text: "MVP",
          link: "/mvp-development-services",
          show: true,
        },
        {
          id: 2,
          src: StaffAIcon,
          text: "Staff Augmentation",
          link: "/staff-augmentation-services",
          show: false,
        },
        {
          id: 3,
          src: ProductDevelopmentIcon,
          text: "Product Development",
          link: "/product-development-services",
          show: false,
        },
        {
          id: 4,
          src: GrowthConsultationIcon,
          text: "Growth Consultation",
          link: "/growth-consultation",
          show: false,
        },
      ],
    },
  ],
};
