import React from "react";
import { Container } from "react-bootstrap";
import styles from "../../../assets/css/service-popular-platform.module.scss";
import { platformList } from "./ServicePopularPlatformData";

const ServicePopularPlatform = ({ servicePlatformData }) => {
  return (
    <div
      className={`pdtb ${styles.service_platform}`}
      style={servicePlatformData.platformBg}
    >
      <Container>
        <div className="row text-center">
          <div className="col-lg-12 sub-heading">
            <h2 className={servicePlatformData.platformBg ? "text-white":""}>
              {servicePlatformData.title}
            </h2>
          </div>
        </div>
        <ul className="row">
          {servicePlatformData.technologyList.map((elem, index) => {
            return (
              <li
                className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                key={index}
              >
                <div
                  className={`d-flex align-items-center ${styles.service_platform_list}`}
                >
                  <img src={elem.source} alt="joomla" />
                  <h5>{elem.name}</h5>
                </div>
              </li>
            );
          })}
        </ul>
      </Container>
    </div>
  );
};

export default ServicePopularPlatform;
