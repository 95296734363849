import React, { useEffect, useState } from "react";
import { getblogs } from "../../api_action/apiAction";
import { Link } from "react-router-dom";

const HeaderMegaMenuBlog = ({ service, url }) => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
      // axios.get(API_PATH).then(res => setBlogData(res.data))
      // console.log(API_PATH)

      getblogs(`/posts?page=1&per_page=2&categories=${url}`,(result)=>{
        setBlogs(result.data)
      })
    }, []);

    return (
        <div className="row justify-content-end" key={service.id}>
            {blogs?.map((blog, index) => (
              <div className="col-xxl-5 col-lg-6" key={index}>
            <Link
              to={blog?.link}
              className="blog-card ms-0 p-0"
              target="_blank"
            >
              <img
                src={blog.featured_media_src_url}
                alt="blog Image"
                className="w-100"
              />
              <div className="content-box">
                <h5
                  dangerouslySetInnerHTML={{
                    __html: blog.title.rendered,
                  }}
                ></h5>
              </div>
            </Link>
          </div>
            ))}
        </div>
    );
};

export default HeaderMegaMenuBlog;
