import React from "react";
import { Container } from "react-bootstrap";
import { deploymentProcessIcon, designPrototypeProcessIcon, developmentProcessIcon, evaluationProcessIcon, maintenanceProcessIcon, researchProcessIcon, testingProcessIcon } from "../../assets/img";
import styles from '../../assets/css/our-process-ecommerce-page.module.scss'
const ECommerceOurProcess = ({ourProcessData}) => {
    const processImg =[
        researchProcessIcon,designPrototypeProcessIcon,developmentProcessIcon,testingProcessIcon,deploymentProcessIcon,
        evaluationProcessIcon,maintenanceProcessIcon
    ];
    const processTitle =[
        "Research","Design & Prototype","Development", "Testing", "Deployment","Evaluation","Maintenance"
    ]
    return (
        <section className={`${styles.ecoomerce_process} pdtb`}>
            <Container>
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 text-center">
                        <div className="sub-heading mb-0">
                            <h2>{ourProcessData?.heading}</h2>
                            <p>{ourProcessData?.desc}</p>
                        </div>
                    </div>
                </div>
                <ul className={styles.ecmrc_prcs_lst}>
                {
                    ourProcessData?.list.map((list, index)=>(
                        <li className={styles.ecmrc_prcs_lst_itm} key={list.id}>
                            <div className={styles.ecmrc_prcs_lst_itm_img}><img src={processImg[index]} alt={processTitle[index]} /></div>
                            <div className={styles.content_sec}>
                            <h4>{processTitle[index]}</h4>
                            <p className="mb-0">{list.desc}</p>
                            </div>
                        </li>

                    ))
                }
                    {/* <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process1} alt="Research" /></div>
                        <h4>Research</h4>
                        <p className="mb-0">Consultation & Requirement Gathering, Market & Competitor’s Research & Goal Definition</p>
                    </li>
                    <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process2} alt="Design" /></div>
                        <h4>Design & Prototype</h4>
                        <p className="mb-0">Product Architecture & Design, Prototype, MVP & Wireframes</p>
                    </li>
                    <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process3} alt="Development" /></div>
                        <h4>Development</h4>
                        <p className="mb-0">Functional Implementation, Software Coding, & Optimization</p>
                    </li>
                    <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process4} alt="Testing" /></div>
                        <h4>Testing</h4>
                        <p className="mb-0">Quality Assurance, Touble-Shooting, & Testing</p>
                    </li>
                    <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process5} alt="Deployment" /></div>
                        <h4>Deployment</h4>
                        <p className="mb-0">Launch, Beta Live, & Live</p>
                    </li>
                    <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process6} alt="Evaluation" /></div>
                        <h4>Evaluation</h4>
                        <p className="mb-0">Performance Evaluation, & Analytics Implementation</p>
                    </li>
                    <li className={styles.ecmrc_prcs_lst_itm}>
                        <div className={styles.ecmrc_prcs_lst_itm_img}><img src={Process7} alt="Maintenance" /></div>
                        <h4>Maintenance</h4>
                        <p className="mb-0">Monitoring, Feedback, Analysis, & Complete Support</p>
                    </li> */}
                </ul>
            </Container>
        </section>
    )
}


export default ECommerceOurProcess