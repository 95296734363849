// import {} from "../../assets/img";
import { Link } from "react-router-dom";
import styles from "../../assets/css/salesforse/salesforce-cta.module.scss";
import { Container } from "react-bootstrap";
import Modal_form from "../Banner/Modal";
const SalesforceCta = () => {
  return (
    <section className={`${styles.looking_tech}`}>
      <Container>
        <div className="row align-items-end">
          <div className="col-lg-9">
            <div className={`${styles.panner} mt-0`}>
              <div className="sub-heading">
                <h2 className="text-white">
                  Looking For a <span> technology </span> partner?
                </h2>
              </div>
              <p className="mb-3">
                We’re here to help you with the best solutions. Send us your
                query
              </p>
              <p className="mb-0">
                USA: <Link to="tel:+1 303 800 6089">+1 303 800 6089</Link>,
                INDIA:<Link to="tel: +91 141 403 6138"> +91 141 403 6138</Link>,
                <Link to="tel:+91 637 658 5239">+91 637 658 5239</Link>
              </p>
              <p className="mb-0">
                <Link to="mailto:contact@scrumdigital.com">
                  Email ID: contact@scrumdigital.com
                </Link>
                |{" "}
                <Link to="skype:sales@scrumdigital.com?chat">
                  Skype ID: scrum.digital
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className={`${styles.panner} text-end`}>
              {/* <button className="theme-btn">Contact Now!</button> */}
              <Modal_form
              btnName={"Contact Now!"}
          />
            </div>

          </div>

        </div>
      </Container>
    </section>
  );
};
export default SalesforceCta;
