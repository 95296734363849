import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from '../../assets/css/chatbot-benefits.module.scss';
import { FaRegHandPointRight } from "react-icons/fa";


const ChatbotBenefits = () => {
    return (
        <section className={`pdtb ${styles.chatbot_benefits}`}>
            <Container className="container-lg">
                <Row>
                    <Col lg={6} >
                        <div className="sub-heading-outline">
                            <h2>
                                <span className="text-uppercase">Benefits of Chatbot</span> Development
                            </h2>
                            <p>
                                Implementing chatbots can revolutionize your business operations, enhance customer engagement, and boost efficiency while delivering unparalleled user experiences.
                            </p>
                        </div>
                        <div className={styles.chatbot_benefits_list}>
                            <i><FaRegHandPointRight /></i>
                            <h5>Elevating User Interaction</h5>
                            <p>Chatbots revolutionize engagement by leveraging generative AI to offer instant responses and interactive conversations. They make interactions lively and personalized, keeping users engaged and satisfied with real-time assistance and dynamic conversations.</p>
                        </div>
                        <div className={`mb-0 ${styles.chatbot_benefits_list}`}>
                            <i><FaRegHandPointRight /></i>
                            <h5>24/7 Assistance</h5>
                            <p>They provide uninterrupted service, ensuring users have access to information and support anytime, enhancing user experience and reliability.</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={styles.chatbot_benefits_right_sec}>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>Insights and Learning</h5>
                                <p>These virtual assistants are invaluable for gathering user data and insights. They analyze interactions to understand user needs better, helping in tailoring services and improving user experiences.</p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>Gaining a Competitive Edge</h5>
                                <p>Implementing chatbots can set a business apart, offering innovative and efficient customer service. This technological edge can significantly enhance customer satisfaction and loyalty</p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>Enhancing Efficiency and Savings</h5>
                                <p>Chatbots streamline operations, automating routine tasks and handling multiple queries simultaneously, which reduces workload on human staff and cuts operational costs.</p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>Improved Scalability</h5>
                                <p>Chatbots adapt effortlessly to handle growing customer interactions, ensuring your business scales smoothly without compromising service quality.</p>
                            </div>
                            <div className={`mb-0 ${styles.chatbot_benefits_list}`}>
                                <i><FaRegHandPointRight /></i>
                                <h5>Enhanced Customer Engagement</h5>
                                <p>By providing instant, personalized support, chatbot platforms foster deeper connections with the audience, increasing customer loyalty and brand affinity.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default ChatbotBenefits;