import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/our-blog-sec.module.scss";
import { Container } from "react-bootstrap";
import {Link} from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Slider from "react-slick";
import { getblogs } from "../../../api_action/apiAction";
import { blogApi } from "../../../config/config";
const slider2ResponsiveSettings = [
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 2,
      centerMode: false
    },

  },
  {
    breakpoint: 575,
    settings: {
      slidesToShow: 1,
      centerMode: false
    },

  },
];
const OurBlogSec = () => {
  const slider = React.useRef(null);

  const handleClick = () => {
    window.open("https://scrumdigital.com/blog/", "_blank");
  };
  const [blogData, setBlogData] = useState([])

  const API_PATH = `${blogApi}/posts?page=1&per_page=5&_embed/getblogs`;



  useEffect(() => {
    // axios.get(API_PATH).then(res => setBlogData(res.data))
    // console.log(API_PATH)

    getblogs("/posts?page=1&per_page=5&_embed/getblogs",(result)=>{
      setBlogData(result.data)
    })
  }, []);



  return (
    <>
      <section className={`pdtb ${styles.blog_sec}`}>
        <Container>
          <div className="row align-items-end pb-0 pb-lg-5">
            <div className="col-lg-9">
              <div className="sub-heading text-center text-lg-start mb-0">
                <h2>Our Blog - Latest tech <span className="text-uppercase">insights</span></h2>
                <p className="mb-0">
                  The tech world is evolving every passing day. Explore the
                  latest tech trends and new-age technology through these
                  informational blogs on trends and
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-end d-none d-lg-block">
              <button
                className={`theme-btn ${styles.theme_btn}`}
                onClick={handleClick}
              >
                View all blog
                <i>
                  <HiOutlineArrowNarrowRight />
                </i>
              </button>
            </div>
          </div>
          <ul className={`justify-content-center blog-slider ${styles.blog_lst}`}>
              <Slider
                ref={slider}
                arrows={false}
                slidesToShow={3}
                slidesToScroll={1}
                swipeToSlide={true}
                focusOnSelect={true}
                responsive={slider2ResponsiveSettings}
                autoplay={true}
                autoplaySpeed={3000}
                speed={1000}
              >
                {/* {blogData.map(
                  (blog, index) =>
                  (
                    <li key={index}>
                      <Link to={blog.href} className={styles.blog_list_img}>
                        <img src={blog.imgSrc} alt={blog.heading} />
                      </Link>
                      <div className={styles.content}>
                        <h5>
                          <Link href={blog.href}>{blog.heading}</Link>
                        </h5>
                        <p>{blog.desc}</p>
                      </div>
                    </li>
                  )
                )} */}
                {
                  blogData?.map((list, i) => (
                      <li key={i}>
                        <Link to={list.link} className={styles.blog_list_img}><img src={list.yoast_head_json.og_image[0].url} alt={list.slug}  /></Link>
                        <div className={styles.content}>
                          <Link to={list.link} ><h5 dangerouslySetInnerHTML={{ __html: list.title.rendered }} /></Link>
                          <p dangerouslySetInnerHTML={{ __html: list.excerpt.rendered }} />
                        </div>
                      </li>
                  ))
                }
              </Slider>
          </ul>
          <div className="text-center mt-5 d-block d-lg-none">
            <button
              className={`theme-btn ${styles.theme_btn}`}
              onClick={handleClick}
            >
              View all blog{" "}
              <i>
                <HiOutlineArrowNarrowRight />
              </i>
            </button>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurBlogSec;
