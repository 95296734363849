import HeadMetaSec from "../../components/HeadMetaSec";
import SaleBanner from "../../components/Salesforce/SaleBanner";
import Saleslidercertified from "../../components/Salesforce/SalesforceCertified/SalesforceCertifiedSlider";
import HireSalesDeveloper from "../../components/Salesforce/HireSalesDeveloper";
import SalesforseServiceSection from "../../components/Salesforce/SalesforceServices/SalesforceServices";
import ProcessOfHiring from "../../components/Salesforce/ProcessOfHiring/ProcessOfHiring";
import SalesforceCta from "../../components/Salesforce/SalesforceCta";
import ScrumSalesforce from "../../components/Salesforce/ScrumSalesforce";
import { SalesforceData } from "../../components/jsonData/SalesforceData";

function Salesforce() {
  return (
    <>
      <HeadMetaSec HeadSecData={SalesforceData.helmetSec} />
      <SaleBanner />
      <Saleslidercertified />
      <HireSalesDeveloper
        HireSalesDeveloperData={SalesforceData.HireSalesDeveloperData}
      />
      <SalesforseServiceSection />
      <ProcessOfHiring />
      <SalesforceCta />
      <ScrumSalesforce />
    </>
  );
}

export default Salesforce;
