import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import styles from './web-app-security.module.scss'
import Modal_form from "../Banner/Modal";

const WebAppSecurity = () =>{
    const btnName =<>Work With Us <span><FaChevronRight /></span></>
    return(
        <section className={`pdtb ${styles.web_app_security}`}>
        <Container>
            <Row>
                <Col lg={10} className="mx-auto">
                    <div className={`sub-heading text-center ${styles.sub_heading}`}>
                        <h2>Advanced <span>Web Security</span> Measures</h2>
                        <p>
                        We incorporate robust security procedures throughout every stage of the software development lifecycle.
                        </p>
                    </div>
                </Col>
            </Row>
             <Row className={`justify-content-center ${styles.security_list}`}>
                <Col lg={4} md={6}>
                    <div className={styles.security_item}>
                        <h3>Customer <span>Security</span></h3>
                        <ul>
                            <li>Customized Strategy</li>
                            <li>Guaranteed NDA and SLA</li>
                            <li>Policies on Intellectual Property</li>
                        </ul>
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={styles.security_item}>
                        <h3>Data <span>Security</span></h3>
                        <ul>
                            <li>Compliance with Regulations</li>
                            <li>Encryption of Data</li>
                            <li>Following Security Standards and Protocols</li>
                        </ul>
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={styles.security_item}>
                        <h3>App <span>Security</span></h3>
                        <ul>
                            <li>Testing Automation</li>
                            <li>Modeling of Threats</li>
                            <li>Blocking of Attacks</li>
                        </ul>
                    </div>
                </Col>
             </Row>
             <div className="text-center">
                    <Modal_form btnName={btnName} />
             </div>
        </Container>

        </section>
    )
}


export default WebAppSecurity