import React from "react";
import { Link } from "react-router-dom";
import styles from "./../../../assets/css/ContactUs.module.scss";

const OfficeAddressList = ({ officeAddressData }) => {
  return (
    <>
      {officeAddressData.map((address, i) => (
        <li className="col-lg-5 col-md-6" key={i}>
          <div className={`${styles.office_address_list}`}>
            <div className={styles.img_box}>
              <img src={address.countryImg} alt={address.alt} className="img-fluid" />
            </div>
            <ul>
              {address.add.map((elem, index) => {
                return (
                  <li className={styles.content} key={index}>
                    <div className={`${styles.logo}`}>
                      <img src={elem.source} alt={elem.imgAlt} />
                    </div>
                    <div>
                      {elem.title && <h5>{elem.title}</h5>}
                      {elem.para && <p>{elem.para}</p>}
                      {elem.arr &&
                        elem.arr.map((e, i) => {
                          return (
                            <Link key={i} to={e.value}>
                              {e.link}
                            </Link>
                          );
                        })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
      ))}
    </>
  );
};

export default OfficeAddressList;
