import React from "react";
import styles from './features-app-needs.module.scss'
import { Col, Container, Row } from "react-bootstrap";
import AboutServiceCard from "../services/AboutServiceCard";
import { Affordable, Agile, Longevity, Team, eCommerceAppIcon, educationalAppIcon, entertainmentAppIcon, foodDeliveryAppIcon, gamingAppIcon, healthCareAppIcon, lifeStyleAppIcon, travelingAppIcon } from "../../assets/img";
import { Link } from "react-router-dom";


const FeaturesAppNeeds = () =>{
    const aboutServiceCardData = [
        {
          id:1,
          imgSrc:gamingAppIcon,
          alt:"gaming app",
          title:<>Gaming Apps</>,
          desc:"Every click, a victory."
        },
        {
          id:2,
          imgSrc:foodDeliveryAppIcon,
          alt:"food delivery",
          title:<>Food Delivery Apps</>,
          desc:"Hunger meets its match."
        },
        {
          id:3,
          imgSrc:educationalAppIcon,
          alt:"educational app",
          title:<>Educational Apps</>,
          desc:"Knowledge at your fingertips."
        },
        {
          id:4,
          imgSrc:healthCareAppIcon,
          alt:"health care app",
          title:<>Healthcare Apps</>,
          desc:"Wellness in your hands."
        },
        {
          id:5,
          imgSrc:eCommerceAppIcon,
          alt:"e-commerce app",
          title:<>E-commerce Apps</>,
          desc:"Shopping made effortless."
        },
        {
          id:6,
          imgSrc:travelingAppIcon,
          alt:"travel app",
          title:<>Travel Apps</>,
          desc:"Adventure awaits you."
        },
        {
          id:7,
          imgSrc:lifeStyleAppIcon,
          alt:"lifestyle app",
          title:<>Lifestyle Apps</>,
          desc:"Life, beautifully curated."
        },
        {
          id:8,
          imgSrc:entertainmentAppIcon,
          alt:"Entertainment Apps",
          title:<>Entertainment Apps</>,
          desc:"Endless fun awaits."
        },
      ]
    return(
        <section className={`pdtb ${styles.features_need}`}>
        <Container>
            <Row>
            <Col lg={10} className="mx-auto">
              <div className="sub-heading text-center">
                <h2>Revolutionize Your <span>Digital Experience</span></h2>
                <p>
                  Enhance your daily experiences with our wide array of apps,
                  from thrilling gaming innovations to smooth shopping journeys,
                  bringing ease and happiness straight to you.
                 </p>
              </div>
            </Col>
            </Row>
            <div className={`d-flex flex-wrap ${styles.features_need_list}`}>
            {/* {
                  aboutServiceCardData.map((list)=><AboutServiceCard key={list.id} cardData ={list}/>)
            } */}
            {
                  aboutServiceCardData.map((list)=>(
                    <div className={styles.card_about_service} key={list.id}>
                        <div className={`${styles.card_icon}`}>
                          <img src={list?.imgSrc} alt={list.alt} />
                        </div>
                        <h5>
                          {list.title}
                        </h5>
                        <p>{list.desc}</p>
                      </div>
                  ))
            }
            </div>
        </Container>

        </section>
    )
}

export default FeaturesAppNeeds