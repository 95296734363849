import { Link } from "react-router-dom";
import ContMail from "./ContMail";
import assets from "./../../assets/css/ContactUs.module.scss";
import { skype_icon, mail_bottom } from "./../../assets/img";
const ContactMail = () => {
  return (
    <section className={`${assets.contact_mail}`}>
      <div className="container">
        <ul>
          <li>
            <ContMail
              href="mailto:contact@scrumdigital.com"
              img_mail={skype_icon}
              touch_with="Get in touch with us"
              Contes_mail="contact@scrumdigital.com"
            />
          </li>
          <li>
            <ContMail
              href="skype:sales@scrumdigital.com?chat"
              img_mail={mail_bottom}
              touch_with="Our Skype ID"
              Contes_mail="sales@scrumdigital.com"
            />
          </li>
        </ul>
      </div>
    </section>
  );
};
export default ContactMail;
