import React from "react";
import styles from "./web-dev-why-choose-us.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { HmgAboutCalendar, HmgAboutImg, brandVisibility, customization, enhancedLeads, innovativeSolutions, scalability, whyChooseUsImg } from "../../assets/img";

const WebDevWhyChooseUs = () => {
  return (
    <section className={`pdtb ${styles.why_choose_us}`}>
      <Container>
        <Row>
          <Col lg={5} md={8} sm={8} xs={10} className={`${styles.why_choose_us_left_img} mx-auto`}>
            <img src={whyChooseUsImg} alt="why choose us website development" className="img-fluid" />
          </Col>
          <Col lg={7} className={styles.why_choose_us_right}>
            <div className="sub-heading">
              <h2>
                What Sets Us <span>Apart?</span>
              </h2>
              <p>
                At Scrum Digital, we place a high value on careful planning and
                diligent administration to guarantee the timely completion of
                complex website development projects.
              </p>
            </div>
            <ul>
              <li>We guarantee timely and effective project execution.</li>
              <li>Specialist in creating custom websites.</li>
              <li>We offer competitive pricing.</li>
              <li>We prioritize excellent customer service.</li>
            </ul>
            <div className={styles.right_img_sec}>
              <div className={styles.right_img_sec_list}>
                <div className={`${styles.hmg_about_list_item}`}>
                  <span className={`${styles.hmg_about_list_item_img}`}>
                    <img src={customization} alt="Customization" />
                  </span>
                  <h3>Customization</h3>
                  {/* <p className="mb-0">Lorem ipsum dolor sit</p> */}
                </div>
              </div>
              <div className={styles.right_img_sec_list}>
                <div className={`${styles.hmg_about_list_item}`}>
                  <span className={`${styles.hmg_about_list_item_img}`}>
                    <img src={scalability} alt="Scalability" />
                  </span>
                  <h3>Scalability</h3>
                  {/* <p className="mb-0">Lorem ipsum dolor sit</p> */}
                </div>
              </div>
              <div className={styles.right_img_sec_list}>
                <div className={`${styles.hmg_about_list_item}`}>
                  <span className={`${styles.hmg_about_list_item_img}`}>
                    <img src={brandVisibility} alt="Brand Visibility" />
                  </span>
                  <h3>Brand Visibility</h3>
                  {/* <p className="mb-0">Lorem ipsum dolor sit</p> */}
                </div>
              </div>
              <div className={styles.right_img_sec_list}>
                <div className={`${styles.hmg_about_list_item}`}>
                  <span className={`${styles.hmg_about_list_item_img}`}>
                    <img src={innovativeSolutions} alt="Innovative Solutions" />
                  </span>
                  <h3>Innovative Solutions</h3>
                  {/* <p className="mb-0">Lorem ipsum dolor sit</p> */}
                </div>
              </div>
              <div className={styles.right_img_sec_list}>
                <div className={`${styles.hmg_about_list_item}`}>
                  <span className={`${styles.hmg_about_list_item_img}`}>
                    <img src={enhancedLeads} alt="Enhanced Leads" />
                  </span>
                  <h3>Enhanced Leads</h3>
                  {/* <p className="mb-0">Lorem ipsum dolor sit</p> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <ul>
                    <li>
                      <div className={`${styles.hmg_about_list_item}`}>
                        <span className={`${styles.hmg_about_list_item_img}`}>
                          <img src={HmgAboutCalendar} alt="pp" />
                        </span>
                        <h3>98%</h3>
                        <p className="mb-0">Lorem ipsum dolor sit</p>
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.hmg_about_list_item}`}>
                        <span className={`${styles.hmg_about_list_item_img}`}>
                          <img src={HmgAboutCalendar} alt="pp" />
                        </span>
                        <h3>4400+</h3>
                        <p className="mb-0">Lorem ipsum dolor sit</p>
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.hmg_about_list_item}`}>
                        <span className={`${styles.hmg_about_list_item_img}`}>
                          <img src={HmgAboutCalendar} alt="pp" />
                        </span>
                        <h3>1000+</h3>
                        <p className="mb-0">Lorem ipsum dolor sit</p>
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.hmg_about_list_item}`}>
                        <span className={`${styles.hmg_about_list_item_img}`}>
                          <img src={HmgAboutCalendar} alt="pp" />
                        </span>
                        <h3>200+</h3>
                        <p className="mb-0">Lorem ipsum dolor sit</p>
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.hmg_about_list_item}`}>
                        <span className={`${styles.hmg_about_list_item_img}`}>
                          <img src={HmgAboutCalendar} alt="pp" />
                        </span>
                        <h3>13+</h3>
                        <p className="mb-0">Lorem ipsum dolor sit</p>
                      </div>
                    </li>
                </ul> */}
      </Container>
    </section>
  );
};

export default WebDevWhyChooseUs;
