import {
  ServiceCricket,
  ServiceFootball,
  ServiceKabaddi,
  ServiceMultiSports,
} from "../../../assets/img";

export const serviceList = [
  {
    id: 1,
    title: (
      <>
        Fantasy <span>Cricket</span> App Development
      </>
    ),
    description:
      "With our skilled team of developers specializing in Fantasy Cricket App Development, we have extensive experience in crafting apps tailored for cricket enthusiasts. Our skilled team of fantasy app developers is well-versed in the latest industry trends, including dream11 app development, ensuring that your app stands out in the competitive market.",
    imgSrc: ServiceCricket,
    alt: "cricket",
  },
  {
    id: 2,
    title: (
      <>
        Fantasy <span>Kabaddi </span> App Development
      </>
    ),
    description:
      "Elevate the excitement of kabaddi with our cutting-edge app development services. Our fantasy app development solutions are tailored to create engaging and user-friendly kabaddi apps that captivate users. Our Kabaddi Fantasy Sports Platforms are designed to seamlessly handle high user volumes with delivering real-time updates on players' performances.",
    imgSrc: ServiceKabaddi,
    alt: "kabaddi",
  },
  {
    id: 3,
    title: (
      <>
        Fantasy <span>Football</span> App Development
      </>
    ),
    description:
      "Score big with our top-notch fantasy football app development services. We excel in creating Fantasy Football Apps that keep users engaged with actions. Our skilled team of fantasy sports developers is well-versed in the latest trends in fantasy football app development, ensuring that your app is built with robust security and high efficiency.",
    imgSrc: ServiceFootball,
    alt: "football",
  },
  {
    id: 4,
    title: (
      <>
        Fantasy <span>Multi-Sports</span> App Development
      </>
    ),
    description:
      "Experience the ultimate thrill of multiple sports with our expert multi-sports app development services. Our fantasy app developers are skilled in creating comprehensive and versatile apps that cater to a wide range of sports, including basketball, American football, and more. As a trusted fantasy app development company, we ensure that your multi-sports app delivers a seamless and engaging user experience.",
    imgSrc: ServiceMultiSports,
    alt: "multiSports",
  },
];
