import React, { useEffect } from "react";
import DeliveryServiceBanner from "../../components/DeliveryServices/DeliveryServiceBanner";
import TrustedBy from "../../components/DeliveryServices/TrustedBy/TrustedBy";
import SellYourBusiness from "../../components/DeliveryServices/SellYourBusiness/SellYourBusiness";
import LastTenYear from "../../components/DeliveryServices/LastTenYear/LastTenYear";
import FeatureOfOnline from "../../components/DeliveryServices/FeatureOfOnline/FeatureOfOnline";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import AdvanceFeatureOnlineRestaurant from "../../components/DeliveryServices/FeatureOnlineRestaurant/FeatureOnlineRestaurant";
import MobileScreenSlider from "../../components/DeliveryServices/MobileScreenSlider/MobileScreenSlider";
import FAQ from "../../components/services/FAQ";
import { OnDemandAppData } from "../../components/jsonData/OnDemandAppServiceData";
import HeadMetaSec from "../../components/HeadMetaSec";

const DeliveryAppDevelopmentCompany = () => {
  useEffect(() => {
    const deliveryHeader = document.querySelector(".th-header");
    deliveryHeader.classList.add("delivery-header");
    return () => deliveryHeader.classList.remove("delivery-header");
  }, []);
  const ctaContent={
    ctaHeading:<>From concept to code, <span>we've got you covered.</span></>,
     btnName:"Begin Your App Adventure!"
  }
  return (
    <>
      <HeadMetaSec HeadSecData={OnDemandAppData.helmetSec} />
      <DeliveryServiceBanner
        DeliveryServiceBannerData={OnDemandAppData.bannerSec}
      />
      <TrustedBy />
      <SellYourBusiness />
      <LastTenYear />
      <FeatureOfOnline />
      <OnDemandFoodCta {...ctaContent}/>
      <AdvanceFeatureOnlineRestaurant
        onlineRestaurant={OnDemandAppData.onlineRestaurantSec}
      />
      <MobileScreenSlider
        mobileSliderData={OnDemandAppData.MobileScreenSliderSec}
      />
      <FAQ faqData={OnDemandAppData.faqSec} />
    </>
  );
};

export default DeliveryAppDevelopmentCompany;
