function TextErrorMsg(props) {
  const errorStyle = {
    fontSize: "13px",
    color: "#ff0000",
    marginTop: "3px",
  };
  return (
    <small className="text-danger" style={errorStyle}>
      {props.children}
    </small>
  );
}

export default TextErrorMsg;
