import { SwiftImg, applicationDevelopmentBanner, serviceBannerWebsiteDev } from "../../assets/img";


export const WebsiteDevServicesData  = {

    helmetSec: {
        title: "Custom Website Development Services -  Scrum Digital",
        description:
          "Our website development company offers tailored web solutions that grow your visibility, reach & directly increases sales. Click for a free consultation!",
        keywords:
          "Website Development Services, Website Development Company, Custom Website Development Services, Website Development Solutions",
        conical: "/website-development-company",
      },

    bannerData: {
        bannerHeading: (
          <>
           Website Development Services
          </>
        ),
        banner_bott_head:
          "We build and create high-quality websites that give value to customers and offer engaging user experiences.",
        // bannerImg: applicationDevelopmentBanner,
        bannerImg: serviceBannerWebsiteDev,
        alt: "website development services",
      },
       ctaContent:{
        ctaHeading:<>From concept to code, <span>we've got you covered.</span></>,
         btnName:"Begin Your App Adventure!"
      },
       ourProcessData:{
        heading:<>How We Develop <span>Websites?</span></>,
        desc:"We focus on working closely with our clients and streamlining our development process to deliver top-notch solutions.",
        list:[
          {
            id:1,
            desc:"Exploring market trends and coordinating with your company's objectives."
          },
          {
            id:2,
            desc:"Developing interactive prototypes that improve the user experience."
          },
          {
            id:3,
            desc:"Building robust websites with key features, maintaining code quality."
          },
          {
            id:4,
            desc:"Conducting in-depth tests for usability and cross-browser compatibility."
          },
          {
            id:5,
            desc:"Rolling out your website smoothly to engage and captivate your audience."
          },
          {
            id:6,
            desc:"Analyzing the performance of the website and tweaking our strategies."
          },
          {
            id:7,
            desc:"Regular updates and performance tuning based on user feedback."
          },
        ]
      },
      technologyData : {
        darkTheme:true,
        title:<>Developing Tech <span className="d-inline d-lg-block">Innovations for the Future</span></>,
        desc:"Explore our all-inclusive website development hub covering a wide range of technology platforms. Take an in depth look into the complex network of platforms and resources that serves as the basis for our creative solutions.",
        technologyList: [
            {
                id:1,
                eventKey:"ReactJS",
                tabBtnTitle:"ReactJS",
                tabContent:{
                    title:<>ReactJS</>,
                    desc:"We utilize React for dynamic interfaces and efficient, consistent component reuse in web development.",
                    serviceList:[
                        "Dynamic, responsive web interfaces.",
                        "Reusable components for consistency.",
                        "Strong community, reliable solutions.",
                        "Efficient development cycle.",
                        "Cutting-edge user experiences."
                    ],
                    // technologyList:[
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    // ]
                },

            },
            {
                id:2,
                eventKey:"Flutter",
                tabBtnTitle:"Flutter",
                tabContent:{
                    title:<>Flutter</>,
                    desc:"We use Flutter to create flexible native built apps with smooth cross-platform UI consistency.",
                    serviceList:[
                        "Cross-platform development with native performance.",
                        "Unified UI consistency across all devices.",
                        "Expansive widget library for intuitive interfaces.",
                        "Rapid development with hot reload feature.",
                        "Engaging user experiences with rich animations."
                    ],
                    // technologyList:[
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    // ]
                },

            },
            {
                id:3,
                eventKey:"JavaScript",
                tabBtnTitle:"JavaScript",
                tabContent:{
                    title:<>JavaScript</>,
                    desc:"Our interactive websites and complex online applications are made possible by JavaScript's versatility and widespread use.",
                    serviceList:[
                        "Interactive, dynamic web content creation.",
                        "Broad compatibility across all web browsers.",
                        "Large ecosystem with extensive libraries and frameworks.",
                        "Asynchronous programming for responsive applications.",
                        "Versatile use cases, from front-end to back-end."
                    ],
                    // technologyList:[
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    // ]
                },

            },
            {
                id:4,
                eventKey:"Angular",
                tabBtnTitle:"Angular",
                tabContent:{
                    title:<>Angular</>,
                    desc:"Angular empowers our company to build sophisticated and interactive websites and applications, leveraging its robust framework and comprehensive features.",
                    serviceList:[
                        "Dynamic UI with data binding.",
                        "Seamless cross-browser compatibility.",
                        "Extensive development libraries.",
                        "Responsive with asynchronous support.",
                        "Versatile full-stack framework."
                    ],
                    // technologyList:[
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    //     {
                    //         imgSrc:SwiftImg,
                    //         alt:"service",
                    //         name:"Kotlin"
                    //     },
                    // ]
                },

            },
        ]
    },

      // technologyWeWorkData : {
      //   darkTheme:true,
      //   heading:<><span className="text-uppercase">Technologies</span> we work with</>,
      //   desc:"Exploring Cutting-Edge Technologies to Shape Innovative Ecommerce Experiences.",
      // },
       normalCtaData : {
        title:<>Shape The Digital <span>Future With Us.</span></>
      }


}