import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BannerAbout from "../../components/Banner/BannerAbout";
import HeadMetaSec from "../../components/HeadMetaSec";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import OurBlogSec from "../../components/Home/OurBlogSec/OurBlogSec";
import OurClientSec from "../../components/Home/OurClientsSec";
import ProjectsHelpedOurClients from "../../components/Home/ProjectsHelpedOurClients";
import { servicePageData } from "../../components/jsonData/ServicePageData";
import AboutService from "../../components/services/AboutService";
import CloudPlatformPartners from "../../components/services/CloudPlatformPartners";
import CompanyProfileCounter from "../../components/services/ComponyProfileCounter";
import EnhancedMobilitySolution from "../../components/services/EnhancedMobilitySolutions";
import FAQ from "../../components/services/FAQ";
import ImgContentSec from "../../components/services/ImgContentSec";
// import IndustriesWeServe from "../../components/services/IndustryWeServe/IndustriesWeServe";
import ServicePopularPlatform from "../../components/services/ServicePopularPlatform/ServicePopularPlatform";
import ServicesOfTechnology from "../../components/services/ServicesOfTechnology";
import TechnologiesForCms from "../../components/services/TechnologiesForCms";
import WhyChooseUsServices from "../../components/services/WhyChooseUsService";
import Home from "./Home";
import InnovatingJust from "../../components/Home/InnovatingJust/InnovatingJust";
import MobileApplicatioServices from "../../components/MAD/MobileApplicatioServices";
import NotFoundPage from "./NotFoundPage";

const ServicePage = () => {
  const [cmsTechnology, setCmsTechnology] = useState(false);
  const [cloudPlatformPartners, setCloudPlatformPartners] = useState(false);
  const [mobileAppDevService, setMobileAppDevService] = useState(false)
  const params = useParams();
  const { name } = params || {};
  const serviceData = servicePageData.find((elem) => elem.link === name);
  const location = useLocation();


  const bg = {
    backgroundColor: "#fff",
  };
  const bg1 = {
    backgroundColor: "#f5f5f5",
  };

  useEffect(() => {
    if (location.pathname === "/cms-development-services") {
      setCmsTechnology(true);
    } else {
      setCmsTechnology(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/cloud-computing-solutions") {
      setCloudPlatformPartners(true);
    } else {
      setCloudPlatformPartners(false);
    }
    // if (location.pathname === "/mobile-application-development") {
    //   setMobileAppDevService(true);
    // } else {
    //   setMobileAppDevService(false);
    // }
  }, [location.pathname]);

  const ctaContent={
    ctaHeading:<>From concept to code, <span>we've got you covered.</span></>,
     btnName:"Begin Your App Adventure!"
  }
  const innovatingData = {
    title:<>Industries <span className="text-uppercase">We Serve</span></>,
    desc:<>The breakneck pace at which digital transformation is taking
    place is not a surprise. We design solutions depending on a
    company’s size, products, internal workflow, customer base, and
    much more.</>
  }

  return (
    <>
      {serviceData ? (
        <>
          <HeadMetaSec HeadSecData={serviceData.helmetSec} />
          {serviceData.bannerData && (
            <BannerAbout bannerData={serviceData.bannerData} />
          )}
          {/* {
            mobileAppDevService && <MobileApplicatioServices/>
          } */}
          {
            serviceData.aboutSec && <AboutService aboutServiceSec={serviceData.aboutSec} />
          }
          {serviceData.imgContentSec1 && (
            <ImgContentSec imgContentSec={serviceData.imgContentSec1} />
          )}
          {serviceData.companyProfileCounterSec && (
            <CompanyProfileCounter
              companyProfileCounterSec={serviceData.companyProfileCounterSec}
            />
          )}
          <ServicesOfTechnology services={serviceData.serviceSec} />
          {/* <IndustriesWeServe /> */}
          <InnovatingJust headingData={innovatingData} />
          {serviceData.imgContentSec2 && (
            <ImgContentSec imgContentSec={serviceData.imgContentSec2} />
          )}
          {serviceData.enhanceMobileData && (
            <EnhancedMobilitySolution
              enhanceMobilityData={serviceData.enhanceMobileData}
            />
          )}

          {cmsTechnology && <TechnologiesForCms />}
          {serviceData.servicePlatformData && (
            <ServicePopularPlatform
              servicePlatformData={serviceData.servicePlatformData}
            />
          )}
          {serviceData.whyChooseUs && (
            <WhyChooseUsServices whyChooseUsService={serviceData.whyChooseUs} />
          )}
          {serviceData.whyChooseUs1 && (
            <WhyChooseUsServices
              whyChooseUsService={serviceData.whyChooseUs1}
            />
          )}
          {cloudPlatformPartners && <CloudPlatformPartners />}
          <FAQ faqData={serviceData.FAQSec} />
          <OnDemandFoodCta {...ctaContent}/>
          <ProjectsHelpedOurClients
            Bg={serviceData.FAQSec.faqBg ?  bg1 :bg}
          />
          <OurClientSec />
          <OurBlogSec />
        </>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default ServicePage;
