import React from "react";
import styles from './mobile-app-platform.module.scss'
import { mobileAppDevIosService, nativeAppDevelopmentPfIcon, progressiveWebAppDevelopmentPfIcon, wearablesSoftwareDevelopmentPfIcon } from "../../assets/img";


const MobileAppPlatform = () => {
    return(
        <section className={`pdtb ${styles.mbl_service_pltfrm}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="sub-heading text-center">
                <h2>Diverse Mobile App Development Solutions</h2>
                <p className="text-white">
                Our team of skilled mobile application developers brings over years
                of experience in crafting solutions tailored to meet market demands,
                elevate your brand's presence, and drive business growth and development.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={`${styles.mbl_service_pltfrm_item}`}>
                <img src={mobileAppDevIosService} alt="react native" />
                <h4>React Native App Development</h4>
                <p>
                    Leveraging the powerful React Native framework, we specialize in building high-performance,
                    cross-platform applications that deliver a native-like user experience.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={`${styles.mbl_service_pltfrm_item}`}>
                <img src={nativeAppDevelopmentPfIcon} alt="native app development" />
                <h4>Native Mobile App Development</h4>
                <p>
                Our developers are skilled at using their experience to create high-end native
                applications that satisfy your unique security requirements and business goals
                for both the iOS and Android platforms.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={`${styles.mbl_service_pltfrm_item}`}>
                <img src={progressiveWebAppDevelopmentPfIcon} alt="progressive web app development" />
                <h4>Progressive Web App Development</h4>
                <p>
                We provide functionalities similar to native apps and the ability to install, ensuring accessibility for everyone, everywhere, on any device, using a single codebase.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={`${styles.mbl_service_pltfrm_item}`}>
                <img src={wearablesSoftwareDevelopmentPfIcon} alt="Wearables and Embedded Software Development" />
                <h4>Wearables and Embedded Software Development</h4>
                <p>
                Our services extend to developing companion applications for a variety of wearable technologies, as well as integrating with smart devices or custom peripherals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default MobileAppPlatform