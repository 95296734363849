import {
  usa,
  india_image,
  us_flag,
  ind_flag,
  location_pin,
  phone,
  mail,
} from "../../../assets/img";

export const OfficeAddressData = [
  {
    id: 1,
    countryImg: usa,
    alt:"usa contact us page",
    add: [
      {
        id: 1,
        source: us_flag,
        imgAlt: "usFlag",
        title: "United States",
      },
      {
        id: 2,
        source: location_pin,
        imgAlt: "location",
        title: "Scrum Digital Inc",
        para: "447 Broadway, 2nd Floor Suite #2057, New York, New York 10013, United States",
      },
      {
        id: 3,
        source: phone,
        imgAlt: "phone",
        arr: [
          {
            value: "tel:+1 303 800 6089",
            link: "+1 303 800 6089",
          },
        ],
      },
      {
        id: 4,
        source: mail,
        imgAlt: "mail",
        arr: [
          {
            value: "mailto:contact@scrumdigital.com",
            link: "contact@scrumdigital.com",
          },
        ],
      },
    ],
  },
  {
    countryImg: india_image,
    alt:"india contact us page",
    add: [
      {
        id: 1,
        source: ind_flag,
        imgAlt: "usFlag",
        title: "INDIA",
      },
      {
        id: 2,
        source: location_pin,
        imgAlt: "location",
        title: "Scrum Digital Pvt Ltd",
        para: "53/202, Padmani VT Rd, Mansarovar, Jaipur, Rajasthan 302020",
      },
      {
        id: 3,
        source: phone,
        imgAlt: "phone",
        arr: [
          { id: 1, value: "tel:+91 141 403 6138", link: "+91 141 403 6138" },
          { id: 2, value: "tel:+91 637 658 5239", link: "+91 637 658 5239" },
        ],
      },
      {
        id: 4,
        source: mail,
        imgAlt: "mail",
        arr: [
          {
            value: "mailto:contact@scrumdigital.com",
            link: "contact@scrumdigital.com",
          },
        ],
      },
    ],
  },
];
