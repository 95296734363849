import React from "react";
import { Helmet } from "react-helmet";

const HeadMetaSec = ({ HeadSecData }) => {
  return (
    <Helmet>
      <title>{HeadSecData.title}</title>
      <meta name="description" content={HeadSecData.description} />
      <meta name="keywords" content={HeadSecData.keywords} />
      <link rel="canonical" href={`https://scrumdigital.com${HeadSecData.conical}`} />
      {/* <meta property="og:image" content={HeadSecData.ogimg}"/og-logo.png" /> */}
      <meta property="og:image" content={HeadSecData.ogimg?HeadSecData.ogimg:"/og-logo.png"} />
      <meta name="robots" content="index,follow" />
      <meta property="og:url" content={HeadSecData.conical} />
      <meta property="og:title" content={HeadSecData.title} />
      <meta property="og:site_name" content="Scrum Digital" />
      <meta property="og:description" content={HeadSecData.description} />
      <meta property="og:type" content="page" />
      {/* <meta property="og:image" content="" /> */}
      <meta property="og:locale" content="en_us" />

    </Helmet>
  );
};

export default HeadMetaSec;
