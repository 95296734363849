import { FaArrowRightLong } from "react-icons/fa6";

export const FooterFormField = {
  inputFieldData:[
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
      as: "input",
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      as: "input",
    },
    {
      id: 3,
      name: "mobileNumber",
      as: "input",
      required:1,
      custom_class:"footer-phone-field"
    },
    {
      id: 7,
      name: "textarea",
      type: "textarea",
      placeholder: "Brief about the project",
      as: "textarea",
    },
    {
      id:8,
      type:"checkbox",
      pId:"accept_checkbox_footer",
      name:"accept",
      labelTitle:"I accept",
      link:"Terms of Use",
      href:"/terms-of-use",
      target:"_blank"
    },
    {
      id:9,
      type:"checkbox",
      pId:"send_footer",
      name:"send",
      labelTitle:"Send me NDA",
    }
  ],
  btnData:{
    align_center:false,
    btnName :<>Send your Inquiry <i><FaArrowRightLong /></i></>
  }
}
;