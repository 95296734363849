import {
  TrustedByBmw,
  TrustedByGanna,
  TrustedByGoogle,
  TrustedByMakeMyTrip,
  TrustedByNissan,
  TrustedByTanishq,
  TrustedByTata,
  TrustedByUnilever,
} from "../../../assets/img";

export const trustedData = [
  {
    alt: "MakeMyTrip",
    src: TrustedByMakeMyTrip,
  },
  {
    alt: "ganna",
    src: TrustedByGanna,
  },
  {
    alt: "tanishq",
    src: TrustedByTanishq,
  },
  {
    alt: "unilever",
    src: TrustedByUnilever,
  },
  {
    alt: "bmw",
    src: TrustedByBmw,
  },
  {
    alt: "tata",
    src: TrustedByTata,
  },
  {
    alt: "google",
    src: TrustedByGoogle,
  },
  {
    alt: "nissan",
    src: TrustedByNissan,
  },
];
