import React from "react";
import styles from "../../assets/css/delivery-service-banner.module.scss";
import { Container } from "react-bootstrap";

const DeliveryServiceBanner = ({ DeliveryServiceBannerData }) => {
  return (
    <>
      <section className={`${styles.banner}`}>
        <Container className="container-lg">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className={styles.left}>
                <h1>{DeliveryServiceBannerData.heading}</h1>
                {DeliveryServiceBannerData.desc}
                <button className="theme-btn">Get your free quote</button>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.right}>
                <img
                  src={DeliveryServiceBannerData.src}
                  className="img-fluid"
                  alt="bannerImg"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DeliveryServiceBanner;
