import React from "react";
import { Link } from "react-router-dom";
import styles from "../../assets/css/service-of-technology.module.scss";
import { Container } from "react-bootstrap";

const ServicesOfTechnology = ({ services }) => {
  const customColWidth = services.list.length;
  return (
    <>
      <section className={`pdtb ${styles.service_of_technology}`} style={services.bg && services.bg}>
        <Container>
          <div className="row">
            <div className={`${services.col?services.col:"col-lg-10"} mx-auto`}>
              <div className={`sub-heading text-center ${styles.sub_heading}`}>
                <h2 className={services.bg ? "text-white":""}>{services.title}</h2>
                <p className={services.bg ? "text-white":""}>{services.desc}</p>
              </div>
            </div>
          </div>
          <ul className="row">
            {services.list.map((service, index) => (
              <li
                className={`${
                  customColWidth === 2 || customColWidth === 4
                    ? "col-lg-6"
                    : "col-lg-4"
                }  col-md-6 col-12`}
                key={index}
              >
                {!service.href ? (
                  <div className={`${styles.service_list}`}>
                    <img src={service.source} alt={service.alt} />
                    <h2>{service.title}</h2>
                    <p>{service.content}</p>
                  </div>
                ) : (
                  <Link to={service.href} className={`d-block ${styles.service_list}`}>
                    <img src={service.source} alt={service.alt} />
                    <h2>{service.title}</h2>
                    <p>{service.content}</p>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </Container>
      </section>
    </>
  );
};

export default ServicesOfTechnology;
