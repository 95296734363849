import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from '../../assets/css/chatbot-benefits.module.scss'
import { FaRegHandPointRight } from "react-icons/fa";


const WhyChooseUsECommerce = () => {
    return (
        <section className={`pdtb ${styles.chatbot_benefits}`}>
            <Container className="container-lg">
                <Row>
                    <Col lg={6} >
                        <div className="sub-heading-outline">
                            <h2>
                                <span className="text-uppercase">WHY CHOOSE OUR</span> <br /> E-commerce Solutions?
                            </h2>
                            <p>
                                Our team provides tailored ecommerce development solutions, designed to cater
                                to your distinct business and technological needs, guaranteeing seamless
                                execution across diverse models.
                            </p>
                        </div>
                        {/* <div className={`mb-0 ${styles.chatbot_benefits_list}`}>
                            <i><FaRegHandPointRight /></i>
                            <h5>Web Portals</h5>
                            <p>
                                We provide specialized web portals designed for retail businesses to streamline
                                vendor management, customer engagement, brand development, and product catalog display.
                            </p>
                        </div> */}
                    </Col>
                    <Col lg={6}>
                        <div className={styles.chatbot_benefits_right_sec}>
                        <div className={` ${styles.chatbot_benefits_list}`}>
                            <i><FaRegHandPointRight /></i>
                            <h5>Web Portals</h5>
                            <p>
                                We provide specialized web portals designed for retail businesses to streamline
                                vendor management, customer engagement, brand development, and product catalog display.
                            </p>
                        </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>Headless Commerce</h5>
                                <p>
                                Leveraging the latest in eCommerce technology, we craft custom, template-independent
                                designs for websites, mobile applications, and various digital interfaces.
                                </p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>PWA (Progressive Web Apps)</h5>
                                <p>
                                We advocate for Progressive Web Apps (PWAs) as a unified solution for
                                web and mobile platforms, ensuring responsive designs across diverse screen dimensions.
                                </p>
                            </div>
                            <div className={styles.chatbot_benefits_list}>
                                <i><FaRegHandPointRight /></i>
                                <h5>B2C & B2B eCommerce</h5>
                                <p>
                                    We develop instinctive online storefronts that enhance the
                                    shopping experience for B2C consumers and simplify intricate
                                    B2B transactions.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default WhyChooseUsECommerce;