import React, { useEffect } from "react";
import DeliveryServiceBanner from "../../components/DeliveryServices/DeliveryServiceBanner";
import TrustedBy from "../../components/DeliveryServices/TrustedBy/TrustedBy";
import FeatureOfOnline from "../../components/DeliveryServices/FeatureOfOnline/FeatureOfOnline";
import OnDemandFoodCta from "../../components/Home/OnDemandFoodCta";
import AdvanceFeatureOnlineRestaurant from "../../components/DeliveryServices/FeatureOnlineRestaurant/FeatureOnlineRestaurant";
import MobileScreenSlider from "../../components/DeliveryServices/MobileScreenSlider/MobileScreenSlider";
import FAQ from "../../components/services/FAQ";
import AppLikeUberEats from "../../components/DeliveryServices/AppLikeUberEats";
import BusinessModel from "../../components/DeliveryServices/BusinessModel/BusinessModel";
import { FoodDeliveryAppDevData } from "../../components/jsonData/FoodDeliveryAppDevData";
import HeadMetaSec from "../../components/HeadMetaSec";

const FoodDeliveryAppDev = () => {
  useEffect(() => {
    const deliveryHeader = document.querySelector(".th-header");
    deliveryHeader.classList.add("delivery-header");
    return () => deliveryHeader.classList.remove("delivery-header");
  }, []);
  const ctaContent={
    ctaHeading:<>From concept to code, <span>we've got you covered.</span></>,
     btnName:"Begin Your App Adventure!"
  }
  return (
    <>
      <HeadMetaSec HeadSecData={FoodDeliveryAppDevData.helmetSec} />
      <DeliveryServiceBanner
        DeliveryServiceBannerData={FoodDeliveryAppDevData.bannerSec}
      />
      <TrustedBy />
      <AppLikeUberEats />
      <BusinessModel />
      <FeatureOfOnline />
      <OnDemandFoodCta {...ctaContent} />
      <AdvanceFeatureOnlineRestaurant
        onlineRestaurant={FoodDeliveryAppDevData.onlineRestaurantSec}
      />
      <MobileScreenSlider
        mobileSliderData={FoodDeliveryAppDevData.MobileScreenSliderSec}
      />
      <FAQ faqData={FoodDeliveryAppDevData.faqSec} />
    </>
  );
};

export default FoodDeliveryAppDev;
