import React from "react";
import styles from "../../../assets/css/fantasy-feature.module.scss";
import {
  AdminPanelImg,
  AdvanceFeatureImg,
  UserPanelImg,
} from "../../../assets/img";

export const RightImgDesign = (props) => {
  return (
    <div className={styles.right_img_design}>
      <img src={props.src} alt={props.alt} className="img-fluid" />
    </div>
  );
};

export const LeftAdminDesign = (props) => {
  return (
    <div className={styles.left_admin_design}>
      <div className={styles.admin_img_sec}>
        <img src={props.src} alt={props.alt} className="img-fluid" />
      </div>
    </div>
  );
};
