import React from "react";
import ContactBanner from "../../components/Banner/ContactBanner";
import ContactForm from "../../components/Contact_us/ContactForm";
import ContactMail from "../../components/Contact_us/ContactMail";
import OfficeAddress from "../../components/Contact_us/OfficeAddress/OfficeAddress";
import HeadMetaSec from "../../components/HeadMetaSec";
import { ContactUsPageData } from "../../components/jsonData/ContactUsPageData";

function ContactUs() {
  return (
    <>
      <HeadMetaSec HeadSecData={ContactUsPageData.helmetSec} />
      <ContactBanner bannerData={ContactUsPageData.bannerSec} />
      <ContactForm />
      <OfficeAddress />
      <ContactMail />
    </>
  );
}

export default ContactUs;
