
import { HmgAboutApps, HmgAboutCalendar, HmgAboutCustomerSatisfaction, HmgAboutExperts1, HmgAboutIcon2, aboutUsProviding } from "../../assets/img";
import HmgAboutList from "../Home/HmgAbout/HmgAboutList";
import styles from "./../../assets/css/Provide.module.scss";


const Providing = (provide) => {
  const data = {
    mainImgAlt:"about us Providing IT Solutions",
    imgSrc:aboutUsProviding,
    heading:<> Providing IT Solutions for your Dynamic <span>Business Demands</span></>,
    className: `${styles.sub_heading} sub-heading`,
    desc:<>
      <p className="page-main-para">
            We are one of the fastest growing IT, consulting and digital
            technology solutions company headquartered in the USA with a sales
            and support facilitation office in India. We deliver cutting-edge
            next generation technology solutions to businesses of all sizes,
            from growing start-ups to large enterprises, strengthening their
            value and shaping their powerful digital future. Over the years, we
            have helped clients solve their business challenges and stay ahead
            of the competition by embracing the latest technologies. We
            constantly strive to be uniquely positioned to drive digital
            transformations and creating greater value for all our clients.
            Partner with us to unlock the best digital possibilities for your
            business.
        </p>
    </>,
    // list:[
    //   {
    //     id:1,
    //     title:"3+",
    //     imgSrc:HmgAboutCalendar,
    //     desc:"Years of Industry Experience",
    //     alt:"Experience"
    //   },
    //   {
    //     id:2,
    //     title:"50+",
    //     imgSrc:HmgAboutIcon2,
    //     desc:"Happy Clients",
    //     alt:"Happy Clients"
    //   },

    //   {
    //     id:3,
    //     title:"150+",
    //     imgSrc:HmgAboutApps,
    //     desc:"Project Delivered",
    //     alt:"Project delivered"
    //   },
    //   {
    //     id:4,
    //     title:"35+",
    //     imgSrc:HmgAboutExperts1,
    //     desc:"Senior and Expert Staff",
    //     alt:"senior staff"
    //   },
    // ]
    list: [
      {
        id: 1,
        title: "5+",
        imgSrc: HmgAboutCalendar,
        desc: "Years of Experience",
        alt: "Experience"
      },
      {
        id: 2,
        title: "70+",
        imgSrc: HmgAboutIcon2,
        desc: "Clients",
        alt: "clients"
      },

      {
        id: 3,
        title: "100+",
        imgSrc: HmgAboutApps,
        desc: "Project Delivered",
        alt: "Project Delivered"
      },
      {
        id: 4,
        title: "35+",
        imgSrc: HmgAboutExperts1,
        desc: "Senior Experts",
        alt: "Experts"
      },
      {
        id: 5,
        title: "100%",
        imgSrc: HmgAboutCustomerSatisfaction,
        desc: "Customer Satisfaction",
        alt: "Customer Satisfaction"
      },
    ]
  }
  return (
    <section className={`pdtb ${styles.provide_business}`}>
      <div className="container">
        <HmgAboutList {...data}/>
      </div>
    </section>
  );
};
export default Providing;
