import {
  OnDemandDeliveryBanner,
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
} from "../../assets/img";

export const OnDemandAppData = {
  helmetSec: {
    title:
      "On Demand Delivery App Development Solution: Food, Grocery, Medicine & More",
    description:
      "Scrum Digital's custom on-demand delivery app development solution offers seamless order placement, real-time tracking, and more. Contact us today!",
    keywords: "",
    conical:
      "/services/delivery-app-development-company",
  },
  bannerSec: {
    heading: (
      <>
        On-Demand Delivery <span>App Development Solution</span>
      </>
    ),
    desc: (
      <>
        <p>
          We specialize in custom on-demand delivery app development for
          businesses of all sizes. Partner with us to enhance your delivery
          operations, improve customer experience, and stay ahead of the
          competition in industries such as food, grocery, logistics, and more.
        </p>
        <p>Contact us to unlock the power of an on-demand delivery app.</p>
      </>
    ),
    src: OnDemandDeliveryBanner,
  },
  onlineRestaurantSec: {
    desc: "Optimize your restaurant app with advanced features that streamline operations and enhance customer experience.",
  },
  MobileScreenSliderSec: {
    heading: (
      <>
        {" "}
        Check Out The <span>Latest Designs</span> Of Our Food Delivery App
        Solutions
      </>
    ),
    desc: "Our visually appealing and user-friendly designs ensure a seamless online ordering experience for customers, drivers, and restaurant partners.",
    screenList: [
      {
        alt: "screen",
        src: Screen1,
      },
      {
        alt: "screen",
        src: Screen2,
      },
      {
        alt: "screen",
        src: Screen3,
      },
      {
        alt: "screen",
        src: Screen4,
      },
      {
        alt: "screen",
        src: Screen5,
      },
      {
        alt: "screen",
        src: Screen6,
      },
      {
        alt: "screen",
        src: Screen7,
      },
    ],
  },
  faqSec: {
    title: (
      <>
        <span>FAQ's</span> for Application Development
      </>
    ),
    inner: [
      {
        id: 1,
        question: "What is an on demand delivery app?",
        answer:
          "An on demand delivery app is a digital platform that connects customers with delivery services, allowing them to request and receive products or services on demand.",
      },
      {
        id: 2,
        question: "What are the key features of an on-demand delivery app?",
        answer:
          "Essential features of an on-demand delivery app include customer app, driver app, restaurant app, and admin panel. These apps should have functionalities such as order placement, real-time tracking, payment integration, and ratings/reviews.",
      },
      {
        id: 3,
        question: "How long does it take to develop a delivery app?",
        answer:
          "The development timeline for a delivery app depends on the complexity and customization requirements. To develop a delivery app it can typically range from 2-5 months for a robust and feature-rich solution.",
      },
      {
        id: 4,
        question:
          "Can you customize an on-demand delivery app as per business requirements?",
        answer:
          "Yes, as a leading delivery app development company, we offer custom solutions tailored to your specific business needs. Our team can incorporate unique features, branding, and design elements to make your app stand out.",
      },
      {
        id: 5,
        question: "Can you integrate third-party APIs into the delivery app?",
        answer:
          "Yes, we can integrate third-party APIs for functionalities such as payment gateways, geolocation, SMS notifications, and more to enhance the app's capabilities and user experience.",
      },
      {
        id: 6,
        question:
          "Do you provide ongoing support and maintenance for the delivery app?",
        answer:
          "Yes, we offer comprehensive support and maintenance services post-launch to ensure the smooth functioning of your delivery app. This includes bug fixes, updates, and technical assistance.",
      },
      {
        id: 7,
        question:
          "Is the on-demand delivery app scalable to accommodate business growth?",
        answer:
          "Yes, our on-demand delivery app solution is scalable, allowing you to handle increased user traffic, order volume, and business expansion. We use scalable technologies and architectures to ensure seamless performance as your business grows.",
      },
      {
        id: 8,
        question:
          "Can you develop a delivery app for both Android and iOS platforms?",
        answer:
          "Yes, we provide cross-platform app development services to create delivery apps that are compatible with both Android and iOS platforms, ensuring wider reach and accessibility for your customers.",
      },
    ],
    Note: (
      <>
        <strong>Note:</strong> For specific inquiries or more information,
        please contact us directly.
      </>
    ),
  },
};
