import {
  salespecialist,
  saleplatform,
  saledevelopersell,
  saleservicepath,
  salefieldservices,
  salecertifiedcrm,
  salecertified,
} from "../../../assets/img";

export const SliderData = [
  {
    id: 1,
    src: salespecialist,
    alt: "certificate",
  },
  {
    id: 2,
    src: saleplatform,
    alt: "certificate",
  },
  {
    id: 3,
    src: saledevelopersell,
    alt: "certificate",
  },
  {
    id: 4,
    src: saleservicepath,
    alt: "certificate",
  },
  {
    id: 5,
    src: salefieldservices,
    alt: "certificate",
  },
  {
    id: 6,
    src: salecertifiedcrm,
    alt: "certificate",
  },
  {
    id: 7,
    src: salecertified,
    alt: "certificate",
  },
  {
    id: 8,
    src: salespecialist,
    alt: "certificate",
  },
];
