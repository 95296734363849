import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Footer from './elements/Footer';
import Header from './elements/Header';
import { useState } from 'react';
// import './style.scss';



const MainLayout = () => {
    const [overlayVisible, setOverlayVisible] = useState(false);

    return (
        <>
            <ToastContainer limit={1} />
            <div className={`overlay ${overlayVisible ? "visible" : ""}`}></div>
            <Header setOverLayVisible={setOverlayVisible}/>

            <Outlet />

            <Footer />
        </>
    );
};

export default MainLayout;
