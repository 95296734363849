import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
const ModalFormSubmit = (props) => {
  return (
    <>
      <Modal
        size="md"
        show={props.show}
        onHide={() => props.hide()}
        aria-labelledby="example"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example" className="text-success">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.desc}</Modal.Body>
      </Modal>
    </>
  );
};
export default ModalFormSubmit;
