import React from "react";
import { Container } from "react-bootstrap";
import { SellYourBusinessData } from "./SellYourBusinessData";
import styles from "../../../assets/css/delivery-service-sell-your-business.module.scss";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const SellYourBusiness = () => {
  return (
    <>
      <section className={`pdtb ${styles.sell_your_business}`}>
        <Container>
          <div className="row">
            <div
              className={`col-lg-9 sub-heading mx-auto ${styles.sub_heading}`}
            >
              <h2>
                Experts Develop <span> Delivery App Tailored </span>
                to Sell Your Business
              </h2>
              <p>
                Our expert team develops customized on-demand delivery apps
                designed to boost your business. With tailored solutions, we
                enhance your operations & help you sell with ease.
              </p>
            </div>
          </div>
          <ul className="row">
            {SellYourBusinessData.map((elem, index) => {
              const { src, title, alt, href } = elem;
              return (
                <li key={index} className="col-lg-4 col-sm-6">
                  <div className={styles.Sell_Yr_busi_inner}>
                    <img src={src} alt={alt} />
                    <div className={styles.overlay}>
                      <div>
                        <h3>{title}</h3>
                        <Link to={href}>
                          Book Free Demo{" "}
                          <span>
                            <HiOutlineArrowNarrowRight />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Container>
      </section>
    </>
  );
};

export default SellYourBusiness;
