import {
  AdminPanelScreen,
  CustomerAppScreen,
  DriverAppScreen,
  RestaurantAppScreen,
} from "../../../assets/img";

export const featureOnlineListData = [
  {
    id: 1,
    pid: "list1",
    col: "col-lg-4",
    orderImg: "order-lg-1",
    col2: "col-lg-8",
    orderContent: "order-lg-2",
    imgSource: CustomerAppScreen,
    title: (
      <>
        <span>Customer</span> App
      </>
    ),
    content:
      "The customer app, developed through ScrumDigital, serves as the user-friendly interface for browsing and ordering food from restaurants, with features such as:",
    appLists: [
      "User-friendly interface",
      "Advanced search and filtering options",
      "Secure registration & login features",
      "Customizable profile settings",
      "Real-time tracking of orders",
      "Push notifications for order updates & promotional offers",
    ],
  },
  {
    id: 2,
    pid: "list2",
    col: "col-lg-4",
    orderImg: "order-lg-2",
    col2: "col-lg-8",
    orderContent: "order-lg-1",
    imgSource: DriverAppScreen,
    title: (
      <>
        <span>Driver</span> App
      </>
    ),
    content:
      "The driver app enables efficient food deliveries with order management, push notifications & many more, making it a crucial tool for drivers on the go.",
    appLists: [
      "Driver Profile",
      "Scrum Digital LLC",
      "Real-time order updates & notifications",
      "In-app messaging & calling",
      "Earnings tracking & reporting",
      "Ratings & feedback",
    ],
  },
  {
    id: 3,
    pid: "list3",
    col: "col-lg-4",
    orderContent: "order-lg-2",
    col2: "col-lg-8",
    orderImg: "order-lg-1",
    imgSource: RestaurantAppScreen,
    title: (
      <>
        <span>Restaurant</span> App
      </>
    ),
    content:
      "We prioritize incorporating robust and efficient features for merchants, aimed at maximizing their productivity and ensuring a seamless experience.",
    appLists: [
      "Restaurant Profile",
      "Order management tools",
      "Real-time order updates & notifications",
      "In-app messaging & calling for communication",
      "Live Order tracking & reporting",
      "Ratings & reviews for feedback",
    ],
  },
  {
    id: 3,
    pid: "list4",
    col: "col-lg-6",
    orderImg: "order-lg-2",
    col2: "col-lg-6",
    orderContent: "order-lg-1",
    imgSource: AdminPanelScreen,
    title: (
      <>
        <span>Admin</span> App
      </>
    ),
    content:
      "We offer next-gen bespoke features for admins to ensure smooth and efficient business management for your food delivery venture.",
    appLists: [
      "Dashboard with real-time analytics",
      "Order management tools",
      "Content Management System (CMS)",
      "Payment management",
      "Customer Relationship Management (CRM)",
      "Security features",
    ],
  },
];
